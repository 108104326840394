import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	files: [],
	fileUrl: '',
	isFetching: false,
	fileType: ''
});

export default function employmentContractReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.FILE.UPLOAD_FILE_LOADING: {
			return state.merge({
				isFetching: true
			});
		}

		case TYPE.FILE.UPLOAD_FILE_FAILURE: {
			return state.merge({
				fileUrl: '',
				fileType: 'error',
				isFetching: false
			});
		}

		case TYPE.FILE.UPLOAD_FILE_SUCCESS: {
			return state.merge({
				fileUrl: action.payload,
				fileType: action.fileType,
				isFetching: false
			});
		}

		default:
			return state;
	}
}
