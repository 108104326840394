import { useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';

import ListTable from './ListTable';

function TimeResearch() {
	const [year, setYear] = useState(2023);

	return (
		<Grid
			container
			spacing={2}
			style={{
				overflow: 'auto',
				maxHeight: 'calc(100vh - 164px)',
				width: 'calc(100% + 16px)'
			}}
		>
			<Grid item xs={6}>
				<FormControl style={{ width: 250 }} variant="outlined" size="small">
					<InputLabel id="label">Chọn năm học</InputLabel>
					<Select
						fullWidth
						size="small"
						variant="outlined"
						labelId="label"
						value={year}
						onChange={(e) => {
							console.log(e.target.value);
							setYear(e.target.value);
						}}
						SelectDisplayProps={{ style: { padding: 8, borderRadius: 4 } }}
						MenuProps={{
							getContentAnchorEl: null,
							MenuListProps: { style: { padding: 0 } },
							anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
							transformOrigin: { vertical: 'top', horizontal: 'center' }
						}}
					>
						{[2022, 2023, 2024].map((year) => (
							<MenuItem key={year} value={year}>
								{year} - {year + 1}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<ListTable year={year} />
		</Grid>
	);
}

export default TimeResearch;
