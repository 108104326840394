import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';
import { notification } from '../home/action';

export const getAllDepartments = () => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.getAllDepartments();
	dispatch({
		type: TYPE.DEPARTMENT.GET_ALL_DEPARTMENTS_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.GET_ALL_DEPARTMENTS_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});

		return true;
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.GET_ALL_DEPARTMENTS_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);

		return true;
	}
};

export const getAllFaculties = () => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.getAllFaculties();
	dispatch({
		type: TYPE.DEPARTMENT.GET_ALL_FACULTIES_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.GET_ALL_FACULTIES_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});

		return true;
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.GET_ALL_FACULTIES_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);

		return true;
	}
};

export const getAllVisibleDepartmentsByProfileId = (profileId, role) => async (dispatch) => {
	dispatch({
		type: TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});

	if (role === 'sudo-admin') {
		const api = API_URLS.DEPARTMENT.getAllDepartments();
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch({
				type: TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_SUCCESS,
				payload: response.data.data,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
			});
		} else {
			dispatch({
				type: TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_FAILURE,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
			});
			dispatch(
				notification({
					type: 'error',
					message: 'Lấy dữ liệu thất bại!'
				})
			);
		}
	} else {
		const apiProfileInfo = API_URLS.PROFILE.getProfileInfoByProfileId(profileId);
		const { response: responseProfileInfo, error: errorProfileInfo } = await apiCall({
			...apiProfileInfo
		});

		if (
			!errorProfileInfo &&
			responseProfileInfo.status === 200 &&
			responseProfileInfo.data.success
		) {
			const payload = responseProfileInfo.data.data.departments;

			dispatch({
				type: TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_SUCCESS,
				payload,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
			});
		} else {
			dispatch({
				type: TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_FAILURE,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
			});
			dispatch(
				notification({
					type: 'error',
					message: 'Lấy dữ liệu thất bại'
				})
			);
		}
	}
};

export const insertDepartment = (payload) => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.insertDepartment();
	dispatch({
		type: TYPE.DEPARTMENT.INSERT_DEPARTMENT_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.INSERT_DEPARTMENT_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm dữ liệu thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.INSERT_DEPARTMENT_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Thêm dữ liệu thất bại!'
			})
		);
		return false;
	}

	// if (typeof cleanUp === 'function') {
	//   cleanUp();
	// }
};

export const updateDepartment = (id, payload, cleanUp) => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.updateDepartment(id);
	dispatch({
		type: TYPE.DEPARTMENT.UPDATE_DEPARTMENT_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({
		...api,
		payload
	});
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.UPDATE_DEPARTMENT_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Cập nhật dữ liệu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.UPDATE_DEPARTMENT_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Cập nhật dữ liệu thất bại!'
			})
		);
	}

	if (typeof cleanUp === 'function') {
		cleanUp();
	}
};

export const deleteDepartment = (id) => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.deleteDepartment(id);
	dispatch({
		type: TYPE.DEPARTMENT.DELETE_DEPARTMENT_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.DELETE_DEPARTMENT_SUCCESS,
			id,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Xoá dữ liệu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.DELETE_DEPARTMENT_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Xoá dữ liệu thất bại!'
			})
		);
	}
};

export const getAllProfiles = () => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.getAllProfiles();
	dispatch({
		type: TYPE.DEPARTMENT.GET_ALL_PROFILE_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.GET_ALL_PROFILE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.GET_ALL_PROFILE_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getProfileByDepartmentId = (departmentId) => async (dispatch) => {
	const api = API_URLS.DEPARTMENT.getProfileByDepartmentId(departmentId);
	dispatch({
		type: TYPE.DEPARTMENT.GET_PROFILE_BY_DEPARTMENT_ID_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.GET_PROFILE_BY_DEPARTMENT_ID_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.GET_PROFILE_BY_DEPARTMENT_ID_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const addProfileToDepartment = (payload) => async (dispatch) => {
	// add profile to department, then reload profiles in department if success
	const api = API_URLS.DEPARTMENT.addProfileToDepartment();
	dispatch({
		type: TYPE.DEPARTMENT.INSERT_DEPARTMENT_PROFILE_LOADING,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.DEPARTMENT.INSERT_DEPARTMENT_PROFILE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm thành viên thành công!'
			})
		);
		// action
		dispatch(getProfileByDepartmentId(payload.departmentId));
	} else {
		dispatch({
			type: TYPE.DEPARTMENT.INSERT_DEPARTMENT_PROFILE_FAILURE,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Thêm thành viên thất bại!'
			})
		);
	}
};

export const deleteProfileFromDepartment =
	(departmentId, departmentProfileId) => async (dispatch) => {
		const api = API_URLS.DEPARTMENT.deleteProfileFromDepartment(departmentProfileId);
		dispatch({
			type: TYPE.DEPARTMENT.DELETE_DEPARTMENT_PROFILE_LOADING,
			meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
		});
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch({
				type: TYPE.DEPARTMENT.DELETE_DEPARTMENT_PROFILE_SUCCESS,
				payload: {
					departmentId: departmentId,
					departmentProfileId: departmentProfileId
				},
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
			});
			dispatch(
				notification({
					type: 'success',
					message: 'Xóa thành viên thành công!'
				})
			);
			// action
			dispatch(getProfileByDepartmentId(departmentId));
		} else {
			dispatch({
				type: TYPE.DEPARTMENT.DELETE_DEPARTMENT_PROFILE_FAILURE,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_FAILURE] }
			});
			dispatch(
				notification({
					type: 'error',
					message: 'Xóa thành viên thất bại!'
				})
			);
		}
	};

export const updateDepartmentProfileById =
	(departmentProfileId, departmentId, profileId, roleInDepartment) => async (dispatch) => {
		const api = API_URLS.DEPARTMENT.updateDepartmentProfileById(departmentProfileId);
		const payload = {
			id: departmentProfileId,
			departmentId: departmentId,
			profileId: profileId,
			roleInDepartment: roleInDepartment
		};
		const { response, error } = await apiCall({ ...api, payload });
		if (!error && response.status === 200 && response.data.success) {
			dispatch({
				type: TYPE.DEPARTMENT.UPDATE_DEPARTMENT_PROFILE_SUCCESS,
				payload: response.data.data,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
			});
			dispatch(
				notification({
					type: 'success',
					message: 'Cập nhật dữ liệu thành công!'
				})
			);
		} else {
			dispatch({
				type: TYPE.DEPARTMENT.UPDATE_DEPARTMENT_PROFILE_FAILURE,
				payload: response.data.data,
				meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_SUCCESS] }
			});
			dispatch(
				notification({
					type: 'error',
					message: 'Cập nhật dữ liệu thất bại!'
				})
			);
		}
	};

export const setSelectedDepartmentId = (id) => async (dispatch) => {
	dispatch({
		type: 'SET_SELECTED_DEPARTMENT_ID',
		id,
		meta: { prefix: [PREFIX.DEPARTMENT, PREFIX.API_CALLING] }
	});
};
