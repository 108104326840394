import React, { useState } from 'react';
import { Tab, Card, Tabs } from '@material-ui/core';

import RankTableTable from '../../containers/AdminSetting/RankTable/RankTableTable';
import RankTableButton from '../../containers/AdminSetting/RankTable/RankTableButton';
import ResearchTimeTable from '../../containers/AdminSetting/ResearchTime/ResearchTimeTable';
import ResearchTimeButton from '../../containers/AdminSetting/ResearchTime/ResearchTimeButton';
import EarningsTableTable from '../../containers/AdminSetting/EarningsTable/EarningsTableTable';
import EarningsTableButton from '../../containers/AdminSetting/EarningsTable/EarningsTableButton';
import RewardInformationV2 from '../../containers/AdminSetting/RewardInformation/RewardInformationV2/RewardInformationV2';

import theme from '../../theme';
import RewardInformationButton from '../../containers/AdminSetting/RewardInformation/RewardInformationButton/RewardInformationButton';

function AdminSetting() {
	const [value, setValue] = useState(0);

	const handleChange = (_, newValue) => setValue(newValue);

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				style={{ padding: theme.spacing(1) }}
				{...other}
			>
				{value === index && children}
			</div>
		);
	}

	return (
		<Card
			style={{ padding: 8, minHeight: 'calc(100vh - 108px)', maxHeight: 'calc(100vh - 108px)' }}
		>
			<Tabs
				centered
				value={value}
				onChange={handleChange}
				style={{ marginBottom: theme.spacing(3) }}
			>
				<Tab label="Bảng tính giờ nghiên cứu" />
				<Tab label="Quy định mức thưởng" />
				<Tab label="Bảng tính rank tạp chí" />
				<Tab label="Bảng tính lương" />
			</Tabs>

			<TabPanel value={value} index={0}>
				<ResearchTimeButton />
				<ResearchTimeTable />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<RewardInformationButton />
				<RewardInformationV2 />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<RankTableButton />
				<RankTableTable />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<EarningsTableButton />
				<EarningsTableTable />
			</TabPanel>
		</Card>
	);
}

export default AdminSetting;
