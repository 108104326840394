import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Button,
	Divider,
	Typography,
	TextField,
	makeStyles,
	createStyles,
	MenuItem
} from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import {
	getRewardInformationV2,
	updateTypeFilter
} from '../../../../reducer/rewardInformation/action';
import { REWARD_V2_TYPES } from '../../../../constants/selectOptions';
import RewardInformationInsertModal from '../RewardInformationInsertModal';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			maxHeight: 300
		}
	})
);

function RewardInformationButton(props) {
	const { typeFilter, listV2, getRewardInformationV2, updateTypeFilter } = props;
	const classes = useStyles();
	const [insertingType, setInsertingType] = useState(false);
	const [deletingType, setDeletingType] = useState(false);

	useEffect(() => {
		const checkIndex = props.listV2?.findIndex((e) => e.type === props.typeFilter);
		if (checkIndex < 0 && props.listV2.length > 0) {
			props.updateTypeFilter(props.listV2[0]?.type);
		}
	}, [props.listV2]);

	useEffect(() => {
		getRewardInformationV2();
	}, []);

	const handleReload = () => getRewardInformationV2();
	const handleInsertType = () => setInsertingType(true);
	const handleDeleteType = () => setDeletingType(true);

	return (
		<>
			<Grid container>
				<Grid item xs={3}>
					<Typography variant="h5" style={{ lineHeight: '36px' }}>
						Quy định mức thưởng
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								select
								fullWidth
								size="small"
								variant="outlined"
								value={typeFilter}
								label="Chọn loại mức thưởng"
								onChange={(e) => {
									updateTypeFilter(e.target.value);
									getRewardInformationV2();
								}}
								SelectProps={{
									MenuProps: {
										classes: { list: classes.root },
										anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
										transformOrigin: { vertical: 'top', horizontal: 'center' },
										getContentAnchorEl: null
									}
								}}
							>
								{REWARD_V2_TYPES.map((e, index) => {
									return (
										<MenuItem key={index} value={e.value}>
											{e.name}
										</MenuItem>
									);
								})}
							</TextField>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="outlined"
								color="secondary"
								fullWidth
								onClick={handleDeleteType}
								style={{ padding: '7px 0' }}
							>
								Xoá dữ liệu
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button variant="outlined" startIcon={<Replay />} onClick={handleReload}>
						Tải lại dữ liệu
					</Button>
					<Button
						variant="contained"
						color="primary"
						startIcon={<Add />}
						onClick={handleInsertType}
						style={{ marginLeft: 8 }}
					>
						Thêm mức thưởng
					</Button>
				</Grid>
			</Grid>
			<Divider style={{ margin: '8px 0' }} />

			<RewardInformationInsertModal
				inserting={insertingType}
				setInserting={setInsertingType}
				handleReload={handleReload}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	typeFilter: select(state, 'rewardInformationReducer', 'typeFilter'),
	listV2: select(state, 'rewardInformationReducer', 'listV2')
});

const mapDispatchToProps = (dispatch) => ({
	getRewardInformationV2: () => dispatch(getRewardInformationV2()),
	updateTypeFilter: (typeFilter) => dispatch(updateTypeFilter(typeFilter))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(RewardInformationButton));
