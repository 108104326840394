import React from 'react';
import { Box, Tab, Chip, Grid, Tabs, Badge, Avatar, Divider, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import Patent from '../../../components/Patent';
import Project from '../../../components/Project';
import Solution from '../../../components/Solution';
import Monograph from '../../../components/Monograph';
import ScientificResearch from '../../../components/ScientificResearch';

import { statisticUnaccepted } from '../../../reducer/profile/action';
import ContractProfile from '../../User/ContractProfile/ContractProfile';
import { resetPages } from '../../../reducer/home/action';
import noImage from '../../../assets/noimage.png';

function WorkTab(props) {
	const [value, setValue] = React.useState(0);
	const [count, setCount] = React.useState({});

	const list = window.location.href.split('/');
	const lastValueList = list[list.length - 1].split('=');
	const selectedProfileId =
		lastValueList.length > 1 ? lastValueList[lastValueList.length - 1] : null;

	const handleChange = (_, newValue) => setValue(newValue);
	const handleReload = () => {
		if (parseInt(selectedProfileId) !== props.profile?.id) {
			props.statisticUnaccepted({ departmentId: 0, profileId: props.profile.id, other: true });
		}
	};

	const TabPanel = (props) => {
		const { children, value, index, ...other } = props;
		return (
			<div
				{...other}
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
			>
				{value === index && children}
			</div>
		);
	};

	React.useEffect(() => {
		const temp = props.statisticUnacceptedDetail;
		const countScientificResearch =
			temp?.numJournal +
			temp?.numDomesticJournal +
			temp?.numConference +
			temp?.numDomesticConference +
			temp?.numBook +
			temp?.numOther;
		const countSolution = temp?.numSolution;
		const countPatent = temp?.numPatent;
		const countContract = temp?.numProductContract;
		const countProject = temp?.numResearchProject;
		const countMonograph = temp?.numMonograph;
		setCount({
			scientificResearch: countScientificResearch,
			solution: countSolution,
			patent: countPatent,
			contract: countContract,
			project: countProject,
			monograph: countMonograph
		});
	}, [props.statisticUnacceptedDetail]);

	const currentProfile = props.listProfile.find(
		(e) => e.id === parseInt(selectedProfileId || props.profile?.id)
	);

	return (
		<>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item xs={10}>
					<Tabs
						value={value}
						scrollButtons="off"
						variant="scrollable"
						textColor="secondary"
						indicatorColor="secondary"
						onChange={handleChange}
					>
						{props.tabList.map((tab) => (
							<Tab
								key={tab.key}
								label={
									props.role === 'sudo-admin' ? (
										<Badge
											color="error"
											className="work-tab-badge"
											badgeContent={count[tab.key] || 0}
										>
											{tab.title}
										</Badge>
									) : (
										<>{tab.title}</>
									)
								}
								style={{ padding: '0 24px' }}
							/>
						))}
					</Tabs>
				</Grid>
				<Grid item xs={2}>
					<Tooltip title="Ấn vào để xem hợp đồng nhân sự">
						<Chip
							size="medium"
							color="primary"
							variant={value === 5 ? 'default' : 'outlined'}
							label={<b>{`Hợp đồng: ${currentProfile?.name}`}</b>}
							avatar={
								<Avatar
									alt={currentProfile?.name?.split(' ')[-1]?.toUpperCase()}
									src={
										currentProfile?.avatar
											? `${process.env.REACT_APP_DOMAIN}${currentProfile?.avatar}`
											: noImage
									}
								/>
							}
							style={{ float: 'right', cursor: 'pointer', marginRight: 16 }}
							onClick={() => setValue(5)}
						/>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			<TabPanel value={value} index={0}>
				<ScientificResearch adminTab={false} handleReload={handleReload} other />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Solution handleReload={handleReload} other />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Patent handleReload={handleReload} other />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Project handleReload={handleReload} other />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<Monograph handleReload={handleReload} other />
			</TabPanel>
			<TabPanel value={value} index={5}>
				<ContractProfile profileInfo={currentProfile} />
			</TabPanel>
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	listProfile: select(state, 'profileReducer', 'list'),
	listPatentById: select(state, 'patentReducer', 'listById'),
	listProjectById: select(state, 'projectReducer', 'listById'),
	listSolutionById: select(state, 'solutionReducer', 'listById'),
	listContractById: select(state, 'contractReducer', 'listById'),
	listMonographById: select(state, 'monographReducer', 'listById'),
	statisticUnacceptedOther: select(state, 'profileReducer', 'statisticUnacceptedOther'),
	statisticUnacceptedDetail: select(state, 'profileReducer', 'statisticUnacceptedDetail'),
	listScientificResearchById: select(state, 'scientificResearchReducer', 'listById')
});

const mapDispatchToProps = (dispatch) => ({
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	resetPages: () => dispatch(resetPages())
});

const areEqual = (prevProps, nextProps) =>
	prevProps?.address === nextProps?.address &&
	prevProps?.listProfile === nextProps?.listProfile &&
	prevProps.statisticUnacceptedDetail?.numJournal ===
		nextProps.statisticUnacceptedDetail?.numJournal &&
	prevProps.statisticUnacceptedDetail?.numDomesticJournal ===
		nextProps.statisticUnacceptedDetail?.numDomesticJournal &&
	prevProps.statisticUnacceptedDetail?.numConference ===
		nextProps.statisticUnacceptedDetail?.numConference &&
	prevProps.statisticUnacceptedDetail?.numDomesticConference ===
		nextProps.statisticUnacceptedDetail?.numDomesticConference &&
	prevProps.statisticUnacceptedDetail?.numBook === nextProps.statisticUnacceptedDetail?.numBook &&
	prevProps.statisticUnacceptedDetail?.numOther === nextProps.statisticUnacceptedDetail?.numOther &&
	// && prevProps.statisticUnacceptedDetail?.numWorkingPaper === nextProps.statisticUnacceptedDetail?.numWorkingPaper
	prevProps.statisticUnacceptedDetail?.numSolution ===
		nextProps.statisticUnacceptedDetail?.numSolution &&
	prevProps.statisticUnacceptedDetail?.numPatent ===
		nextProps.statisticUnacceptedDetail?.numPatent &&
	prevProps.statisticUnacceptedDetail?.numProductContract ===
		nextProps.statisticUnacceptedDetail?.numProductContract &&
	prevProps.statisticUnacceptedDetail?.numResearchProject ===
		nextProps.statisticUnacceptedDetail?.numResearchProject &&
	prevProps.statisticUnacceptedDetail?.numMonograph ===
		nextProps.statisticUnacceptedDetail?.numMonograph &&
	prevProps.statisticUnacceptedOther?.numJournal ===
		nextProps.statisticUnacceptedOther?.numJournal &&
	prevProps.statisticUnacceptedOther?.numDomesticJournal ===
		nextProps.statisticUnacceptedOther?.numDomesticJournal &&
	prevProps.statisticUnacceptedOther?.numConference ===
		nextProps.statisticUnacceptedOther?.numConference &&
	prevProps.statisticUnacceptedOther?.numDomesticConference ===
		nextProps.statisticUnacceptedOther?.numDomesticConference &&
	prevProps.statisticUnacceptedOther?.numBook === nextProps.statisticUnacceptedOther?.numBook &&
	prevProps.statisticUnacceptedOther?.numOther === nextProps.statisticUnacceptedOther?.numOther &&
	// && prevProps.statisticUnacceptedOther?.numWorkingPaper === nextProps.statisticUnacceptedOther?.numWorkingPaper
	prevProps.statisticUnacceptedOther?.numSolution ===
		nextProps.statisticUnacceptedOther?.numSolution &&
	prevProps.statisticUnacceptedOther?.numPatent === nextProps.statisticUnacceptedOther?.numPatent &&
	prevProps.statisticUnacceptedOther?.numProductContract ===
		nextProps.statisticUnacceptedOther?.numProductContract &&
	prevProps.statisticUnacceptedOther?.numResearchProject ===
		nextProps.statisticUnacceptedOther?.numResearchProject &&
	prevProps.statisticUnacceptedOther?.numMonograph ===
		nextProps.statisticUnacceptedOther?.numMonograph &&
	prevProps.listPatentById.length === nextProps.listPatentById.length &&
	prevProps.listProjectById.length === nextProps.listProjectById.length &&
	prevProps.listSolutionById.length === nextProps.listSolutionById.length &&
	prevProps.listContractById.length === nextProps.listContractById.length &&
	prevProps.listMonographById.length === nextProps.listMonographById.length &&
	prevProps.listScientificResearchById.length === nextProps.listScientificResearchById.length;

export default connect(mapStateToProps, mapDispatchToProps)(toJs(React.memo(WorkTab, areEqual)));
