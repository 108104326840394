import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Divider, MenuItem, TextField, CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getFilteredScientificResearch } from '../../../reducer/home/action';

function ListScientificResearch(props) {
	const [filter, setFilter] = useState('scientificResearchJournal');

	useEffect(() => {
		if (props.haveDepartmentsList) {
			props.getFilteredScientificResearch(1, -1, {
				dashboardYear: props.chosenYear,
				departmentId: props.chosenDepartmentId,
				acceptedOne: true
			});
		}
	}, [props.haveDepartmentsList, props.chosenYear, props.chosenDepartmentId]);

	const getArticleTitle = (info, index) => {
		let rank = '';
		let impactFactor = '';
		let impactFactorFiveYear = '';

		const doi = info.scientificResearch?.doi || '';
		const title = info.scientificResearch?.title || '';
		const author = info.scientificResearch?.listAuthor?.join(', ') || '';
		const journal =
			info.journal?.title || info.journalDomestic?.title || info.conference?.title || '';

		if (info.journal) {
			rank = info.journal?.coreCollection === 'Scopus' ? 'Scopus' : info.journal.sjrBestQuartile;
			impactFactor = info.journal?.impactFactor || '';
			impactFactorFiveYear = info.journal?.impactFactorFiveYear || '';
		}

		if (info.journalDomestic) rank = `Điểm GS-PGS ${info.journalDomestic.score}`;

		if (info.conference) {
			if (info.conference.level === 'domestic') rank = `Điểm GS-PGS ${info.conference.type}`;
			else rank = `${info.conference.rank}`;
		}

		return (
			<Grid item xs={12}>
				<div
					key={`sr-${index}`}
					style={{ display: 'flex', flexDirection: 'row', textAlign: 'justify' }}
				>
					<p style={{ marginRight: 5, color: 'gray' }}>{index + 1}.</p>
					<p style={{ width: 'calc(100% - 26px)' }}>
						<span style={{ color: 'gray' }}>{author}, </span>
						<i style={{ color: 'red' }}>{`"${title}"`} </i>
						<b>{journal}.</b>

						<br />

						{doi === '' &&
						rank === '' &&
						impactFactor === '' &&
						impactFactorFiveYear === '' ? null : (
							<span style={{ color: 'gray' }}>
								<span>[</span>
								{doi === '' ? (
									<span></span>
								) : (
									<>
										<a
											href={doi}
											target="_blank"
											rel="noreferrer"
											style={{ width: 'calc(100% - 26px)' }}
										>
											{doi}
										</a>
										<span>; </span>
									</>
								)}
								{rank === '' ? <span></span> : <b style={{ color: 'black' }}>{rank}; </b>}
								{impactFactor === '' ? <span></span> : <span>***IF: {impactFactor}***; </span>}
								{impactFactorFiveYear === '' ? (
									<span></span>
								) : (
									<span>***IF(5 year): {impactFactorFiveYear}***</span>
								)}
								<span>]</span>
							</span>
						)}
					</p>
				</div>
			</Grid>
		);
	};

	const listFilter = [
		{
			value: 'scientificResearchJournal',
			type: 'scientificResearchJournal',
			title: 'Tạp chí quốc tế'
		},
		{
			value: 'scientificResearchJournalDomestic',
			type: 'scientificResearchJournalDomestic',
			title: 'Tạp chí trong nước'
		},
		{
			value: 'scientificResearchConference',
			type: 'scientificResearchConference',
			title: 'Hội thảo quốc tế'
		},
		{
			value: 'scientificResearchConferenceDomestic',
			type: 'scientificResearchConferenceDomestic',
			title: 'Hội thảo trong nước'
		},
		{ value: 'chapterJournal', type: 'chapterJournal', title: 'Chương sách' },
		{ value: 'scientificResearchOther', type: 'scientificResearchOther', title: 'Tạp chí khác' },
		{ value: 'all', type: 'all', title: 'Tất cả' }
	];

	let listFiltered = props.listScientificResearchAccepted.filter(
		(item) => item.scientificResearch.accepted
	);
	// // console.log(filter, listFiltered)
	if (filter !== 'all') {
		listFiltered = listFiltered.filter(
			(item) => item.scientificResearch?.type === filter && item.scientificResearch?.purposeId !== 4
		);
		// if (filter === 'scientificResearchJournal') listFiltered = listFiltered.filter(item => item.journal && item.scientificResearch?.purposeId !== 4);
		// if (filter === 'scientificResearchJournalDomestic') listFiltered = listFiltered.filter(item => item.scientificResearch === && item.scientificResearch?.purposeId !== 4);
		// if (filter === 'scientificResearchConference') listFiltered = listFiltered.filter(item => item.conference?.level === "international" && item.scientificResearch?.purposeId !== 4);
		// if (filter === 'scientificResearchConferenceDomestic') listFiltered = listFiltered.filter(item => item.conference?.level === "domestic" && item.scientificResearch?.purposeId !== 4);
		// if (filter === 'chapterJournal') listFiltered = listFiltered.filter(item => item.scientificResearch?.type === "book" && item.scientificResearch?.purposeId !== 4);
		// if (filter === 'scientificResearchOther') listFiltered = [];
	}

	return (
		<>
			<Grid item xs={12} md={6}>
				<TextField
					select
					fullWidth
					value={filter}
					variant="outlined"
					size="small"
					SelectProps={{
						MenuProps: {
							getContentAnchorEl: null,
							anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
							transformOrigin: { vertical: 'top', horizontal: 'center' }
						}
					}}
					onChange={(e) => setFilter(e.target.value)}
				>
					{listFilter.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.title}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12}>
				<Divider
					style={{
						marginLeft: -16,
						backgroundColor: '#fff',
						width: 'calc(100% + 32px)',
						boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 3px'
					}}
				/>
			</Grid>
			{props.isFetching ? (
				<CircularProgress style={{ margin: '64px 0' }} />
			) : (
				<Grid item xs={12} style={{ height: '100%' }}>
					<Box
						paddingRight={1}
						marginTop={-2}
						height="calc(100vh - 214px)"
						minWidth="calc(100% + 8px)"
						style={{ overflowY: 'auto' }}
					>
						{listFiltered.map((item, index) => (
							<React.Fragment key={`article-${index}`}>
								{getArticleTitle(item, index)}
							</React.Fragment>
						))}
					</Box>
				</Grid>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	profile: select(state, 'profileReducer', 'detail'),
	listScientificResearchAccepted: select(state, 'homeReducer', 'dataScientificResearch'),
	isFetching: select(state, 'homeReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	getFilteredScientificResearch: (page, pageSize, payload) =>
		dispatch(getFilteredScientificResearch(page, pageSize, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ListScientificResearch));
