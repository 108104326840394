import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	list: [],
	isFetching: false
});

const sortFunc = (a, b) => a.id - b.id;

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.INTERNAL_RESEARCH.GET_ALL_INTERNAL_RESEARCH_LOADING:
		case TYPE.INTERNAL_RESEARCH.INSERT_INTERNAL_RESEARCH_LOADING:
		case TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_LOADING:
		case TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_STATUS_LOADING:
		case TYPE.INTERNAL_RESEARCH.DELETE_INTERNAL_RESEARCH_LOADING:
		case TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.INTERNAL_RESEARCH.GET_ALL_INTERNAL_RESEARCH_FAILURE:
		case TYPE.INTERNAL_RESEARCH.INSERT_INTERNAL_RESEARCH_FAILURE:
		case TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_FAILURE:
		case TYPE.INTERNAL_RESEARCH.DELETE_INTERNAL_RESEARCH_FAILURE:
		case TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_STATUS_FAILURE:
		case TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.INTERNAL_RESEARCH.GET_ALL_INTERNAL_RESEARCH_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [...(action.payload || [])].sort(sortFunc)
			});

		case TYPE.INTERNAL_RESEARCH.INSERT_INTERNAL_RESEARCH_SUCCESS: {
			return state.merge({
				isFetching: false,
				list: [...state.get('list'), action.payload].sort(sortFunc)
			});
		}

		case TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [
					...state.get('list').filter((e) => e?.id !== action.payload?.id),
					action.payload
				].sort(sortFunc)
			});

		case TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_STATUS_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [
					...state.get('list').filter((e) => e?.id !== action?.id),
					{ ...state.get('list').find((e) => e?.id === action?.id), status: action.status }
				].sort(sortFunc)
			});

		case TYPE.INTERNAL_RESEARCH.DELETE_INTERNAL_RESEARCH_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [...state.get('list')].filter((e) => e.id !== action.id)
			});

		case TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [...(action.payload || [])].sort(sortFunc)
			});

		default:
			return state;
	}
}
