import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Chip, Tooltip, TextField, Typography, InputAdornment } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTable from '../../../components/KPTable';
import ProjectDeleteModal from '../ProjectDeleteModal';
import ProjectUpdateModal from '../ProjectUpdateModal';

import { statisticUnaccepted } from '../../../reducer/profile/action';
import { acceptProject, updateProject } from '../../../reducer/project/action';

function ProjectTable(props) {
	const [editting, setEditting] = useState(null);
	const [updating, setUpdating] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleUpdate = (e) => setUpdating(e);
	const handleEditYear = (e) => setEditting(e);

	const handleDelete = (event, e) => {
		setDeleting(e.id);
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (value) => setEditting({ ...editting, dashboardYear: value });

	const handleAccept = (e) => {
		props.acceptProject(e.id, !e.accepted).then((success) => {
			if (success) {
				props.statisticUnaccepted({ departmentId: 0, profileId: props.profile?.id });
				if (props.role === 'sudo-admin')
					props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
			}
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		props.updateProject(editting.id, editting);
		setEditting(null);
	};

	const handleClear = () => {
		props.updateProject(editting.id, { ...editting, dashboardYear: null });
		setEditting(null);
	};

	const list = [];
	if (props.type === 'all') props.listProject.forEach((e) => list.push(e.researchProject));
	else props.listProjectById.forEach((e) => list.push(e.researchProject));

	return (
		<>
			<KPTable
				shadow="none"
				rowsPerPage={10}
				maxHeight="calc(100vh - 288px)"
				columns={[
					{
						title: <b>Tên đề tài</b>,
						key: 'title',
						width: props.role === 'sudo-admin' ? '20%' : '30%',
						sortable: true,
						searchable: true
					},
					{
						title: <b>Xếp loại</b>,
						key: 'type',
						width: '20%',
						filters: [
							{
								text: 'Cơ bản',
								value: 'basic'
							},
							{
								text: 'Ứng dụng',
								value: 'application'
							}
						],
						onFilter: (value, row) => row.filter((e) => value === e.type),
						render: (value) =>
							value === 'basic' ? 'Cơ bản' : value === 'application' ? 'Ứng dụng' : ''
					},
					{
						title: <b>Giá trị</b>,
						key: 'value',
						width: '15%',
						render: (value) => `${value} VND`
					},
					props.role === 'sudo-admin' && props.type === 'all'
						? {
								title: <b>Năm hiển thị</b>,
								key: 'dashboardYear',
								width: '12%',
								searchable: true,
								render: (value, row) =>
									row?.id !== editting?.id ? (
										<Tooltip title="Click để chỉnh sửa năm">
											<div style={{ cursor: 'pointer' }} onClick={() => handleEditYear(row)}>
												{value > 0 ? value : 'Chưa có năm hiển thị'}
											</div>
										</Tooltip>
									) : (
										<form onSubmit={handleSubmit}>
											<TextField
												fullWidth
												size="small"
												type="tel"
												variant="outlined"
												value={editting?.dashboardYear || ''}
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															style={{ cursor: 'pointer' }}
															onClick={handleClear}
														>
															<Clear style={{ fontSize: 18 }} />
														</InputAdornment>
													)
												}}
												onChange={(e) => handleChange(parseInt(e.target.value))}
											/>
										</form>
									)
							}
						: {
								key: 'dashboardYear',
								width: '0%',
								render: () => null
							},
					{
						title: <b>Trạng thái</b>,
						key: 'accepted',
						width: '15%',
						render: (value, row) => {
							if (row.purposeId === 4) {
								return (
									<Chip size="small" color={'primary'} label={'Đề tài cũ'} variant="outlined" />
								);
							}
							return (
								<Chip
									size="small"
									color={value ? 'primary' : 'default'}
									label={value ? 'Đã được duyệt' : 'Chưa được duyệt'}
									variant="outlined"
								/>
							);
						},
						type: 'include',
						filters: [
							{
								text: 'Đã được duyệt',
								value: true
							},
							{
								text: 'Chưa được duyệt',
								value: false
							}
						],
						onFilter: (value, row) => row.filter((e) => value.includes(e.accepted))
					},
					{
						title: <b>Tác vụ</b>,
						key: 'action',
						width: '20%',
						render: (value, row) =>
							row?.id !== editting?.id ? (
								<>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="primary"
										onClick={() => handleUpdate(row)}
									>
										Chi tiết
									</Typography>
									{row.locked ? (
										<Typography
											className="table-action-buton"
											variant="caption"
											color="textPrimary"
										>
											{`Đã khóa ${
												(row.locked === 'kpi' && 'KPI') ||
												(row.locked === 'reward' && 'thưởng') ||
												(row.locked === 'timeResearch' && 'giờ nghiên cứu')
											}`}
										</Typography>
									) : (
										<>
											{(props.type !== 'all' || props.role === 'sudo-admin') && (
												<Typography
													className="table-action-buton"
													variant="caption"
													color="secondary"
													onClick={(event) => handleDelete(event, row)}
												>
													Xoá
												</Typography>
											)}
											{props.role === 'sudo-admin' &&
												props.filter !== 'workingPaper' &&
												row.purposeId !== 4 && (
													<>
														<Typography
															className="table-action-buton"
															variant="caption"
															color="primary"
															onClick={(event) => handleAccept(row)}
														>
															{row.accepted ? 'Hủy xác nhận' : 'Xác nhận'}
														</Typography>
													</>
												)}
										</>
									)}
								</>
							) : (
								<>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="primary"
										onClick={handleSubmit}
									>
										Lưu
									</Typography>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="secondary"
										onClick={() => setEditting(null)}
									>
										Huỷ
									</Typography>
								</>
							)
					}
				]}
				rows={list.sort((a) => (!a.accepted ? -1 : 1))}
				loading={props.isFetching}
			/>

			<ProjectUpdateModal adminTab={props.adminTab} updating={updating} setUpdating={setUpdating} />
			<ProjectDeleteModal deleting={deleting} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	isFetching: select(state, 'projectReducer', 'isFetching'),
	listProject: select(state, 'projectReducer', 'listFiltered'),
	listProjectById: select(state, 'projectReducer', 'listById')
});

const mapDispatchToProps = (dispatch) => ({
	acceptProject: (id, accept) => dispatch(acceptProject(id, accept)),
	updateProject: (id, payload) => dispatch(updateProject(id, payload)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ProjectTable));
