import React, { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	Button,
	Divider,
	Tooltip,
	Typography,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Tabs,
	Tab
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styles from './index.module.css';
import { tabsList } from './tabsList';
import { render } from '../utils/render';
import { CalculateStudentResearchStep } from './utils/CalculateStudentResearchStep';

function StudentProjectModal(props) {
	const { open, setOpen, year, type, detail } = props;
	const [step, setStep] = useState(0);
	const [files, setFiles] = useState(null);
	const [values, setValues] = useState(null);
	const [members, setMembers] = useState([]);
	const [council, setCouncil] = useState(null);
	const [tabValue, setTabValue] = useState(0);
	const [instructors, setInstructors] = useState([]);
	const [modalProperties, setModalProperties] = useState(null);

	const handleClose = () => {
		setOpen(null);
	};

	useEffect(() => {
		if (open) {
			const _modalProperties = render(open);

			setModalProperties(_modalProperties);
			setFiles(null);
			setValues(null);
			setMembers([]);
			setInstructors([]);
			setTabValue(0);

			if (open.report) {
				setStep(0);
			} else {
				setStep(detail?.leaveReason ? 0 : 1);
			}
		}
	}, [open]);

	useEffect(() => {
		if (props.detail) {
			setInstructors(
				props?.detail?.instructors?.map((instructor) => ({
					...instructor,
					isPhenikaa: !!instructor.profileId
				}))
			);
			setMembers(props.detail?.researchMembers || []);
			setValues({
				...props.detail,
				instructors: undefined,
				researchMembers: undefined
			});
		}
	}, [props.detail]);

	const giaiDoan = CalculateStudentResearchStep(detail);

	var renderProps = {
		open,
		type,
		year,
		step,
		detail,
		files,
		values,
		members,
		council,
		giaiDoan,
		instructors,
		setStep,
		setFiles,
		setValues,
		setMembers,
		setCouncil,
		setInstructors,
		handleClose,
		chosenDepartmentId: props.chosenDepartmentId
	};

	if (open?.export) renderProps.rows = open.rows;

	return (
		<Dialog
			fullWidth
			open={!!props.open}
			maxWidth={modalProperties?.size || 'md'}
			PaperProps={{
				style: { maxWidth: modalProperties?.detail ? 960 + 200 : null }
			}}
		>
			<DialogTitle classes={{ root: styles['dialog-title-container'] }}>
				<Grid container>
					<Grid item xs={5} classes={{ root: styles['dialog-title'] }}>
						<Typography variant="h5">
							<b>{modalProperties?.title}</b>
						</Typography>
					</Grid>
					<Grid item xs={7}>
						<Box display="flex" alignItems="center">
							<Box flexGrow={1}>
								{modalProperties?.reportHeader && <>{modalProperties?.reportHeader(step)}</>}
							</Box>
							<IconButton onClick={handleClose}>
								<Close />
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			</DialogTitle>
			<Divider />
			<DialogContent style={modalProperties?.style}>
				{modalProperties?.detail ? (
					<Box display="flex">
						<Box width={200} bgcolor="#fafafa">
							<Tabs
								value={tabValue}
								orientation="vertical"
								onChange={(_, val) => setTabValue(val)}
								classes={{ flexContainer: styles['flex-container'] }}
							>
								{tabsList.map((e, id) => {
									let isDisabled =
										(id !== tabsList.length - 1 && id + 1 > giaiDoan) ||
										(id === tabsList.length - 1 && giaiDoan < 2);

									return <Tab key={`giai-doan-${id}`} value={id} label={e} disabled={isDisabled} />;
								})}
							</Tabs>
						</Box>
						<Box width="1px">
							<Divider style={{ height: 'calc(100% + 64px)' }} />
						</Box>
						{(() => {
							let _open;
							switch (tabValue) {
								case 0:
									_open = { detailOnlyInfo: true };
									break;
								case 1:
									_open = { detailOnlyMembers: true };
									break;
								case 2:
									_open = { reportDetail: true };
									break;
								case 3:
									_open = { defaultDetailNotCouncil: true };
									break;
								case 5:
									_open = { document: true };
									break;
								default:
									break;
							}

							return (
								<Box width="calc(100% - 60px)">
									{render(_open)?.style?.overflow === 'hidden' ? (
										render(_open)?.form(renderProps)
									) : (
										<Box
											width="calc(100% - 48px)"
											padding="16px 24px"
											height="calc(100% + 100px)"
											maxHeight="calc(100vh - 216px)"
											style={{ overflow: 'auto' }}
										>
											{render(_open)?.form(renderProps)}
										</Box>
									)}
								</Box>
							);
						})()}
					</Box>
				) : (
					modalProperties?.form(renderProps)
				)}
			</DialogContent>
			<DialogActions style={{ padding: '8px 24px' }}>
				{modalProperties?.actionButtons(renderProps).map((item, index) => (
					<React.Fragment key={`action-button-${index}`}>
						{item?.closeButton ? (
							<Button variant="outlined" startIcon={<Close />} onClick={handleClose}>
								Đóng
							</Button>
						) : (
							<>
								{item?.disabledText ? (
									<Tooltip
										key={index}
										disableHoverListener={!item?.disabledText}
										title={item?.disabledText || 'N/A'}
									>
										<span>
											<Button disabled={!!item?.disabledText} {...item.props}>
												{item.content}
											</Button>
										</span>
									</Tooltip>
								) : (
									<Button {...item.props}>{item.content}</Button>
								)}
							</>
						)}
					</React.Fragment>
				))}
			</DialogActions>
		</Dialog>
	);
}

export default StudentProjectModal;
