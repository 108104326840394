import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import { KPTable } from '../../../components/KPTComponents';
import JournalDomesticUpdateModal from '../JournalDomesticUpdateModal';
import JournalDomesticDeleteModal from '../JournalDomesticDeleteModal';

function JournalDomesticTable(props) {
	const [updating, setUpdating] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleUpdate = (e) => setUpdating(e);

	const handleDelete = (event, e) => {
		setDeleting(e.id);
		setAnchorEl(event.currentTarget);
	};

	const column = [
		{
			title: <b>Tên tạp chí</b>,
			key: 'title',
			width: '20%',
			searchable: true,
			sorter: (a, b) => a.title.localeCompare(b.title)
		},
		{ title: <b>ISSN/ISBN</b>, key: 'issn', width: '10%' },
		{ title: <b>Loại</b>, key: 'type', width: '10%' },
		{ title: <b>Ngành</b>, key: 'major', width: '15%' },
		{ title: <b>Nhà xuất bản</b>, key: 'publisher', width: '25%' },
		{
			title: <b>Điểm GS-PGS</b>,
			key: 'score',
			width: '10%',
			sorter: (a, b) => {
				const firstA = parseFloat(a.score.split(' - ')[0]);
				const secondA = parseFloat(a.score.split(' - ')[1]);
				const firstB = parseFloat(b.score.split(' - ')[0]);
				const secondB = parseFloat(b.score.split(' - ')[1]);

				if (firstA < firstB) return -1;
				if (firstB > firstA) return 1;
				if (secondA < secondB) return -1;
				if (secondA > secondB) return 1;
				return 0;
			}
		}
	];

	if (props.role === 'sudo-admin') {
		column.push({
			title: <b>Tác vụ</b>,
			key: 'action',
			width: '10%',
			render: (_, row) => (
				<Grid container>
					<Grid item xs={6}>
						<Typography
							className="table-action-buton"
							variant="caption"
							color="primary"
							onClick={() => handleUpdate(row)}
						>
							Chi tiết
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							className="table-action-buton"
							variant="caption"
							color="error"
							onClick={(event) => handleDelete(event, row)}
						>
							Xoá
						</Typography>
					</Grid>
				</Grid>
			)
		});
	}

	let rows = [...props.list];

	return (
		<>
			<KPTable
				rowsPerPage={20}
				filterObj={props.filterObj}
				maxHeight="calc(100vh - 258px)"
				rows={rows}
				columns={column}
			/>
			<JournalDomesticUpdateModal updating={updating} setUpdating={setUpdating} />
			<JournalDomesticDeleteModal
				deleting={deleting}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	list: select(state, 'journalDomesticReducer', 'list')
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalDomesticTable));
