import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
	Grid,
	Box,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	CircularProgress,
	Tab,
	Tabs,
	Checkbox,
	FormControlLabel
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import moment from 'moment';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import {
	getEmploymentContractInfoById,
	updateEmploymentContractV2,
	createKPI
} from '../../../reducer/employmentContract/action';
import KPIForm from '../KPIForm/KPIForm';
import KPIRewardTable from '../KPIRewardTable/KPIRewardTable';
import KPTimePicker from '../../../components/KPTimePicker';
import { Done } from '@material-ui/icons';
import { getAllContractsByProfileId } from '../../../reducer/profile/action';
import TYPE from '../../../constants/type';

const useStyles = makeStyles((theme) =>
	createStyles({
		btnCancel: {
			marginRight: theme.spacing(1)
		},
		formGroup: {
			marginBottom: theme.spacing(2)
		},
		tableCellHeader: {
			fontWeight: 'bold'
		},
		kpiValueChip: {
			backgroundColor: '#009473',
			color: theme.palette.common.white
		},
		kpiScientificResearch: {
			// margin: theme.spacing(1, 0),
			// paddingLeft: 0,
		},
		textField: {
			'& textarea': {
				color: '#000000'
			},
			'& textarea:disabled': {
				color: 'red'
			},
			'& label:disabled': {
				color: 'red'
			}
		}
	})
);

const getInitial = (title) => {
	const type = title.split('|');
	let res = '';
	type.forEach((type) => {
		switch (type.trim()) {
			case 'Science Citation Index Expanded':
				if (res !== '') res += ' | SCIE';
				else res += 'SCIE';
				break;
			case 'Social Sciences Citation Index':
				if (res !== '') res += ' | SSCI';
				else res += 'SSCI';
				break;
			case 'Arts & Humanities Citation Index':
				if (res !== '') res += ' | AHCI';
				else res += 'AHCI';
				break;
			case 'Emerging Sources Citation Index':
				if (res !== '') res += ' | ESCI';
				else res += 'ESCI';
			default:
				if (res !== '') res += ' | Scopus';
				else res += 'Scopus';
				break;
		}
	});
	return res;
};

function KPIProgress(props) {
	const { profileInfo } = props;
	const classes = useStyles();

	/**
	 * @description Bài báo
	 */
	const papers = useMemo(() => {
		const filteredPapers = (profileInfo?.scientificResearch ?? []).filter(
			(scientificResearchElement) => {
				if (
					scientificResearchElement.journal !== null ||
					scientificResearchElement.conference !== null
				) {
					return true;
				}
				return false;
			}
		);
		return filteredPapers;
	}, [profileInfo]);

	/**
	 * @description Sáng chế
	 */
	const patents = useMemo(() => {
		return {
			'USPTO (Utility)-TGC': (profileInfo?.patent ?? []).filter(
				(patentElement) => patentElement.patent.type === 'USPTO (Utility)-TGC'
			),
			'USPTO (Design)-TGC': (profileInfo?.patent ?? []).filter(
				(patentElement) => patentElement.patent.type === 'USPTO (Design)-TGC'
			),
			'USPTO (Plant)-TGC': (profileInfo?.patent ?? []).filter(
				(patentElement) => patentElement.patent.type === 'USPTO (Plant)-TGC'
			),
			'EPO-TGC': (profileInfo?.patent ?? []).filter(
				(patentElement) => patentElement.patent.type === 'EPO-TGC'
			),
			'Bằng sáng chế (VN)-TGC': (profileInfo?.patent ?? []).filter(
				(patentElement) => patentElement.patent.type === 'Bằng sáng chế (VN)-TGC'
			)
		};
	}, [profileInfo]);

	/**
	 * @description Hợp đồng chuyển giao
	 */
	const productContracts = useMemo(() => {
		return profileInfo?.productContract ?? [];
	}, [profileInfo]);

	/**
	 * @description Giải pháp hữu ích
	 */
	const solutions = useMemo(() => {
		return profileInfo?.solution ?? [];
	}, [profileInfo]);

	/**
	 * @description Chương sách
	 */
	const chapters = useMemo(() => {
		return profileInfo?.monograph ?? [];
	}, [profileInfo]);

	const getRoleInPaper = useCallback((role) => {
		switch (role) {
			case 'main':
				return 'Tác giả chính';
			case 'member':
				return 'Đồng tác giả';
			case 'mainOther':
				return 'Tác giả chính tính hộ';
			case 'memberOther':
				return 'Đồng tác giả tính hộ';
			case 'other':
				return 'Khác';
			default:
				return 'Không xác định được vai trò';
		}
	}, []);

	const getRoleInMonograph = useCallback((role) => {
		switch (role) {
			case 'main':
				return 'Chủ biên';
			case 'member':
				return 'Đồng tác giả';
			case 'mainOther':
				return 'Chủ biên tính hộ';
			case 'memberOther':
				return 'Đồng tác giả tính hộ';
			case 'other':
				return 'Khác';
			default:
				return 'Không xác định được vai trò';
		}
	}, []);

	const getTypeMonograph = useCallback((type) => {
		switch (type) {
			case 'chapter':
				return 'Chương sách';
			case 'monograph':
				return 'Chuyên khảo';
			case 'reference':
				return 'Tham khảo';
			case 'curriculum':
				return 'Giáo trình';
			case 'other':
				return 'Khác';
			default:
				return 'Không xác định được vai trò';
		}
	}, []);

	const renderPaperRow = (paperElement) => {
		if (paperElement.journal)
			return (
				<TableRow key={paperElement.scientificResearch.id}>
					<TableCell>{paperElement.scientificResearch?.title}</TableCell>
					<TableCell>{paperElement.journal?.sjrBestQuartile}</TableCell>
					<TableCell>{paperElement.journal?.rank}</TableCell>
					<TableCell>-</TableCell>
					<TableCell>{(paperElement.scientificResearch?.listAuthor ?? []).length}</TableCell>
					<TableCell>{paperElement.scientificResearch.numberMainAuthor}</TableCell>
					<TableCell>{getRoleInPaper(paperElement.scientificResearch?.roleInPaper)}</TableCell>
					<TableCell>{getInitial(paperElement.journal?.coreCollection)}</TableCell>
				</TableRow>
			);
		else
			return (
				<TableRow key={paperElement.scientificResearch.id}>
					<TableCell>{paperElement.scientificResearch?.title}</TableCell>
					<TableCell>-</TableCell>
					<TableCell>-</TableCell>
					<TableCell>{`Rank ${paperElement.conference?.rank}`}</TableCell>
					<TableCell>{(paperElement.scientificResearch?.listAuthor ?? []).length}</TableCell>
					<TableCell>{paperElement.scientificResearch.numberMainAuthor}</TableCell>
					<TableCell>{getRoleInPaper(paperElement.scientificResearch?.roleInPaper)}</TableCell>
					<TableCell>-</TableCell>
				</TableRow>
			);
	};

	return (
		<>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Box>
						<Typography>Bài báo</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableCellHeader}>Tiêu đề</TableCell>
									<TableCell className={classes.tableCellHeader}>Xếp hạng Q</TableCell>
									<TableCell className={classes.tableCellHeader}>Xếp hạng R</TableCell>
									<TableCell className={classes.tableCellHeader}>Xếp hạng hội thảo</TableCell>
									<TableCell className={classes.tableCellHeader}>Số lượng tác giả</TableCell>
									<TableCell className={classes.tableCellHeader}>Số lượng tác giả chính</TableCell>
									<TableCell className={classes.tableCellHeader}>Vai trò tác giả</TableCell>
									<TableCell className={classes.tableCellHeader}>ISI/Scopus</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{papers.map((paperElement) => {
									return renderPaperRow(paperElement);
								})}
							</TableBody>
						</Table>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box>
						<Typography>Sáng chế</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableCellHeader}>Tên sáng chế</TableCell>
									<TableCell className={classes.tableCellHeader}>Loại sáng chế</TableCell>
									<TableCell className={classes.tableCellHeader}>Ngày cấp bằng</TableCell>
									{/* <TableCell className={classes.tableCellHeader}>Ngày chấp nhận</TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{patents['USPTO (Utility)-TGC'] &&
									patents['USPTO (Utility)-TGC'].length > 0 &&
									patents['USPTO (Utility)-TGC'].map((patentElement) => {
										return (
											<TableRow key={patentElement.patent.id}>
												<TableCell>{patentElement.patent.title}</TableCell>
												<TableCell>{patentElement.patent.type}</TableCell>
												<TableCell>
													{dayjs(patentElement.patent.issueAt).format('DD-MM-YYYY')}
												</TableCell>
												{/* <TableCell>{dayjs(patentElement.patent.acceptedDay).format('DD-MM-YYYY')}</TableCell> */}
											</TableRow>
										);
									})}
								{patents['USPTO (Design)-TGC'] &&
									patents['USPTO (Design)-TGC'].length > 0 &&
									patents['USPTO (Design)-TGC'].map((patentElement) => {
										return (
											<TableRow key={patentElement.patent.id}>
												<TableCell>{patentElement.patent.title}</TableCell>
												<TableCell>{patentElement.patent.type}</TableCell>
												<TableCell>
													{dayjs(patentElement.patent.issueAt).format('DD-MM-YYYY')}
												</TableCell>
												{/* <TableCell>{dayjs(patentElement.patent.acceptedDay).format('DD-MM-YYYY')}</TableCell> */}
											</TableRow>
										);
									})}
								{patents['USPTO (Plant)-TGC'] &&
									patents['USPTO (Plant)-TGC'].length > 0 &&
									patents['USPTO (Plant)-TGC'].map((patentElement) => {
										return (
											<TableRow key={patentElement.patent.id}>
												<TableCell>{patentElement.patent.title}</TableCell>
												<TableCell>{patentElement.patent.type}</TableCell>
												<TableCell>
													{dayjs(patentElement.patent.issueAt).format('DD-MM-YYYY')}
												</TableCell>
												{/* <TableCell>{dayjs(patentElement.patent.acceptedDay).format('DD-MM-YYYY')}</TableCell> */}
											</TableRow>
										);
									})}
								{patents['EPO-TGC'] &&
									patents['EPO-TGC'].length > 0 &&
									patents['EPO-TGC'].map((patentElement) => {
										return (
											<TableRow key={patentElement.patent.id}>
												<TableCell>{patentElement.patent.title}</TableCell>
												<TableCell>{patentElement.patent.type}</TableCell>
												<TableCell>
													{dayjs(patentElement.patent.issueAt).format('DD-MM-YYYY')}
												</TableCell>
												{/* <TableCell>{dayjs(patentElement.patent.acceptedDay).format('DD-MM-YYYY')}</TableCell> */}
											</TableRow>
										);
									})}
								{patents['Bằng sáng chế (VN)-TGC'] &&
									patents['Bằng sáng chế (VN)-TGC'].length > 0 &&
									patents['Bằng sáng chế (VN)-TGC'].map((patentElement) => {
										return (
											<TableRow key={patentElement.patent.id}>
												<TableCell>{patentElement.patent.title}</TableCell>
												<TableCell>{patentElement.patent.type}</TableCell>
												<TableCell>
													{dayjs(patentElement.patent.issueAt).format('DD-MM-YYYY')}
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box>
						<Typography>Giải pháp hữu ích</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableCellHeader}>Tên giải pháp</TableCell>
									<TableCell className={classes.tableCellHeader}>Ngày cấp bằng</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{solutions.map((solutionElement) => {
									return (
										<TableRow key={solutionElement.solution.id}>
											<TableCell>{solutionElement.solution.title}</TableCell>
											<TableCell>
												{dayjs(solutionElement.solution.issueAt).format('DD-MM-YYYY')}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box>
						<Typography>Sách/chương sách</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableCellHeader}>Tiêu đề</TableCell>
									<TableCell className={classes.tableCellHeader}>Ngôn ngữ</TableCell>
									<TableCell className={classes.tableCellHeader}>Loại</TableCell>
									<TableCell className={classes.tableCellHeader}>Nhà xuất bản</TableCell>
									<TableCell className={classes.tableCellHeader}>Số lượng tác giả</TableCell>
									<TableCell className={classes.tableCellHeader}>Vai trò tác giả</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{chapters.map((chapterElement) => {
									return (
										<TableRow key={chapterElement?.monograph.id}>
											<TableCell>{chapterElement?.monograph.title}</TableCell>
											<TableCell>
												{chapterElement?.monograph.language === 'english' ? 'Tiếng Anh' : 'Khác'}
											</TableCell>
											<TableCell>{getTypeMonograph(chapterElement?.monograph?.type)}</TableCell>
											<TableCell>{chapterElement?.monograph.publisher}</TableCell>
											<TableCell>{(chapterElement?.monograph?.listMember ?? []).length}</TableCell>
											<TableCell>
												{getRoleInMonograph(chapterElement?.monograph?.roleInMonograph)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

function EmploymentContractUpdateModal(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(true);
	const [chosenKpi, setChosenKpi] = useState(0);
	const [values, setValues] = useState({
		duration: null,
		startAt: null,
		finishAt: null,
		note: null
	});
	const [openKPIModal, setOpenKPIModal] = useState(false);
	const [chosenYear, setChosenYear] = useState(new Date().getFullYear());

	const { updating, setUpdating } = props;
	const { contract, kpiInfo } = props.info;

	useEffect(() => {
		initial();
	}, []);

	useEffect(() => {
		if (
			!!props.chosenYear &&
			!!kpiInfo &&
			sortedByYear(kpiInfo)[chosenKpi]?.kpi?.year !== props.chosenYear
		) {
			let tempChosenKpi = 0;
			sortedByYear(kpiInfo).forEach((item, index) => {
				if (item.kpi.year === props.chosenYear) tempChosenKpi = index;
			});
			setChosenKpi(tempChosenKpi);
		}
	}, [props.chosenYear, kpiInfo]);

	function clear() {
		dispatch({
			type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_FAILURE
		});
	}

	const year = props?.updating?.contract?.kpi.year;

	async function initial() {
		clear();
		let contractId = null;
		let contractYear = updating.contract.year;
		if (updating && updating.contract && updating.contract.id) {
			contractId = updating.contract.id;
		} else if (props.profileId) {
			const lstContract = await getAllContractsByProfileId(props.profileId)(dispatch);
			if (lstContract.length > 0) {
				const activeContract = lstContract.filter((e) => e.contract?.active === true).pop();
				contractId = activeContract?.contract?.id;
			}
		}
		if (contractId && contractId > 0) {
			props.getEmploymentContractInfoById(contractId, year);
		}
	}

	const handleChangeTab = (event, newValue) => {
		setChosenKpi(newValue);
	};

	const sortedByYear = (arr) => {
		if (arr === undefined || arr === null) return [];
		const sortedData = [].concat(arr).sort((a, b) => (a.kpi.year > b.kpi.year ? 1 : -1));
		return sortedData;
	};

	const handleChangeForm = (name, value) => {
		setValues({ ...values, [name]: value });
	};

	const dateFormat = (value) => {
		const dateForm = value?.replaceAll('/', '');
		const date = dateForm?.substr(0, 2);
		const month = dateForm?.substr(2, 2);
		const year = dateForm?.substr(-4);
		return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
	};

	const addYears = (date, n) => {
		const dt = new Date(moment(date));
		return new Date(dt.setFullYear(dt.getFullYear() + n));
	};

	const handleSubmit = () => {
		const _contract = updating?.contract || contract;
		props.updateEmploymentContractV2(_contract.id, {
			profileId: props.profileId || props.info.contract?.profileId,
			note: values?.note ?? _contract.note,
			startAt: values?.startAt ? dateFormat(values?.startAt) : _contract.startAt,
			duration: values?.duration ?? _contract.duration,
			finishAt: dateFormat(
				moment(
					addYears(
						dateFormat(values?.startAt) ?? _contract.startAt,
						values?.duration ?? _contract.duration
					)
				).format('DD/MM/YYYY')
			)
		});
		setUpdating(null);
	};

	const handleCreateKPI = () => {
		const _contract = updating.contract || contract;
		props.createKPI({
			contractId: _contract?.id,
			year: chosenYear,
			type: sortedByYear(kpiInfo)[chosenKpi]?.kpi?.type ?? 'R',
			kpiSolution:
				sortedByYear(kpiInfo)[chosenKpi]?.kpi?.kpiSolution.map((item) => {
					return {
						...item,
						id: null,
						kpiId: null
					};
				}) ?? [],
			kpiScientificResearch:
				sortedByYear(kpiInfo)[chosenKpi]?.kpi?.kpiScientificResearch.map((item) => {
					return {
						...item,
						id: null,
						kpiId: null
					};
				}) ?? [],
			kpiPatent:
				sortedByYear(kpiInfo)[chosenKpi]?.kpi?.kpiPatent.map((item) => {
					return {
						...item,
						id: null,
						kpiId: null
					};
				}) ?? [],
			kpiProductContract:
				sortedByYear(kpiInfo)[chosenKpi]?.kpi?.kpiProductContract.map((item) => {
					return {
						...item,
						id: null,
						kpiId: null
					};
				}) ?? []
		});
		setOpenKPIModal(false);
	};

	const profile =
		props.lstContract?.find(
			(e) =>
				e.profile?.id === contract?.profileId ||
				e.profile?.id === props.profileId ||
				e.profile?.id === updating?.contract?.profileId
		)?.profile ||
		props?.profile ||
		props?.updating?.profile;

	return (
		<>
			<Dialog fullWidth maxWidth="xl" open={updating !== null} onClose={() => setUpdating(null)}>
				<DialogTitle>
					{disabled ? 'Chi tiết hợp đồng' : 'Cập nhật hợp đồng'} - {profile?.name} - {profile?.code}
				</DialogTitle>
				<DialogContent>
					{props.isFetching ? (
						<div style={{ textAlign: 'center' }}>
							<CircularProgress size={20} />
						</div>
					) : (
						<div>
							<Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 10 }}>
								{contract?.duration === 1000 ? (
									<Grid item xs={2}>
										<Button
											size="small"
											variant="outlined"
											disabled
											style={{ height: 40, width: '100%' }}
										>
											<FormControlLabel
												label="Vô thời hạn"
												style={{ width: '100%', paddingRight: 0 }}
												control={<Checkbox color="primary" disabled checked />}
											/>
										</Button>
									</Grid>
								) : (
									<Grid item xs={2}>
										<TextField
											fullWidth
											label="Thời hạn hợp đồng"
											variant="outlined"
											size="small"
											disabled={disabled}
											value={values?.duration ?? contract?.duration}
											onChange={(event) =>
												handleChangeForm('duration', parseInt(event.target.value))
											}
										/>
									</Grid>
								)}
								<Grid item xs={2}>
									<KPTimePicker
										fullWidth
										disabled={disabled}
										label="Ngày bắt đầu"
										value={
											values?.startAt ??
											moment(contract?.startAt).format('DD/MM/YYYY') ??
											'dd/mm/yyyy'
										}
										setValue={(newValue) => handleChangeForm('startAt', newValue)}
									/>
								</Grid>
								{contract?.duration !== 1000 && (
									<Grid item xs={2}>
										<KPTimePicker
											fullWidth
											disabled
											label="Ngày kết thúc"
											value={
												moment(
													addYears(
														dateFormat(values?.startAt) ?? contract?.startAt,
														values?.duration ?? contract?.duration
													)
												).format('DD/MM/YYYY') ?? 'dd/mm/yyyy'
											}
											setValue={(newValue) => {
												handleChangeForm('finishAt', newValue);
											}}
										/>
									</Grid>
								)}
							</Grid>

							<Grid
								container
								justifyContent="space-between"
								spacing={2}
								style={{ marginBottom: 10 }}
							>
								{props.info !== null && (
									<>
										<Tabs value={chosenKpi} onChange={handleChangeTab} scrollButtons="auto">
											{sortedByYear(kpiInfo)?.map((item, index) => (
												<Tab
													label={
														<div>
															<div style={{ display: 'inline-block' }}>
																{item.kpi.year}
																{item.kpi?.titleNote ? ` (${item.kpi?.titleNote})` : null}
															</div>
															{item.kpi.completed && (
																<Done style={{ color: 'green', marginTop: 10 }} />
															)}
														</div>
													}
													value={index}
												/>
											))}
										</Tabs>
										{!disabled && kpiInfo.length < contract?.duration && (
											<Button
												style={{ height: '0%' }}
												variant="contained"
												color="primary"
												onClick={() => setOpenKPIModal(true)}
											>
												Thêm KPI cho năm mới
											</Button>
										)}
									</>
								)}
							</Grid>

							<Grid
								container
								justifyContent="space-between"
								spacing={2}
								style={{ marginBottom: 10 }}
							>
								<Grid item xs={7} style={{ borderRight: 'thin solid gray' }}>
									{/* New KPI form after 17/7 update */}
									<KPIForm
										kpiInfo={sortedByYear(kpiInfo)[chosenKpi]}
										profile={profile}
										disabled={disabled}
									/>
								</Grid>

								<Grid item xs={5}>
									<KPIRewardTable kpiInfo={sortedByYear(kpiInfo)[chosenKpi]} />
								</Grid>

								<Grid item xs={12}>
									<div style={{ borderBottom: '1px solid gray' }} />
								</Grid>

								<Grid item xs={12}>
									<TextField
										multiline
										fullWidth
										rows={3}
										disabled={disabled}
										variant="outlined"
										label="Ghi chú"
										className={classes.textField}
										onChange={(event) => handleChangeForm('note', event.target.value)}
										value={values?.note ?? contract?.note}
									/>
								</Grid>

								<Grid item xs={12}>
									<KPIProgress profileInfo={sortedByYear(kpiInfo)[chosenKpi]} />
								</Grid>
							</Grid>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => {
							if (disabled) setUpdating(null);
							else setDisabled(true);
						}}
					>
						Huỷ
					</Button>
					{disabled ? (
						<Button
							disabled={props?.dbRole !== 'sudo-admin'}
							variant="contained"
							color="primary"
							onClick={() => setDisabled(false)}
						>
							Chỉnh sửa
						</Button>
					) : (
						<Button variant="contained" color="primary" onClick={() => handleSubmit()}>
							Xác nhận
						</Button>
					)}
				</DialogActions>
			</Dialog>

			<Dialog open={openKPIModal} onClose={() => setOpenKPIModal(false)}>
				<DialogTitle>Chọn năm tạo KPI mới</DialogTitle>
				<DialogContent>
					<TextField
						variant="outlined"
						size="small"
						value={chosenYear || 0}
						onChange={(event) => setChosenYear(parseInt(event.target.value))}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={() => setOpenKPIModal(false)}>
						Huỷ
					</Button>
					<Button variant="contained" color="primary" onClick={() => handleCreateKPI()}>
						Xác nhận
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const mapStateToProps = (state) => ({
	dbRole: select(state, 'userReducer', 'role'),
	info: select(state, 'employmentContractReducer', 'contractInfo'),
	// lstContract: select(state, "employmentContractReducer", "list"),
	isFetching: select(state, 'employmentContractReducer', 'isFetching'),
	isFetchingKpi: select(state, 'employmentContractReducer', 'isFetchingKpi'),
	rewardInfo: select(state, 'employmentContractReducer', 'rewardInfo')
});

const mapDispatchToProps = (dispatch) => ({
	getEmploymentContractInfoById: (id, year) => dispatch(getEmploymentContractInfoById(id, year)),
	updateEmploymentContractV2: (id, payload) => dispatch(updateEmploymentContractV2(id, payload)),
	createKPI: (payload) => dispatch(createKPI(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(EmploymentContractUpdateModal));
