import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider, Typography } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getAllProfile } from '../../../reducer/profile/action';
import {
	getAllPatent,
	getAllPatentByProfileId,
	getFilteredPatent
} from '../../../reducer/patent/action';

import PatentInsertModal from '../PatentInsertModal';

function PatentButton(props) {
	const { getAllPatent, getAllPatentByProfileId, getAllProfile, getFilteredPatent } = props;

	const [inserting, setInserting] = useState(false);

	let profileId = props.selectedProfileId;
	if (!profileId) profileId = props.profileDetail?.id;

	useEffect(() => {
		if (props.listProfile.length === 0) getAllProfile();
	}, [getAllProfile, props.listProfile.length]);

	useEffect(() => {
		if (props.type !== 'all' && profileId) getAllPatentByProfileId(profileId);
	}, [profileId]);

	useEffect(() => {
		if (props.type === 'all' && props.listFilteredPatent.length === 0 && !props.isFetching)
			getFilteredPatent(1, -1, { departmentId: props.department });
	}, []);

	useEffect(() => {
		if (props.type === 'all' && !props.isFetching && !props.isFetchingAccepted)
			getFilteredPatent(1, -1, { departmentId: props.department });
	}, [props.department, getFilteredPatent]);

	const handleReload = () => {
		if (props.type !== 'all') {
			getAllPatentByProfileId(profileId);
			props.handleReload();
		} else {
			getFilteredPatent(1, -1, { departmentId: props.department });
			props.handleReload();
		}
	};

	const handleInsert = () => setInserting(true);

	return (
		<>
			<Grid container>
				<Grid item xs={6}>
					<Typography variant="h5" style={{ lineHeight: '36px' }}>
						Bằng sáng chế
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<div style={{ float: 'right' }}>
						<Button variant="outlined" startIcon={<Replay />} onClick={handleReload}>
							Tải lại
						</Button>
						{props.type !== 'all' && (
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add />}
								style={{ marginLeft: 8 }}
								onClick={handleInsert}
							>
								Thêm
							</Button>
						)}
					</div>
				</Grid>
			</Grid>
			<Divider style={{ margin: '8px 0' }} />
			<PatentInsertModal inserting={inserting} setInserting={setInserting} />
		</>
	);
}

const mapStateToProps = (state) => ({
	isFetching: select(state, 'patentReducer', 'isFetching'),
	isFetchingAccepted: select(state, 'patentReducer', 'isFetchingAccepted'),

	listPatent: select(state, 'patentReducer', 'list'),
	listProfile: select(state, 'profileReducer', 'list'),
	profileDetail: select(state, 'profileReducer', 'detail'),
	listPatentById: select(state, 'patentReducer', 'listById'),
	listFilteredPatent: select(state, 'patentReducer', 'listFiltered')
});

const mapDispatchToProps = (dispatch) => ({
	getAllProfile: () => dispatch(getAllProfile()),
	getAllPatent: () => dispatch(getAllPatent()),
	getAllPatentByProfileId: (id) => dispatch(getAllPatentByProfileId(id)),
	getFilteredPatent: (page, pageSize, payload) =>
		dispatch(getFilteredPatent(page, pageSize, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(PatentButton));
