import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Card,
	Button,
	Typography,
	CardContent,
	CardActions,
	Fab,
	Tooltip,
	Chip
} from '@material-ui/core';
import { CameraAlt, Edit } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import ProfileUpdateForm from '../ProfileUpdateForm';

import './Profile.css';
import FileUploadModal from '../../File/FileUploadModal';

import { uploadFile } from '../../../reducer/file/action';
import {
	updateProfile,
	getProfScore,
	getProfileInfoByProfileId
} from '../../../reducer/profile/action';

import noImage from '../../../assets/noimage.png';
import theme from '../../../theme';

function Profile(props) {
	const [editting, setEditting] = useState(false);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);

	const { isUploading, fileUrl, profile, updateProfile, getProfScore } = props;

	// useEffect(() => {
	//   if (
	//     isUploading === false &&
	//     fileUrl !== "" &&
	//     fileUrl.includes("/avatar/")
	//   ) {
	//     updateProfile(profile.id, {
	//       ...profile,
	//       avatar: fileUrl,
	//     });
	//   }
	// }, [isUploading, fileUrl, profile, updateProfile]);

	useEffect(() => {
		if (profile) {
			getProfScore(profile.id);
		}
	}, [profile, getProfScore]);

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);

	const handleUpload = (file) => {
		props
			.uploadFile(props.profile?.id, 'avatar', file)
			.then((data) => {
				if (data.success) setUploadModalOpen(false);
				updateProfile(profile.id, {
					...profile,
					avatar: data.data
				});
			})
			.catch();
	};

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Card
				style={{
					padding: 8,
					position: 'relative',
					height: 190,
					marginBottom: 8
				}}
			>
				<div id="profile-avatar-border">
					{props.profile?.avatar && props.profile?.avatar !== '' ? (
						<img
							src={`${process.env.REACT_APP_DOMAIN}${props.profile?.avatar}`}
							style={{
								verticalAlign: 'middle',
								width: 180,
								height: 180,
								borderRadius: '50%',
								objectFit: 'cover'
							}}
						/>
					) : (
						<img
							src={noImage}
							style={{
								verticalAlign: 'middle',
								width: 180,
								height: 180,
								borderRadius: '50%',
								objectFit: 'cover'
							}}
						/>
					)}
					<Tooltip title="Thay ảnh đại diện">
						<Fab id="avatar-change-button">
							<CameraAlt style={{ fontSize: 24 }} onClick={handleOpenUploadModal} />
						</Fab>
					</Tooltip>
				</div>
			</Card>
			<Card id="profile-form">
				<CardContent
					id="card-content-asdck"
					style={{ display: editting ? 'none' : 'block', flexGrow: 1 }}
				>
					<Box display="flex" flexDirection="row" justifyContent="center" mb="32px">
						<Typography variant="h4">{props.profile?.name}</Typography>
					</Box>
					<Box style={{ width: '100%', textAlign: 'center' }} mb="32px">
						<Chip
							style={{ fontSize: 20, padding: 20, borderRadius: 4 }}
							label={`Điểm xét GS-PGS: ${props.profScore.toFixed(2)}`}
							color={'primary'}
						/>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Mã PU:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.code}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Điện thoại:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.phone}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Email:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.mail}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Liên hệ:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.address}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Học vị:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.degree}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Học hàm:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.academicRank}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Chuyên ngành:
						</Typography>
						<Typography className="profile-attr-value">{props.profile?.major || 'N/A'}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography variant="inherit" className="profile-attr">
							Năm tốt nghiệp:
						</Typography>
						<Typography className="profile-attr-value">
							{props.profile?.graduatedYearPhd}
						</Typography>
					</Box>
				</CardContent>
				<CardActions
					style={{
						display: editting ? 'none' : 'block',
						padding: theme.spacing(2)
					}}
				>
					<Button
						fullWidth
						variant="outlined"
						startIcon={<Edit />}
						onClick={() => setEditting(true)}
					>
						Chỉnh sửa
					</Button>
				</CardActions>
				<ProfileUpdateForm editting={editting} setEditting={setEditting} />
				<FileUploadModal
					open={uploadModalOpen}
					handleClose={handleCloseUploadModal}
					handleUpload={handleUpload}
					isImage={true}
				/>
			</Card>
		</Box>
	);
}

const mapStateToProps = (state) => ({
	isUploading: select(state, 'fileReducer', 'isFetching'),
	profile: select(state, 'profileReducer', 'detail'),
	fileUrl: select(state, 'fileReducer', 'fileUrl'),
	fileType: select(state, 'fileReducer', 'fileType'),
	isFetchingProfScore: select(state, 'profileReducer', 'isFetchingProfScore'),
	profScore: select(state, 'profileReducer', 'profScore')
});

const mapDispatchToProps = (dispatch) => ({
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	updateProfile: (id, payload) => dispatch(updateProfile(id, payload)),
	getProfScore: (profileId) => dispatch(getProfScore(profileId))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(Profile));
