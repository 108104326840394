import React, { useContext } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stepper,
	Step,
	StepLabel
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import SelectProfileStep from './SelectProfileStep';
import EmploymentContractInsertStep from './EmploymentContractInsertStep';

import { insertEmploymentContract } from '../../../reducer/employmentContract/action';
import { EmploymentContractInsertContext } from './EmploymentContractInsertContext';

const useStyles = makeStyles((theme) =>
	createStyles({
		formGroup: {
			marginBottom: theme.spacing(2)
		},
		btnCancel: {
			marginRight: theme.spacing(1)
		},
		btnBack: {
			marginRight: theme.spacing(1)
		}
	})
);

const STEPS = ['Chọn nhân sự', 'Tạo hợp đồng nhân sự'];

function EmploymentContractInsertModal(props) {
	const { inserting, setInserting } = props;

	const classes = useStyles();

	const { currentStep, setCurrentStep, setProfile } = useContext(EmploymentContractInsertContext);

	const handleCloseEmploymentContractInsertModal = () => {
		setInserting(false);
		setProfile(null);
		setCurrentStep(0);
	};

	const handleClickBtnNext = () => setCurrentStep((step) => step + 1);
	const handleClickBtnPrev = () => setCurrentStep((step) => step - 1);

	return (
		<>
			<Dialog
				fullWidth
				maxWidth="lg"
				open={inserting}
				onClose={handleCloseEmploymentContractInsertModal}
			>
				<DialogTitle>Thêm hợp đồng nhân sự</DialogTitle>
				<DialogContent>
					<Stepper alternativeLabel activeStep={currentStep} style={{ padding: '0px 2px' }}>
						{STEPS.map((step) => {
							return (
								<Step key={step}>
									<StepLabel>{step}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					{currentStep === 0 && <SelectProfileStep />}
					{currentStep === 1 && <EmploymentContractInsertStep setInserting={setInserting} />}
					<Box textAlign="center" pt={2}>
						<Button
							color="default"
							variant="outlined"
							className={classes.btnBack}
							disabled={currentStep === 0}
							onClick={handleClickBtnPrev}
						>
							Quay lại
						</Button>
						<Button
							color="primary"
							variant="contained"
							disabled={currentStep === STEPS.length - 1}
							onClick={handleClickBtnNext}
						>
							Tiếp theo
						</Button>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box px={4} py={1}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleCloseEmploymentContractInsertModal}
						>
							Huỷ
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
}

const mapStateToProps = (state) => ({
	profile: select(state, 'profileReducer', 'detail')
});

const mapDispatchToProps = (dispatch) => ({
	insertEmploymentContract: (payload) => dispatch(insertEmploymentContract(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(EmploymentContractInsertModal));
