import { fromJS } from 'immutable';
import TYPE from '../../constants/type';

const initialState = fromJS({
	isFetching: false,
	scientificCounclis: []
});

const scientificCouncilReducer = (state = initialState, action) => {
	switch (action.type) {
		case TYPE.SCIENTIFIC_COUNCLI.GET_SCIENTIFIC_COUNCLI_LOADING:
		case TYPE.SCIENTIFIC_COUNCLI.CREATE_SCIENTIFIC_COUNCLI_LOADING:
		case TYPE.SCIENTIFIC_COUNCLI.UPDATE_SCIENTIFIC_COUNCLI_LOADING:
		case TYPE.SCIENTIFIC_COUNCLI.DELETE_SCIENTIFIC_COUNCLI_LOADING:
			return state.merge({
				isFetching: true
			});
		case TYPE.SCIENTIFIC_COUNCLI.GET_SCIENTIFIC_COUNCLI_FAILURE:
		case TYPE.SCIENTIFIC_COUNCLI.CREATE_SCIENTIFIC_COUNCLI_FAILURE:
		case TYPE.SCIENTIFIC_COUNCLI.UPDATE_SCIENTIFIC_COUNCLI_FAILURE:
		case TYPE.SCIENTIFIC_COUNCLI.DELETE_SCIENTIFIC_COUNCLI_FAILURE:
			return state.merge({
				isFetching: false
			});
		case TYPE.SCIENTIFIC_COUNCLI.GET_SCIENTIFIC_COUNCLI_SUCCESS:
			return state.merge({
				isFetching: false,
				scientificCounclis: [...state.get('scientificCounclis'), ...action.payload]
			});
		case TYPE.SCIENTIFIC_COUNCLI.CREATE_SCIENTIFIC_COUNCLI_SUCCESS:
			return state.merge({
				isFetching: false,
				scientificCounclis: [...state.get('scientificCounclis'), action.payload]
			});
		// case TYPE.SCIENTIFIC_COUNCLI.UPDATE_SCIENTIFIC_COUNCLI_SUCCESS:
		//   return {
		//     ...state,
		//     isFetching: false,
		//     scientificCounclis: [...state.scientificCounclis.map((s) => s.id !== action.payload.id ? s : action.payload)]
		//   }
		// case TYPE.SCIENTIFIC_COUNCLI.DELETE_SCIENTIFIC_COUNCLI_SUCCESS:
		//   return {
		//     ...state,
		//     isFetching: false,
		//     scientificCounclis: [...state.scientificCounclis.filter((s) => s.id !== action.payload.id )]
		//   }
		default:
			return state;
	}
};

export default scientificCouncilReducer;
