import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getFilteredMonograph } from '../../../reducer/monograph/action';

function ListMonograph(props) {
	useEffect(() => {
		props.getFilteredMonograph(1, -1, {
			// accepted: true,
			dashboardYear: props.chosenYear,
			departmentId: props.chosenDepartmentId,
			acceptedOne: true
		});
	}, [props.chosenYear]);

	const getMonographType = (type) => {
		switch (type) {
			case 'monograph':
				return 'Sách chuyên khảo';
			case 'curriculum':
				return 'Sách giáo khoa';
			case 'reference':
				return 'Sách tham khảo';
			case 'instruction':
				return 'Sách hướng dẫn';
			case 'chapter':
				return 'Chương sách';
			default:
				return '';
		}
	};

	const getMonographTitle = (info, index) => {
		return (
			<div key={`monograph-${index}`} style={{ marginTop: 16 }}>
				<div>
					<b style={{ color: 'gray' }}>
						{index + 1}. Tên {getMonographType(info.monograph.type)}:{' '}
					</b>
					<i style={{ color: 'red' }}> {info.monograph.title}</i>
				</div>
				<div style={{ marginLeft: 20 }}>
					<b>- Tác giả: </b>
					{info.monograph.listMember?.join(', ') || ''}
					<br />
					<b>- ISBN: </b>
					{info.monograph.isbn || ''}
					<br />
					<b>- Nhà xuất bản: </b>
					{info.monograph.publisher || ''}
					<br />
					<b>- Năm xuất bản: </b>
					{info.monograph.year || ''}
					<br />
				</div>
			</div>
		);
	};

	return props.isFetching ? (
		<div style={{ textAlign: 'center' }}>
			<CircularProgress />
		</div>
	) : (
		<Box
			paddingRight={1}
			height="calc(100vh - 214px)"
			minWidth="calc(100% + 8px)"
			style={{ overflowY: 'auto' }}
		>
			{props.listMonographAccepted.map((item, index) => getMonographTitle(item, index))}
		</Box>
	);
}

const mapStateToProps = (state) => ({
	listMonographAccepted: select(state, 'monographReducer', 'listFiltered'),
	isFetching: select(state, 'monographReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	getFilteredMonograph: (page, pageSize, payload) =>
		dispatch(getFilteredMonograph(page, pageSize, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ListMonograph));
