import React from 'react';

import { KPTextform } from '../../../../components/KPTComponents';

function StopForm(props) {
	const { values, setValues } = props;

	const textForm = [
		{
			key: 'leaveReason',
			label: 'Lý do xin dừng thực hiện đề tài',
			xs: 12,
			height: 6,
			required: true,
			disabled: props.open?.reportStop
		},
		{
			fieldOptions: { width: 'calc(100% - 32px)' },
			key: 'leaveFileLink',
			type: 'dragzone',
			label: 'File đơn xin dừng thực hiện đề tài',
			xs: 12,
			required: true
		}
	];

	const handleSubmit = (values) => props.handleSubmit(values);

	return (
		<KPTextform
			id="reportForm"
			textForm={textForm}
			values={values}
			setValues={setValues}
			handleSubmit={handleSubmit}
		/>
	);
}

export default StopForm;
