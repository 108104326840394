import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { PREFIX } from '../../constants/enums';
import { API_URLS } from '../../constants/api';

import { notification } from '../home/action';

export const getAllUsers = () => async (dispatch) => {
	const apiGetAllUsers = API_URLS.USER.getAllUsers();
	const apiGetAllProfiles = API_URLS.PROFILE.getAllProfiles();
	dispatch({
		type: TYPE.HUMAN_RESOURCE.GET_ALL_USERS_LOADING,
		meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_CALLING] }
	});

	const { response: responseUsers, error: errorUsers } = await apiCall({ ...apiGetAllUsers });
	const { response: responseProfiles, error: errorProfiles } = await apiCall({
		...apiGetAllProfiles
	});

	if (
		!errorUsers &&
		responseUsers.status === 200 &&
		responseUsers.data.success &&
		!errorProfiles &&
		responseProfiles.status === 200 &&
		responseProfiles.data.success
	) {
		const users = responseUsers.data.data.filter((userElement) => userElement.id !== 0);
		const profiles = responseProfiles.data.data;
		for (let user of users) {
			user.profile = profiles.find((profileElement) => profileElement.userId === user.id);
		}
		dispatch({
			type: TYPE.HUMAN_RESOURCE.GET_ALL_USERS_SUCCESS,
			payload: users,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Lấy danh sách nhân sự thành công'
			})
		);
	} else {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.GET_ALL_USERS_FAILURE,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Không lấy được danh sách nhân sự'
			})
		);
	}
};

export const updateRole = (userId, role, cleanUp) => async (dispatch) => {
	const apiUpdateRole = API_URLS.USER.updateRole();
	dispatch({
		type: TYPE.HUMAN_RESOURCE.UPDATE_ROLE_LOADING,
		meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_CALLING] }
	});
	const payload = { role, userId };
	const { response, error } = await apiCall({ ...apiUpdateRole, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.UPDATE_ROLE_SUCCESS,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_SUCCESS] },
			payload: response.data.data
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Gán quyền thành công'
			})
		);
	} else {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.UPDATE_ROLE_FAILURE,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Gán quyền thất bại'
			})
		);
	}

	if (typeof cleanUp === 'function') {
		cleanUp();
	}
};

export const deleteUserById = (userId, cleanUp) => async (dispatch) => {
	const apiDeleteUserById = API_URLS.USER.deleteUserById(userId);
	dispatch({
		type: TYPE.HUMAN_RESOURCE.DELETE_USER_LOADING,
		meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...apiDeleteUserById });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.DELETE_USER_SUCCESS,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_SUCCESS] },
			payload: userId
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Xóa tài khoản thành công'
			})
		);
	} else {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.DELETE_USER_FAILURE,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Xóa tài khoản thất bại'
			})
		);
	}

	if (typeof cleanUp === 'function') {
		cleanUp();
	}
};

export const insertUser = (payload, callbackOnSuccess) => async (dispatch) => {
	const api = API_URLS.USER.createNewUser();
	dispatch({
		type: TYPE.HUMAN_RESOURCE.CREATE_USER_LOADING,
		meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({
		...api,
		payload: payload
	});
	if (!error && response.status === 200 && response.data.success) {
		callbackOnSuccess(response.data.data);
		dispatch({
			type: TYPE.HUMAN_RESOURCE.CREATE_USER_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Tạo user thành công'
			})
		);
	} else if (!error && response.status === 200) {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.CREATE_USER_FAILURE,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_FAILURE] }
		});
		if (response.data.message.includes('duplicate')) {
			dispatch(
				notification({
					type: 'error',
					message: 'Tạo user thất bại. Tài khoản đã tồn tại'
				})
			);
		}
	} else {
		dispatch({
			type: TYPE.HUMAN_RESOURCE.CREATE_USER_FAILURE,
			meta: { prefix: [PREFIX.HUMANRESOURCE, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Tạo user thất bại. Lỗi không xác định'
			})
		);
	}
};
