import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HumanResourceButton from '../../containers/HumanResource/HumanResourceButton';
import HumanResourceTable from '../../containers/HumanResource/HumanResourceTable';

const useStyles = makeStyles((theme) => {
	return {
		card: {
			padding: theme.spacing(2),
			minHeight: 'calc(100vh - 124px)',
			maxHeight: 'calc(100vh - 124px)'
		}
	};
});

function HumanResource() {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<HumanResourceButton />
			<HumanResourceTable />
		</Card>
	);
}

export default HumanResource;
