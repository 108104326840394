import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Box, Button, Divider, TextField } from '@material-ui/core';

import styles from './index.module.css';

const monthList = [
	{ value: 1, name: 'Tháng 01' },
	{ value: 2, name: 'Tháng 02' },
	{ value: 3, name: 'Tháng 03' },
	{ value: 4, name: 'Tháng 04' },
	{ value: 5, name: 'Tháng 05' },
	{ value: 6, name: 'Tháng 06' },
	{ value: 7, name: 'Tháng 07' },
	{ value: 8, name: 'Tháng 08' },
	{ value: 9, name: 'Tháng 09' },
	{ value: 10, name: 'Tháng 10' },
	{ value: 11, name: 'Tháng 11' },
	{ value: 12, name: 'Tháng 12' }
];

function KPTimePickerForm(props) {
	const dateRef = React.useRef();

	const [date, setDate] = useState(1);
	const [month, setMonth] = useState(1);
	const [year, setYear] = useState(2023);
	const [isFocused, setFocus] = useState(false);

	const yearList = [];
	for (let i = 1900; i <= 2050; i++) yearList.push(i);

	useEffect(() => {
		if (props.open) {
			const valueList = props?.value?.split('/');
			if (!isNaN(parseInt(valueList[0]))) setDate(parseInt(valueList[0]));
			if (!isNaN(parseInt(valueList[1]))) setMonth(parseInt(valueList[1]));
			if (!isNaN(parseInt(valueList[2]))) setYear(parseInt(valueList[2]));
		}
	}, [props.open, props.value]);

	const handleClose = () => props.handleClose();
	const handleDatePlus = (num) => setDate(parseInt(date) + num);

	const handleSubmit = () => {
		props.handleChangeForm(
			dayjs(`${month}/${date}/${year}`).format('DD-MM-YYYY').replaceAll('-', '/')
		);
		handleClose();
	};

	return (
		<form>
			<Box display="flex" width="100%">
				<Box width="30%">
					<TextField
						fullWidth
						type="tel"
						size="small"
						label="Ngày"
						variant="outlined"
						disabled={props.disabledDate}
						value={`${parseInt(date) < 10 ? 0 : ''}${parseInt(date)}`}
						onChange={(e) => setDate(parseInt(e.target.value) || 0)}
						inputRef={dateRef}
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
						InputProps={{
							style: { paddingRight: 0 },
							endAdornment: (
								<Box display="flex">
									<Divider
										orientation="vertical"
										style={{
											height: 40,
											backgroundColor: isFocused ? '#243474' : '#bdbdbd',
											width: isFocused ? 2 : 1
										}}
									/>
									<Box
										width={20}
										display="flex"
										flexDirection="column"
										justifyContent="center"
										alignItems="center"
									>
										<ExpandLess
											className={props.disabled ? styles.numDisabledIcon : styles.numIcon}
											onClick={() => {
												if (!props.disabled) handleDatePlus(1);
											}}
											style={{ height: `calc(50% - ${isFocused ? 1 : 0.5}px)` }}
										/>
										<Divider
											style={{
												width: 20,
												backgroundColor: isFocused ? '#243474' : '#bdbdbd',
												height: isFocused ? 2 : 1
											}}
										/>
										<ExpandMore
											className={props.disabled ? styles.numDisabledIcon : styles.numIcon}
											onClick={() => {
												if (!props.disabled) handleDatePlus(-1);
											}}
											style={{ height: `calc(50% - ${isFocused ? 2 : 0.5}px)` }}
										/>
									</Box>
								</Box>
							)
						}}
					/>
				</Box>
				<Box width="calc(40% - 16px)" margin="0 8px">
					<TextField
						select
						fullWidth
						size="small"
						label="Tháng"
						variant="outlined"
						value={month}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						onChange={(e) => setMonth(e.target.value)}
					>
						{monthList.map((e) => (
							<option key={`month-${e.value}`} value={e.value}>
								{e.name}
							</option>
						))}
					</TextField>
				</Box>
				<Box width="30%">
					<Autocomplete
						options={yearList}
						disableClearable
						filterSelectedOptions
						getOptionLabel={(option) => `${option}`}
						onChange={(_, val) => setYear(val)}
						value={year}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								label="Năm"
								variant="outlined"
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
				</Box>
			</Box>
			<Button
				fullWidth
				color="primary"
				variant="contained"
				style={{ marginTop: 8 }}
				onClick={handleSubmit}
			>
				Xác nhận
			</Button>
		</form>
	);
}

export default KPTimePickerForm;
