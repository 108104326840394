import React from 'react';
import { useContext } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Grid,
	Typography,
	TextField,
	MenuItem,
	Button,
	IconButton,
	Chip,
	Checkbox,
	FormControlLabel,
	InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Add, WarningOutlined } from '@material-ui/icons';
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import toJS from '../../../../hoc/toJS';

import { insertEmploymentContract } from '../../../../reducer/employmentContract/action';

import { EmploymentContractInsertContext } from '../EmploymentContractInsertContext';

const employmentContractSchema = yup.object().shape({
	infinite: yup.boolean().default(false),
	duration: yup.string().when('infinite', {
		is: false,
		then: yup
			.string()
			.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
			.required('Không được để trống'),
		otherwise: yup.string().default('1000')
	}),
	startAt: yup.date().required('Không được để trống'),
	finishAt: yup.string(),
	kpiCompleted: yup.boolean(),
	kpi: yup.object().shape({
		type: yup.string().required('Không được để trống'),
		year: yup
			.string()
			.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
			.required('Không được để trống'),
		kpiScientificResearch: yup.array().when('type', {
			is: 'R',
			then: yup.array().of(
				yup.object().shape({
					num: yup
						.string()
						.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
						.required('Không được để trống'),
					numFloat: yup
						.string()
						.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
						.required('Không được để trống'),
					typeQ: yup
						.string()
						.oneOf(['Q1', 'Q2', 'Q3', 'Q4', 'Scopus'])
						.required('Không được để trống'),
					typeR: yup.string().when('typeQ', {
						is: 'Scopus',
						then: yup.string(),
						otherwise: yup
							.string()
							.oneOf(['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'TCN'])
							.required('Không được để trống')
					}),
					roleInPaper: yup.string().oneOf(['main', 'member']).required('Không được để trống')
				})
			),
			otherwise: yup.array().of(
				yup.object().shape({
					num: yup
						.string()
						.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
						.required('Không được để trống'),
					numFloat: yup
						.string()
						.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
						.required('Không được để trống'),
					typeQ: yup
						.string()
						.oneOf(['Q1', 'Q2', 'Q3', 'Q4', 'Scopus'])
						.required('Không được để trống'),
					typeR: yup.string(),
					roleInPaper: yup.string().oneOf(['main', 'member']).required('Không được để trống')
				})
			)
		}),
		// kpiScientificResearch: yup.array().of(
		//   yup.object().shape({
		//     num: yup
		//       .string()
		//       .matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
		//       .required('Không được để trống'),
		//     numFloat: yup
		//       .string()
		//       .matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
		//       .required('Không được để trống'),
		//     typeQ: yup.string().oneOf(['Q1', 'Q2', 'Q3', 'Q4']).required('Không được để trống'),
		//     typeR: yup.string().oneOf(['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'TCN']).required('Không được để trống'),
		//     roleInPaper: yup.string().oneOf(['main', 'member']).required('Không được để trống'),
		//     // value: yup
		//     //   .string()
		//     //   .matches(/^[1-9]/, 'Nhập số nguyên lớn hơn không')
		//     //   .required('Không được để trống'),
		//   })
		// ),
		kpiPatent: yup.array().of(
			yup.object().shape({
				type: yup
					.string()
					.oneOf(
						[
							'USPTO (Utility)-TGC',
							'USPTO (Design)-TGC',
							'USPTO (Plant)-TGC',
							'EPO-TGC',
							'Bằng sáng chế (VN)-TGC'
						],
						'Chọn một trong 5 loại đã cho'
					)
					.required('Không được để trống'),
				num: yup
					.string()
					.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
					.required('Không được để trống'),
				value: yup
					.string()
					.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
					.required('Không được để trống')
			})
		),
		kpiProductContract: yup.array().of(
			yup.object().shape({
				value: yup
					.string()
					.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
					.required('Không được để trống'),
				num: yup
					.string()
					.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
					.required('Không được để trống')
			})
		),
		kpiSolution: yup.array().of(
			yup.object().shape({
				value: yup
					.string()
					.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
					.required('Không được để trống'),
				num: yup
					.string()
					.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
					.required('Không được để trống')
			})
		)
	})
});

const employmentContractInitialValues = {
	infinite: false,
	duration: 1,
	startAt: dayjs().format('YYYY-MM-DD'),
	finishAt: dayjs().add(1, 'years').format('YYYY-MM-DD'),
	kpiCompleted: false,
	kpi: {
		type: 'Q',
		year: new Date().getFullYear(),
		kpiScientificResearch: [], // [{ num: 0, typeQ: 'Q1', typeR: 'R1', roleInPaper: 'main', value: 0 }],
		kpiPatent: [], // [{ num: 1, type: 'Bằng sáng chế (VN)-TGC' }],
		kpiProductContract: [], // [{ num: 1, value: 10_000_000 }],
		kpiSolution: [] // [{ num: 1, value: 10_000_000 }],
	}
};

const useStyles = makeStyles((theme) => {
	return {
		kpiScientificResearch: {
			margin: theme.spacing(1, 0)
		}
	};
});

function EmploymentContractInsertStep(props) {
	const { setInserting } = props;

	const { profile, setProfile, setCurrentStep } = useContext(EmploymentContractInsertContext);

	const numberWithDots = (num) => {
		return num
			.toString()
			.replace(/\./g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	const handleCloseEmploymentContractInsertModal = () => {
		setInserting(false);
		setProfile(null);
		setCurrentStep(0);
	};
	const classes = useStyles();

	if (profile === null) {
		return (
			<Box textAlign="center">
				<WarningOutlined />
				<Typography>Chưa chọn nhân sự</Typography>
			</Box>
		);
	}
	return (
		<Box>
			<Formik
				initialValues={employmentContractInitialValues}
				validationSchema={employmentContractSchema}
				onSubmit={(values) => {
					const contract = {
						...values,
						active: true,
						duration: values.infinite ? 1000 : Number(values.duration),
						profileId: profile.id,
						startAt: dayjs(values.startAt).format('YYYY-MM-DDThh:mm:ss[Z]'),
						finishAt: dayjs(values.startAt)
							.add(values.infinite ? 1000 : Number(values.duration), 'years')
							.format('YYYY-MM-DDThh:mm:ss[Z]'),
						kpi: null,
						listKpi: [
							{
								...values.kpi,
								year: parseInt(values.kpi.year),
								kpiScientificResearch: values.kpi.kpiScientificResearch.map((element) => {
									return {
										...element,
										num: Number(Math.round(element.numFloat)),
										value: 0,
										numFloat: Number(element.numFloat)
									};
								}),
								kpiPatent: (values.kpi.kpiPatent ?? []).map((element) => {
									return { ...element, num: Number(element.num), value: Number(element.value) };
								}),
								kpiProductContract: (values.kpi.kpiProductContract ?? []).map((element) => {
									return { ...element, num: Number(element.num), value: Number(element.value) };
								}),
								kpiSolution: (values.kpi.kpiSolution ?? []).map((element) => {
									return { ...element, num: Number(element.num), value: Number(element.value) };
								}),
								numSolution: (values.kpi.kpiSolution ?? []).length
							}
						]
					};
					props.insertEmploymentContract({
						profile: profile,
						contract: contract
					});
					handleCloseEmploymentContractInsertModal();
				}}
			>
				{(formikProps) => {
					const { values, handleSubmit } = formikProps;
					return (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography>Thời gian</Typography>
							</Grid>
							{values.infinite && (
								<>
									<Grid item xs={2}>
										<Field type="checkbox" name="infinite">
											{(fieldProps) => {
												return (
													<FormControlLabel
														label="Vô thời hạn"
														control={<Checkbox {...fieldProps.field} />}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={5}>
										<Field name="startAt">
											{(fieldProps) => {
												return (
													<TextField
														fullWidth
														variant="outlined"
														type="date"
														size="small"
														label="Bắt đầu"
														{...fieldProps.field}
														helperText={<ErrorMessage name="startAt" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={5}>
										<Field name="kpi.year">
											{(fieldProps) => {
												return (
													<TextField
														fullWidth
														variant="outlined"
														size="small"
														label="Năm đầu tiên của hợp đồng"
														{...fieldProps.field}
														helperText={<ErrorMessage name="kpi.year" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Grid>
								</>
							)}
							{!values.infinite && (
								<>
									<Grid item xs={2}>
										<Field name="infinite">
											{(fieldProps) => {
												return (
													<FormControlLabel
														label="Vô thời hạn"
														control={<Checkbox {...fieldProps.field} />}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={3}>
										<Field name="duration">
											{(fieldProps) => {
												return (
													<TextField
														fullWidth
														variant="outlined"
														size="small"
														label="Thời hạn (năm)"
														{...fieldProps.field}
														helperText={<ErrorMessage name="duration" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={4}>
										<Field name="startAt">
											{(fieldProps) => {
												return (
													<TextField
														fullWidth
														variant="outlined"
														type="date"
														size="small"
														label="Bắt đầu"
														{...fieldProps.field}
														helperText={<ErrorMessage name="startAt" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={3}>
										<Field name="kpi.year">
											{(fieldProps) => {
												return (
													<TextField
														fullWidth
														variant="outlined"
														size="small"
														label="Năm đầu tiên của hợp đồng"
														{...fieldProps.field}
														helperText={<ErrorMessage name="kpi.year" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Grid>
								</>
							)}
							<Grid item xs={4}>
								<Field type="checkbox" name="kpi.type">
									{(fieldProps) => {
										return (
											<TextField
												select
												fullWidth
												variant="outlined"
												size="small"
												label="Loại hợp đồng"
												{...fieldProps.field}
												helperText={<ErrorMessage name="kpi.type" />}
												FormHelperTextProps={{ error: true }}
												InputLabelProps={{ shrink: true }}
											>
												<MenuItem key="Q" value="Q">
													Hợp đồng chỉ tính theo Q
												</MenuItem>
												<MenuItem key="R" value="R">
													Hợp đồng tính theo Q và R
												</MenuItem>
											</TextField>
										);
									}}
								</Field>
							</Grid>
							<Grid item xs={12}>
								<Chip
									variant="outlined"
									size="small"
									color="secondary"
									label={
										values.infinite
											? `KPI đăng ký dưới đây được áp dụng cho năm ${values.kpi.year}`
											: `KPI đăng ký dưới đây được áp dụng cho năm đầu tiên của hợp đồng`
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography>Số lượng bài báo xếp hạng Q, R</Typography>
							</Grid>
							<FieldArray
								name="kpi.kpiScientificResearch"
								render={(arrayHelpers) => {
									return (
										<React.Fragment>
											{values.kpi.kpiScientificResearch.map((_, index) => {
												return (
													<Grid item xs={12} key={index} className={classes.kpiScientificResearch}>
														<Grid container spacing={2}>
															<Grid item xs={3}>
																<Field name={`kpi.kpiScientificResearch[${index}].typeQ`}>
																	{(fieldProps) => {
																		return (
																			<TextField
																				{...fieldProps.field}
																				select
																				fullWidth
																				variant="outlined"
																				label="Xếp hạng Q"
																				size="small"
																				helperText={
																					<ErrorMessage
																						name={`kpi.kpiScientificResearch[${index}].typeQ`}
																					/>
																				}
																				FormHelperTextProps={{ error: true }}
																			>
																				<MenuItem value="Q1">Q1</MenuItem>
																				<MenuItem value="Q2">Q2</MenuItem>
																				<MenuItem value="Q3">Q3</MenuItem>
																				<MenuItem value="Q4">Q4</MenuItem>
																				<MenuItem value="Scopus">Scopus</MenuItem>
																			</TextField>
																		);
																	}}
																</Field>
															</Grid>
															{values.kpi.type === 'R' &&
																values.kpi.kpiScientificResearch[index].typeQ !== 'Scopus' && (
																	<Grid item xs={3}>
																		<Field name={`kpi.kpiScientificResearch[${index}].typeR`}>
																			{(fieldProps) => {
																				return (
																					<TextField
																						{...fieldProps.field}
																						select
																						fullWidth
																						variant="outlined"
																						label="Xếp hạng R"
																						size="small"
																						helperText={
																							<ErrorMessage
																								name={`kpi.kpiScientificResearch[${index}].typeR`}
																							/>
																						}
																						FormHelperTextProps={{ error: true }}
																					>
																						<MenuItem value="TCN">TCN</MenuItem>
																						<MenuItem value="R1">R1</MenuItem>
																						<MenuItem value="R2">R2</MenuItem>
																						<MenuItem value="R3">R3</MenuItem>
																						<MenuItem value="R4">R4</MenuItem>
																						<MenuItem value="R5">R5</MenuItem>
																						<MenuItem value="R6">R6</MenuItem>
																						<MenuItem value="R7">R7</MenuItem>
																						<MenuItem value="R8">R8</MenuItem>
																						<MenuItem value="R9">R9</MenuItem>
																						<MenuItem value="R10">R10</MenuItem>
																					</TextField>
																				);
																			}}
																		</Field>
																	</Grid>
																)}
															<Grid item xs={3}>
																<Field name={`kpi.kpiScientificResearch[${index}].roleInPaper`}>
																	{(fieldProps) => {
																		return (
																			<TextField
																				select
																				fullWidth
																				variant="outlined"
																				size="small"
																				{...fieldProps.field}
																			>
																				<MenuItem value="main">Tác giả chính</MenuItem>
																				<MenuItem value="member">Đồng tác giả</MenuItem>
																			</TextField>
																		);
																	}}
																</Field>
															</Grid>
															<Grid item xs={2}>
																<Field name={`kpi.kpiScientificResearch[${index}].numFloat`}>
																	{(fieldProps) => {
																		return (
																			<TextField
																				fullWidth
																				variant="outlined"
																				label="Số lượng"
																				size="small"
																				{...fieldProps.field}
																				helperText={
																					<ErrorMessage
																						name={`kpi.kpiScientificResearch[${index}].numFloat`}
																					/>
																				}
																				FormHelperTextProps={{ error: true }}
																				InputLabelProps={{ shrink: true }}
																			/>
																		);
																	}}
																</Field>
															</Grid>
															<Grid item xs={1}>
																<IconButton
																	color="secondary"
																	onClick={() => arrayHelpers.remove(index)}
																>
																	<Delete />
																</IconButton>
															</Grid>
														</Grid>
													</Grid>
												);
											})}
											<Grid item xs={12}>
												<Button
													variant="outlined"
													color="primary"
													size="medium"
													startIcon={<Add />}
													onClick={() =>
														arrayHelpers.push({
															num: 0,
															numFloat: '',
															typeQ: '',
															typeR: '',
															roleInPaper: 'main',
															value: 0
														})
													}
												>
													Thêm bài báo
												</Button>
											</Grid>
										</React.Fragment>
									);
								}}
							/>
							<Grid item xs={12}>
								<Typography>Sáng chế</Typography>
							</Grid>
							<FieldArray
								name="kpi.kpiPatent"
								render={(arrayHelpers) => {
									return (
										<>
											{values.kpi.kpiPatent.map((_, index) => {
												return (
													<React.Fragment key={index}>
														<Grid item xs={4}>
															<Field name={`kpi.kpiPatent[${index}].type`}>
																{(fieldProps) => {
																	return (
																		<TextField
																			select
																			fullWidth
																			size="small"
																			variant="outlined"
																			{...fieldProps.field}
																			helperText={
																				<ErrorMessage name={`kpi.kpiPatent[${index}].patentType`} />
																			}
																			FormHelperTextProps={{ error: true }}
																		>
																			<MenuItem value="USPTO (Utility)-TGC">
																				USPTO (Utility)-TGC
																			</MenuItem>
																			<MenuItem value="USPTO (Design)-TGC">
																				USPTO (Design)-TGC
																			</MenuItem>
																			<MenuItem value="USPTO (Plant)-TGC">
																				USPTO (Plant)-TGC
																			</MenuItem>
																			<MenuItem value="EPO-TGC">EPO-TGC</MenuItem>
																			<MenuItem value="Bằng sáng chế (VN)-TGC">
																				Bằng sáng chế (VN)-TGC
																			</MenuItem>
																		</TextField>
																	);
																}}
															</Field>
														</Grid>
														<Grid item xs={3}>
															<Field name={`kpi.kpiPatent[${index}].value`}>
																{(fieldProps) => {
																	return (
																		<TextField
																			fullWidth
																			variant="outlined"
																			label="Giá trị cho 1 sản phẩm"
																			size="small"
																			{...fieldProps.field}
																			helperText={
																				<ErrorMessage name={`kpi.kpiPatent[${index}].value`} />
																			}
																			FormHelperTextProps={{ error: true }}
																			InputLabelProps={{ shrink: true }}
																			InputProps={{
																				endAdornment: (
																					<InputAdornment position="end">VND</InputAdornment>
																				)
																			}}
																		/>
																	);
																}}
															</Field>
														</Grid>
														<Grid item xs={3}>
															<Field name={`kpi.kpiPatent[${index}].num`}>
																{(fieldProps) => {
																	return (
																		<TextField
																			fullWidth
																			variant="outlined"
																			label="Số lượng"
																			size="small"
																			{...fieldProps.field}
																			helperText={
																				<ErrorMessage name={`kpi.kpiPatent[${index}].num`} />
																			}
																			FormHelperTextProps={{ error: true }}
																			InputLabelProps={{ shrink: true }}
																		/>
																	);
																}}
															</Field>
														</Grid>
														<Grid item xs={2}>
															<IconButton
																color="secondary"
																onClick={() => arrayHelpers.remove(index)}
															>
																<Delete />
															</IconButton>
														</Grid>
													</React.Fragment>
												);
											})}
											<Grid item xs={12}>
												<Button
													variant="outlined"
													color="primary"
													size="medium"
													startIcon={<Add />}
													onClick={() =>
														arrayHelpers.push({
															num: 1,
															type: 'Bằng sáng chế (VN)-TGC',
															value: 10_000_000
														})
													}
												>
													Thêm sáng chế
												</Button>
											</Grid>
										</>
									);
								}}
							/>

							<Grid item xs={12}>
								<Typography>Giải pháp hữu ích</Typography>
							</Grid>
							<FieldArray
								name="kpi.kpiSolution"
								render={(arrayHelpers) => {
									return (
										<>
											{values.kpi.kpiSolution.map((_, index) => {
												return (
													<React.Fragment key={index}>
														<Grid item xs={5}>
															<Field name={`kpi.kpiSolution[${index}].value`}>
																{(fieldProps) => {
																	return (
																		<TextField
																			fullWidth
																			size="small"
																			label="Giá trị cho 1 sản phẩm"
																			variant="outlined"
																			{...fieldProps.field}
																			helperText={
																				<ErrorMessage name={`kpi.kpiSolution[${index}].value`} />
																			}
																			FormHelperTextProps={{ error: true }}
																			InputLabelProps={{ shrink: true }}
																			InputProps={{
																				endAdornment: (
																					<InputAdornment position="end">VND</InputAdornment>
																				)
																			}}
																		/>
																	);
																}}
															</Field>
														</Grid>
														<Grid item xs={5}>
															<Field name={`kpi.kpiSolution[${index}].num`}>
																{(fieldProps) => {
																	return (
																		<TextField
																			fullWidth
																			variant="outlined"
																			label="Số lượng"
																			size="small"
																			{...fieldProps.field}
																			helperText={
																				<ErrorMessage name={`kpi.kpiSolution[${index}].num`} />
																			}
																			FormHelperTextProps={{ error: true }}
																			InputLabelProps={{ shrink: true }}
																		/>
																	);
																}}
															</Field>
														</Grid>
														<Grid item xs={2}>
															<IconButton
																color="secondary"
																onClick={() => arrayHelpers.remove(index)}
															>
																<Delete />
															</IconButton>
														</Grid>
													</React.Fragment>
												);
											})}
											<Grid item xs={12}>
												<Button
													variant="outlined"
													color="primary"
													size="medium"
													startIcon={<Add />}
													onClick={() =>
														arrayHelpers.push({
															num: 1,
															value: 10_000_000
														})
													}
												>
													Thêm giải pháp hữu ích
												</Button>
											</Grid>
										</>
									);
								}}
							/>

							<Grid item xs={12}>
								<Box textAlign="right">
									<Button variant="contained" color="primary" onClick={handleSubmit}>
										Tạo hợp đồng
									</Button>
								</Box>
							</Grid>
						</Grid>
					);
				}}
			</Formik>
		</Box>
	);
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
	insertEmploymentContract: (payload) => dispatch(insertEmploymentContract(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EmploymentContractInsertStep));
