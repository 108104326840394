import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	isFetching: false,
	finishContractStatistic: [],
	reviewContractStatistic: [],
	scientificResearchStatistic: [],
	rewardContractStatistic: [],
	dataScientificResearch: [],
	dataSolution: [],
	dataPatent: [],
	dataMonograph: []
});

export default function solutionReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.STATISTIC.GET_FINISH_CONTRACT_STATISTIC_LOADING:
		case TYPE.STATISTIC.GET_REVIEW_CONTRACT_STATISTIC_LOADING:
		case TYPE.STATISTIC.GET_SCIENTIFIC_RESEARCH_STATISTIC_LOADING:
		case TYPE.STATISTIC.GET_CTKH_STATISTIC_LOADING:
		case TYPE.STATISTIC.GET_REWARD_CONTRACT_STATISTIC_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.STATISTIC.GET_FINISH_CONTRACT_STATISTIC_FAILURE:
		case TYPE.STATISTIC.GET_REVIEW_CONTRACT_STATISTIC_FAILURE:
		case TYPE.STATISTIC.GET_SCIENTIFIC_RESEARCH_STATISTIC_FAILURE:
		case TYPE.STATISTIC.GET_CTKH_STATISTIC_FAILURE:
		case TYPE.STATISTIC.GET_REWARD_CONTRACT_STATISTIC_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.STATISTIC.GET_FINISH_CONTRACT_STATISTIC_SUCCESS:
			return state.merge({
				finishContractStatistic: action.payload || [],
				isFetching: false
			});

		case TYPE.STATISTIC.GET_REVIEW_CONTRACT_STATISTIC_SUCCESS:
			return state.merge({
				reviewContractStatistic: action.payload || [],
				isFetching: false
			});

		case TYPE.STATISTIC.GET_SCIENTIFIC_RESEARCH_STATISTIC_SUCCESS:
			return state.merge({
				scientificResearchStatistic: action.payload || [],
				isFetching: false
			});

		case TYPE.STATISTIC.GET_CTKH_STATISTIC_SUCCESS:
			return state.merge({
				dataScientificResearch: action.payload.dataScientificResearch || [],
				dataSolution: action.payload.dataSolution || [],
				dataPatent: action.payload.dataPatent || [],
				dataMonograph: action.payload.dataMonograph || [],
				isFetching: false
			});

		case TYPE.STATISTIC.GET_REWARD_CONTRACT_STATISTIC_SUCCESS:
			return state.merge({
				rewardContractStatistic: action.payload || [],
				isFetching: false
			});

		// Other
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_SUCCESS: {
			let tempRewardContractStatistic = state.get('rewardContractStatistic');
			tempRewardContractStatistic = tempRewardContractStatistic.map((cc) => {
				if (cc?.contract?.listKpi[0]?.id === action.id) {
					return {
						...cc,
						contract: {
							...cc.contract,
							listKpi: [
								{
									...cc.contract.listKpi[0],
									completed: !cc.contract.listKpi[0].completed
								}
							]
						}
					};
				} else return cc;
			});
			return state.merge({
				rewardContractStatistic: tempRewardContractStatistic,
				isFetchingKPI: false
			});
		}

		default:
			return state;
	}
}
