import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Button,
	Select,
	Divider,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	InputAdornment,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormHelperText,
	MenuItem
} from '@material-ui/core';
import dayjs from 'dayjs';
import { Autocomplete } from '@material-ui/lab';
import { DoubleArrow, CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import FileUploadModal from '../../File/FileUploadModal';
import KPTimePicker from '../../../components/KPTimePicker';
import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';

import {
	insertProject,
	updateProject,
	getProfileInfoByProfileId
} from '../../../reducer/project/action';
import { uploadFile } from '../../../reducer/file/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import { getAllEmploymentContractByProfileId } from '../../../reducer/employmentContract/action';
import moment from 'moment';
import ScientificResearchTimeTable from '../../ScientificResearch/ScientificResearchTimeTable/ScientificResearchTimeTable';

function ProjectInsertModal(props) {
	const { inserting, setInserting } = props;

	const currentYear = new Date().getFullYear();

	const [detail, setDetail] = useState(null);
	const [values, setValues] = useState({
		startAt: `01/01/${currentYear}`,
		finishAt: `01/01/${currentYear}`,
		purposeId: 3
	});
	const [profileList, setProfileList] = useState([]);
	const [timeResearchYear, setTimeResearchYear] = useState(2023);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [files, setFiles] = useState([]);
	const [helperText, setHelperText] = useState(false);

	// Tự gán bản thân vào danh sách tác giả
	React.useEffect(() => {
		if (props.profile?.id && profileList.length === 0) {
			setProfileList([
				{
					name: props.profile.name,
					code: props.profile.code,
					profileId: props.profile.id,
					roleInProject: 'main'
				}
			]);
		}
	}, [props.inserting, props.profile?.id]);
	// Chọn thành viên để lấy đề tài thì load lại đề tài của ông ấy
	React.useEffect(() => {
		if (values?.profileIdToSelectId) props.getProfileInfoByProfileId(values?.profileIdToSelectId);
	}, [values?.profileIdToSelectId]);

	const dateFormat = (value) => {
		const dateForm = value?.replaceAll('/', '');
		const date = dateForm?.substr(0, 2);
		const month = dateForm?.substr(2, 2);
		const year = dateForm?.substr(-4);
		return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
	};

	const valueFormat = (value) => {
		const temp = value.replace(/\D/g, '').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.');
		return temp;
	};

	const handleChangeForm = (name, value) => {
		if (name === 'timeResearchDetailCode') {
			setValues((current) => ({
				...current,
				[name]: value === 'null' ? null : value
			}));
		} else setValues((current) => ({ ...current, [name]: value }));
	};

	const handleChangeProfileList = (index, name, value) => {
		setProfileList((temp) => {
			const tempElm = profileList[index];
			temp.splice(index, 1, { ...tempElm, [name]: value });
			return [...temp];
		});
	};

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInProject: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		const temp = profileList;
		temp.splice(index, 1);
		setProfileList([...temp]);
	};

	const handleSubmit = (e) => {
		// e.preventDefault();
		setInserting(false);
		const d = new Date();

		props
			.insertProject(props.profile?.id, {
				...values,
				dashboardYear: new Date(
					dateFormat(values?.startAt || moment().format('YYYY-MM-DD'))
				).getFullYear(),
				researchProjectProfile: profileList,
				startAt: dateFormat(values?.startAt || moment().format('YYYY-MM-DD')),
				finishAt: dateFormat(values?.finishAt || moment().format('YYYY-MM-DD')),
				profileId: parseInt(values?.profileIdToSelectId),
				patentId: values?.typeToSelectId === 'patent' ? values?.selectId : 0,
				productContractId: values?.typeToSelectId === 'productContract' ? values?.selectId : 0,
				scientificResearchId:
					values?.typeToSelectId === 'scientificResearch' ? values?.selectId : 0,
				solutionId: values?.typeToSelectId === 'solution' ? values?.selectId : 0
			})
			.then((response) => {
				if (response.success && files.length !== 0) {
					props
						.uploadFile(response.data.id, 'researchProject', files)
						.then((responseUpload) => {
							if (responseUpload.success)
								props.updateProject(response.data.id, {
									...response.data,
									fileUrl: responseUpload.data
								});
						})
						.catch();
				}
				if (response.success) {
					props.statisticUnaccepted({
						departmentId: 0,
						profileId: props.profile?.id
					});
					if (props.role === 'sudo-admin')
						props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
				}
			});
		setValues(null);
		setProfileList([]);
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);

	const handleUpload = (files) => {
		setFiles(files);
		handleCloseUploadModal();
	};

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	const profileListFromProps = [{ id: -1, name: '' }];
	props.profileList.forEach((e) => profileListFromProps.push(e));

	const selectList = [];
	if (values?.typeToSelectId) {
		props[values?.typeToSelectId + 's'].forEach((e) => selectList.push(e[values?.typeToSelectId]));
	}

	var timeResearchDetailList =
		props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
			?.timeResearchDetail || [];
	timeResearchDetailList = timeResearchDetailList.filter(
		(e) => !e.allowedUpdateNum && e.type?.includes('researchProject')
	);

	const yearList = [];
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	const needCalKpi = useMemo(() => {
		if (values?.purposeId !== 1) {
			return false;
		}
		return values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0;
	}, [values]);

	const needCalHour = useMemo(() => {
		return values?.purposeId === 5;
	}, [values]);

	const isFullSize = useMemo(() => {
		return needCalKpi || needCalHour;
	}, [needCalKpi, needCalHour]);

	const selectedTimeResearchDetail = useMemo(
		() => timeResearchDetailList?.find((e) => e.code === values?.timeResearchDetailCode),
		[timeResearchDetailList, values]
	);

	const numberOfYear = useMemo(() => {
		const startAt = values?.startAt;
		const finishAt = values?.finishAt;

		if (!startAt || !finishAt) return 1;

		const [startDay, startMonth, startYear] = startAt.split('/').map(Number);
		const [finishDay, finishMonth, finishYear] = finishAt.split('/').map(Number);

		let tempStartYear = startYear;
		let tempFinishYear = finishYear;
		if (startMonth >= 8) tempStartYear++;
		if (finishMonth >= 8) tempFinishYear++;
		return tempFinishYear - tempStartYear + 1;
	}, [values?.startAt, values?.finishAt]);

	const mainProfileIds = useMemo(() => {
		return profileList.filter((e) => e.roleInProject === 'main').map((e) => e.profileId);
	}, [profileList]);

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth={isFullSize ? 'xl' : 'md'}
			open={inserting}
			onClose={() => setInserting(false)}
		>
			<DialogTitle>
				{/* Thêm đề tài nghiên cứu (Chỉ những đề tài đã đượ nghiệm thu) */}
				Thêm đề tài nghiên cứu
			</DialogTitle>
			<DialogContent style={{ display: 'flex', overflowX: 'hidden' }}>
				<div
					style={{
						display: 'flex',
						paddingRight: 16,
						minWidth: isFullSize ? '50%' : '100%',
						maxWidth: isFullSize ? 960 : '100%'
					}}
				>
					<Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 10 }}>
						<Grid item xs={9}>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								label="Tên đề tài"
								value={values?.title || ''}
								onChange={(event) => handleChangeForm('title', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								type="number"
								variant="outlined"
								label="Số lượng thành viên"
								value={values?.numOfMember || ''}
								onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								label="Mã số"
								value={values?.code || ''}
								onChange={(event) => handleChangeForm('code', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel id="type-label" style={{ padding: '0 8px', backgroundColor: 'white' }}>
									Loại hình đề tài
								</InputLabel>
								<Select
									native
									fullWidth
									labelId="type-label"
									value={values?.type || ''}
									onChange={(event) => handleChangeForm('type', event.target.value)}
								>
									<option value={null} />
									<option value="basic">Cơ bản</option>
									<option value="application">Ứng dụng</option>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel id="level-label" style={{ padding: '0 8px', backgroundColor: 'white' }}>
									Cấp
								</InputLabel>
								<Select
									native
									fullWidth
									labelId="level-label"
									value={values?.level || ''}
									onChange={(event) => handleChangeForm('level', event.target.value)}
								>
									<option value={null} />
									<option value="school">Cơ sở</option>
									<option value="ministry">Bộ</option>
									<option value="keyMinistry">Trọng điểm của bộ</option>
									<option value="nafosted">Nafosted</option>
									<option value="domestic">Nhà nước</option>
									<option value="international">Quốc gia</option>
									<option value={null}>Khác</option>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								rows={2}
								fullWidth
								multiline
								size="small"
								variant="outlined"
								label="Sản phẩm đăng ký"
								value={values?.productName || ''}
								onChange={(event) => handleChangeForm('productName', event.target.value)}
							/>
						</Grid>
						<Grid
							item
							xs={3}
							style={{
								paddingLeft: 18.5,
								lineHeight: '40px',
								fontSize: '1em',
								color: 'rgba(0, 0, 0, 0.5)'
							}}
						>
							Thời gian thực hiện:
						</Grid>
						<Grid item xs={4}>
							<KPTimePicker
								fullWidth
								label="Từ ngày"
								value={values?.startAt || `01/01/${currentYear}`}
								setValue={(newValue) => handleChangeForm('startAt', newValue)}
							/>
							{values?.startAt === 'dd/mm/yyyy' && (
								<FormHelperText error>Vui lòng điền thời gian thực hiện!</FormHelperText>
							)}
						</Grid>
						<Grid item xs={1} style={{ textAlign: 'center' }}>
							<DoubleArrow style={{ fontSize: 40, color: 'rgba(0, 0, 0, 0.30)' }} />
						</Grid>
						<Grid item xs={4}>
							<KPTimePicker
								fullWidth
								label="Đến ngày"
								value={values?.finishAt || `01/01/${currentYear}`}
								setValue={(newValue) => handleChangeForm('finishAt', newValue)}
							/>
							{values?.finishAt === 'dd/mm/yyyy' && (
								<FormHelperText error>Vui lòng điền thời gian thực hiện!</FormHelperText>
							)}
						</Grid>
						<Grid item xs={6}>
							<TextField
								select
								fullWidth
								size="small"
								variant="outlined"
								label="Cơ quan chủ trì"
								value={values?.host || ''}
								onChange={(event) => handleChangeForm('host', event.target.value)}
							>
								<MenuItem value={'phenikaa'}>Trường đại học Phenikaa</MenuItem>
								<MenuItem value={'other'}>Trường đại học khác</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								label="Cơ quan tài trợ"
								value={values?.sponsor || ''}
								onChange={(event) => handleChangeForm('sponsor', event.target.value)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								label="Giá trị"
								value={values?.value || ''}
								InputProps={{
									endAdornment: <InputAdornment position="end">VND</InputAdornment>
								}}
								onChange={(event) => handleChangeForm('value', valueFormat(event.target.value))}
							/>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel
									id="status-label"
									style={{ padding: '0 8px', backgroundColor: 'white' }}
								>
									Tình trạng
								</InputLabel>
								<Select
									native
									fullWidth
									labelId="status-label"
									value={values?.status || ''}
									onChange={(event) => handleChangeForm('status', event.target.value)}
								>
									<option value={null} />
									<option value="processing">Đang tiến hành</option>
									<option value="accepted">Đã nghiệm thu</option>
									<option value="extend">Gia hạn</option>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel
									id="purpose-label"
									style={{ padding: '0 8px', backgroundColor: 'white' }}
								>
									Mục đích
								</InputLabel>
								<Select
									native
									fullWidth
									labelId="purpose-label"
									value={values?.purposeId === 0 ? null : values?.purposeId ?? 'none'}
									onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value))}
								>
									{props.purposes.map((e) => {
										if (e.name === 'timeResearch')
											return (
												<option key={e.id} value={e.id}>
													{e.description}
												</option>
											);
										if (e.name === 'oldPaper')
											return (
												<option key={e.id} value={e.id}>
													Đề tài không phải Phenikaa chủ trì
												</option>
											);
										return <option hidden />;
									})}
								</Select>
							</FormControl>
						</Grid>

						{/* <Grid item xs={3}>
              <TextField
                fullWidth
                select
                size='small'
                label='Mục đích'
                variant='outlined'
                value={values?.purposeId ?? 'none'}
                SelectProps={{ native: true }}
                onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value, 10))}
              >
                {props.purposes.map(e => {
                    if (e.name === 'timeResearch')
                      return <option key={e.id} value={e.id}>{e.description}</option>
                    if (e.name === 'oldPaper')
                      return <option key={e.id} value={e.id}>Đề tài không phải Phenikaa chủ trì</option>
                    return <option hidden />
                  })
                }
              </TextField>
            </Grid> */}

						{values?.purposeId === 5 && (
							<>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										size="small"
										value={timeResearchYear}
										SelectProps={{ native: true }}
										label="Năm học tính giờ nghiên cứu"
										variant="outlined"
										onChange={(event) => {
											setTimeResearchYear(parseInt(event.target.value));
											handleChangeForm('timeResearchDetailCode', null);
										}}
									>
										{yearList.map((e) => (
											<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
												{e} - {e + 1}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item xs={9}>
									<TextField
										select
										fullWidth
										size="small"
										label="Nhiệm vụ tính giờ nghiên cứu"
										variant="outlined"
										value={values?.timeResearchDetailCode || null}
										onChange={(event) =>
											handleChangeForm('timeResearchDetailCode', event.target.value)
										}
										InputLabelProps={{
											shrink: !(
												values?.timeResearchDetailCode === undefined ||
												values?.timeResearchDetailCode === null
											)
										}}
										error={!values?.timeResearchDetailCode}
									>
										{timeResearchDetailList.length > 0 ? (
											timeResearchDetailList?.map((e) => (
												<MenuItem
													key={e.code}
													value={e.code}
												>{`${e.description} - ${e.value}(h)`}</MenuItem>
											))
										) : (
											<MenuItem value={null}>Chưa có dữ liệu</MenuItem>
										)}
									</TextField>
									{!values?.timeResearchDetailCode && (
										<FormHelperText error>Phải chọn một nhiệm vụ!</FormHelperText>
									)}
								</Grid>
							</>
						)}

						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Ghi chú"
								variant="outlined"
								value={values?.note || ''}
								multiline
								rows={3}
								onChange={(event) => handleChangeForm('note', event.target.value)}
							/>
						</Grid>

						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>
						<Grid item xs={2}>
							<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
								Thành viên
							</Typography>
						</Grid>
						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>

						<Grid item xs={12}>
							{profileList.map((e, index) => (
								<Grid key={`list-${index}`} container spacing={2}>
									<Grid
										item
										xs={3}
										style={{
											display: 'flex'
										}}
									>
										<FormControl required fullWidth error={!values?.profileIdForKpi}>
											<Button
												fullWidth
												color="primary"
												variant="outlined"
												style={{ height: 40 }}
												startIcon={e.isExternalTeacher ? <CheckBox /> : <CheckBoxOutlineBlank />}
												onClick={() => {
													handleChangeProfileList(index, 'isExternalTeacher', !e.isExternalTeacher);
													handleChangeProfileList(index, 'profileId', 0);
													handleChangeProfileList(index, 'name', null);
												}}
											>
												Ngoài trường
											</Button>
										</FormControl>
									</Grid>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 3 : 3}>
										{e.isExternalTeacher ? (
											<TextField
												onChange={(event) => {
													handleChangeProfileList(index, 'name', event.target.value);
													handleChangeProfileList(index, 'profileId', 0);
													if (event.target.value) setHelperText(false);
												}}
												error={helperText && e.name === null}
												size="small"
												label="Thành viên"
												variant="outlined"
											/>
										) : (
											<Autocomplete
												disableClearable
												options={props.profileList}
												getOptionLabel={(option) => {
													if (!option) return '';
													const code = option.code || '';
													const name = option.name || '';
													if (code && name) {
														return `${code} - ${name}`;
													} else if (code) {
														return code;
													} else if (name) {
														return name;
													} else {
														return '';
													}
												}}
												value={
													profileListFromProps?.find((profile) => profile.id === e.profileId) ||
													profileListFromProps[0]
												}
												onChange={(event, profile) => {
													if (profileList[index]?.profileId === values?.profileIdForKpi)
														handleChangeForm('profileIdForKpi', null);
													handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
													handleChangeProfileList(index, 'name', profile?.name);
													handleChangeProfileList(index, 'code', profile?.code);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														label="Thành viên"
														variant="outlined"
													/>
												)}
											/>
										)}
										{helperText && e.profileId === null && (
											<FormHelperText style={{ color: 'red' }}>Không được để trống</FormHelperText>
										)}
									</Grid>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 4 : 2}>
										<FormControl fullWidth variant="outlined" size="small">
											<InputLabel
												id="roleInProject-label"
												style={{ padding: '0 8px', backgroundColor: 'white' }}
											>
												Vai trò
											</InputLabel>
											<Select
												native
												fullWidth
												labelId="roleInProject-label"
												value={e?.roleInProject || 'main'}
												onChange={(event) =>
													handleChangeProfileList(index, 'roleInProject', event.target.value)
												}
											>
												<option value="main">Chủ nhiệm</option>
												<option value="member">Thành viên</option>
											</Select>
										</FormControl>
									</Grid>
									{values?.purposeId === 1 && (
										<Grid item xs={3}>
											<FormControl required fullWidth error={!values?.profileIdForKpi}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForKpi === 'number' &&
														values?.profileIdForKpi === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														handleChangeForm(
															'profileIdForKpi',
															values?.profileIdForKpi !== e.profileId ? parseInt(e.profileId) : null
														);
													}}
												>
													Tính KPI
												</Button>
												{!values?.profileIdForKpi && (
													<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									{values?.purposeId === 99999999 && (
										<Grid item xs={3}>
											<FormControl required fullWidth error={!values?.profileIdForReward}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForReward === 'number' &&
														values?.profileIdForReward === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														handleChangeForm(
															'profileIdForReward',
															values?.profileIdForReward !== e.profileId
																? parseInt(e.profileId)
																: null
														);
													}}
												>
													Tính thưởng
												</Button>
												{!values?.profileIdForReward && (
													<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									<Grid item xs={2}>
										<Button
											fullWidth
											color="secondary"
											variant="contained"
											style={{ height: 40 }}
											onClick={() => handleRemove(index)}
										>
											Xoá
										</Button>
									</Grid>
								</Grid>
							))}
						</Grid>

						<Grid item xs={12}>
							<Button fullWidth variant="outlined" onClick={handleInsert}>
								Thêm thành viên
							</Button>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						flexDirection: 'column',
						display: needCalKpi ? 'block' : 'none'
					}}
				>
					<EmploymentContractTable
						contract={detail}
						setDetail={setDetail}
						contractList={contractList}
						kpiIdForKpi={values?.kpiIdForKpi}
						contractIdForKpi={values?.contractIdForKpi}
						handleChangeForm={handleChangeForm}
						values={values}
						setValues={setValues}
					/>
					<EmploymentContractDetailTable kpi={detail} />
				</div>
				<div
					style={{
						flexDirection: 'column',
						display: needCalHour ? 'block' : 'none'
					}}
				>
					<ScientificResearchTimeTable
						profiles={profileList}
						mainProfileIds={mainProfileIds}
						totalHour={(selectedTimeResearchDetail?.value ?? 0) / numberOfYear}
						projectMode="project"
					/>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<Grid container justifyContent={'space-between'}>
					<div>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleOpenUploadModal}
							style={{ marginRight: 4 }}
						>
							Upload file minh chứng
						</Button>
					</div>
					<div style={{ float: 'right' }}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setInserting(false)}
							style={{ marginRight: 4 }}
						>
							Huỷ
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleSubmit()}
							disabled={values?.purposeId === 5 && !values?.timeResearchDetailCode}
						>
							Xác nhận
						</Button>
					</div>
				</Grid>
			</DialogActions>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
				defaultFiles={files}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	profileList: select(state, 'profileReducer', 'list'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	isFetchingTimeResearchInformation: select(state, 'timeResearchInformationReducer', 'isFetching'),
	// ----------- //
	scientificResearchs: select(state, 'projectReducer', 'scientificResearchs'),
	solutions: select(state, 'projectReducer', 'solutions'),
	patents: select(state, 'projectReducer', 'patents'),
	productContracts: select(state, 'projectReducer', 'productContracts')
});

const mapDispatchToProps = (dispatch) => ({
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	insertProject: (profileId, payload) => dispatch(insertProject(profileId, payload)),
	updateProject: (profileId, payload) => dispatch(updateProject(profileId, payload)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id)),
	getProfileInfoByProfileId: (id) => dispatch(getProfileInfoByProfileId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ProjectInsertModal));
