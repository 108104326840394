import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider, TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { notification } from '../../../../reducer/home/action';
import { getDOI } from '../../../../reducer/scientificResearch/action';
import {
	getSearchFilteredJournal,
	getJournalByIdOnScientificResearch,
	getSearchFilteredJournalAfterDOI
} from '../../../../reducer/journal/action';

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => setDebouncedValue(value), delay);
		return () => clearTimeout(handler);
	}, [value, delay]);
	return debouncedValue;
}

function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}

function JournalInternational(props) {
	const { values, setValues, handleChangeForm } = props;

	const [searchText, setSearchText] = useState('');
	const debouncedSearchText = useDebounce(searchText, 300);

	useEffect(() => {
		if (values?.publication?.title) setSearchText(values?.publication?.title);
	}, [props.updating, values?.publication?.title]);

	useEffect(() => {
		if (
			debouncedSearchText !== 0 &&
			debouncedSearchText !== props.journalSearchFilterText &&
			!props.isFetchingJournalSearchFilter
		)
			props.getSearchFilteredJournal(1, 100, { title: debouncedSearchText });
	}, [debouncedSearchText]);

	useEffect(() => {
		if (!(Object.keys(props.DOI).length === 0 && props.DOI.constructor === Object)) {
			const datePart = props.DOI.issued['date-parts'][0];

			props.getSearchFilteredJournalAfterDOI(
				1,
				100,
				{
					title: props.DOI?.['container-title'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''),
					issn: (props.DOI?.ISSN?.[0] || props.DOI?.ISBN?.[0])?.replace('-', ''),
					year: datePart.length >= 1 ? datePart[0] : null,
					similarity: 0.8
				},
				props.DOI
			);
		}
	}, [props.isNeedReload]);

	useEffect(() => {
		setValues({
			...values,
			sjrBestQuartile: null,
			rank: null,
			topRank: null,
			coreCollection: null,
			impactFactor: null,
			impactFactorFiveYear: null,
			submissionToAcceptance: null
		});
	}, [values?.journalId]);

	useEffect(() => {
		if (
			// props.listSearchAfterDOI && props.listSearchAfterDOI.length !== 0 &&
			props.infoFromDoiLink &&
			props.infoFromDoiLink.length !== 0
		) {
			let tempListAuthor = '';
			(props.infoFromDoiLink?.author || [])?.forEach((author) => {
				const tempAuthor = `${author.given} ${author.family}`;
				if (tempListAuthor === '') tempListAuthor += tempAuthor;
				else tempListAuthor += `, ${tempAuthor}`;
			});

			// Lây ngày nộp đơn từ issued
			const issuedDay = new Date(2021, 0, 1);
			const createdDay = new Date(2021, 0, 1);
			const datePartIssued = props.infoFromDoiLink.issued['date-parts'][0];
			const datePartCreated = props.infoFromDoiLink.created['date-parts'][0];

			if (datePartIssued.length >= 1) issuedDay.setFullYear(datePartIssued[0]);
			if (datePartCreated.length >= 1) createdDay.setFullYear(datePartCreated[0]);

			setValues({
				...values,
				doi: `https://doi.org/${props.infoFromDoiLink.DOI}`,
				title: props.infoFromDoiLink.title,
				issn: props.infoFromDoiLink?.ISSN?.[0] || props.infoFromDoiLink?.ISBN?.[0],
				issue: props.infoFromDoiLink.issue === undefined ? '' : props.infoFromDoiLink.issue,
				listAuthor: tempListAuthor,
				page: props.infoFromDoiLink.page === undefined ? '' : props.infoFromDoiLink.page,
				publisher:
					props.infoFromDoiLink.publisher === undefined ? '' : props.infoFromDoiLink.publisher,
				volume: props.infoFromDoiLink.volume === undefined ? '' : props.infoFromDoiLink.volume,
				numOfMember: props.infoFromDoiLink.author?.length,
				journalId: props.listSearchAfterDOI[0]?.id ?? null,
				otherTitle:
					props.listSearchAfterDOI?.length === 0 ? props.infoFromDoiLink['container-title'] : '',
				submissionDay:
					datePartIssued[0] > new Date().getFullYear() ? new Date(createdDay) : new Date(issuedDay),
				acceptedDay: new Date(issuedDay),
				year: parseInt(props.infoFromDoiLink.issued['date-parts'])
			});
		}
	}, [props.isFetchingAfterDOI]);

	useEffect(() => {
		const { journalDetail } = props;
		if (values?.journalId !== 0)
			setValues({
				...values,
				sjrBestQuartile: journalDetail?.sjrBestQuartile,
				rank: journalDetail?.rank,
				topRank: journalDetail?.topRank,
				coreCollection: journalDetail?.coreCollection,
				impactFactor: journalDetail?.impactFactor,
				impactFactorFiveYear: journalDetail?.impactFactorFiveYear,
				submissionToAcceptance: journalDetail?.submissionToAcceptance
			});
	}, [props.isFetchingJournalDetail]);

	const handleGetDataFromDOI = () => {
		const type1 = 'https://doi.org/';
		const type2 = 'doi.org/';
		if (values?.doi?.substr(0, type1.length) === type1) {
			props.getDOI(values?.doi);
		} else if (values?.doi?.substr(0, type2.length) === type2) {
			props.getDOI(`https://${values?.doi}`);
		} else {
			props.getDOI(`https://doi.org/${values?.doi}`);
		}
	};

	const handleGetRankFromJournal = () => {
		if (values?.journalId === 0) {
			props.notification({ type: 'error', message: 'Vui lòng nhập tạp chí' });
		} else {
			props.getJournalByIdOnScientificResearch(values?.journalId);
		}
	};

	return (
		<>
			<Grid item xs={9}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Link DOI"
					variant="outlined"
					value={values?.doi || ''}
					onChange={(event) => handleChangeForm('doi', event.target.value)}
					helperText={
						!values?.doi ? (
							<div style={{ color: 'red' }}>
								Vui lòng upload file minh chứng nếu công bố không có link DOI
							</div>
						) : null
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={() => handleGetDataFromDOI()}
					disabled={
						props.disabled ||
						props.isFetchingDOI ||
						props.isFetchingAfterDOI ||
						values?.locked === 'kpi' ||
						values?.locked === 'reward' ||
						values?.lockedForTimeResearch
					}
				>
					{props.isFetchingDOI || props.isFetchingAfterDOI ? (
						<CircularProgress size={24} />
					) : (
						'Nhập từ link DOI'
					)}
				</Button>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Tên công bố"
					variant="outlined"
					value={values?.title || ''}
					onChange={(event) => handleChangeForm('title', event.target.value)}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Nhà xuất bản"
					variant="outlined"
					value={values?.publisher || ''}
					onChange={(event) => handleChangeForm('publisher', event.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="ISSN/ISBN"
					variant="outlined"
					value={values?.issn || ''}
					onChange={(event) => handleChangeForm('issn', event.target.value)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Số lượng tác giả"
					variant="outlined"
					type="numOfMember"
					value={values?.numOfMember || ''}
					onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value), 10)}
					error={!(values?.numOfMember > 0)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Số lượng tác giả chính"
					variant="outlined"
					type="number"
					value={values?.numberMainAuthor || ''}
					onChange={(event) =>
						handleChangeForm('numberMainAuthor', parseInt(event.target.value, 10))
					}
					error={!(values?.numberMainAuthor > 0)}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Tác giả"
					variant="outlined"
					value={values?.listAuthor || ''}
					multiline
					rows={4}
					onChange={(event) => handleChangeForm('listAuthor', event.target.value)}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Issue"
					variant="outlined"
					value={values?.issue || ''}
					onChange={(event) => handleChangeForm('issue', event.target.value)}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Volume"
					variant="outlined"
					value={values?.volume || ''}
					onChange={(event) => handleChangeForm('volume', event.target.value)}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Page"
					variant="outlined"
					value={values?.page || ''}
					onChange={(event) => handleChangeForm('page', event.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					fullWidth
					select
					size="small"
					disabled={props.disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
					label="Mục đích"
					variant="outlined"
					value={values?.purposeId ?? 'none'}
					SelectProps={{ native: true }}
					onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value, 10))}
				>
					{props.purposes.map((e) => (
						<option key={e.id} value={e.id}>
							{e.description}
						</option>
					))}
				</TextField>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Tháng nộp"
					variant="outlined"
					type="number"
					InputProps={{ inputProps: { min: 1, max: 12 } }}
					value={values?.submissionMonth || ''}
					error={!(values?.submissionMonth > 0)}
					onChange={(event) => {
						handleChangeForm('submissionMonth', parseInt(event.target.value, 10));
						// let newDate = values?.submissionDay;
						// let newMonth = Math.max(parseInt(event.target.value) - 1, 0);
						// newMonth = Math.min(parseInt(event.target.value) - 1, 11);
						// if (!isValidDate(newDate)) newDate = new Date(2021, 0, 1);
						// newDate.setMonth(newMonth);
						// handleChangeForm('submissionDay', newDate);
					}}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Năm nộp"
					variant="outlined"
					type="number"
					InputProps={{ inputProps: { min: 1900, max: 3000 } }}
					value={new Date(values?.submissionDay).getFullYear() || ''}
					onChange={(event) => {
						let newDate = values?.submissionDay;
						let newYear = parseInt(event.target.value);
						if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
						newDate.setFullYear(newYear);
						handleChangeForm('submissionDay', newDate);
					}}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Tháng chấp nhận"
					variant="outlined"
					type="number"
					InputProps={{ inputProps: { min: 1, max: 12 } }}
					error={!(values?.acceptedMonth > 0)}
					value={values?.acceptedMonth || ''}
					// value={(() => {
					//   const month = new Date(values?.acceptedDay).getMonth();
					//   if (isNaN(month)) return '';
					//   return month + 1;
					// })()}
					onChange={(event) => {
						handleChangeForm('acceptedMonth', parseInt(event.target.value));
						// let newDate = values?.acceptedDay;
						// let newMonth = Math.max(parseInt(event.target.value) - 1, 0);
						// newMonth = Math.min(parseInt(event.target.value) - 1, 11);
						// if (!isValidDate(newDate)) newDate = new Date(2021, 0, 1);
						// newDate.setMonth(newMonth);
						// handleChangeForm('acceptedDay', newDate);
					}}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Năm chấp nhận"
					variant="outlined"
					type="number"
					InputProps={{ inputProps: { min: 1900, max: 3000 } }}
					value={new Date(values?.acceptedDay).getFullYear() || ''}
					onChange={(event) => {
						let newDate = values?.acceptedDay;
						let newYear = parseInt(event.target.value);
						if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
						newDate.setFullYear(newYear);
						handleChangeForm('acceptedDay', newDate);
					}}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					fullWidth
					disabled={props.disabled}
					size="small"
					type="number"
					label="Điểm GS-PGS"
					variant="outlined"
					value={values?.score?.toString() || ''}
					onChange={(event) => handleChangeForm('score', parseFloat(event.target.value))}
				/>
			</Grid>
			{props.isFetchingJournal ? (
				<CircularProgress size={24} />
			) : (
				<Grid item xs={12}>
					<Autocomplete
						freeSolo
						size="small"
						disabled={props.disabled}
						// disableClearable
						options={props.listSearch}
						getOptionLabel={(option) => (option ? `${option?.title} (${option?.year})` : '')}
						onInputChange={(e) => e && setSearchText(e?.target?.value)}
						onChange={(event, val) => {
							if (val !== null) handleChangeForm('journalId', val.id);
							else handleChangeForm('journalId', 0);
						}}
						value={props.listSearch.find((e) => e.id === values?.journalId) || ''}
						autoHighlight
						renderInput={(params) => (
							<TextField
								{...params}
								label="Tên tạp chí quốc tế"
								margin="normal"
								variant="outlined"
								InputProps={{ ...params.InputProps }}
							/>
						)}
						style={{ marginBottom: -10, marginTop: -16 }}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<TextField
					fullWidth
					size="small"
					disabled={props.disabled}
					label="Tên tạp chí khác (trong trường hợp tạp chí không thuộc Scopus)"
					variant="outlined"
					value={values?.otherTitle || ''}
					onChange={(event) => handleChangeForm('otherTitle', event.target.value)}
				/>
			</Grid>
			<Grid item xs={12} style={{ margin: 'auto' }}>
				<Divider />{' '}
			</Grid>
			<Grid item xs={2}>
				<TextField
					size="small"
					disabled={props.disabled}
					label="Xếp loại Q"
					variant="outlined"
					value={values?.sjrBestQuartile ?? values?.publication?.sjrBestQuartile ?? ''}
					style={{ width: '100%' }}
					onChange={(event) => handleChangeForm('sjrBestQuartile', event.target.value)}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					size="small"
					disabled={props.disabled}
					label="Xếp loại R"
					variant="outlined"
					value={values?.rank ?? values?.publication?.rank ?? ''}
					style={{ width: '100%' }}
					onChange={(event) => handleChangeForm('rank', event.target.value)}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					size="small"
					disabled={props.disabled}
					label="Top Ngành"
					variant="outlined"
					value={values?.topRank ?? values?.publication?.topRank ?? ''}
					style={{ width: '100%' }}
					onChange={(event) => handleChangeForm('topRank', event.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					size="small"
					disabled={props.disabled}
					label="ISI/Scopus"
					variant="outlined"
					value={values?.coreCollection ?? values?.publication?.coreCollection ?? ''}
					style={{ width: '100%' }}
					onChange={(event) =>
						handleChangeForm('sjrBecoreCollectionstQuartile', event.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					size="small"
					disabled={props.disabled}
					label="Impact factor"
					variant="outlined"
					value={values?.impactFactor ?? values?.publication?.impactFactor ?? ''}
					style={{ width: '100%' }}
					onChange={(event) => handleChangeForm('rank', event.target.value)}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					size="small"
					disabled={props.disabled}
					label="Impact factor 5 years"
					variant="outlined"
					value={values?.impactFactorFiveYear ?? values?.publication?.impactFactorFiveYear ?? ''}
					style={{ width: '100%' }}
					onChange={(event) => handleChangeForm('impactFactorFiveYear', event.target.value)}
				/>
			</Grid>
			<Grid item xs={4}>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={() => handleGetRankFromJournal()}
					disabled={
						props.disabled ||
						props.isFetchingJournalDetail ||
						values?.locked === 'kpi' ||
						values?.locked === 'reward' ||
						values?.lockedForTimeResearch
					}
				>
					{props.isFetchingJournalDetail && <CircularProgress size={24} />}
					{!props.isFetchingJournalDetail && 'Tính xếp hạng'}
				</Button>
			</Grid>
			<Grid item xs={12} style={{ margin: 'auto' }}>
				<Divider />{' '}
			</Grid>
		</>
	);
}

const mapStateToProps = (state) => ({
	DOI: select(state, 'scientificResearchReducer', 'DOI'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	listSearch: select(state, 'journalReducer', 'listSearch'),
	journalDetail: select(state, 'journalReducer', 'detailOnScientificResearch'),
	infoFromDoiLink: select(state, 'journalReducer', 'infoFromDoiLink'),
	listSearchAfterDOI: select(state, 'journalReducer', 'listSearchAfterDOI'),
	journalSearchFilterText: select(state, 'journalReducer', 'searchFilterText'),

	isNeedReload: select(state, 'scientificResearchReducer', 'isNeedReload'),
	isFetchingDOI: select(state, 'scientificResearchReducer', 'isFetchingDOI'),
	isFetchingJournal: select(state, 'journalReducer', 'isFetching'),
	isFetchingAfterDOI: select(state, 'journalReducer', 'isFetchingAfterDOI'),
	isFetchingJournalDetail: select(state, 'journalReducer', 'isFetchingDetailOnScientificResearch'),
	isFetchingJournalSearchFilter: select(state, 'journalReducer', 'isFetchingSearchFilter')
});

const mapDispatchToProps = (dispatch) => ({
	getDOI: (url) => dispatch(getDOI(url)),
	notification: (payload) => dispatch(notification(payload)),
	getJournalByIdOnScientificResearch: (id) => dispatch(getJournalByIdOnScientificResearch(id)),
	getSearchFilteredJournal: (page, pageSize, payload) =>
		dispatch(getSearchFilteredJournal(page, pageSize, payload)),
	getSearchFilteredJournalAfterDOI: (page, pageSize, payload, doiInfo) =>
		dispatch(getSearchFilteredJournalAfterDOI(page, pageSize, payload, doiInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalInternational));
