import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	Slide,
	Select,
	Button,
	Divider,
	MenuItem,
	Typography,
	Checkbox,
	FormControlLabel,
	Dialog,
	DialogTitle,
	DialogContent
} from '@material-ui/core';
import { Add, Info, Group, Person, OfflinePin, DoubleArrow } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.css';

import select from '../../../../utils/select';
import theme from '../../../../theme';

import { KPTextform, KPTextfield } from '../../../../components/KPTComponents';

import {
	insertInternalResearch,
	updateInternalResearch,
	updateInternalResearchStatus,
	filterInternalResearchV2
} from '../../../../reducer/internalResearch/action';
import { uploadMultiFile } from '../../../../reducer/file/action';

function ReportForm(props) {
	const dispatch = useDispatch();

	const {
		year,
		handleClose,
		files,
		setFiles,
		values,
		setValues,
		// timeChange, setTimeChange,
		participants,
		setParticipants,
		// nameChange, setNameChange,
		participantsOld
	} = props;

	console.log(values);

	const profileList = useSelector((state) => select(state, 'profileReducer', 'list'));
	const profileDetail = useSelector((state) => select(state, 'profileReducer', 'detail'));

	const [history, setHistory] = useState(false);
	const [nameChange, setNameChange] = useState(false);
	const [timeChange, setTimeChange] = useState({ num: 6, unit: 'month' });

	const disabled = props?.open?.reportDetail || props?.open?.reportAccept;

	const textForm = [
		{
			key: 'box1',
			xs: 12,
			type: 'render',
			render: () => (
				<Box border="solid 1px #bcbcbc" borderRadius={4}>
					<Box
						display="flex"
						bgcolor="#ececec"
						padding="8px 16px"
						alignItems="center"
						borderBottom="solid 1px #bcbcbc"
						style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
					>
						<Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
						<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
							<b>THÔNG TIN CƠ BẢN</b>
						</Typography>
					</Box>
					<Box padding={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box
									padding={2}
									marginTop={4}
									borderRadius={4}
									bgcolor="#fff"
									position="relative"
									border="solid 1px #bcbcbc"
									style={{ borderTopRightRadius: 0 }}
								>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<KPTextfield
												id="vietnameseName"
												value={values?.vietnameseName}
												label="Tên đề tài tiếng Việt"
												handleChangeForm={handleChangeForm}
												disabled={!nameChange || disabled}
												required={true}
											/>
										</Grid>
										<Grid item xs={12}>
											<KPTextfield
												id="englishName"
												value={values?.englishName}
												label="Tên đề tài tiếng Anh"
												handleChangeForm={handleChangeForm}
												disabled={!nameChange || disabled}
												required={true}
											/>
										</Grid>
									</Grid>
									{(props.open?.report ||
										values?.vietnameseNameChange ||
										values?.vietnameseNameChange) &&
										!disabled && (
											<Box
												top={-32}
												right={-1}
												height={32}
												paddingLeft="24px"
												position="absolute"
												bgcolor={'#bcbcbc'}
												style={{
													borderTopRightRadius: 4,
													borderTopLeftRadius: 4,
													clipPath: 'polygon(5% 0%, 100% 0, 100% 100%, 0% 100%)'
												}}
												display="flex"
												alignItems="center"
											>
												<FormControlLabel
													control={
														<Checkbox
															size="small"
															color="default"
															style={{ color: '#fff' }}
															checked={nameChange}
															disabled={disabled}
															onClick={() => setNameChange(!nameChange)}
														/>
													}
													style={{ marginTop: -2 }}
													label={
														<Typography style={{ color: '#fff', fontSize: 14, marginTop: -2 }}>
															<b>Xin điều chỉnh</b>
														</Typography>
													}
												/>
												<Divider
													style={{
														marginTop: 1,
														width: 1,
														height: 30,
														backgroundColor: '#fff !important'
													}}
												/>
												{!props.open?.report && (
													<Typography
														className={styles.detailButton}
														onClick={() => setHistory(!nameChange ? null : { name: true })}
														style={{
															color: !nameChange ? '#FAFAFA' : '#fff',
															cursor: !nameChange ? 'context-menu' : 'pointer'
														}}
													>
														<b>Chi tiết</b>
													</Typography>
												)}
											</Box>
										)}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box display="flex">
									<Box width={180}>
										<KPTextfield
											id="isTimeChange"
											value={values?.isTimeChange}
											type="checkbox"
											name="Xin gia hạn đề tài"
											handleChangeForm={(id, val, type) => {
												setTimeChange({ num: 6, unit: 'month' });
												handleChangeForm(id, val, type);
											}}
											disabled={disabled}
										/>
									</Box>
									{values?.isTimeChange && (
										<Box width="calc(100% - 180px - 16px)" marginLeft={2}>
											<KPTextfield
												id="cc"
												type="render"
												render={() => (
													<Grid container spacing={2}>
														<Grid item xs={6}>
															<KPTextfield
																type="number"
																disabled={true}
																min={0}
																max={100000}
																step={1}
																value={timeChange?.num || 0}
																handleChangeForm={(id, val) =>
																	setTimeChange({ ...timeChange, num: val })
																}
															/>
														</Grid>
														<Grid item xs={6}>
															<Select
																disabled={true}
																fullWidth
																size="small"
																variant="outlined"
																SelectDisplayProps={{ style: { padding: 8, borderRadius: 4 } }}
																MenuProps={{
																	getContentAnchorEl: null,
																	MenuListProps: { style: { padding: 0 } },
																	anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
																	transformOrigin: { vertical: 'top', horizontal: 'center' }
																}}
																value={timeChange?.unit || 'day'}
																onChange={(e) =>
																	setTimeChange({ ...timeChange, unit: e.target.value })
																}
															>
																<MenuItem value={'day'}>Ngày</MenuItem>
																<MenuItem value={'month'}>Tháng</MenuItem>
															</Select>
														</Grid>
													</Grid>
												)}
											/>
										</Box>
									)}
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)
		},
		{
			key: 'box2',
			xs: 12,
			type: 'render',
			render: () => (
				<Box border="solid 1px #bcbcbc" borderRadius={4}>
					<Box
						display="flex"
						bgcolor="#ececec"
						padding="8px 16px"
						alignItems="center"
						borderBottom="solid 1px #bcbcbc"
						style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
					>
						<OfflinePin
							style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }}
						/>
						<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
							<b>BÁO CÁO TIẾN ĐỘ</b>
						</Typography>
					</Box>
					<Box padding={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<KPTextfield
									id="finishedWork"
									xs={12}
									type="dragzone"
									label="Các công việc đã hoàn thành"
									value={values?.finishedWork || ''}
									handleChangeForm={handleChangeForm}
									required={true}
									disabled={props?.open?.reportDetail}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)
		},
		{
			key: 'box3',
			xs: 12,
			type: 'render',
			render: () => (
				<Box border="solid 1px #bcbcbc" borderRadius={4}>
					<Box
						display="flex"
						bgcolor="#ececec"
						padding="8px 16px"
						alignItems="center"
						borderBottom="solid 1px #bcbcbc"
						style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
					>
						<Person style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
						<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
							<b>CHỦ NHIỆM ĐỀ TÀI</b>
						</Typography>
					</Box>
					<Box padding={2}>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								{profileList && (
									<KPTextfield
										type="autoComplete"
										options={profileList}
										getOptionLabel={(option) => `${option.code} - ${option.name}`}
										required={true}
										disabled={props.type === 'personal' || !props?.open?.insert}
										value={profileList.find((e) => e.id === participants[0]?.profileId)}
										label="Tên giảng viên"
										id="participants.0.name"
										handleChangeForm={handleChangeForm}
									/>
								)}
							</Grid>
							<Grid item xs={4}>
								<KPTextfield
									id="participants.0.degree"
									label="Học hàm, học vị"
									handleChangeForm={handleChangeForm}
									value={participants[0].degree}
									required={true}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={4}>
								<KPTextfield
									id="participants.0.department"
									label="Đơn vị công tác"
									handleChangeForm={handleChangeForm}
									value={participants[0].department}
									required={true}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={4}>
								<KPTextfield
									id="participants.0.phone"
									label="Số điện thoại"
									handleChangeForm={handleChangeForm}
									value={participants[0].phone}
									required={true}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={4}>
								<KPTextfield
									id="participants.0.email"
									label="Email"
									handleChangeForm={handleChangeForm}
									value={participants[0].email}
									required={true}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)
		},
		{
			key: 'box4',
			xs: 12,
			type: 'render',
			render: () => (
				<Box border="solid 1px #bcbcbc" borderRadius={4}>
					<Box
						display="flex"
						bgcolor="#ececec"
						padding="8px 16px"
						alignItems="center"
						borderBottom="solid 1px #bcbcbc"
						style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
					>
						<Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
						<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
							<b>CÁN BỘ THAM GIA</b>
						</Typography>
					</Box>
					<Box padding={2}>
						{participants
							.filter((e) => e.researchRole === 'other')
							?.map((member, index) => (
								<Box key={`member-${index + 1}`} display="flex">
									<Box width={212} marginRight={2}>
										<KPTextfield
											value={member.isPhenikaa}
											id={`participants.${index + 1}.isPhenikaa`}
											type="checkbox"
											name="GV trong trường"
											disabled={disabled}
											handleChangeForm={handleChangeForm}
											style={{ marginTop: '25.5px' }}
										/>
									</Box>
									<Grid container spacing={2} style={{ flexGrow: 1 }}>
										<Grid item xs={4}>
											<KPTextfield
												type={member.isPhenikaa ? 'autoComplete' : null}
												options={member.isPhenikaa ? profileList : null}
												getOptionLabel={(option) => `${option.code} - ${option.name}`}
												required={true}
												disabled={disabled}
												value={
													member.isPhenikaa
														? profileList.find((e) => e.id === member?.profileId)
														: member?.name
												}
												label="Tên giảng viên"
												id={`participants.${index + 1}${member.isPhenikaa ? null : '.name'}`}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												required={true}
												value={member.degree}
												disabled={disabled || member?.isPhenikaa}
												label="Học hàm, học vị"
												id={`participants.${index + 1}.degree`}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												required={true}
												disabled={disabled || member?.isPhenikaa}
												value={member.department}
												label="Đơn vị"
												id={`participants.${index + 1}.department`}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
									</Grid>
									{props.open?.report && (
										<Button
											className="error-button"
											color="primary"
											variant="contained"
											style={{ marginLeft: 16, marginTop: '25.5px', height: 35 }}
											onClick={() => {
												const _participants = [...participants];
												_participants.splice(index + 1, 1);
												setParticipants(_participants);
											}}
										>
											Xóa
										</Button>
									)}
								</Box>
							))}
						{props.open?.report && (
							<Button
								fullWidth
								color="primary"
								variant="outlined"
								startIcon={<Add />}
								style={{ height: 35, marginTop: 16, backgroundColor: 'white' }}
								onClick={() => {
									setParticipants([...participants, { isPhenikaa: false, researchRole: 'other' }]);
								}}
							>
								Thêm thành viên
							</Button>
						)}
					</Box>
				</Box>
			)
		}
	];

	const handleReload = () => {
		if (props.type === 'personal') {
			if (!isNaN(profileDetail?.id))
				dispatch(
					filterInternalResearchV2({
						profileId: profileDetail?.id.toString(),
						schoolYear: year
					})
				);
		} else if (props.type === 'admin') {
			if (!isNaN(profileDetail?.id)) dispatch(filterInternalResearchV2({ schoolYear: year }));
		} else {
			// dispatch(getAllInternalResearch());
			if (!isNaN(profileDetail?.id)) dispatch(filterInternalResearchV2({ schoolYear: year }));
		}
	};

	const handleChangeForm = (key, val, type) => {
		if (key?.includes('participants')) type = 'participantField';

		if (type === 'participantField') {
			const keyList = key.split('.');
			const index = parseInt(keyList[1]);
			const fieldKey = keyList?.[2] || 'all';
			const value = [...participants];

			if (fieldKey === 'all')
				value.splice(index, 1, {
					...value[index],
					name: val?.name,
					profileId: val?.id,
					degree: val?.degree,
					department: val?.departmentProfile.find((e) => e.department?.isFaculty)?.department?.name
				});
			else if (fieldKey === 'isPhenikaa')
				value.splice(index, 1, {
					...value[index],
					[fieldKey]: val,
					name: undefined,
					email: undefined
				});
			else value.splice(index, 1, { ...value[index], [fieldKey]: val });

			setParticipants(value);
		} else if (type === 'file') {
			setFiles({ ...files, [key]: val?.[0] });
		} else {
			setValues({ ...values, [key]: val });
		}
	};

	const insertReport = () => {
		// handleClose();

		dispatch(uploadMultiFile(profileDetail?.id, { finishedWork: files?.finishedWork })).then(
			(res) => {
				dispatch(
					insertInternalResearch({
						...props.detail,
						id: undefined,
						parent: props.detail.id,
						englishName: values?.englishName,
						finishedWork: res.data[0],
						vietnameseName: values?.vietnameseName,
						endTime: values?.isTimeChange
							? dayjs(props.detail.endTime).add(timeChange?.num, timeChange?.unit)
							: props.detail?.endTime,
						participants: participants.map((participant) => ({
							...participant,
							id: undefined,
							researchId: undefined
						})),
						status: 'report_submitted'
					})
				).then((res) => {
					if (res) handleReload();
				});
			}
		);
	};

	const handleSubmit = () => {
		props.handleClose();
		switch (props.id) {
			case 'report-form':
				insertReport();
				break;
			case 'report-accept-form':
				dispatch(updateInternalResearchStatus(props.detail.id, 'report_ensure'));
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (values?.englishNameChange || values?.vietnameseNameChange)
			setNameChange({
				english: values?.englishName,
				vietnamese: values?.vietnameseName
			});

		if (values?.timeChange?.num) {
			setTimeChange({
				num: values?.timeChange?.num,
				unit: values?.timeChange?.unit
			});
		}
	}, [values]);

	return (
		<>
			<KPTextform
				id={props.id}
				textForm={textForm}
				handleSubmit={handleSubmit}
				handleChangeForm={handleChangeForm}
			/>
			{history && (
				<Dialog
					TransitionComponent={React.forwardRef((props, ref) => {
						return <Slide direction="up" ref={ref} {...props} />;
					})}
					maxWidth={history?.name ? 'sm' : 'md'}
					fullWidth
					open={!!history}
					onClose={() => setHistory(null)}
				>
					<DialogTitle>
						<b>Lịch sử điều chỉnh</b>
					</DialogTitle>
					<Divider />
					<DialogContent style={{ margin: '16px 0' }}>
						<Box
							width="calc(100% - 20px)"
							position="relative"
							padding={1}
							paddingTop={3}
							border="solid 1px #bcbcbc"
							borderRadius={4}
						>
							<Box className={styles.box}>
								<Typography>
									<b>Trước khi điều chỉnh</b>
								</Typography>
							</Box>
							{history.name ? (
								<>
									<KPTextfield
										id="vietnameseNameOld"
										value={values?.vietnameseNameOld}
										label="Tên đề tài tiếng Việt"
										handleChangeForm={handleChangeForm}
										disabled={true}
									/>
									<Box height={8} />
									<KPTextfield
										id="englishNameOld"
										value={values?.englishNameOld}
										label="Tên đề tài tiếng Anh"
										handleChangeForm={handleChangeForm}
										disabled={true}
									/>
								</>
							) : (
								<>
									{participantsOld
										.filter((e) => e.researchRole === 'other')
										?.map((member, index) => (
											<Box key={`member-${index + 1}`} display="flex" marginBottom={1}>
												<Box width={234} marginRight={2}>
													<KPTextfield
														value={member.isPhenikaa}
														type="checkbox"
														name="GV trong trường"
														handleChangeForm={handleChangeForm}
														disabled={disabled}
														style={{ marginTop: '25.5px' }}
													/>
												</Box>
												<Grid container spacing={2} style={{ flexGrow: 1 }}>
													<Grid item xs={4}>
														<KPTextfield
															type={member.isPhenikaa ? 'autoComplete' : null}
															options={member.isPhenikaa ? profileList : null}
															getOptionLabel={(option) => `${option.code} - ${option.name}`}
															required={true}
															value={
																member.isPhenikaa
																	? profileList.find((e) => e.id === member?.profileId)
																	: member?.name
															}
															label="Tên giảng viên"
															disabled={disabled}
															handleChangeForm={handleChangeForm}
														/>
													</Grid>
													<Grid item xs={4}>
														<KPTextfield
															required={true}
															value={member.degree}
															label="Học hàm, học vị"
															disabled={disabled}
															handleChangeForm={handleChangeForm}
														/>
													</Grid>
													<Grid item xs={4}>
														<KPTextfield
															required={true}
															value={member.department}
															label="Đơn vị"
															disabled={disabled}
															handleChangeForm={handleChangeForm}
														/>
													</Grid>
												</Grid>
											</Box>
										))}
								</>
							)}
						</Box>
						<Box width="calc(100% - 20px)" margin="8px 0" display="flex" justifyContent="center">
							<DoubleArrow
								style={{
									fontSize: 36,
									padding: '0 8px',
									transform: 'rotate(90deg)',
									color: 'rgba(0, 0, 0, 0.30)'
								}}
							/>
						</Box>
						<Box
							width="calc(100% - 20px)"
							position="relative"
							padding={1}
							paddingTop={3}
							border="solid 1px #bcbcbc"
							borderRadius={4}
						>
							<Box className={styles.box}>
								<Typography>
									<b>Sau khi điều chỉnh</b>
								</Typography>
							</Box>
							{history.name ? (
								<>
									<KPTextfield
										id="vietnameseName"
										value={values?.vietnameseName}
										label="Tên đề tài tiếng Việt"
										handleChangeForm={handleChangeForm}
										disabled={true}
									/>
									<Box height={8} />
									<KPTextfield
										id="englishName"
										value={values?.englishName}
										label="Tên đề tài tiếng Anh"
										handleChangeForm={handleChangeForm}
										disabled={true}
									/>
								</>
							) : (
								<>
									{participants
										.filter((e) => e.researchRole === 'other')
										?.map((member, index) => (
											<Box key={`member-${index + 1}`} display="flex" marginBottom={1}>
												<Box width={234} marginRight={2}>
													<KPTextfield
														value={member.isPhenikaa}
														type="checkbox"
														name="GV trong trường"
														handleChangeForm={handleChangeForm}
														disabled={disabled}
														style={{ marginTop: '25.5px' }}
													/>
												</Box>
												<Grid container spacing={2} style={{ flexGrow: 1 }}>
													<Grid item xs={4}>
														<KPTextfield
															type={member.isPhenikaa ? 'autoComplete' : null}
															options={member.isPhenikaa ? profileList : null}
															getOptionLabel={(option) => `${option.code} - ${option.name}`}
															required={true}
															value={
																member.isPhenikaa
																	? profileList.find((e) => e.id === member?.profileId)
																	: member?.name
															}
															label="Tên giảng viên"
															disabled={disabled}
															handleChangeForm={handleChangeForm}
														/>
													</Grid>
													<Grid item xs={4}>
														<KPTextfield
															required={true}
															value={member.degree}
															label="Học hàm, học vị"
															disabled={disabled}
															handleChangeForm={handleChangeForm}
														/>
													</Grid>
													<Grid item xs={4}>
														<KPTextfield
															required={true}
															value={member.department}
															label="Đơn vị"
															disabled={disabled}
															handleChangeForm={handleChangeForm}
														/>
													</Grid>
												</Grid>
											</Box>
										))}
								</>
							)}
						</Box>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}

export default ReportForm;
