import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import select from '../../../../../utils/select';

import { KPTable } from '../../../../../components/KPTComponents';

function DepartmentTab(props) {
	const { listTimeResearch } = props;

	const { isFetching } = useSelector((state) => ({
		isFetching: select(state, 'profileReducer', 'isFetching')
	}));

	const columns = [
		{ key: 'stt', title: <b>STT</b>, width: 25, align: 'center', sticky: true },
		{ key: 'profileCode', width: 70, title: <b>MNS</b>, align: 'center', sticky: true },
		{
			key: 'profileName',
			width: 200,
			title: <b>HỌ TÊN GIẢNG VIÊN</b>,
			align: 'center',
			sticky: true
		},
		{ key: 'researchProjects', width: 200, title: <b>ĐỀ TÀI NGHIÊN CỨU CÁC CẤP (I)</b> },
		{ key: 'scientificResearchs', width: 200, title: <b>BÀI BÁO KHOA HỌC (II)</b> },
		{ key: 'patents', width: 200, title: <b>BẰNG SÁNG CHẾ/BẰNG GIẢI PHÁP HỮU ÍCH (III)</b> },
		{ key: 'monographs', width: 200, title: <b>SÁCH CHUYÊN KHẢO/THAM KHẢO/GIÁO TRÌNH (IV)</b> },
		{ key: 'studentGuidance', width: 200, title: <b>HƯỚNG DẪN SV NCKH (V)</b> },
		{ key: 'scientificReports', width: 200, title: <b>BÁO CÁO, THAM LUẬN (VI)</b> },
		{ key: 'scientificCouncils', width: 200, title: <b>THÀNH VIÊN HỘI ĐỒNG KHOA HỌC (VII)</b> }
	];

	return (
		<Box style={{ overflow: 'auto' }}>
			<KPTable
				loading={isFetching}
				columns={columns}
				rows={listTimeResearch}
				page={null}
				disablePagination={true}
				maxHeight="calc(100vh - 240px)"
			/>
		</Box>
	);
}

export default DepartmentTab;
