import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	year: new Date().getFullYear(),
	list: [],
	detail: null,
	isFetching: false,
	statistic: []
});

export default function timeResearchInformationReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_STATISTIC_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_BY_ID_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.UPDATE_TIME_RESEARCH_INFORMATION_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.DELETE_TIME_RESEARCH_INFORMATION_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_ALL_PROFILE_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.LOCK_TIME_RESEARCH_LOADING:
		case TYPE.TIME_RESEARCH_INFORMATION.LOCK_ALL_TIME_RESEARCH_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_STATISTIC_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_BY_ID_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.UPDATE_TIME_RESEARCH_INFORMATION_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.DELETE_TIME_RESEARCH_INFORMATION_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.LOCK_TIME_RESEARCH_FAILURE:
		case TYPE.TIME_RESEARCH_INFORMATION.LOCK_ALL_TIME_RESEARCH_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_FAILURE: {
			return state.merge({
				isFetching: false,
				detail: null
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.GET_ALL_TIME_RESEARCH_INFORMATION_SUCCESS: {
			return state.merge({
				isFetching: false,
				detail: null
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_SUCCESS: {
			return state.merge({
				list: action.payload || [],
				isFetching: false
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_STATISTIC_SUCCESS: {
			return state.merge({
				statistic: action.payload || [],
				isFetching: false
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_BY_ID_SUCCESS:
		case TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_SUCCESS: {
			return state.merge({
				detail: action.payload || [],
				isFetching: false
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_SUCCESS:
			return state.merge({
				detail: action.payload,
				isFetching: false
			});

		case TYPE.TIME_RESEARCH_INFORMATION.UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS: {
			return state.merge({
				detail: action.payload,
				isFetching: false
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.DELETE_TIME_RESEARCH_INFORMATION_SUCCESS: {
			const newList = state.get('list');
			const index = newList.findIndex((e) => e.id === action.id);
			if (index !== -1) newList.splice(index, 1);
			return state.merge({
				list: [...newList],
				isFetching: false
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_SUCCESS: {
			return state.merge({
				isFetching: false
			});
		}

		case 'YEAR_FILTER':
			return state.merge({
				year: action.id
			});

		case TYPE.TIME_RESEARCH_INFORMATION.LOCK_TIME_RESEARCH_SUCCESS: {
			const newList = state.get('statistic');
			newList[0]?.departmentInfo[0]?.timeResearchInformation.forEach((e) => {
				if (e.id === action.id) e.locked = !e.locked;
			});
			return state.merge({
				statistic: newList,
				isFetching: false
			});
		}

		case TYPE.TIME_RESEARCH_INFORMATION.LOCK_ALL_TIME_RESEARCH_SUCCESS: {
			const newList = state.get('statistic');
			newList[0]?.departmentInfo[0]?.timeResearchInformation.forEach((e) => {
				e.locked = action.locked;
			});
			return state.merge({
				statistic: newList,
				isFetching: false
			});
		}

		default:
			return state;
	}
}
