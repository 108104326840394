import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider, Typography, TextField, MenuItem } from '@material-ui/core';
import { Replay, Add, CloudUpload } from '@material-ui/icons';
import * as XLSX from 'xlsx';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import {
	getEarningsTable,
	postEarningsTable,
	postImportEarningsTable
} from '../../../../reducer/earningsTable/action';
import EarningsTableInsertModal from '../EarningsTableInsertModal';

function EarningTableButton(props) {
	const {
		earningsTableList,
		getEarningsTable,
		postEarningsTable,
		postImportEarningsTable,
		year: defaultYear
	} = props;

	const currentYear = new Date().getFullYear();
	const [year, setYear] = useState(defaultYear || currentYear);
	const [insertModalOpen, setInsertModalOpen] = useState(false);

	const defaultYears = useMemo(() => {
		const startYear = currentYear - 2; // Adjust this as needed
		const endYear = currentYear + 1; // Adjust this as needed
		const years = [];
		for (let y = endYear; y >= startYear; y--) {
			years.push(y);
		}
		return years;
	}, [currentYear]);

	const yearList = useMemo(() => {
		const tempList = new Set(defaultYears);
		earningsTableList.forEach((e) => {
			const parsedYear = parseInt(e.year, 10);
			if (!isNaN(parsedYear)) {
				tempList.add(parsedYear);
			}
		});
		return Array.from(tempList).sort((a, b) => b - a);
	}, [earningsTableList, defaultYears]);

	useEffect(() => {
		if (earningsTableList.length === 0) {
			getEarningsTable(year);
		}
	}, [earningsTableList, getEarningsTable, year]);

	const handleReload = () => getEarningsTable(year);

	const handleYearChange = (e) => {
		const selectedYear = e.target.value;
		setYear(selectedYear);
		getEarningsTable(selectedYear);
	};

	const handleInsert = () => setInsertModalOpen(true);
	const handleCloseInsertModal = () => setInsertModalOpen(false);

	const typeMapping = {
		'Bài báo khoa học': 'scientificResearch',
		'Bằng sáng chế': 'patent',
		'Giải pháp hữu ích': 'solution'
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: 'array' });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			console.log('Dữ liệu Excel:', json);

			const payload = json.slice(1).map((row) => ({
				year,
				type: typeMapping[row[1]] || row[1],
				condition: row[2],
				value: parseInt(row[3].replace(/\D/g, ''))
			}));
			if (payload.length > 0) {
				postImportEarningsTable(payload);
				getEarningsTable(year);
			}
		};

		reader.readAsArrayBuffer(file);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={3}>
					<Typography variant="h5" style={{ lineHeight: '33px' }}>
						Bảng tính lương
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<TextField
						select
						fullWidth
						size="small"
						variant="outlined"
						value={year}
						label="Chọn năm học"
						onChange={handleYearChange}
						SelectProps={{
							MenuProps: {
								anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
								transformOrigin: { vertical: 'top', horizontal: 'center' },
								getContentAnchorEl: null
							}
						}}
					>
						{yearList.map((e, index) => (
							<MenuItem key={index} value={e}>
								{e} - {e + 1}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', margin: '8px 0' }}>
					<input
						accept=".xlsx, .xls"
						style={{ display: 'none' }}
						id="upload-excel-file"
						type="file"
						onChange={handleFileUpload}
					/>
					<label htmlFor="upload-excel-file">
						<Button
							variant="outlined"
							component="span"
							startIcon={<CloudUpload />}
							style={{ marginRight: 8 }}
						>
							Import
						</Button>
					</label>
					<Button
						variant="outlined"
						startIcon={<Replay />}
						onClick={handleReload}
						style={{ marginRight: 8 }}
					>
						Tải lại
					</Button>
					<Button variant="contained" color="primary" startIcon={<Add />} onClick={handleInsert}>
						Thêm bảng lương
					</Button>
				</Grid>
			</Grid>
			<Divider style={{ margin: '8px 0' }} />
			<EarningsTableInsertModal
				open={insertModalOpen}
				onClose={handleCloseInsertModal}
				year={year}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	earningsTableList: select(state, 'earningsTableReducer', 'list'),
	year: select(state, 'earningsTableReducer', 'year')
});

const mapDispatchToProps = (dispatch) => ({
	getEarningsTable: (year) => dispatch(getEarningsTable(year)),
	postImportEarningsTable: (payload) => dispatch(postImportEarningsTable(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(EarningTableButton));
