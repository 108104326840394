import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import KPTable from '../../../components/KPTable';

import HumanResourceRoleModal from '../HumanResourceRoleModal';
import HumanResourceUpdateModal from '../HumanResourceUpdateModal';
import HumanResourceDeleteModal from '../HumanResourceDeleteModal';

import { notification } from '../../../reducer/home/action';
import { getAllUsers, updateRole } from '../../../reducer/humanResource/action';
import HumanResourceProfileInfoModal from '../HumanResourceProfileInfoModal/HumanResourceProfileInfoModal';
import { getAllProfile } from '../../../reducer/profile/action';

const useStyles = makeStyles((_theme) => {
	return {
		btnAction: {
			cursor: 'pointer'
		}
	};
});

function HumanResourceTable(props) {
	const history = useHistory();

	const { getAllUsers, allUsers, updateRole } = props;

	const [profileId, setProfileId] = useState(null); // viewing profile info in modal
	const [updating, setUpdating] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	// for assigning role
	const [roleAnchorEl, setRoleAnchorEl] = useState(null);
	const [assignRoleFor, setAssignRoleFor] = useState(null); // user id
	const [currentRole, setCurrentRole] = useState(''); // role of selected user

	const classes = useStyles();

	useEffect(() => {
		getAllUsers();
		props.getAllProfile();
	}, []);

	const handleClickAssignRoleButton = (event, row) => {
		setRoleAnchorEl(event.currentTarget);
		setAssignRoleFor(row.id);
		setCurrentRole(row.role);
	};

	const handleAssignRole = async (userId, role) => {
		updateRole(userId, role, () => {
			setRoleAnchorEl(null);
			setAssignRoleFor(null);
			setCurrentRole('');
		});
	};

	const handleDeleteHumanResource = (event, row) => {
		setDeleting(row.id);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseProfileInfoModal = () => {
		setProfileId(null);
	};

	return (
		<>
			<KPTable
				shadow="none"
				rowsPerPage={10}
				maxHeight="calc(100vh - 235px)"
				columns={[
					{
						title: <b>Họ tên</b>,
						key: 'name',
						width: '25%',
						searchable: true,
						// render: (value, row) => {
						//   return row.profile?.name ?? "N/A";
						// }
						render: (value, row) => {
							return (
								<a
									href={`${ROUTER.WORK}/profile-id=${row.profile?.id}`}
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<Typography>
										<b>{value}</b>
									</Typography>
								</a>
							);
						}
					},
					{
						title: <b>Email</b>,
						key: 'mail',
						width: '30%',
						searchable: true
					},
					{
						title: <b>Mã PU</b>,
						key: 'code',
						width: '20%',
						searchable: true
					},
					{
						title: <b>Vai trò</b>,
						key: 'role',
						width: '20%',
						render: (value) => {
							switch (value) {
								case 'sudo-admin':
									return 'Admin';
								case 'humanresource':
									return 'Quản lý nhân sự';
								default:
									return 'Nhà nghiên cứu';
							}
						}
					},
					{
						title: <b>Vị trí</b>,
						key: 'occupation',
						width: '20%',
						filters: [
							{
								text: 'Nhà nghiên cứu',
								value: 'researcher'
							},
							{
								text: 'Quản lý nhân sự',
								value: 'humanresource'
							},
							{
								text: 'Postdoc',
								value: 'postdoc'
							},
							{
								text: 'Trợ lý nghiên cứu',
								value: 'tlnc'
							}
						],
						onFilter: (value, row) => row.filter((e) => e.occupation === value),
						render: (value) => {
							switch (value) {
								// case "sudo-admin":
								//   return "Admin";
								case 'humanresource':
									return 'Quản lý nhân sự';
								case 'postdoc':
									return 'Postdoc';
								case 'tlnc':
									return 'Trợ lý nghiên cứu';
								default:
									return 'Nhà nghiên cứu';
							}
						}
					},
					{
						title: <b>Tác vụ</b>,
						key: 'action',
						width: '20%',
						render: (value, row) => {
							return (
								<Box display="flex">
									<Typography
										className="table-action-buton"
										variant="caption"
										color="primary"
										style={{ marginRight: 8 }}
										onClick={() => {
											const profile = props.profileList?.find((p) => p.userId === row.id);
											console.log(props.profileList);
											if (profile) {
												history.push(`/user?profile-id=${profile.id}`);
											}
										}}
									>
										{/* <NavLink
                      style={{ textDecoration: 'none' }}
                      to={(() => {
                        const profile = props.profileList?.find((p) => p.userId === row.id);
                        console.log(profile);
                        return profile ? `/user?profile-id=${profile.id}` : '#';
                      })()}
                    > */}
										Profile
										{/* </NavLink> */}
									</Typography>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="initial"
										style={{ marginRight: 8 }}
										onClick={(event) => handleClickAssignRoleButton(event, row)}
									>
										Gán quyền
									</Typography>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="secondary"
										onClick={(event) => handleDeleteHumanResource(event, row)}
									>
										Xóa
									</Typography>
								</Box>
								// <Box display='flex'>
								//   <Typography
								//     className='table-action-buton'
								//     variant="caption"
								//     color="primary"
								//     style={{ marginRight: 8 }}
								//     onClick={() => setUpdating(row)}
								//   >
								//     Profile
								//   </Typography>
								//   <Typography
								//     className='table-action-buton'
								//     variant="caption"
								//     color="primary"
								//     style={{ marginRight: 8 }}
								//     onClick={() => setProfileId(row.profile?.id)}
								//   >
								//     Lý lịch
								//   </Typography>
								//   <Typography
								//     className='table-action-buton'
								//     variant="caption"
								//     color="secondary"
								//     onClick={(event) => handleDeleteHumanResource(event, row)}
								//   >
								//     Xóa
								//   </Typography>
								// </Box>
							);
						}
					}
				]}
				rows={allUsers.map((item) => {
					return {
						...item,
						name: item?.profile?.name ?? '',
						mail: item?.profile?.mail ?? '',
						code: item?.profile?.code ?? ''
					};
				})}
			/>
			{roleAnchorEl !== null && assignRoleFor !== null && (
				<HumanResourceRoleModal
					roleAnchorEl={roleAnchorEl}
					setRoleAnchorEl={setRoleAnchorEl}
					currentRole={currentRole}
					assignRole={(role) => handleAssignRole(assignRoleFor, role)}
				/>
			)}
			<HumanResourceUpdateModal updating={updating} setUpdating={setUpdating} />
			<HumanResourceDeleteModal deleting={deleting} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
			{profileId && (
				<HumanResourceProfileInfoModal
					profileId={profileId}
					handleCloseProfileInfoModal={handleCloseProfileInfoModal}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	allUsers: select(state, 'humanResourceReducer', 'allUsers'),
	profileList: select(state, 'profileReducer', 'list')
});
const mapDispatchToProps = (dispatch) => ({
	notification: (payload) => dispatch(notification(payload)),
	getAllUsers: () => dispatch(getAllUsers()),
	updateRole: (userId, role, cleanUp) => dispatch(updateRole(userId, role, cleanUp)),
	getAllProfile: () => dispatch(getAllProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(HumanResourceTable));
