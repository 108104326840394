import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getFilteredSolution } from '../../../reducer/solution/action';

function ListSolution(props) {
	useEffect(() => {
		props.getFilteredSolution(1, -1, {
			// accepted: true,
			departmentId: props.chosenDepartmentId,
			dashboardYear: props.chosenYear,
			acceptedOne: true
		});
	}, [props.chosenYear]);

	const getSolutionTitle = (info, index) => {
		return (
			<div key={`solution-${index}`} style={{ marginTop: 16 }}>
				<div>
					<b style={{ color: 'gray' }}>{index + 1}. Tên giải pháp: </b>
					<i style={{ color: 'red' }}> {info.solution.title}</i>
				</div>
				<div style={{ marginLeft: 20 }}>
					<b>- Nhóm tác giả: </b>
					{info.solution.authorTeam || ''}
					<br />
					<b>- Số đơn: </b>
					{info.solution.identificationNumber || ''}
					<br />
					<b>- Ngày nộp đơn: </b>
					{moment(info.solution.submissionDay).format('DD/MM/YYYY') || ''}
					<br />
					<b>- Ngày chấp nhận đơn: </b>
					{moment(info.solution.acceptedDay).format('DD/MM/YYYY') || ''}
					<br />
				</div>
			</div>
		);
	};

	return props.isFetching ? (
		<div style={{ textAlign: 'center' }}>
			<CircularProgress />
		</div>
	) : (
		<Box
			paddingRight={1}
			height="calc(100vh - 214px)"
			minWidth="calc(100% + 8px)"
			style={{ overflowY: 'auto' }}
		>
			{props.listSolutionAccepted.map((item, index) => getSolutionTitle(item, index))}
		</Box>
	);
}

const mapStateToProps = (state) => ({
	isFetching: select(state, 'solutionReducer', 'isFetching'),
	listSolutionAccepted: select(state, 'solutionReducer', 'listFiltered')
});

const mapDispatchToProps = (dispatch) => ({
	getFilteredSolution: (page, pageSize, payload) =>
		dispatch(getFilteredSolution(page, pageSize, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ListSolution));
