import {
	Box,
	Grid,
	Button,
	MenuItem,
	InputBase,
	TextField,
	Typography,
	IconButton,
	Divider
} from '@material-ui/core';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search, Close, Add, Replay } from '@material-ui/icons';

import select from '../../../utils/select';

import { getAllProfile } from '../../../reducer/profile/action';
import { filterCouncil } from '../../../reducer/council/action';
import { getAllFaculties } from '../../../reducer/department/action';
import { filterStudentResearchV2 } from '../../../reducer/studentResearch/action';

import StudentProjectModal from '../StudentProjectModal';
import dayjs from 'dayjs';
import { API_URLS } from '../../../constants/api';
import { apiCall } from '../../../utils/api';

function StudentProjectButton(props) {
	const dispatch = useDispatch();

	const { year, setYear, chosenDepartmentId, setChosenDepartmentId } = props;

	const role = useSelector((state) => select(state, 'userReducer', 'role'));
	const council = useSelector((state) => select(state, 'councilReducer', 'council'));
	const profileDetail = useSelector((state) => select(state, 'profileReducer', 'detail'));
	const faculties = useSelector((state) => select(state, 'departmentReducer', 'faculties'));
	const profileDepartments = useSelector((state) => select(state, 'profileReducer', 'departments'));

	const [open, setOpen] = useState(false);
	const [dueTime, setDueTime] = useState(null);
	const [searchElm, setSearchElm] = useState(null);
	const [chosenCouncilId, setChosenCouncilId] = useState(-1);
	const [isSearching, setIsSearching] = useState(false);
	const [personalFaculties, setPersonalFaculties] = useState(null);

	const yearRange = [0];
	for (var i = parseInt(moment().format('YYYY')); i >= 2021; i--) yearRange.push(i);

	// Vừa vào lấy hêt profile, lấy hết khoa luôn
	useEffect(() => {
		dispatch(getAllProfile());
		dispatch(getAllFaculties());
		dispatch(filterCouncil({ schoolYear: year }));
	}, []);

	useEffect(() => {
		handleReload();
	}, [year, props.type, profileDetail?.id, chosenDepartmentId, chosenCouncilId]);

	useEffect(() => {
		if (props.type === 'admin' && chosenDepartmentId) {
			dispatch(filterCouncil({ departmentId: chosenDepartmentId, schoolYear: year }));
		}
		dispatch(filterCouncil({ schoolYear: year }));
	}, [props.type, chosenDepartmentId]);

	useEffect(() => {
		let newObj;
		if (isSearching) newObj = { ...newObj, search: isSearching ? searchElm : null };

		if (newObj) props.setFilterObj(newObj);
	}, [searchElm, isSearching]);

	useEffect(() => {
		if (council?.length > 0) setChosenCouncilId(council?.[0]);
	}, [council]);

	// Lấy khoa mà mình làm trưởng khoa
	useEffect(() => {
		if (profileDepartments) {
			const faculty = profileDepartments.filter((department) => {
				const matchProfile = department.departmentProfile?.find(
					(item) => item.profileId === profileDetail.id
				);
				if (department?.isFaculty && matchProfile.roleInDepartment.toLowerCase() === 'leader')
					return true;
				return false;
			});
			if (faculty.length > 0) {
				setPersonalFaculties(faculty);
				setChosenDepartmentId(faculty[0].id);
			}
		}
	}, [profileDepartments]);

	const handleReload = () => {
		if (props.type === 'personal') {
			if (profileDetail?.id) {
				dispatch(
					filterStudentResearchV2({
						schoolYear: year,
						profileId: profileDetail.id.toString()
					})
				);
			}
		} else if (props.type === 'admin') {
			if (chosenDepartmentId) {
				dispatch(
					filterStudentResearchV2({
						schoolYear: year,
						departmentId: chosenDepartmentId.toString()
					})
				);
			} else if (role === 'sudo-admin') {
				dispatch(filterStudentResearchV2({ schoolYear: year }));
			}
		} else if (props.type === 'mod') {
			if (chosenDepartmentId) {
				dispatch(
					filterStudentResearchV2({ schoolYear: year, departmentId: chosenDepartmentId.toString() })
				);
			} else {
				dispatch(filterStudentResearchV2({ schoolYear: year }));
			}
		} else {
			if (!isNaN(chosenCouncilId))
				dispatch(
					filterStudentResearchV2({
						schoolYear: year,
						departmentId: chosenDepartmentId ? chosenDepartmentId.toString() : '-1',
						studentCouncilId: chosenCouncilId.toString()
					})
				);
		}

		// Lấy giá trị thời gian
		const api = API_URLS.YEARLY_DUE_DATE.filterYearlyDueDate();
		apiCall({ ...api, payload: { type: 'student', schoolYear: props.year } }).then(
			({ response, error }) => {
				if (!error && response.status === 200 && response.data.success) {
					setDueTime(response.data.data[0]);
				}
			}
		);
	};

	const handleInsert = () => setOpen({ insert: true });

	const IS_OPEN_REGISTER =
		dayjs(dueTime?.regStartTime) <= dayjs() && dayjs() <= dayjs(dueTime?.regEndTime);

	return (
		<>
			<Grid item xs={12}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						setIsSearching(true);
					}}
				>
					<Grid container justifyContent="space-between">
						<Grid item xs={6}>
							<Typography variant="h5" style={{ lineHeight: '40px' }}>
								<b>Đề tài Nghiên cứu khoa học cấp Sinh viên</b>
							</Typography>
						</Grid>
						<Grid item xs={6} style={{ display: 'flex' }}>
							<Box display="flex" flexGrow={1}>
								<Box
									display="flex"
									alignItems="center"
									border="solid 1px #bcbcbc"
									borderRadius={4}
									marginRight={0.5}
									width="100%"
								>
									<InputBase
										placeholder="Tìm kiếm"
										value={searchElm || ''}
										style={{ padding: '0 12px', flexGrow: 1 }}
										onChange={(e) => {
											setIsSearching(false);
											setSearchElm(e.target.value);
										}}
										endAdornment={
											searchElm && (
												<IconButton
													size="small"
													onClick={() => {
														setIsSearching(true);
														setSearchElm(null);
													}}
												>
													<Close size="small" />
												</IconButton>
											)
										}
									/>
									<Divider orientation="vertical" style={{ width: 1, height: 38 }} />
									<Box padding="0 16px">
										<IconButton size="small" type="submit">
											<Search size="small" />
										</IconButton>
									</Box>
								</Box>
							</Box>
							<TextField
								select
								margin="dense"
								size="small"
								variant="outlined"
								SelectProps={{
									MenuProps: {
										getContentAnchorEl: null,
										anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
										transformOrigin: { vertical: 'top', horizontal: 'center' }
									}
								}}
								label="Năm học"
								value={year}
								onChange={(event) => setYear(event.target.value)}
								style={{
									width: 136,
									minWidth: 136,
									maxWidth: 136,
									margin: '0 4px'
								}}
							>
								{yearRange.map((year) => (
									<MenuItem key={`year-${year}`} value={year}>
										{year === 0 ? 'Tất cả' : `${year} - ${year + 1}`}
									</MenuItem>
								))}
							</TextField>
							{props.type === 'admin' || props.type === 'conference' ? (
								<>
									{((props.type === 'admin' && faculties && chosenDepartmentId) ||
										role === 'sudo-admin') && (
										<Autocomplete
											disableClearable
											options={personalFaculties || faculties}
											// disabled={role !== "sudo-admin"}
											getOptionLabel={(option) => option.name}
											onChange={(_, val) => setChosenDepartmentId(val?.id)}
											value={(personalFaculties || faculties)?.find(
												(e) => e.id === chosenDepartmentId
											)}
											getOptionSelected={(option, value) => option.id === value?.id}
											renderInput={(params) => (
												<TextField
													{...params}
													size="small"
													label="Khoa"
													variant="outlined"
													placeholder="Cả trường"
												/>
											)}
											style={{ width: 260 }}
										/>
									)}
									{props.type === 'conference' && council && (
										<Autocomplete
											disableClearable
											options={council}
											disabled={role !== 'sudo-admin'}
											getOptionLabel={(option) => option.name}
											onChange={(_, val) => setChosenCouncilId(val?.id)}
											value={council?.find((e) => e.id === chosenCouncilId)}
											getOptionSelected={(option, value) => option.id === value?.id}
											renderInput={(params) => (
												<TextField
													{...params}
													size="small"
													label="Tiểu ban"
													variant="outlined"
													placeholder="Cả trường"
												/>
											)}
											style={{ width: 260 }}
										/>
									)}
								</>
							) : (
								<>
									<Autocomplete
										disableClearable
										options={personalFaculties || faculties}
										// disabled={role !== "sudo-admin"}
										getOptionLabel={(option) => option.name}
										onChange={(_, val) => setChosenDepartmentId(val?.id)}
										value={(personalFaculties || faculties)?.find(
											(e) => e.id === chosenDepartmentId
										)}
										getOptionSelected={(option, value) => option.id === value?.id}
										renderInput={(params) => (
											<TextField
												{...params}
												size="small"
												label="Khoa"
												variant="outlined"
												placeholder="Cả trường"
											/>
										)}
										style={{ width: 260 }}
									/>
									<Button
										variant="outlined"
										style={{ margin: '0 4px', padding: '5px 0', minWidth: 54 }}
										onClick={handleReload}
									>
										<Replay />
									</Button>
									<Button
										color="primary"
										variant="contained"
										disabled={!IS_OPEN_REGISTER}
										style={{ marginLeft: 4, padding: '5px 0', minWidth: 54 }}
										onClick={handleInsert}
									>
										<Add />
									</Button>
								</>
							)}
						</Grid>
					</Grid>
				</form>
			</Grid>

			<StudentProjectModal open={open} year={year} type={props.type} setOpen={setOpen} />
		</>
	);
}

export default StudentProjectButton;
