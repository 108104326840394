import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const updateTypeFilter = (type) => async (dispatch) => {
	dispatch({
		type: 'UPDATE_TYPE_FILTER',
		payload: type,
		meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_CALLING] }
	});
};

export const getRewardInformation = () => async (dispatch) => {
	const api = API_URLS.REWARD_INFORMATION.getRewardInformation();
	dispatch({
		type: TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const updateRewardInformation = (id, payload) => async (dispatch) => {
	const api = API_URLS.REWARD_INFORMATION.updateRewardInformation(id);
	dispatch({
		type: TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_SUCCESS,
			id: id,
			payload: response.data.data,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getRewardInformationV2 = () => async (dispatch) => {
	const api = API_URLS.REWARD_INFORMATION.getRewardInformationV2();
	dispatch({
		type: TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_V2_LOADING,
		meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_V2_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_V2_FAILURE,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const updateRewardInformationV2 = (id, payload) => async (dispatch) => {
	const api = API_URLS.REWARD_INFORMATION.updateRewardInformationV2(id);
	dispatch({
		type: TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_V2_LOADING,
		meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_V2_SUCCESS,
			id: id,
			payload: response.data.data,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Cập nhật dữ liệu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_V2_FAILURE,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Cập nhật dữ liệu thất bại!'
			})
		);
	}
};

export const postRewardInformationV2 = (payload) => async (dispatch) => {
	const api = API_URLS.REWARD_INFORMATION.insertRewardInformationV2(payload);
	dispatch({
		type: TYPE.REWARD_INFORMATION.INSERT_REWARD_INFORMATION_V2_LOADING,
		meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.REWARD_INFORMATION.INSERT_REWARD_INFORMATION_V2_SUCCESS,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm mới thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.REWARD_INFORMATION.DELETE_REWARD_INFORMATION_V2_FAILURE,
			meta: { prefix: [PREFIX.REWARD_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Thêm mới thất bại!'
			})
		);
	}
};
