import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Button,
	Select,
	Divider,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	InputAdornment,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
	Chip,
	FormHelperText
} from '@material-ui/core';
import dayjs from 'dayjs';
import { Autocomplete } from '@material-ui/lab';
import { DoubleArrow, CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import FileUploadModal from '../../File/FileUploadModal';
import FilePreviewModal from '../../File/FilePreviewModal';
import KPTimePicker from '../../../components/KPTimePicker';

import { getAllEmploymentContractByProfileId } from '../../../reducer/employmentContract/action';
import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';

import { uploadFile } from '../../../reducer/file/action';
import { updateProject, getProfileInfoByProfileId } from '../../../reducer/project/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import ScientificResearchTimeTable from '../../ScientificResearch/ScientificResearchTimeTable/ScientificResearchTimeTable';

function ProjectUpdateModal(props) {
	const { updating, setUpdating, updateProject } = props;
	const [disabled, setDisabled] = useState(true);

	const currentYear = new Date().getFullYear();
	const [detail, setDetail] = useState(null);

	const [values, setValues] = useState(null);
	const [profileList, setProfileList] = useState([]);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const [timeResearchYear, setTimeResearchYear] = useState(2023);
	const [helperText, setHelperText] = useState(false);

	useEffect(() => {
		if (updating) resetValues();
	}, [updating]);

	// Chọn thành viên để lấy đề tài thì load lại đề tài của ông ấy
	React.useEffect(() => {
		if (values?.profileIdToSelectId) props.getProfileInfoByProfileId(values?.profileIdToSelectId);
	}, [values?.profileIdToSelectId]);

	useEffect(() => {
		if (values && props.isUploading === false && props.fileUrl !== '') {
			setValues({
				...values,
				fileUrl: props.fileUrl
			});
			updateProject(values.id, {
				...values,
				researchProjectProfile: profileList,
				startAt: dateFormat(values?.startAt),
				finishAt: dateFormat(values?.finishAt),
				dashboardYear: new Date(dateFormat(values?.startAt)).getFullYear(),
				fileUrl: props.fileUrl
			});
		}
	}, [props.isUploading]);

	useEffect(() => {
		if (values?.timeResearchDetailCode) {
			const selectedYear = props.timeResearchInformationList?.find(
				(info) =>
					!!info.timeResearchDetail.find((detail) => detail.code === values?.timeResearchDetailCode)
			)?.year;

			setTimeResearchYear(selectedYear);
		}
	}, [values?.timeResearchDetailCode]);

	const dateFormat = (value) => {
		const dateForm = value?.replaceAll('/', '');
		const date = dateForm?.substr(0, 2);
		const month = dateForm?.substr(2, 2);
		const year = dateForm?.substr(-4);
		return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
	};

	const valueFormat = (value) => {
		const temp = value.replace(/\D/g, '').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.');
		return temp;
	};

	const resetValues = () => {
		let _typeToSelectId = 'patent';
		let _selectId = updating.patentId;
		if (updating.productContractId > 0) {
			_typeToSelectId = 'productContract';
			_selectId = updating.productContractId;
		}
		if (updating.scientificResearchId > 0) {
			_typeToSelectId = 'scientificResearch';
			_selectId = updating.scientificResearchId;
		}
		if (updating.solutionId > 0) {
			_typeToSelectId = 'solution';
			_selectId = updating.solutionId;
		}
		setValues({
			...updating,
			startAt: dayjs(updating.startAt)?.format('DD-MM-YYYY')?.replaceAll('-', '/'),
			finishAt: dayjs(updating.finishAt)?.format('DD-MM-YYYY')?.replaceAll('-', '/'),
			dashboardYear: new Date(dateFormat(updating?.startAt)).getFullYear(),
			// acceptedDay: dayjs(updating.acceptedDay)?.format('DD-MM-YYYY')?.replaceAll('-', '/'),
			// submissionDay: dayjs(updating.submissionDay)?.format('DD-MM-YYYY')?.replaceAll('-', '/'),
			typeToSelectId: _typeToSelectId,
			selectId: _selectId,
			profileIdToSelectId: updating.profileId
		});
		setProfileList(
			([...updating.researchProjectProfile] || []).map((item) => ({
				...item,
				name: item.isExternalTeacher ? item.name : getName(item.profileId),
				code: item.isExternalTeacher ? '' : getCode(item.profileId)
			}))
		);
		const timeResearchYearId = parseInt(updating.timeResearchDetailCode?.split('-')[0]);
		const year =
			props.timeResearchInformationList?.find((e) => e.year === timeResearchYearId)?.year || 2021;
		setTimeResearchYear(year);
	};

	const handleChangeForm = (name, value) => {
		if (name === 'timeResearchDetailCode') {
			setValues((current) => ({
				...current,
				[name]: value === 'null' ? null : value
			}));
		} else setValues((current) => ({ ...current, [name]: value }));
	};

	const handleChangeProfileList = (index, name, value) => {
		setProfileList((temp) => {
			const tempElm = temp[index];
			const updatedElm = { ...tempElm, [name]: value };
			const updatedTemp = [...temp];
			updatedTemp.splice(index, 1, updatedElm);
			return updatedTemp;
		});
	};

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInProject: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		const temp = profileList;
		temp.splice(index, 1);
		setProfileList([...temp]);
	};

	const handleClose = () => {
		if (!disabled) {
			resetValues();
			setDisabled(true);
		} else {
			setUpdating(null);
			setDisabled(true);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (disabled) {
			setDisabled(false);
		} else {
			updateProject(values.id, {
				...values,
				researchProjectProfile: profileList,
				startAt: dateFormat(values?.startAt),
				finishAt: dateFormat(values?.finishAt),
				dashboardYear: new Date(dateFormat(values?.startAt)).getFullYear()
				// acceptedDay: dateFormat(values?.acceptedDay),
				// submissionDay: dateFormat(values?.submissionDay),
			}).then((success) => {
				if (success) {
					props.statisticUnaccepted({
						departmentId: 0,
						profileId: props.profile?.id
					});
					if (props.role === 'sudo-admin')
						props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
				}
			});
			setUpdating(null);
			setDisabled(true);
		}
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);
	const handleOpenPreviewModal = () => setPreviewModalOpen(true);
	const handleClosePreviewModal = () => setPreviewModalOpen(false);

	const handleUpload = (file) => {
		props
			.uploadFile(values.id, 'researchProject', file)
			.then((success) => {
				if (success) setUploadModalOpen(false);
			})
			.catch();
	};

	var timeResearchDetailList =
		props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
			?.timeResearchDetail || [];
	timeResearchDetailList = timeResearchDetailList.filter(
		(e) => !e.allowedUpdateNum && e.type?.includes('researchProject')
	);

	const yearList = [];
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	const selectList = [];
	if (values?.typeToSelectId) {
		props[values?.typeToSelectId + 's'].forEach((e) => selectList.push(e[values?.typeToSelectId]));
	}

	const getName = (profileId) => {
		return props.profileList.find((e) => e.id === profileId)?.name ?? null;
	};
	const getCode = (profileId) => {
		return props.profileList.find((e) => e.id === profileId)?.code ?? null;
	};

	const renderLockButton = () => {
		if (!values?.locked) return null;
		return (
			<Chip
				variant="outlined"
				label={`Đã khóa tính ${
					(values?.locked === 'kpi' && 'KPI') ||
					(values?.locked === 'reward' && 'thưởng') ||
					(values?.locked === 'timeResearch' && 'giờ nghiên cứu')
				}`}
				color="primary"
				style={{ fontSize: 15, float: 'right', marginRight: 16 }}
			/>
		);
	};

	const handleSendFeedback = () => {
		props.history.push({
			pathname: ROUTER.NOTIFICATION,
			state: {
				sendTo: 'personal',
				title: 'Thông báo về sai sót thông tin trong CTKH',
				chosenProfiles: profileList.reduce((accumulator, currentValue) => {
					accumulator.push(currentValue.profileId);
					return accumulator;
				}, []),
				data: updating,
				dataType: 'project'
			}
		});
	};

	const needCalKpi = useMemo(() => {
		if (values?.purposeId !== 1) {
			return false;
		}
		return values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0;
	}, [values]);

	const needCalHour = useMemo(() => {
		return values?.purposeId === 5;
	}, [values]);

	const isFullSize = useMemo(() => {
		return needCalKpi || needCalHour;
	}, [needCalKpi, needCalHour]);

	const selectedTimeResearchDetail = useMemo(
		() => timeResearchDetailList?.find((e) => e.code === values?.timeResearchDetailCode),
		[timeResearchDetailList, values]
	);

	const numberOfYear = useMemo(() => {
		const startAt = values?.startAt;
		const finishAt = values?.finishAt;

		if (!startAt || !finishAt) return 1;

		const [startDay, startMonth, startYear] = startAt.split('/').map(Number);
		const [finishDay, finishMonth, finishYear] = finishAt.split('/').map(Number);

		let tempStartYear = startYear;
		let tempFinishYear = finishYear;
		if (startMonth >= 8) tempStartYear++;
		if (finishMonth >= 8) tempFinishYear++;
		return tempFinishYear - tempStartYear + 1;
	}, [values?.startAt, values?.finishAt]);

	const mainProfileIds = useMemo(() => {
		return profileList.filter((e) => e.roleInProject === 'main').map((e) => e.profileId);
	}, [profileList]);

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth={isFullSize ? 'xl' : 'md'}
			open={updating !== null}
			onClose={() => {
				setUpdating(null);
				setDisabled(true);
			}}
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle>
					{disabled ? 'Chi tiết' : 'Cập nhật'}
					{renderLockButton()}
				</DialogTitle>
				<DialogContent style={{ display: 'flex', overflowX: 'hidden' }}>
					<div
						style={{
							display: 'flex',
							paddingRight: 16,
							minWidth: isFullSize ? '50%' : '100%',
							maxWidth: isFullSize ? 960 : '100%'
						}}
					>
						<Grid container alignItems="flex-start" spacing={2} style={{ marginBottom: 10 }}>
							<Grid item xs={9}>
								<TextField
									fullWidth
									disabled={disabled}
									size="small"
									variant="outlined"
									label="Tên đề tài"
									value={values?.title || ''}
									onChange={(event) => handleChangeForm('title', event.target.value)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									fullWidth
									disabled={disabled}
									size="small"
									type="number"
									variant="outlined"
									label="Số lượng thành viên"
									value={values?.numOfMember || ''}
									onChange={(event) =>
										handleChangeForm('numOfMember', parseInt(event.target.value))
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									fullWidth
									disabled={disabled}
									size="small"
									variant="outlined"
									label="Mã số"
									value={values?.code || ''}
									onChange={(event) => handleChangeForm('code', event.target.value)}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControl fullWidth variant="outlined" size="small">
									<InputLabel
										id="type-label"
										style={{ padding: '0 8px', backgroundColor: 'white' }}
									>
										Loại hình đề tài
									</InputLabel>
									<Select
										native
										fullWidth
										disabled={disabled}
										labelId="type-label"
										value={values?.type || ''}
										onChange={(event) => handleChangeForm('type', event.target.value)}
									>
										<option value={null} />
										<option value="basic">Cơ bản</option>
										<option value="application">Ứng dụng</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth variant="outlined" size="small">
									<InputLabel
										id="level-label"
										style={{ padding: '0 8px', backgroundColor: 'white' }}
									>
										Cấp
									</InputLabel>
									<Select
										native
										fullWidth
										disabled={disabled}
										labelId="level-label"
										value={values?.level || ''}
										onChange={(event) => handleChangeForm('level', event.target.value)}
									>
										<option value={null} />
										<option value="school">Cơ sở</option>
										<option value="ministry">Bộ</option>
										<option value="keyMinistry">Trọng điểm của bộ</option>
										<option value="nafosted">Nafosted</option>
										<option value="domestic">Nhà nước</option>
										<option value="international">Quốc gia</option>
										<option value={null}>Khác</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									rows={2}
									fullWidth
									disabled={disabled}
									multiline
									size="small"
									variant="outlined"
									label="Sản phẩm đăng ký"
									value={values?.productName || ''}
									onChange={(event) => handleChangeForm('productName', event.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs={3}
								style={{
									paddingLeft: 18.5,
									lineHeight: '40px',
									fontSize: '1em',
									color: 'rgba(0, 0, 0, 0.5)'
								}}
							>
								Thời gian thực hiện:
							</Grid>
							<Grid item xs={4}>
								<KPTimePicker
									fullWidth
									label="Từ ngày"
									value={values?.startAt || `01/01/${currentYear}`}
									disabled={disabled}
									setValue={(newValue) => handleChangeForm('startAt', newValue)}
								/>
								{values?.startAt === 'dd/mm/yyyy' && (
									<FormHelperText error>Vui lòng điền thời gian thực hiện!</FormHelperText>
								)}
								{/* <TextField
                fullWidth
                disabled={disabled}
                size='small'
                type='date'
                variant='outlined'
                label='Từ ngày'
                value={values?.startAt || moment().format("YYYY-MM-DD")}
                onChange={(event) => handleChangeForm('startAt', event.target.value)}
              /> */}
							</Grid>
							<Grid item xs={1} style={{ textAlign: 'center' }}>
								<DoubleArrow style={{ fontSize: 40, color: 'rgba(0, 0, 0, 0.30)' }} />
							</Grid>
							<Grid item xs={4}>
								<KPTimePicker
									fullWidth
									label="Đến ngày"
									value={values?.finishAt || `01/01/${currentYear}`}
									disabled={disabled}
									setValue={(newValue) => handleChangeForm('finishAt', newValue)}
								/>
								{values?.finishAt === 'dd/mm/yyyy' && (
									<FormHelperText error>Vui lòng điền thời gian thực hiện!</FormHelperText>
								)}
								{/* <TextField
                fullWidth
                disabled={disabled}
                size='small'
                type='date'
                variant='outlined'
                label='Đến ngày'
                value={values?.finishAt || moment().format("YYYY-MM-DD")}
                onChange={(event) => handleChangeForm('finishAt', event.target.value)}
              /> */}
							</Grid>
							<Grid item xs={6}>
								<TextField
									select
									fullWidth
									disabled={disabled}
									size="small"
									variant="outlined"
									label="Cơ quan chủ trì"
									value={values?.host || ''}
									onChange={(event) => handleChangeForm('host', event.target.value)}
								>
									<MenuItem value={'phenikaa'}>Trường đại học Phenikaa</MenuItem>
									<MenuItem value={'other'}>Trường đại học khác</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									disabled={disabled}
									size="small"
									variant="outlined"
									label="Cơ quan tài trợ"
									value={values?.sponsor || ''}
									onChange={(event) => handleChangeForm('sponsor', event.target.value)}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									fullWidth
									disabled={disabled}
									size="small"
									variant="outlined"
									label="Giá trị"
									value={values?.value || ''}
									InputProps={{
										endAdornment: <InputAdornment position="end">VND</InputAdornment>
									}}
									onChange={(event) => handleChangeForm('value', valueFormat(event.target.value))}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormControl fullWidth disabled={disabled} variant="outlined" size="small">
									<InputLabel
										id="status-label"
										style={{ padding: '0 8px', backgroundColor: 'white' }}
									>
										Tình trạng
									</InputLabel>
									<Select
										native
										fullWidth
										disabled={disabled}
										labelId="status-label"
										value={values?.status || ''}
										onChange={(event) => handleChangeForm('status', event.target.value)}
									>
										<option value={null} />
										<option value="processing">Đang tiến hành</option>
										<option value="accepted">Đã nghiệm thu</option>
										<option value="extend">Gia hạn</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl fullWidth variant="outlined" size="small">
									<InputLabel
										id="purpose-label"
										style={{ padding: '0 8px', backgroundColor: 'white' }}
									>
										Mục đích
									</InputLabel>
									<Select
										native
										fullWidth
										labelId="purpose-label"
										disabled={disabled}
										value={values?.purposeId ?? 'none'}
										onChange={(event) =>
											handleChangeForm('purposeId', parseInt(event.target.value))
										}
									>
										{props.purposes.map((e, index) => {
											if (e.name === 'timeResearch')
												return (
													<option key={e.id} value={e.id}>
														{e.description}
													</option>
												);
											if (e.name === 'oldPaper')
												return (
													<option key={e.id} value={e.id}>
														Đề tài không phải Phenikaa chủ trì
													</option>
												);
											return <option hidden key={`hidden-${index}`} />;
										})}
									</Select>
								</FormControl>
							</Grid>

							{values?.purposeId === 5 && (
								<>
									<Grid item xs={3}>
										<TextField
											select
											fullWidth
											size="small"
											value={timeResearchYear}
											disabled={disabled}
											SelectProps={{ native: true }}
											label="Năm học tính giờ nghiên cứu"
											variant="outlined"
											onChange={(event) => {
												setTimeResearchYear(parseInt(event.target.value));
												handleChangeForm('timeResearchDetailCode', null);
											}}
										>
											{yearList.map((e) => (
												<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
													{e} - {e + 1}
												</option>
											))}
										</TextField>
									</Grid>
									<Grid item xs={9}>
										<TextField
											select
											fullWidth
											size="small"
											disabled={disabled}
											label="Nhiệm vụ tính giờ nghiên cứu"
											variant="outlined"
											value={values?.timeResearchDetailCode || null}
											onChange={(event) =>
												handleChangeForm('timeResearchDetailCode', event.target.value)
											}
											InputLabelProps={{
												shrink: !(
													values?.timeResearchDetailCode === undefined ||
													values?.timeResearchDetailCode === null
												)
											}}
											error={!values?.timeResearchDetailCode}
										>
											{timeResearchDetailList.length > 0 ? (
												timeResearchDetailList?.map((e) => (
													<MenuItem
														key={e.code}
														value={e.code}
													>{`${e.description} - ${e.value}(h)`}</MenuItem>
												))
											) : (
												<MenuItem value={null}>Chưa có dữ liệu</MenuItem>
											)}
										</TextField>
									</Grid>
								</>
							)}
							<Grid item xs={12}>
								<TextField
									fullWidth
									size="small"
									label="Ghi chú"
									variant="outlined"
									value={values?.note || ''}
									multiline
									rows={3}
									disabled={disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
									onChange={(event) => handleChangeForm('note', event.target.value)}
								/>
							</Grid>

							<Grid item xs={5} style={{ margin: 'auto' }}>
								<Divider />{' '}
							</Grid>
							<Grid item xs={2}>
								<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
									Thành viên
								</Typography>
							</Grid>
							<Grid item xs={5} style={{ margin: 'auto' }}>
								<Divider />{' '}
							</Grid>

							<Grid item xs={12}>
								{profileList.map((e, index) => (
									<Grid key={`list-${index}`} container spacing={2}>
										<Grid
											item
											xs={3}
											style={{
												display: 'flex'
											}}
										>
											<FormControl required fullWidth error={!values?.profileIdForKpi}>
												<Button
													disabled={disabled}
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													startIcon={e.isExternalTeacher ? <CheckBox /> : <CheckBoxOutlineBlank />}
													onClick={() => {
														handleChangeProfileList(
															index,
															'isExternalTeacher',
															!e.isExternalTeacher
														);
														handleChangeProfileList(index, 'profileId', 0);
														handleChangeProfileList(index, 'name', null);
													}}
												>
													Ngoài trường
												</Button>
											</FormControl>
										</Grid>
										<Grid
											item
											xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 3 : 3}
										>
											{e.isExternalTeacher ? (
												<TextField
													disabled={disabled}
													defaultValue={e.name}
													onChange={(event) => {
														handleChangeProfileList(index, 'name', event.target.value);
														handleChangeProfileList(index, 'profileId', 0);
														if (event.target.value) setHelperText(false);
													}}
													error={helperText && e.name === null}
													fullWidth={true}
													size="small"
													label="Thành viên"
													variant="outlined"
												/>
											) : (
												<Autocomplete
													disableClearable
													disabled={disabled}
													options={props.profileList}
													getOptionLabel={(option) => `${option.code} - ${option.name}`}
													value={props.profileList?.find((profile) => profile.id === e.profileId)}
													onChange={(event, profile) => {
														handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
														handleChangeProfileList(index, 'name', profile?.name);
														handleChangeProfileList(index, 'code', profile?.code);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															size="small"
															label="Thành viên"
															variant="outlined"
														/>
													)}
												/>
											)}
											{helperText && e.profileId === null && (
												<FormHelperText style={{ color: 'red' }}>
													Không được để trống
												</FormHelperText>
											)}
										</Grid>
										<Grid
											item
											xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 3 : 3}
										>
											<FormControl fullWidth variant="outlined" size="small">
												<InputLabel
													id="roleInProject-label"
													style={{ padding: '0 8px', backgroundColor: 'white' }}
												>
													Vai trò
												</InputLabel>
												<Select
													native
													fullWidth
													disabled={disabled}
													labelId="roleInProject-label"
													value={e?.roleInProject || 'main'}
													onChange={(event) =>
														handleChangeProfileList(index, 'roleInProject', event.target.value)
													}
												>
													<option value="main">Chủ nhiệm</option>
													<option value="member">Thành viên</option>
												</Select>
											</FormControl>
										</Grid>
										{values?.purposeId === 1 && (
											<Grid item xs={3}>
												<FormControl required fullWidth error={!values?.profileIdForKpi}>
													<Button
														fullWidth
														color="primary"
														variant="outlined"
														style={{ height: 40 }}
														disabled={disabled}
														startIcon={
															typeof values?.profileIdForKpi === 'number' &&
															values?.profileIdForKpi === e.profileId ? (
																<CheckBox />
															) : (
																<CheckBoxOutlineBlank />
															)
														}
														onClick={() => {
															setDetail(null);
															props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
															handleChangeForm(
																'profileIdForKpi',
																values?.profileIdForKpi !== e.profileId
																	? parseInt(e.profileId)
																	: null
															);
														}}
													>
														Tính KPI
													</Button>
													{!values?.profileIdForKpi && (
														<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
													)}
												</FormControl>
											</Grid>
										)}
										{values?.purposeId === 99999999 && (
											<Grid item xs={3}>
												<FormControl required fullWidth error={!values?.profileIdForReward}>
													<Button
														fullWidth
														color="primary"
														variant="outlined"
														disabled={disabled}
														style={{ height: 40 }}
														// disabled={e.profileId === null}
														startIcon={
															typeof values?.profileIdForReward === 'number' &&
															values?.profileIdForReward === e.profileId ? (
																<CheckBox />
															) : (
																<CheckBoxOutlineBlank />
															)
														}
														onClick={() => {
															setDetail(null);
															handleChangeForm(
																'profileIdForReward',
																values?.profileIdForReward !== e.profileId
																	? parseInt(e.profileId)
																	: null
															);
														}}
													>
														Tính thưởng
													</Button>
													{!values?.profileIdForReward && (
														<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
													)}
												</FormControl>
											</Grid>
										)}
										<Grid item xs={2}>
											<Button
												fullWidth
												disabled={disabled}
												color="secondary"
												variant="contained"
												style={{ height: 40 }}
												onClick={() => handleRemove(index)}
											>
												Xoá
											</Button>
										</Grid>
									</Grid>
								))}
							</Grid>

							<Grid item xs={12}>
								<Button fullWidth disabled={disabled} variant="outlined" onClick={handleInsert}>
									Thêm thành viên
								</Button>
							</Grid>
							<div
								style={{
									flexDirection: 'column',
									display:
										values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0
											? 'block'
											: 'none'
								}}
							>
								<EmploymentContractTable
									contract={detail}
									setDetail={setDetail}
									contractList={contractList}
									kpiIdForKpi={values?.kpiIdForKpi}
									contractIdForKpi={values?.contractIdForKpi}
									handleChangeForm={handleChangeForm}
									values={values}
									setValues={setValues}
								/>
								<EmploymentContractDetailTable kpi={detail} />
							</div>
						</Grid>
					</div>
					<div
						style={{
							flexDirection: 'column',
							display: needCalHour ? 'block' : 'none'
						}}
					>
						<ScientificResearchTimeTable
							profiles={profileList}
							mainProfileIds={mainProfileIds}
							totalHour={(selectedTimeResearchDetail?.value ?? 0) / numberOfYear}
							projectMode="project"
						/>
					</div>
				</DialogContent>
				<DialogActions style={{ padding: '0 24px 8px 24px' }}>
					<Grid container justifyContent={'space-between'}>
						<div>
							<Button
								disabled={disabled}
								variant="outlined"
								color="primary"
								onClick={handleOpenUploadModal}
								style={{ marginRight: 4 }}
							>
								Upload file minh chứng
							</Button>
							<Button variant="contained" color="primary" onClick={handleOpenPreviewModal}>
								Preview file minh chứng
							</Button>
						</div>
						<div>
							{props.adminTab && (
								<Button variant="outlined" onClick={handleSendFeedback}>
									Gửi feedback
								</Button>
							)}
							<Button
								variant="outlined"
								color="secondary"
								onClick={handleClose}
								style={{ margin: '0 4px' }}
							>
								Huỷ
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={
									(!disabled &&
										((values?.purposeId === 1 &&
											!(values?.profileIdForKpi && values?.contractIdForKpi)) ||
											(values?.purposeId === 99999999 && !values?.profileIdForReward) ||
											(values?.purposeId === 5 && !values?.timeResearchDetailCode))) ||
									values?.locked
								}
							>
								{disabled ? 'Chỉnh sửa' : 'Xác nhận'}
							</Button>
						</div>
					</Grid>
				</DialogActions>
			</form>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
			/>
			<FilePreviewModal
				open={previewModalOpen}
				handleClose={handleClosePreviewModal}
				url={values?.fileUrl}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	history: select(state, 'homeReducer', 'history'),
	profile: select(state, 'profileReducer', 'detail'),
	fileUrl: select(state, 'fileReducer', 'fileUrl'),
	profileList: select(state, 'profileReducer', 'list'),
	isUploading: select(state, 'fileReducer', 'isFetching'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	isFetchingTimeResearchInformation: select(state, 'timeResearchInformationReducer', 'isFetching'),
	// ----------- //
	scientificResearchs: select(state, 'projectReducer', 'scientificResearchs'),
	solutions: select(state, 'projectReducer', 'solutions'),
	patents: select(state, 'projectReducer', 'patents'),
	productContracts: select(state, 'projectReducer', 'productContracts')
});

const mapDispatchToProps = (dispatch) => ({
	updateProject: (id, payload) => dispatch(updateProject(id, payload)),
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	getProfileInfoByProfileId: (id) => dispatch(getProfileInfoByProfileId(id)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ProjectUpdateModal));
