import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllCouncil = () => async (dispatch) => {
	const api = API_URLS.COUNCIL.getAllCouncil();
	dispatch({
		type: TYPE.COUNCIL.GET_ALL_COUNCIL_LOADING,
		meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.COUNCIL.GET_ALL_COUNCIL_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.COUNCIL.GET_ALL_COUNCIL_FAILURE,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_FAILURE] }
		});
	}
};

export const filterCouncil = (payload) => async (dispatch) => {
	const api = API_URLS.COUNCIL.filterCouncil();
	dispatch({
		type: TYPE.COUNCIL.FILTER_COUNCIL_LOADING,
		meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.COUNCIL.FILTER_COUNCIL_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.COUNCIL.FILTER_COUNCIL_FAILURE,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_FAILURE] }
		});
	}
};

export const insertCouncil = (payload, departments) => async (dispatch) => {
	const api = API_URLS.COUNCIL.insertCouncil();
	dispatch({
		type: TYPE.COUNCIL.INSERT_COUNCIL_LOADING,
		meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.COUNCIL.INSERT_COUNCIL_SUCCESS,
			payload: { ...response.data.data, departments },
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.COUNCIL.INSERT_COUNCIL_FAILURE,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_FAILURE] }
		});
		return false;
	}
};

export const updateCouncil = (payload, departments) => async (dispatch) => {
	const api = API_URLS.COUNCIL.updateCouncil();
	dispatch({
		type: TYPE.COUNCIL.UPDATE_COUNCIL_LOADING,
		meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.COUNCIL.UPDATE_COUNCIL_SUCCESS,
			payload: { ...payload.councilInfo, departments },
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.COUNCIL.UPDATE_COUNCIL_FAILURE,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_FAILURE] }
		});
		return false;
	}
};

export const deleteCouncil = (id) => async (dispatch) => {
	const api = API_URLS.COUNCIL.deleteCouncil(id);
	dispatch({
		type: TYPE.COUNCIL.DELETE_COUNCIL_LOADING,
		meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.COUNCIL.DELETE_COUNCIL_SUCCESS,
			id,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.COUNCIL.DELETE_COUNCIL_FAILURE,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_FAILURE] }
		});
		return false;
	}
};

export const insertInternalCouncil = (payload) => async (dispatch) => {
	const api = API_URLS.COUNCIL.insertInternalCouncil();
	dispatch({
		type: TYPE.COUNCIL.INSERT_INTERNAL_COUNCIL_LOADING,
		meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.COUNCIL.INSERT_INTERNAL_COUNCIL_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.COUNCIL.INSERT_INTERNAL_COUNCIL_FAILURE,
			meta: { prefix: [PREFIX.COUNCIL, PREFIX.API_FAILURE] }
		});
		return false;
	}
};
