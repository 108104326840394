import React from 'react';

import SolutionTable from '../../containers/Solution/SolutionTable';
import SolutionButton from '../../containers/Solution/SolutionButton';

function Solution(props) {
	const list = window.location.href.split('/');
	const lastValueList = list[list.length - 1].split('=');
	const selectedProfileId =
		lastValueList.length > 1 ? lastValueList[lastValueList.length - 1] : null;

	return (
		<div style={{ padding: 8, minHeight: 'calc(100vh - 178px)', position: 'relative' }}>
			<SolutionButton
				type={props.type}
				department={props.department}
				selectedProfileId={selectedProfileId}
				handleReload={props.handleReload}
			/>
			<SolutionTable
				adminTab={props.adminTab}
				type={props.type}
				department={props.department}
				selectedProfileId={selectedProfileId}
				handleReload={props.handleReload}
			/>
		</div>
	);
}

export default Solution;
