import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Grid, Typography, TextField, Button, InputAdornment } from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import KPTable from '../../../../components/KPTable';
import select from '../../../../utils/select';
import {
	getRewardInformationV2,
	updateRewardInformationV2
} from '../../../../reducer/rewardInformation/action';

const valueFormat = (value) => {
	const temp = value.replace(/\D/g, '').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.');
	return temp;
};

export default function RewardInformationV2() {
	const [editting, setEditting] = useState(null);
	const dispatch = useDispatch();

	const { listV2, isFetching, typeFilter } = useSelector((state) => ({
		listV2: select(state, 'rewardInformationReducer', 'listV2'),
		isFetching: select(state, 'rewardInformationReducer', 'isFetching'),
		typeFilter: select(state, 'rewardInformationReducer', 'typeFilter')
	}));

	useEffect(() => {
		dispatch(getRewardInformationV2());
	}, []);

	const handleReload = () => {
		dispatch(getRewardInformationV2());
	};

	const handleChange = (value) =>
		setEditting({
			...editting,
			value: parseInt(value.replaceAll('.', ''))
		});

	const handleSumit = () => {
		dispatch(updateRewardInformationV2(editting.id, editting));

		setEditting(null);
	};

	const list = listV2.filter((e) => e.type === typeFilter) || [];

	return (
		<>
			<KPTable
				shadow="none"
				rowsPerPage={20}
				maxHeight="calc(100vh - 288px)"
				columns={[
					{
						title: <b>ID</b>,
						key: 'id',
						width: '5%'
					},
					{
						title: <b>Loại công trình</b>,
						key: 'condition',
						width: '15%',
						searchable: true,
						render: (value) => {
							if (value === 'chapterEnglish') return 'Chương sách tiếng anh';
							if (value === 'chapterOther') return 'Chương sách ngôn ngữ khác tiếng anh';
							if (value === 'monographEnglish') return 'Sách tiếng anh';
							if (value === 'monographOther') return 'Sách ngôn ngữ khác tiếng anh';
							return value;
						}
					},
					{
						title: <b>Chi tiết</b>,
						key: 'description',
						width: '50%',
						render: (value) => value.split('\n').map((e) => <div>{e}</div>)
					},
					{
						title: <b>Giá trị</b>,
						key: 'value',
						width: '20%',
						sorter: (a, b) => a.value - b.value,
						render: (value, row) => {
							if (editting?.id === row.id) {
								return (
									<form onSubmit={handleSumit}>
										<TextField
											autoFocus
											fullWidth
											size="small"
											variant="outlined"
											value={valueFormat(editting?.value.toString() || '')}
											InputProps={{
												endAdornment: <InputAdornment position="end">VND</InputAdornment>
											}}
											onChange={(event) => handleChange(event.target.value)}
										/>
									</form>
								);
							}
							return `${valueFormat(value.toString() || '')} VND`;
						}
					},
					{
						title: <b>Tác vụ</b>,
						key: 'action',
						width: '10%',
						render: (value, row) => {
							return (
								<>
									{editting?.id !== row.id ? (
										<Typography
											className="table-action-buton"
											variant="caption"
											color="primary"
											onClick={() => setEditting(row)}
										>
											Sửa
										</Typography>
									) : (
										<>
											<Typography
												className="table-action-buton"
												variant="caption"
												color="primary"
												onClick={handleSumit}
											>
												Lưu
											</Typography>
											<Typography
												className="table-action-buton"
												variant="caption"
												color="secondary"
												onClick={() => setEditting(null)}
											>
												Huỷ
											</Typography>
										</>
									)}
								</>
							);
						}
					}
				]}
				rows={list.sort((a, b) => a.id - b.id)}
				loading={isFetching}
			/>
		</>
	);
}
