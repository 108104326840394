import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	isFetching: false,
	filteredProfileReward: []
});

export default function employmentContractReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.REWARD.GET_FILTERED_PROFILE_REWARD_LOADING:
		case TYPE.REWARD.CAL_PROFILE_REWARD_LOADING:
		case TYPE.REWARD.DELETE_PROFILE_REWARD_LOADING:
		case TYPE.REWARD.ACCEPT_PROFILE_REWARD_LOADING:
		case TYPE.REWARD.LOCK_PROFILE_REWARD_LOADING:
		case TYPE.REWARD.LOCK_ALL_PROFILE_REWARD_LOADING:
		case TYPE.REWARD.CAL_ALL_PROFILE_REWARD_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.REWARD.GET_FILTERED_PROFILE_REWARD_FAILURE:
		case TYPE.REWARD.CAL_PROFILE_REWARD_FAILURE:
		case TYPE.REWARD.DELETE_PROFILE_REWARD_FAILURE:
		case TYPE.REWARD.ACCEPT_PROFILE_REWARD_FAILURE:
		case TYPE.REWARD.LOCK_PROFILE_REWARD_FAILURE:
		case TYPE.REWARD.LOCK_ALL_PROFILE_REWARD_FAILURE:
		case TYPE.REWARD.CAL_ALL_PROFILE_REWARD_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.REWARD.GET_FILTERED_PROFILE_REWARD_SUCCESS:
			return state.merge({
				isFetching: false,
				filteredProfileReward: action.payload
			});

		case TYPE.REWARD.CAL_PROFILE_REWARD_SUCCESS:
			var temp = state.get('filteredProfileReward');
			temp[0] = action.payload;

			return state.merge({
				isFetching: false,
				filteredProfileReward: temp
			});

		case TYPE.REWARD.CAL_ALL_PROFILE_REWARD_SUCCESS:
			return state.merge({
				isFetching: false
			});

		case TYPE.REWARD.DELETE_PROFILE_REWARD_SUCCESS:
			return state.merge({
				isFetching: false,
				filteredProfileReward: []
			});

		case TYPE.REWARD.ACCEPT_PROFILE_REWARD_SUCCESS:
			temp = state.get('filteredProfileReward');
			temp.forEach((item) => {
				if (item.id === action.id) item.accepted = !item.accepted;
			});

			return state.merge({
				isFetching: false,
				filteredProfileReward: temp
			});

		case TYPE.REWARD.LOCK_PROFILE_REWARD_SUCCESS:
			temp = state.get('filteredProfileReward');
			temp.forEach((item) => {
				if (item.id === action.id) item.locked = !item.locked;
			});

			return state.merge({
				isFetching: false,
				filteredProfileReward: temp
			});

		case TYPE.REWARD.LOCK_ALL_PROFILE_REWARD_SUCCESS:
			temp = state.get('filteredProfileReward');
			temp.forEach((item) => {
				item.locked = action.locked;
			});

			return state.merge({
				isFetching: false,
				filteredProfileReward: temp
			});

		default:
			return state;
	}
}
