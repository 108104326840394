import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useField } from 'formik';

import GridCellExpand from './GridCellExpand';

const useStyles = makeStyles((theme) => {
	return {
		tableParent: {
			height: 400
		},
		table: {
			'& .MuiDataGrid-colCellTitle': {
				fontWeight: 'bold'
			}
		},
		btnSelect: {
			margin: theme.spacing(0, 0, 0, 1)
		}
	};
});

function DomesticPublishmentModal(props) {
	const { open, handleClose, scientificResearchs } = props;

	const classes = useStyles();

	const [domesticPublishmentInput, , domesticPublishmentHelper] = useField(
		'scientificResearchs.domesticPublishments'
	);
	const [domesticPublishmentIds, setDomesticPublishmentIds] = useState([]);

	useEffect(() => {
		if (domesticPublishmentInput.value.length > 0 && domesticPublishmentIds.length === 0) {
			setDomesticPublishmentIds(domesticPublishmentInput.value.map((element) => element.id));
		}
	}, [domesticPublishmentInput]);

	/**
	 * @type {import('@material-ui/data-grid').GridColDef[]}
	 */
	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 100,
			hide: true
		},
		{
			field: 'title',
			headerName: 'Tiêu đề',
			width: 800,
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		}
	];

	const rows = scientificResearchs
		.filter((scientificResearchElement) => {
			return (
				scientificResearchElement.scientificResearch.type === 'chapterJournalDomestic' ||
				scientificResearchElement.scientificResearch.type === 'scientificResearchJournalDomestic' ||
				scientificResearchElement.scientificResearch.type === 'chapterConferenceDomestic' ||
				scientificResearchElement.scientificResearch.type === 'scientificResearchConferenceDomestic'
			);
		})
		.map((domesticElement) => {
			const id = domesticElement.scientificResearch.id;
			const authors = (domesticElement.scientificResearch.listAuthor ?? []).join(', ');
			const paperTitle = domesticElement.scientificResearch.title;
			const year = domesticElement.scientificResearch.year;
			const journalTitle =
				domesticElement?.journalDomestic?.title ??
				domesticElement?.scientificResearch?.otherTitle ??
				'';
			const volume = domesticElement.scientificResearch.volume;
			const page = domesticElement.scientificResearch.page;

			const title = `${authors} (${year}). ${paperTitle}. ${journalTitle}. Volume ${volume}, pp ${page}.`;

			return { id, title, year };
		});

	/**
	 *
	 * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
	 */
	const handleSelectionModelChange = (params) => {
		const selectedRows = rows.filter((rowElement) => {
			if (params.includes(rowElement.id)) {
				return true;
			}
			return false;
		});
		domesticPublishmentHelper.setValue(selectedRows);
		setDomesticPublishmentIds(params);
	};

	const handleSelect = () => {
		handleClose(false);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
			<DialogTitle>Công bố trên các tạp chí khoa học quốc gia</DialogTitle>
			<DialogContent>
				<Box className={classes.tableParent}>
					<DataGrid
						className={classes.table}
						columns={columns}
						rows={rows}
						checkboxSelection={true}
						selectionModel={domesticPublishmentIds}
						onSelectionModelChange={handleSelectionModelChange}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={() => handleClose(false)}>
					Hủy
				</Button>
				<Button
					variant="contained"
					color="primary"
					className={classes.btnSelect}
					onClick={handleSelect}
				>
					Chọn
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DomesticPublishmentModal;
