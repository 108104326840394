import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { LOGO } from './logo';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const TYPES = [
	{ value: 'scientificResearchJournal', title: 'Tạp chí quốc tế' },
	{ value: 'scientificResearchJournalDomestic', title: 'Tạp chí trong nước' },
	{ value: 'scientificResearchConference', title: 'Hội thảo quốc tế' },
	{ value: 'scientificResearchConferenceDomestic', title: 'Hội thảo trong nước' },
	{ value: 'chapterJournal', title: 'Chương sách' },
	{ value: 'scientificResearchOther', title: 'Tạp chí khác' },

	{ title: 'Cơ bản', value: 'basic' },
	{ title: 'Ứng dụng', value: 'application' },

	{ title: 'Chương sách', value: 'chapter' },
	{ title: 'Chuyên khảo', value: 'monograph' },
	{ title: 'Tham  khảo', value: 'reference' },
	{ title: 'Giáo trình', value: 'curriculum' },

	{ title: 'Mentor khởi nghiệp (Nộp hồ sơ đăng ký tham gia)', value: 'startup_mentor_register' },
	{
		title: 'Mentor khởi nghiệp (Hồ sơ được chọn vào vòng bán kết và chung kết cấp Trường)',
		value: 'startup_mentor_school'
	},
	{
		title: 'Mentor khởi nghiệp (Hồ sơ tham gia và đạt giải cấp quốc gia)',
		value: 'startup_mentor_national'
	},
	{
		title: 'Hướng dẫn sinh viên thi NCKH quốc gia, quốc tế/ Các cuộc thi sáng tạo KHCN',
		value: 'student_guidance'
	},
	{ title: 'Hướng dẫn sinh viên NCKH cấp trường', value: 'student_research' },
	// { title: "Hướng dẫn sinh viên thi NCKH quốc gia/ Các cuộc thi sáng tạo KHCN", value: "" },

	{ title: 'Hội đồng đánh giá Hồ sơ thành lập Nhóm nghiên cứu', value: 'research_group' },
	{
		title: 'Hội đồng xét chọn đề tài nghiên cứu cấp cơ sở',
		value: 'internal_research_select_council'
	},
	{
		title: 'Hội đồng nghiệm thu đề tài nghiên cứu cấp cơ sở',
		value: 'internal_research_accept_council'
	},
	{
		title: 'Hội đồng nghiệm thu đề tài sinh viên NCKH cấp trường',
		value: 'student_research_council'
	},
	{ title: 'Hội đồng nghiệm thu giáo trình', value: 'textbook' },
	{ title: 'Tổ chức hội thảo, Tọa đàm khoa học cấp Trường', value: 'uni_seminar' },
	{ title: 'Tổ chức Hội thảo, Tọa đàm khoa học cấp Khoa/Viện', value: 'faculty_seminar' },
	{
		title: 'Hội đồng khoa học đánh giá các đề tài tham gia Hội nghị sinh viên NCKH cấp trường',
		value: 'student_research_reviewer'
	}
];

export const renderType = (value) => TYPES.find((e) => e.value === value)?.title || '';
export const renderFloat = (value) => value?.toFixed(2);

export const converted = (data, departments) => {
	const res = {
		researchProjects: data.researchProjects?.map((e, id) => ({
			...e,
			stt: id + 1,
			type: renderType(e.type),
			host: 'Trường Đại học Phenikaa',
			role: `Tác giả ${e.roleInProject === 'main' ? 'chính' : 'phụ'}`,
			duration: `${e.duration} năm`,
			timeAmount: renderFloat(e.timeAmount),
			timeAmount2022: renderFloat(e.dashboardTimeAmount / e.duration),
			dashboardTimeAmount: renderFloat(e.dashboardTimeAmount)
		})),
		scientificResearchs: data.scientificResearchs
			?.filter((e) => !['15', '16', '17'].find((code) => code === e.timeResearchDetailCode))
			?.map((e, id) => ({
				...e,
				stt: id + 1,
				type: renderType(e.type),
				info: `${e.listAuthor} - ${e.title} - ${e.year}`,
				role: `Tác giả ${e.roleInPaper === 'main' ? 'chính' : 'phụ'}`,
				timeAmount: renderFloat(e.timeAmount)
			})),
		scientificReports: data.scientificResearchs
			?.filter((e) => !!['15', '16', '17'].find((code) => code === e.timeResearchDetailCode))
			?.map((e, id) => ({
				...e,
				stt: id + 1,
				type: renderType(e.type),
				info: `${e.listAuthor} - ${e.title} - ${e.year}`,
				role: `Tác giả ${e.roleInPaper === 'main' ? 'chính' : 'phụ'}`,
				timeAmount: renderFloat(e.timeAmount)
			})),
		patents: [...(data?.patents || []), ...(data?.solutions || [])].map((e, id) => ({
			...e,
			stt: id + 1,
			type: e?.roleInPatent ? e.type : 'Giải pháp hữu ích',
			host: 'Trường Đại học Phenikaa',
			info: `${e.listMember} - ${e.title} - ${dayjs(e.acceptedDay).year()}`,
			timeAmount: renderFloat(e.timeAmount)
		})),
		monographs: data.monographs?.map((e, id) => ({
			...e,
			stt: id + 1,
			type: renderType(e.type),
			info: `${e.listMember} - ${e.title} - ${e.year}`,
			role: e.roleInMonograph === 'main' ? 'Chủ biên' : 'Đồng tác giả',
			timeAmount: renderFloat(e.timeAmount)
		})),
		studentGuidance: data.studentGuidanceV2?.map((e, id) => ({
			...e,
			stt: id + 1,
			type: renderType(e.type),
			department: departments?.find((d) => d.id === e.departmentId)?.name,
			schoolYear: '2023-2024',
			timeAmount: renderFloat(e.timeAmount)
		})),
		scientificCouncils: data.scientificCouncilV2s?.map((e, id) => ({
			...e,
			stt: id + 1,
			department: departments?.find((d) => d.id === e.departmentId)?.name,
			type: renderType(e.type),
			timeAmount: renderFloat(e.timeAmount),
			schoolYear: '2023-2024',
			role: (() => {
				const type = [
					{ title: 'Chủ tịch HĐ', value: 'chairman' },
					{ title: 'Thư kí HĐ', value: 'secretary' },
					{ title: 'Ủy viên', value: 'commissioner' },
					{ title: 'Ủy viên phản biện', value: 'reviewer' }
				].find((t) => t.value === e.role);
				return type?.title || '';
			})()
		}))
	};
	return res;
};

export const converteDepartment = (data) =>
	data.map((item, id) => {
		const timeResearch = converted(item);

		const res = {
			...item,
			stt: id + 1,
			researchProjects:
				timeResearch?.researchProjects?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0,
			scientificResearchs:
				timeResearch?.scientificResearchs?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0,
			patents:
				timeResearch?.patents?.reduce((total, curr) => total + parseFloat(curr.timeAmount), 0) || 0,
			monographs:
				timeResearch?.monographs?.reduce((total, curr) => total + parseFloat(curr.timeAmount), 0) ||
				0,
			studentGuidance:
				timeResearch?.studentGuidance?.reduce(
					(total, curr) => total + (curr.accepted ? parseFloat(curr.timeAmount) : 0),
					0
				) || 0,
			scientificReports:
				timeResearch?.scientificReports?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0,
			scientificCouncils:
				timeResearch?.scientificCouncils?.reduce(
					(total, curr) => total + (curr.accepted ? parseFloat(curr.timeAmount) : 0),
					0
				) || 0
		};

		res.total =
			res.researchProjects +
			res.scientificResearchs +
			res.patents +
			res.monographs +
			res.studentGuidance +
			res.scientificReports +
			res.scientificCouncils;
		return res;
	});

const setCellValue = (cell, properties) => {
	for (const item in properties) cell[item] = properties[item];
};

const BORDER_STYLES = {
	top: { style: 'thin', color: { argb: 'FF000000' } },
	left: { style: 'thin', color: { argb: 'FF000000' } },
	bottom: { style: 'thin', color: { argb: 'FF000000' } },
	right: { style: 'thin', color: { argb: 'FF000000' } }
};

export const exportPersonal = async (timeResearch, dmTimeReseach, profileDetail, year) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('BẢNG THỐNG KÊ');

	const imageId2 = workbook.addImage({ base64: LOGO, extension: 'png' });
	worksheet.addImage(imageId2, 'A1:B3');

	worksheet.getColumn(1).width = 5;
	worksheet.getColumn(2).width = 40;
	worksheet.getColumn(3).width = 20;
	worksheet.getColumn(4).width = 15;
	worksheet.getColumn(5).width = 20;
	worksheet.getColumn(6).width = 25;
	worksheet.getColumn(7).width = 15;
	worksheet.getColumn(8).width = 20;
	worksheet.getColumn(9).width = 20;
	worksheet.getColumn(10).width = 20;
	worksheet.getColumn(11).width = 20;
	worksheet.getColumn(12).width = 20;
	worksheet.getColumn(13).width = 20;

	setCellValue(worksheet.getCell('E1'), {
		font: { size: 10, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM'
	});
	setCellValue(worksheet.getCell('E2'), {
		font: { size: 10, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: 'Độc lập - Tự do - Hạnh phúc'
	});
	setCellValue(worksheet.getCell('A4'), {
		font: { size: 10, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: `BẢNG THỐNG KÊ KẾT QUẢ NGHIÊN CỨU KHOA HỌC, QUY ĐỔI RA GIỜ NCKH NĂM HỌC ${year}-${year + 1}`
	});
	setCellValue(worksheet.getCell('A10'), {
		font: { size: 14, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: 'DANH MỤC HOẠT ĐỘNG NGHIÊN CỨU'
	});

	setCellValue(worksheet.getCell('A6'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `Họ và tên: ${profileDetail.name}`
	});
	setCellValue(worksheet.getCell('A7'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `Học hàm/học vị: ${profileDetail.academicRank}`
	});
	setCellValue(worksheet.getCell('A8'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `Đơn vị: ${profileDetail.departmentProfile?.[0]?.department?.name}`
	});
	setCellValue(worksheet.getCell('F6'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `Mã nhân sự: ${profileDetail.code}`
	});
	setCellValue(worksheet.getCell('F7'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `Vị trí/chức vụ (nếu có):`
	});
	setCellValue(worksheet.getCell('F8'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `Bộ môn (nếu có):`
	});

	worksheet.mergeCells('E1:J1');
	worksheet.mergeCells('E2:J2');
	worksheet.mergeCells('A4:M4');
	worksheet.mergeCells('A10:M10');
	worksheet.mergeCells('A6:E6');
	worksheet.mergeCells('A7:E7');
	worksheet.mergeCells('A8:E8');
	worksheet.mergeCells('F6:M6');
	worksheet.mergeCells('F7:M7');
	worksheet.mergeCells('F8:M8');

	const EXCEL_TABLES = [
		{
			index: 'I',
			title: 'ĐỀ TÀI NGHIÊN CỨU CÁC CẤP',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'title',
					title: {
						richText: [
							{ font: { bold: true }, text: 'TÊN ĐỀ TÀI*\n' },
							{ font: { italic: true }, text: '(Nhập tên đề tài nghiên cứu)' }
						]
					}
				},
				{
					key: 'type',
					title: {
						richText: [
							{ font: { bold: true }, text: 'LOẠI ĐỀ TÀI*' },
							{ font: { italic: true }, text: '\n(Chọn Loại để tài)' }
						]
					}
				},
				{
					key: 'code',
					title: {
						richText: [
							{ font: { bold: true }, text: 'MÃ SỐ ĐỀ TÀI*' },
							{ font: { italic: true }, text: '\n(Nhập mã số ĐT)' }
						]
					}
				},
				{
					key: 'role',
					title: {
						richText: [
							{ font: { bold: true }, text: 'VAI TRÒ TRONG ĐỂ TÀI*' },
							{ font: { italic: true }, text: '\n(Chọn vài trò đóng góp)' }
						]
					}
				},
				{
					key: 'duration',
					title: {
						richText: [
							{ font: { bold: true }, text: 'THỜI GIAN THỰC HIỆN*' },
							{ font: { italic: true }, text: '\n(Chọn số năm thực hiện)' }
						]
					}
				},
				{
					key: 'numOfMember',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ THÀNH VIÊN ĐỀ TÀI' },
							{ font: { italic: true }, text: '\n(Tự nhập số thành viên theo QĐ phê duyệt ĐT)' }
						]
					}
				},
				{
					key: 'host',
					title: {
						richText: [
							{ font: { bold: true }, text: 'CƠ QUAN CHỦ TRÌ' },
							{
								font: { italic: true },
								text: '\n(Chỉ nghiệm thu cho ĐT do Phenikaa là cơ quan chủ trì)'
							}
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI CỦA ĐỀ TÀI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount2022',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				},
				{
					title: { richText: [{ font: { bold: true }, text: 'GHI CHÚ' }] }
				}
			],
			dataSource: timeResearch?.researchProjects || [{}],
			total:
				timeResearch?.researchProjects?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0
		},
		{
			index: 'II',
			title:
				'BÀI BÁO KHOA HỌC (KHÔNG SỬ DỤNG ĐỂ NGHIỆM THU KPI HOẶC NHẬN THƯỞNG VƯỢT ĐỊNH MỨC KPI NGHIÊN CỨU)',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'info',
					title: {
						richText: [
							{
								font: { bold: true },
								text: 'DANH SÁCH TÁC GIẢ, TÊN BÀI BÁO, VOLUME, NĂM CÔNG BỐ*\n'
							},
							{ font: { italic: true }, text: '(Nhập đầy đủ thông tin)' }
						]
					}
				},
				{
					key: 'doi',
					title: {
						richText: [
							{ font: { bold: true }, text: 'LINK/DOI*' },
							{
								font: { italic: true },
								text: '\n(Nhập link/DOI hoặc gửi minh chúng nếu Bài báo không online)'
							}
						]
					}
				},
				{
					key: 'journalName',
					title: {
						richText: [
							{ font: { bold: true }, text: 'TÊN TẠP CHÍ' },
							{ font: { italic: true }, text: '\n(Nhập tên tạp chí/Hội nghị)' }
						]
					}
				},
				{
					key: 'type',
					title: {
						richText: [
							{ font: { bold: true }, text: 'LOẠI TẠP CHÍ' },
							{ font: { italic: true }, text: '\n(Chọn thể loại)' }
						]
					}
				},
				{
					key: 'role',
					title: {
						richText: [
							{ font: { bold: true }, text: 'VAI TRÒ (TGC/ĐTG)' },
							{ font: { italic: true }, text: '\n(Chọn vai trò đóng góp)' }
						]
					}
				},
				{
					key: 'numberMainAuthor',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ TG CHÍNH' },
							{ font: { italic: true }, text: '\n(Nhập số tác giả chính)' }
						]
					}
				},
				{
					key: 'numOfMember',
					title: {
						richText: [
							{ font: { bold: true }, text: 'TỔNG SỐ TÁC GIẢ' },
							{ font: { italic: true }, text: '\n(Nhập tống số tác giả)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI CỦA BÀI BÁO' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				},
				{
					title: {
						richText: [
							{
								font: { italic: true },
								text: 'Bài báo đã sử dụng để nghiệm thu KPI nghiên cứu hoặc nhận thưởng thì không kê khai'
							}
						]
					}
				}
			],
			dataSource: timeResearch?.scientificResearchs || [{}],
			total:
				timeResearch?.scientificResearchs?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0
		},
		{
			index: 'III',
			title:
				'SÁCH CHUYÊN KHẢO/THAM KHẢO/GIÁO TRÌNH (KHÔNG SỬ DỤNG ĐỂ NGHIỆM THU KPI HOẶC NHẬN THƯỞNG VƯỢT ĐỊNH MỨC KPI NGHIÊN CỨU)',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'info',
					title: {
						richText: [
							{ font: { bold: true }, text: 'DANH SÁCH TÁC GIẢ, SÁCH,  NĂM XUẤT BẢN\n' },
							{ font: { italic: true }, text: '(Nhập đầy đủ thông tin)' }
						]
					}
				},
				{
					key: 'isbn',
					title: {
						richText: [
							{ font: { bold: true }, text: 'CHỈ SỐ (ISBN)' },
							{ font: { italic: true }, text: '\n(Nhập chỉ số ISBN)' }
						]
					}
				},
				{
					key: 'publisher',
					title: {
						richText: [
							{ font: { bold: true }, text: 'NHÀ XUẤT BẢN' },
							{ font: { italic: true }, text: '\n(Nhập tên NXB)' }
						]
					}
				},
				{
					key: 'type',
					title: {
						richText: [
							{ font: { bold: true }, text: 'LOẠI SÁCH' },
							{ font: { italic: true }, text: '\n(Chọn loại sách)' }
						]
					}
				},
				{
					key: 'role',
					title: {
						richText: [
							{ font: { bold: true }, text: 'VAI TRÒ (CHỦ BIÊN/ĐTG)' },
							{ font: { italic: true }, text: '\n(Chọn vai trò đóng góp)' }
						]
					}
				},
				{
					key: 'numberMainAuthor',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ ĐỒNG CHỦ BIÊN' },
							{ font: { italic: true }, text: '\n(Chọn số lượng Chủ biên)' }
						]
					}
				},
				{
					key: 'numOfMember',
					title: {
						richText: [
							{ font: { bold: true }, text: 'TỔNG SỐ TÁC GIẢ' },
							{ font: { italic: true }, text: '\n(Nhập tống số tác giả)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI CỦA SÁCH' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				},
				{
					title: {
						richText: [
							{
								font: { italic: true },
								text: 'Sách đã sử dụng để nghiệm thu KPI nghiên cứu hoặc nhận thưởng thì không kê khai'
							}
						]
					}
				}
			],
			dataSource: timeResearch?.monographs || [{}],
			total:
				timeResearch?.monographs?.reduce((total, curr) => total + parseFloat(curr.timeAmount), 0) ||
				0
		},
		{
			index: 'IV',
			title:
				'BẰNG SÁNG CHẾ/BẰNG GIẢI PHÁP HỮU ÍCH  (KHÔNG SỬ DỤNG ĐỂ NGHIỆM THU KPI HOẶC NHẬN THƯỞNG VƯỢT ĐỊNH MỨC KPI NGHIÊN CỨU)',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'info',
					title: {
						richText: [
							{
								font: { bold: true },
								text: 'DANH SÁCH TÁC GIẢ, TÊN SÁNG CHẾ/GIẢI PHÁP HỮU ÍCH, NĂM CẤP BẰNG\n'
							}
						]
					}
				},
				{
					key: 'identificationNumber',
					title: { richText: [{ font: { bold: true }, text: 'SỐ BẰNG/SỐ QĐ CẤP BẰNG' }] }
				},
				{
					key: 'issueBy',
					title: { richText: [{ font: { bold: true }, text: 'CƠ QUAN CẤP' }] }
				},
				{
					key: 'type',
					title: {
						richText: [
							{ font: { bold: true }, text: 'LOẠI BẰNG' },
							{ font: { italic: true }, text: '\n(Chọn loại bằng)' }
						]
					}
				},
				{
					key: 'host',
					title: { richText: [{ font: { bold: true }, text: 'CƠ QUAN SỞ HỮU' }] }
				},
				{
					key: 'numOfOwner',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ CƠ QUAN ĐỒNG SỞ HỮU' },
							{ font: { italic: true }, text: '\n(Nhập số lượng cơ quan Đồng Sở hữu)' }
						]
					}
				},
				{
					key: 'numOfMember',
					title: {
						richText: [
							{ font: { bold: true }, text: 'TỔNG SỐ TÁC GIẢ' },
							{ font: { italic: true }, text: '\n(Nhập tống số tác giả)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI CỦA BẰNG SC/GPHI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				},
				{
					title: {
						richText: [
							{
								font: { italic: true },
								text: 'Bằng SHTT đã sử dụng để nghiệm thu KPI nghiên cứu hoặc nhận thưởng thì không kê khai'
							}
						]
					}
				}
			],
			dataSource: timeResearch?.patents || [{}],
			total:
				timeResearch?.patents?.reduce((total, curr) => total + parseFloat(curr.timeAmount), 0) || 0
		},
		{
			index: 'V',
			title:
				'HƯỚNG DẪN SINH VIÊN NGHIÊN CỨU KHOA HỌC (CÓ QUYẾT ĐỊNH GIAO ĐỀ TÀI VÀ NGHIỆM THU ĐỀ TÀI)',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'title',
					title: { richText: [{ font: { bold: true }, text: 'TÊN ĐỀ TÀI, NĂM THỰC HIỆN\n' }] }
				},
				{
					key: 'schoolYear',
					title: { richText: [{ font: { bold: true }, text: 'NĂM THỰC HIỆN' }] }
				},
				{
					key: 'instructors',
					title: { richText: [{ font: { bold: true }, text: 'DANH SÁCH NGƯỜI HƯỚNG DẪN' }] }
				},
				{
					key: 'type',
					title: {
						richText: [
							{ font: { bold: true }, text: 'LOẠI ĐỂ TÀI' },
							{ font: { italic: true }, text: '\n(Chọn loại đề tài)' }
						]
					}
				},
				{
					key: 'member',
					title: { richText: [{ font: { bold: true }, text: 'TÊN SINH VIÊN THAM GIA' }] }
				},
				{
					key: 'department',
					title: { richText: [{ font: { bold: true }, text: 'KHOA/VIỆN' }] }
				},
				{
					key: 'numberInstructer',
					title: { richText: [{ font: { bold: true }, text: 'SỐ NGƯỜI HƯỚNG DẪN' }] }
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				}
			],
			dataSource: (timeResearch?.studentGuidance || [{}]).filter((e) => e.accepted),
			total:
				timeResearch?.studentGuidance?.reduce(
					(total, curr) => total + (curr.accepted ? parseFloat(curr.timeAmount) : 0),
					0
				) || 0
		},
		{
			index: 'VI',
			title: 'BÁO CÁO, THAM LUẬN',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'title',
					title: { richText: [{ font: { bold: true }, text: 'TÊN BÁO CÁO/THAM LUẬN\n' }] }
				},
				{
					key: 'journalName',
					title: { richText: [{ font: { bold: true }, text: 'TÊN HỘI THẢO' }] }
				},
				{
					title: { richText: [{ font: { bold: true }, text: 'ĐƠN VỊ TỔ CHỨC' }] }
				},
				{
					key: 'type',
					title: { richText: [{ font: { bold: true }, text: 'PHÂN LOẠI' }] }
				},
				{
					title: { richText: [{ font: { bold: true }, text: 'THỜI GIAN TỔ CHỨC' }] }
				},
				{
					title: { richText: [{ font: { bold: true }, text: 'ĐỊA ĐIỂM TỔ CHỨC' }] }
				},
				{
					title: { richText: [{ font: { bold: true }, text: 'SỐ NGƯỜI BÁO CÁO' }] }
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI CỦA BÀI BÁO CÁO' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				}
			],
			dataSource: timeResearch?.scientificReports || [{}],
			total:
				timeResearch?.scientificReports?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0
		},
		{
			index: 'VII',
			title: 'THÀNH VIÊN HỘI ĐỒNG KHOA HỌC',
			columns: [
				{ key: 'stt', title: 'STT' },
				{
					key: 'title',
					title: {
						richText: [
							{ font: { bold: true }, text: 'TÊN ĐỐI TƯỢNG\n' },
							{
								font: { italic: true },
								text: '\n(Nhóm nghiên cứu/Giáo trình/Đề tài nghiệm thu/Hội thảo)'
							}
						]
					}
				},
				{
					key: 'schoolYear',
					title: { richText: [{ font: { bold: true }, text: 'NĂM THỰC HIỆN' }] }
				},
				{
					key: 'info',
					title: {
						richText: [
							{ font: { bold: true }, text: 'THÔNG TIN LIÊN QUAN' },
							{
								font: { italic: true },
								text: '\n(Tên trưởng nhóm nghiên cứu/Mã số đề tài/Tên người soạn giáo trình…)'
							}
						]
					}
				},
				{
					key: 'type',
					title: { richText: [{ font: { bold: true }, text: 'LOẠI HỘI ĐỒNG' }] }
				},
				{
					key: 'role',
					title: { richText: [{ font: { bold: true }, text: 'VAI TRÒ' }] }
				},
				{
					key: 'department',
					title: { richText: [{ font: { bold: true }, text: 'KHOA/VIỆN' }] }
				},
				{
					key: 'dashboardTimeAmount',
					title: { richText: [{ font: { bold: true }, text: 'SỐ THÀNH VIÊN' }] }
				},
				{
					key: 'dashboardTimeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ QUY ĐỔI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: `SỐ GIỜ TÍNH CHO NĂM HỌC ${year}-${year + 1}` },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ TÍNH CHO NGƯỜI KHAI' },
							{ font: { italic: true }, text: '\n(Tự động)' }
						]
					}
				},
				{
					key: 'timeAmount',
					title: {
						richText: [
							{ font: { bold: true }, text: 'SỐ GIỜ PHÒNG KHCN DUYỆT' },
							{ font: { italic: true }, text: '\n(Phòng KHCN duyệt)' }
						]
					}
				}
			],
			dataSource: (timeResearch?.scientificCouncils || [{}]).filter((e) => e.accepted),
			total:
				timeResearch?.scientificCouncils?.reduce(
					(total, curr) => total + (curr.accepted ? parseFloat(curr.timeAmount) : 0),
					0
				) || 0
		}
	];

	setCellValue(worksheet.getCell('A10'), {
		font: { size: 14, bold: true },
		alignment: { horizontal: 'center' },
		border: BORDER_STYLES,
		value: 'DANH MỤC HOẠT ĐỘNG NGHIÊN CỨU'
	});

	var startRowIndex = 11;
	EXCEL_TABLES.forEach((table, tableIndex) => {
		// Header
		setCellValue(worksheet.getCell(`A${startRowIndex}`), {
			font: { size: 11, bold: true },
			alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
			border: BORDER_STYLES,
			value: table.index
		});
		setCellValue(worksheet.getCell(`B${startRowIndex}`), {
			font: { size: 11, bold: true },
			alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
			border: BORDER_STYLES,
			value: table.title
		});
		worksheet.mergeCells(`B${startRowIndex}:M${startRowIndex}`);
		startRowIndex += 1;

		// Table Header
		table.columns.forEach((column, index) => {
			setCellValue(worksheet.getCell(`${ALPHABET[index]}${startRowIndex}`), {
				font: { size: 11, bold: true },
				alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
				border: BORDER_STYLES,
				value: column.title
			});
		});
		startRowIndex += 1;

		// Table Body
		table.dataSource.forEach((row, rowIndex) => {
			table.columns.forEach((column, columnIndex) => {
				setCellValue(worksheet.getCell(`${ALPHABET[columnIndex]}${startRowIndex + rowIndex}`), {
					font: { size: 11 },
					alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
					border: BORDER_STYLES,
					value: row?.[column.key]
				});
			});
		});
		startRowIndex += table.dataSource.length;

		// Footer
		setCellValue(worksheet.getCell(`A${startRowIndex}`), {
			font: { size: 11, bold: true },
			alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
			border: BORDER_STYLES,
			value: `Tổng (${table.index})`
		});
		setCellValue(worksheet.getCell(`K${startRowIndex}`), {
			font: { size: 11, bold: true },
			alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
			border: BORDER_STYLES,
			value: renderFloat(table.total)
		});
		setCellValue(worksheet.getCell(`L${startRowIndex}`), {
			font: { size: 11, bold: true },
			alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
			border: BORDER_STYLES,
			value: renderFloat(table.total)
		});
		worksheet.mergeCells(`A${startRowIndex}:J${startRowIndex}`);
		startRowIndex += 1;
	});

	const totalTimeResearch = EXCEL_TABLES.reduce((total, curr) => (total += curr.total), 0) || 0;

	setCellValue(worksheet.getCell(`A${startRowIndex + 0}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: 'TỔNG (I - VII)'
	});
	setCellValue(worksheet.getCell(`K${startRowIndex + 0}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: renderFloat(totalTimeResearch)
	});
	setCellValue(worksheet.getCell(`L${startRowIndex + 0}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: renderFloat(totalTimeResearch)
	});
	setCellValue(worksheet.getCell(`A${startRowIndex + 1}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: `ĐỊNH MỨC GIỜ NGHIÊN CỨU KHOA HỌC TRONG NĂM ${year}-${year + 1}`
	});
	setCellValue(worksheet.getCell(`K${startRowIndex + 1}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: renderFloat(dmTimeReseach)
	});
	setCellValue(worksheet.getCell(`L${startRowIndex + 1}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: renderFloat(dmTimeReseach)
	});
	setCellValue(worksheet.getCell(`A${startRowIndex + 2}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: 'SỐ GIỜ NGHIÊN CỨU KHOA HỌC VƯỢT ĐỊNH MỨC'
	});
	setCellValue(worksheet.getCell(`K${startRowIndex + 2}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: renderFloat(totalTimeResearch - dmTimeReseach)
	});
	setCellValue(worksheet.getCell(`L${startRowIndex + 2}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		border: BORDER_STYLES,
		value: renderFloat(totalTimeResearch - dmTimeReseach)
	});
	setCellValue(worksheet.getCell(`J${startRowIndex + 5}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		value: 'Người kê khai'
	});
	setCellValue(worksheet.getCell(`J${startRowIndex + 6}}`), {
		font: { size: 11 },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		value: '(Ký, ghi rõ họ tên)'
	});

	worksheet.mergeCells(`A${startRowIndex + 0}:J${startRowIndex + 0}`);
	worksheet.mergeCells(`A${startRowIndex + 1}:J${startRowIndex + 1}`);
	worksheet.mergeCells(`A${startRowIndex + 2}:J${startRowIndex + 2}`);
	worksheet.mergeCells(`J${startRowIndex + 5}:K${startRowIndex + 5}`);
	worksheet.mergeCells(`J${startRowIndex + 6}:K${startRowIndex + 6}`);

	for (let i = 11; i <= startRowIndex + 2; i++) {
		for (let j = 1; j < 13; j++) {
			worksheet.getCell(`${ALPHABET[j]}${i}}`).border = BORDER_STYLES;
		}
	}

	workbook.xlsx.writeBuffer().then(function (buffer) {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Tính giờ nghiên cứu.xlsx');
	});
};

export const exportDepartment = async (listTimeResearch, selectedDepartmentName) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('BẢNG THỐNG KÊ');

	const imageId2 = workbook.addImage({ base64: LOGO, extension: 'png' });
	worksheet.addImage(imageId2, 'A1:B3');

	setCellValue(worksheet.getCell('E1'), {
		font: { size: 10, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM'
	});
	setCellValue(worksheet.getCell('E2'), {
		font: { size: 10, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: 'Độc lập - Tự do - Hạnh phúc'
	});
	setCellValue(worksheet.getCell('A4'), {
		font: { size: 10, bold: true },
		alignment: { horizontal: 'center', vertical: 'middle' },
		value: 'BẢNG THỐNG KÊ KẾT QUẢ NGHIÊN CỨU KHOA HỌC, QUY ĐỔI RA GIỜ CHUẨN NĂM HỌC 2023-2024'
	});
	setCellValue(worksheet.getCell('A6'), {
		font: { size: 10, bold: true },
		alignment: { vertical: 'middle' },
		value: `ĐƠN VỊ: ${selectedDepartmentName}`
	});

	worksheet.mergeCells('E1:J1');
	worksheet.mergeCells('E2:J2');
	worksheet.mergeCells('A4:L4');
	worksheet.mergeCells('A6:C6');

	const columns = [
		{ key: 'stt', width: 7 },
		{ key: 'profileCode', width: 20 },
		{ key: 'profileName', width: 30 },
		{ key: 'researchProjects', width: 15 },
		{ key: 'scientificResearchs', width: 15 },
		{ key: 'patents', width: 15 },
		{ key: 'monographs', width: 15 },
		{ key: 'studentGuidance', width: 15 },
		{ key: 'scientificReports', width: 15 },
		{ key: 'scientificCouncils', width: 15 },
		{ key: 'total', width: 15 },
		{ key: 'note', width: 15 }
	];

	worksheet.columns = columns;

	worksheet.addRow([
		'STT',
		'MNS',
		'HỌ TÊN GIẢNG VIÊN',
		'ĐỀ TÀI NGHIÊN CỨU CÁC CẤP (I)',
		'BÀI BÁO KHOA HỌC (II)',
		'BẰNG SÁNG CHẾ/BẰNG GIẢI PHÁP HỮU ÍCH (III)',
		'SÁCH CHUYÊN KHẢO/THAM KHẢO/GIÁO TRÌNH (IV)',
		'HƯỚNG DẪN SV NCKH (V)',
		'BÁO CÁO, THAM LUẬN (VI)',
		'THÀNH VIÊN HỘI ĐỒNG KHOA HỌC (VII)',
		'TỔNG',
		'DUYỆT CỦA PHÒNG KHCN'
	]);

	listTimeResearch.forEach((timeResearch) => {
		worksheet.addRow(columns.map((e) => timeResearch?.[e.key]));
	});

	setCellValue(worksheet.getCell(`C${7 + listTimeResearch.length + 3}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		value: 'Xác nhận của Phòng KHCN'
	});
	setCellValue(worksheet.getCell(`C${7 + listTimeResearch.length + 4}}`), {
		font: { size: 11 },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		value: '(Ký, ghi rõ họ tên)'
	});
	setCellValue(worksheet.getCell(`I${7 + listTimeResearch.length + 3}}`), {
		font: { size: 11, bold: true },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		value: 'TRƯỞNG ĐƠN VỊ'
	});
	setCellValue(worksheet.getCell(`I${7 + listTimeResearch.length + 4}}`), {
		font: { size: 11 },
		alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
		value: '(Ký, ghi rõ họ tên)'
	});

	worksheet.mergeCells(`C${7 + listTimeResearch.length + 3}:D${7 + listTimeResearch.length + 3}`);
	worksheet.mergeCells(`C${7 + listTimeResearch.length + 4}:D${7 + listTimeResearch.length + 4}`);
	worksheet.mergeCells(`I${7 + listTimeResearch.length + 3}:J${7 + listTimeResearch.length + 3}`);
	worksheet.mergeCells(`I${7 + listTimeResearch.length + 4}:J${7 + listTimeResearch.length + 4}`);

	for (let i = 0; i <= listTimeResearch.length; i++) {
		for (let j = 0; j <= 11; j++) {
			worksheet.getCell(`${ALPHABET[j]}${i + 7}}`).alignment = {
				wrapText: true,
				horizontal: 'center',
				vertical: 'middle'
			};
			worksheet.getCell(`${ALPHABET[j]}${i + 7}}`).border = BORDER_STYLES;
		}
	}

	workbook.xlsx.writeBuffer().then(function (buffer) {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Tính giờ nghiên cứu.xlsx');
	});
};
