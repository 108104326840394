import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Button,
	Select,
	Divider,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	FormHelperText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import dayjs from 'dayjs';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import FileUploadModal from '../../File/FileUploadModal';
import KPTimePicker from '../../../components/KPTimePicker';
import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';

import { uploadFile } from '../../../reducer/file/action';
import { insertPatent, updatePatent } from '../../../reducer/patent/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import { getAllEmploymentContractByProfileId } from '../../../reducer/employmentContract/action';

function PatentInsertModal(props) {
	const { inserting, setInserting } = props;

	const currentYear = new Date().getFullYear();

	const [detail, setDetail] = useState(null);
	const [values, setValues] = useState({
		purposeId: 3,
		issueAt: `01/01/${currentYear}`,
		acceptedDay: `01/01/${currentYear}`,
		submissionDay: `01/01/${currentYear}`
	});
	const [profileList, setProfileList] = useState([]);
	const [helperText, setHelperText] = useState(false);
	const [timeResearchYear, setTimeResearchYear] = useState(2023);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [files, setFiles] = useState([]);

	// Tự gán bản thân vào danh sách tác giả
	useEffect(() => {
		if (props.profile?.id && profileList.length === 0) {
			setProfileList([
				{
					profileId: props.profile.id,
					roleInPatent: 'main'
				}
			]);
		}
	}, [inserting, props.profile?.id]);

	// Bỏ KPI bỏ luôn contract
	useEffect(() => {
		if (!values?.profileIdForKpi) handleChangeForm('contractIdForKpi', null);
	}, [values?.profileIdForKpi]);

	const dateFormat = (value) => {
		const dateForm = value?.replaceAll('/', '');
		const date = dateForm?.substr(0, 2);
		const month = dateForm?.substr(2, 2);
		const year = dateForm?.substr(-4);
		return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
	};

	const handleChangeForm = (name, value) => {
		setValues({ ...values, [name]: value });
		if (name === 'timeResearchDetailCode') {
			setValues({
				...values,
				[name]: value === 'null' ? null : value
			});
		} else if (name === 'purposeId') {
			setValues({
				...values,
				purposeId: value,
				profileIdForKpi: null,
				contractIdForKpi: null,
				profileIdForReward: null
			});
		}
	};

	const handleChangeProfileList = (index, name, value) => {
		const temp = profileList;
		const tempElm = profileList[index];
		temp.splice(index, 1, { ...tempElm, [name]: value });
		setProfileList([...temp]);
	};

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInPatent: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		const temp = profileList;
		temp.splice(index, 1);
		setProfileList([...temp]);
	};

	const handleSubmit = (e) => {
		if (values?.type) {
			setInserting(false);
			const d = new Date();
			props
				.insertPatent(props.profile?.id, {
					...values,
					patentProfile: profileList,
					dashboardYear: new Date(
						dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD'))
					).getFullYear(),
					issueAt: dateFormat(values?.issueAt || moment().format('YYYY-MM-DD')),
					acceptedDay: dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD')),
					submissionDay: dateFormat(values?.submissionDay || moment().format('YYYY-MM-DD'))
				})
				.then((response) => {
					if (response.success && files) {
						// // console.log(response.data, files);
						props
							.uploadFile(response.data.id, 'patent', files)
							.then((responseUpload) => {
								if (responseUpload.success)
									props.updatePatent(response.data.id, {
										...response.data,
										fileUrl: responseUpload.data
									});
							})
							.catch();
					}
					if (response.success) {
						props.statisticUnaccepted({
							departmentId: 0,
							profileId: props.profile?.id
						});
						if (props.role === 'sudo-admin')
							props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
					}
				});
			setValues(null);
			setProfileList([]);
		} else {
			setHelperText(true);
		}
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);

	const handleUpload = (files) => {
		setFiles(files);
		handleCloseUploadModal();
	};

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	const profileListFromProps = [{ id: -1, name: '' }];
	props.profileList.forEach((e) => profileListFromProps.push(e));

	var timeResearchDetailList =
		props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
			?.timeResearchDetail || [];
	console.log(timeResearchDetailList);
	timeResearchDetailList = timeResearchDetailList.filter(
		(e) => !e.allowedUpdateNum && e.type?.includes('patent')
	);

	const yearList = [];
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth={values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 'xl' : 'md'}
			open={inserting}
			onClose={() => setInserting(false)}
		>
			<DialogTitle>Thêm bằng sáng chế</DialogTitle>
			<DialogContent style={{ display: 'flex', overflowX: 'hidden' }}>
				<div
					style={{
						display: 'flex',
						paddingRight: 16,
						minWidth:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? '50%' : '100%',
						maxWidth:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 960 : '100%'
					}}
				>
					<Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 10 }}>
						<Grid item xs={9}>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								label="Tên bằng sáng chế"
								value={values?.title || ''}
								onChange={(event) => handleChangeForm('title', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								fullWidth
								size="small"
								type="number"
								variant="outlined"
								label="Số lượng đồng sở hữu"
								value={values?.numOfOwner || ''}
								onChange={(event) => handleChangeForm('numOfOwner', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											values={values?.isPhenikaa}
											onChange={(event) => {
												handleChangeForm('isPhenikaa', event.target.checked);
											}}
										/>
									}
									label="Trường Đại học Phenikaa là Cơ quan chủ trì"
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<TextField
								rows={2}
								fullWidth
								multiline
								size="small"
								variant="outlined"
								label="Nhóm tác giả"
								value={values?.authorTeam || ''}
								onChange={(event) => handleChangeForm('authorTeam', event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								size="small"
								type="number"
								variant="outlined"
								label="Số lượng thành viên tham gia"
								value={values?.numOfMember || ''}
								onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={6}>
							<KPTimePicker
								fullWidth
								label="Ngày cấp bằng"
								value={values?.issueAt || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('issueAt', newValue)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								label="Cơ sở cấp"
								variant="outlined"
								value={values?.issueBy || ''}
								onChange={(event) => handleChangeForm('issueBy', event.target.value)}
							/>
						</Grid>
						<Grid item xs={4}>
							<KPTimePicker
								fullWidth
								label="Ngày nộp đơn"
								value={values?.submissionDay || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('submissionDay', newValue)}
							/>
						</Grid>
						<Grid item xs={4}>
							<KPTimePicker
								fullWidth
								label="Ngày chấp nhận đơn"
								value={values?.acceptedDay || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('acceptedDay', newValue)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								size="small"
								label="Số (Identification number)"
								variant="outlined"
								value={values?.identificationNumber || ''}
								onChange={(event) => handleChangeForm('identificationNumber', event.target.value)}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								size="small"
								label="Số lượng trang"
								variant="outlined"
								value={values?.pageNumber || ''}
								onChange={(event) => handleChangeForm('pageNumber', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								size="small"
								type="number"
								label="Điểm GS-PGS"
								variant="outlined"
								value={values?.score?.toString() || ''}
								onChange={(event) => handleChangeForm('score', parseFloat(event.target.value))}
							/>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel
									id="purpose-label"
									style={{ padding: '0 8px', backgroundColor: 'white' }}
								>
									Mục đích
								</InputLabel>
								<Select
									native
									fullWidth
									labelId="purpose-label"
									value={values?.purposeId ?? 'none'}
									onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value))}
								>
									{props.purposes.map((e) => (
										<option key={e.id} value={e.id}>
											{e.description}
										</option>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth size="small" variant="outlined" error={helperText}>
								<InputLabel style={{ padding: '0 8px', backgroundColor: 'white' }}>
									Loại bằng
								</InputLabel>
								<Select
									native
									fullWidth
									value={values?.type || ''}
									onChange={(event) => {
										const value = event.target.value;
										handleChangeForm('type', value);
										if (value !== null) setHelperText(false);
									}}
								>
									<option value={null} />
									<option value="USPTO (Utility)-TGC">USPTO (Utility)-TGC</option>
									<option value="USPTO (Design)-TGC">USPTO (Design)-TGC</option>
									<option value="USPTO (Plant)-TGC">USPTO (Plant)-TGC</option>
									<option value="EPO-TGC">EPO-TGC</option>
									<option value="Bằng sáng chế (VN)-TGC">Bằng sáng chế (VN)-TGC</option>
								</Select>
								{helperText ? <FormHelperText>Không được để trống</FormHelperText> : null}
							</FormControl>
						</Grid>

						{values?.purposeId === 5 && (
							<>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										size="small"
										value={timeResearchYear}
										SelectProps={{ native: true }}
										label="Năm học tính giờ nghiên cứu"
										variant="outlined"
										onChange={(event) => {
											setTimeResearchYear(parseInt(event.target.value));
											handleChangeForm('timeResearchDetailCode', null);
										}}
									>
										{yearList.map((e) => (
											<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
												{e} - {e + 1}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item xs={9}>
									<TextField
										select
										fullWidth
										size="small"
										label="Nhiệm vụ tính giờ nghiên cứu"
										variant="outlined"
										value={values?.timeResearchDetailCode || null}
										onChange={(event) =>
											handleChangeForm('timeResearchDetailCode', event.target.value)
										}
										InputLabelProps={{
											shrink: !(
												values?.timeResearchDetailCode === undefined ||
												values?.timeResearchDetailCode === null
											)
										}}
										error={!values?.timeResearchDetailCode}
									>
										{timeResearchDetailList.length > 0 ? (
											timeResearchDetailList?.map((e) => (
												<MenuItem
													key={e.code}
													value={e.code}
												>{`${e.description} - ${e.value}(h)`}</MenuItem>
											))
										) : (
											<MenuItem value={null}>Chưa có dữ liệu</MenuItem>
										)}
									</TextField>
									{!values?.timeResearchDetailCode && (
										<FormHelperText error>Phải chọn một nhiệm vụ!</FormHelperText>
									)}
								</Grid>
							</>
						)}

						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Ghi chú"
								variant="outlined"
								value={values?.note || ''}
								multiline
								rows={3}
								onChange={(event) => handleChangeForm('note', event.target.value)}
							/>
						</Grid>

						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>
						<Grid item xs={2}>
							<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
								Thành viên
							</Typography>
						</Grid>
						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>

						<Grid item xs={12}>
							{profileList.map((e, index) => (
								<Grid key={`list-${index}`} container spacing={2}>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 9 : 6}>
										<Autocomplete
											disableClearable
											options={props.profileList}
											value={
												profileListFromProps?.find((profile) => profile.id === e.profileId) ||
												profileListFromProps[0]
											}
											getOptionLabel={(option) => `${option.code} - ${option.name}`}
											onChange={(event, profile) => {
												if (profileList[index]?.profileId === values?.profileIdForKpi)
													handleChangeForm('profileIdForKpi', null);
												handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
											}}
											renderInput={(params) => (
												<TextField {...params} size="small" label="Thành viên" variant="outlined" />
											)}
										/>
									</Grid>
									{values?.purposeId === 1 && (
										<Grid item xs={3}>
											<FormControl required fullWidth error={!values?.profileIdForKpi}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForKpi === 'number' &&
														values?.profileIdForKpi === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														handleChangeForm(
															'profileIdForKpi',
															values?.profileIdForKpi !== e.profileId ? parseInt(e.profileId) : null
														);
													}}
												>
													Tính KPI
												</Button>
												{!values?.profileIdForKpi && (
													<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									{values?.purposeId === 99999999 && (
										<Grid item xs={3}>
											<FormControl required fullWidth error={!values?.profileIdForReward}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForReward === 'number' &&
														values?.profileIdForReward === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														handleChangeForm(
															'profileIdForReward',
															values?.profileIdForReward !== e.profileId
																? parseInt(e.profileId)
																: null
														);
													}}
												>
													Tính thưởng
												</Button>
												{!values?.profileIdForReward && (
													<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									<Grid item xs={3}>
										<Button
											fullWidth
											color="secondary"
											variant="contained"
											style={{ height: 40 }}
											onClick={() => handleRemove(index)}
										>
											Xoá
										</Button>
									</Grid>
								</Grid>
							))}
						</Grid>

						<Grid item xs={12}>
							<Button fullWidth variant="outlined" onClick={handleInsert}>
								Thêm thành viên
							</Button>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						flexDirection: 'column',
						display:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 'block' : 'none'
					}}
				>
					<EmploymentContractTable
						contract={detail}
						setDetail={setDetail}
						contractList={contractList}
						kpiIdForKpi={values?.kpiIdForKpi}
						contractIdForKpi={values?.contractIdForKpi}
						handleChangeForm={handleChangeForm}
						values={values}
						setValues={setValues}
					/>
					<EmploymentContractDetailTable kpi={detail} />
				</div>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<Grid container justifyContent={'space-between'}>
					<div>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleOpenUploadModal}
							style={{ marginRight: 4 }}
						>
							Upload file minh chứng
						</Button>
					</div>
					<div style={{ float: 'right' }}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setInserting(false)}
							style={{ marginRight: 4 }}
						>
							Huỷ
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={
								(values?.purposeId === 1 &&
									!(values?.profileIdForKpi && values?.contractIdForKpi)) ||
								(values?.purposeId === 99999999 && !values?.profileIdForReward) ||
								(values?.purposeId === 5 && !values?.timeResearchDetailCode)
							}
							onClick={() => handleSubmit()}
						>
							Xác nhận
						</Button>
					</div>
				</Grid>
			</DialogActions>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
				defaultFiles={files}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	profileList: select(state, 'profileReducer', 'list'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	isFetchingTimeResearchInformation: select(state, 'timeResearchInformationReducer', 'isFetching'),
	fileUrl: select(state, 'fileReducer', 'fileUrl')
});

const mapDispatchToProps = (dispatch) => ({
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	insertPatent: (profileId, payload) => dispatch(insertPatent(profileId, payload)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id)),
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	updatePatent: (profileId, payload) => dispatch(updatePatent(profileId, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(PatentInsertModal));
