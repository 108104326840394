import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider, Typography } from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { getRankTable } from '../../../../reducer/rankTable/action';

function RankTableButton(props) {
	const { rankTableList, getRankTable } = props;

	const [year, setYear] = useState(2021);

	useEffect(() => {
		if (rankTableList.length === 0) getRankTable(year);
	}, []);

	const handleReload = () => getRankTable(year);

	return (
		<>
			<Grid container>
				<Grid item xs={6}>
					<Typography variant="h5" style={{ lineHeight: '36px' }}>
						Bảng tính rank nghiên cứu
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant="outlined"
						startIcon={<Replay />}
						onClick={handleReload}
						style={{ float: 'right' }}
					>
						Tải lại
					</Button>
				</Grid>
			</Grid>
			<Divider style={{ margin: '8px 0' }} />
		</>
	);
}

const mapStateToProps = (state) => ({
	rankTableList: select(state, 'rankTableReducer', 'list')
});

const mapDispatchToProps = (dispatch) => ({
	getRankTable: (year) => dispatch(getRankTable(year))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(RankTableButton));
