import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducer';

const logger = createLogger({
	collapsed: true,
	titleFormatter: (action) => `${action.meta && action.meta.prefix} ${action.type}`
});

let mdw = applyMiddleware(thunk);
if (process.env.REACT_APP_NODE_ENV === 'test' || process.env.REACT_APP_NODE_ENV === 'development') {
	mdw = applyMiddleware(thunk, logger);
}

const store = createStore(reducers, composeWithDevTools(mdw));

export default store;
