import { useState, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Box, Chip, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import KPTable from '../../../components/KPTable';
import DepartmentProfileModal from '../DepartmentProfileModal';
import DepartmentDeleteMemberModal from '../DepartmentDeleteMemberModal';
import { DepartmentContext } from '../DepartmentContext';
import DepartmentMemberRoleModal from '../DepartmentMemberRoleModal/DepartmentMemberRoleModal';
import HumanResourceProfileInfoModal from '../../HumanResource/HumanResourceProfileInfoModal';

import { updateDepartmentProfileById } from '../../../reducer/department/action';
import EmploymentContractUpdateModal2 from '../../EmploymentContract/EmploymentContractUpdateModal2';
import { getAllContractsByProfileId } from '../../../reducer/profile/action';

const useStyles = makeStyles(() =>
	createStyles({
		btnAction: {
			cursor: 'pointer'
		}
	})
);

const ROLE_IN_DEPARTMENT = {
	leader: 'Trưởng đơn vị',
	'vice-leader': 'Phó đơn vị'
};

function DepartmentMemberTable(props) {
	const { visibleDepartments, departmentProfiles, allProfiles, updateDepartmentProfileById } =
		props;
	const [member, setMember] = useState(null);
	const { selectedDepartmentId } = useContext(DepartmentContext);
	const classes = useStyles();

	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [profileId, setProfileId] = useState(null);
	const [roleAnchorEl, setRoleAnchorEl] = useState(null);
	const [updating, setUpdating] = useState(null);

	const [currentRole, setCurrentRole] = useState(null);
	const [assignRoleFor, setAssignRoleFor] = useState(null); // profileId

	const handleDelete = (event, row) => {
		const department = visibleDepartments.find((departmentElement) => {
			return departmentElement.id === selectedDepartmentId;
		});
		if (department) {
			const departmentProfile = department.departmentProfile.find((departmentProfileElement) => {
				return departmentProfileElement.profileId === row.id;
			});
			if (departmentProfile) {
				setDeleting(departmentProfile.id);
				setAnchorEl(event.currentTarget);
			}
		}
	};

	const handleClickAssignRoleButton = (event, row) => {
		setCurrentRole(row.roleInDepartment);
		setRoleAnchorEl(event.currentTarget);
		setAssignRoleFor(row.id);
	};

	/**
	 *
	 * @param {'leader' | 'member'} roleInDepartment
	 */
	const handleAssignRoleInDepartment = (profileId, roleInDepartment) => {
		const department = visibleDepartments.find((departmentElement) => {
			return departmentElement.id === selectedDepartmentId;
		});
		if (department) {
			const departmentProfile = department.departmentProfile.find((departmentProfileElement) => {
				return departmentProfileElement.profileId === profileId;
			});
			if (departmentProfile) {
				updateDepartmentProfileById(
					departmentProfile.id,
					departmentProfile.departmentId,
					departmentProfile.profileId,
					roleInDepartment
				);
				setRoleAnchorEl(null);
				setAssignRoleFor(null);
			}
		}
	};

	const sortedDepartmentProfiles = useMemo(() => {
		const roleToNumber = {
			leader: 2,
			'vice-leader': 1
		};
		const cloneDepartmentProfiles = departmentProfiles.slice(0);
		return cloneDepartmentProfiles.sort((departmentProfileA, departmentProfileB) => {
			const roleA = roleToNumber[departmentProfileA.roleInDepartment] ?? 0;
			const roleB = roleToNumber[departmentProfileB.roleInDepartment] ?? 0;
			// descending order
			return roleB - roleA;
		});
	}, [departmentProfiles]);

	const handleCloseProfileInfoModal = () => {
		setProfileId(null);
	};

	return (
		<>
			<Box m={1} />
			{selectedDepartmentId === 0 && (
				<KPTable
					shadow="none"
					rowsPerPage={10}
					maxHeight="calc(100vh - 288px)"
					columns={[
						{
							title: <b>Họ và tên</b>,
							key: 'name',
							searchable: true,
							render: (value, row) => (
								<a
									href={`${ROUTER.WORK}/profile-id=${row.id}`}
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<Typography>
										<b>{row.name}</b>
									</Typography>
								</a>
							)
						},
						{
							title: <b>Mã PU</b>,
							key: 'code',
							searchable: true
						},
						{
							title: <b>Email</b>,
							key: 'mail',
							searchable: true
						},
						{
							title: <b>Ngành</b>,
							key: 'major',
							searchable: true,
							render: (value, row) => <Typography>{row.major}</Typography>
						},
						{
							title: <b>Tác vụ</b>,
							key: 'actions',
							render: (value, row) => {
								return (
									<>
										<Button variant="text" color="primary" onClick={() => setMember(row)}>
											Profile
										</Button>
										<Button variant="text" onClick={() => setProfileId(row.id)}>
											Lý lịch
										</Button>
										<Button
											variant="text"
											style={{ color: '#f39c12' }}
											onClick={() => setUpdating(row.id)}
										>
											Hợp đồng nhân sự
										</Button>
									</>
								);
							}
						}
					]}
					rows={allProfiles}
				/>
			)}

			{selectedDepartmentId !== 0 && (
				<KPTable
					shadow="none"
					rowsPerPage={10}
					maxHeight="calc(100vh - 288px)"
					columns={[
						{
							title: <b>Họ và tên</b>,
							key: 'name',
							// width: '10%',
							searchable: true,
							render: (value, row) => {
								return (
									<Link
										to={`${ROUTER.WORK}/profile-id=${row.id}`}
										style={{ color: 'black', textDecoration: 'none' }}
									>
										<Typography>
											<b>{row.name}</b>
										</Typography>
									</Link>
								);
							}
						},
						{
							title: <b>Mã PU</b>,
							key: 'code',
							// width: '10%',
							searchable: true
						},
						{
							title: <b>Email</b>,
							key: 'mail',
							// width: '10%',
							searchable: true
						},
						{
							title: <b>Chức vụ</b>,
							key: 'role-in-department',
							// width: '10%',
							render: (value, row) => {
								if (row.roleInDepartment === 'leader') {
									return (
										<Chip
											label={ROLE_IN_DEPARTMENT[row.roleInDepartment]}
											color="primary"
											size="small"
										/>
									);
								}
								return (
									<Chip label="Nhà nghiên cứu" color="primary" size="small" variant="outlined" />
								);
							}
						},
						{
							title: <b>Ngành</b>,
							key: 'major',
							// width: '5%',
							render: (value, row) => <Typography>{row.major}</Typography>
						},
						{
							title: <b>Tác vụ</b>,
							key: 'actions',
							// width: '5%',
							render: (value, row) => {
								return (
									<>
										<Button
											variant="text"
											color="primary"
											size="small"
											onClick={() => setMember(row)}
										>
											Profile
										</Button>
										<Button variant="text" size="small" onClick={() => setProfileId(row.id)}>
											Lý lịch
										</Button>
										<Button
											variant="text"
											size="small"
											style={{ color: '#f39c12' }}
											onClick={() => setUpdating(row.id)}
										>
											Hợp đồng nhân sự
										</Button>
										<Button
											variant="text"
											size="small"
											style={{ color: 'green' }}
											onClick={(event) => handleClickAssignRoleButton(event, row)}
										>
											Gán quyền
										</Button>
										<Button
											variant="text"
											size="small"
											color="secondary"
											onClick={(event) => handleDelete(event, row)}
										>
											Xóa
										</Button>
									</>
								);
							}
						}
					]}
					rows={sortedDepartmentProfiles}
				/>
			)}
			{roleAnchorEl && assignRoleFor && (
				<DepartmentMemberRoleModal
					roleAnchorEl={roleAnchorEl}
					setRoleAnchorEl={setRoleAnchorEl}
					currentRole={currentRole}
					assignRole={(roleInDepartment) =>
						handleAssignRoleInDepartment(assignRoleFor, roleInDepartment)
					}
				/>
			)}
			{member && <DepartmentProfileModal member={member} setMember={setMember} />}
			{anchorEl && (
				<DepartmentDeleteMemberModal
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					deleting={deleting}
					setDeleting={setDeleting}
				/>
			)}
			{profileId && (
				<HumanResourceProfileInfoModal
					profileId={profileId}
					handleCloseProfileInfoModal={handleCloseProfileInfoModal}
				/>
			)}
			{updating && (
				<EmploymentContractUpdateModal2 profileId={updating} setUpdating={setUpdating} />
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	userId: select(state, 'userReducer', 'userId'),
	role: select(state, 'userReducer', 'role'),
	visibleDepartments: select(state, 'departmentReducer', 'visibleDepartments'),
	departments: select(state, 'departmentReducer', 'departments'),
	departmentProfiles: select(state, 'departmentReducer', 'departmentProfiles'),
	allProfiles: select(state, 'departmentReducer', 'allProfiles')
});

const mapDispatchToProps = (dispatch) => ({
	updateDepartmentProfileById: (departmentProfileId, departmentId, profileId, roleInDepartment) =>
		dispatch(
			updateDepartmentProfileById(departmentProfileId, departmentId, profileId, roleInDepartment)
		),
	getEmploymentContractInfoByProfile: (profileId) => dispatch(getAllContractsByProfileId(profileId))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(DepartmentMemberTable));
