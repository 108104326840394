import { Dialog, DialogTitle, DialogContent, Grid, Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { KPTable } from '../../../components/KPTComponents';
import { useDispatch, useSelector } from 'react-redux';
import { insertUser } from '../../../reducer/humanResource/action';
import select from '../../../utils/select';

function AutoInsertModal(props) {
	const { open, setOpen, listNewProfile, setListNewProfile } = props;

	const dispatch = useDispatch();
	const isFetching = useSelector((state) => select(state, 'humanResourceReducer', 'isFetching'));

	const columns = [
		{ title: <b>Họ và tên</b>, key: 'name' },
		{ title: <b>Email</b>, key: 'mail' },
		{ title: <b>Mã PU</b>, key: 'code' },
		{
			title: <b>Tác vụ</b>,
			key: 'action',
			render: (_, row) => (
				<Typography
					className="table-action-buton"
					variant="caption"
					color="primary"
					onClick={async () => {
						const payload = {
							username: row.mail,
							role: 'researcher',
							occupation: 'researcher',
							name: row.name,
							code: row.code
						};

						const callbackOnSuccess = () => {
							setListNewProfile(listNewProfile.filter((p) => p.mail !== row.mail));
						};
						dispatch(insertUser(payload, callbackOnSuccess));
					}}
				>
					Thêm tài khoản
				</Typography>
			)
		}
	];

	return (
		<Dialog fullWidth keepMounted maxWidth="md" open={open} onClose={() => setOpen(false)}>
			<DialogTitle>Thêm người dùng</DialogTitle>
			<DialogContent>
				<KPTable
					columns={columns}
					rows={listNewProfile || []}
					page={null}
					loading={isFetching}
					disablePagination={true}
				/>
			</DialogContent>
		</Dialog>
	);
}

export default AutoInsertModal;
