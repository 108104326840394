import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { KPTextform } from '../../../../components/KPTComponents';
import { uploadMultiFile } from '../../../../reducer/file/action';
import {
	updateStudentResearch,
	updateStudentResearchStatus
} from '../../../../reducer/studentResearch/action';

import select from '../../../../utils/select';
import fileXinDung from '../../../../assets/files/DeTaiSinhVien/BMSVNCKH-08A ĐXDTHĐT.docx';

function StopForm(props) {
	const dispatch = useDispatch();

	const { values, setValues } = props;

	const profileDetail = useSelector((state) => select(state, 'profileReducer', 'detail'));

	const textForm = [
		{
			key: 'leaveReason',
			label: 'Lý do xin dừng thực hiện đề tài',
			value: values?.leaveReason,
			xs: 12,
			height: 7,
			required: true,
			disabled: props.disabled
		},
		{
			fieldOptions: { width: 'calc(100% - 32px)' },
			key: 'leaveFileLink',
			value: values?.leaveFileLink,
			type: 'dragzone',
			label: (color) => (
				<Typography className="kpt-textfield-label" style={{ color }}>
					{`File đơn xin dừng (Tải file mẫu `}
					<a href={fileXinDung} style={{ color: 'blue' }} download={'BMSVNCKH-08A ĐXDTHĐT.docx'}>
						tại đây
					</a>
					{`)`}
					<span style={{ color: '#c90000' }}>*</span>
					{`:`}
				</Typography>
			),
			xs: 12,
			required: true,
			disabled: props.disabled
		}
	];

	const stopProject = () => {
		dispatch(uploadMultiFile(profileDetail?.id, { leaveFile: values?.leaveFileLink })).then(
			(res) => {
				if (res.success) {
					const files = res.data;
					dispatch(
						updateStudentResearch({
							...props.detail,
							leaveFileLink: files[0],
							status: 'report_stop_default',
							leaveReason: values?.leaveReason
						})
					);
				}
			}
		);
	};

	const acceptStopProject = () =>
		dispatch(updateStudentResearchStatus(props.detail.id, 'report_stop_accepted'));

	const handleSubmit = () => {
		props.handleClose();
		switch (props.id) {
			case 'stop-form':
				stopProject();
				break;
			case 'stop-accept-form':
				acceptStopProject();
				break;
			default:
				break;
		}
	};

	return (
		<KPTextform
			id={props.id}
			textForm={textForm}
			values={values}
			setValues={setValues}
			handleSubmit={handleSubmit}
		/>
	);
}

export default StopForm;
