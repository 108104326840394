import React from 'react';
import { connect } from 'react-redux';
import { Button, Popover } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { statisticUnaccepted } from '../../../reducer/profile/action';
import { deleteScientificResearch } from '../../../reducer/scientificResearch/action';
import { deleteScientificResearchOther } from '../../../reducer/scientificResearchOther/action';

function ScientificResearchDeleteModal(props) {
	const {
		deleting,
		anchorEl,
		setAnchorEl,
		deleteScientificResearch,
		deleteScientificResearchOther
	} = props;

	const handleSubmit = () => {
		setAnchorEl(null);
		if (props.filter !== 'other') {
			deleteScientificResearch(deleting).then((success) => {
				if (success) {
					props.statisticUnaccepted({ departmentId: 0, profileId: props.profile?.id });
					if (props.role === 'sudo-admin')
						props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
				}
			});
		} else {
			deleteScientificResearchOther(deleting);
		}
	};

	return (
		<Popover
			open={anchorEl !== null}
			anchorEl={anchorEl}
			onClose={() => setAnchorEl(null)}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<div style={{ padding: 8 }}>
				<div>Xác nhận xoá?</div>
				<div style={{ float: 'right', padding: '8px 0' }}>
					<Button
						size="small"
						variant="outlined"
						color="secondary"
						onClick={() => setAnchorEl(null)}
						style={{ marginRight: 4 }}
					>
						Huỷ
					</Button>
					<Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
						Xác nhận
					</Button>
				</div>
			</div>
		</Popover>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail')
});

const mapDispatchToProps = (dispatch) => ({
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	deleteScientificResearch: (id) => dispatch(deleteScientificResearch(id)),
	deleteScientificResearchOther: (id) => dispatch(deleteScientificResearchOther(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ScientificResearchDeleteModal));
