import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getRankTable = () => async (dispatch) => {
	const api = API_URLS.RANK_TABLE.getRankTable();
	dispatch({
		type: TYPE.RANK_TABLE.GET_RANK_TABLE_LOADING,
		meta: { prefix: [PREFIX.RANK_TABLE, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.RANK_TABLE.GET_RANK_TABLE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.RANK_TABLE, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.RANK_TABLE.GET_RANK_TABLE_FAILURE,
			meta: { prefix: [PREFIX.RANK_TABLE, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const updateRankTable = (id, payload) => async (dispatch) => {
	const api = API_URLS.RANK_TABLE.updateRankTable(id);
	dispatch({
		type: TYPE.RANK_TABLE.UPDATE_RANK_TABLE_LOADING,
		meta: { prefix: [PREFIX.RANK_TABLE, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.RANK_TABLE.UPDATE_RANK_TABLE_SUCCESS,
			id: id,
			payload: response.data.data,
			meta: { prefix: [PREFIX.RANK_TABLE, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.RANK_TABLE.UPDATE_RANK_TABLE_FAILURE,
			meta: { prefix: [PREFIX.RANK_TABLE, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};
