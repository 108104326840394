import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Replay } from '@material-ui/icons';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';

import { DepartmentContext } from '../DepartmentContext';
import DepartmentInsertModal from '../DepartmentInsertModal';

import { getAllDepartments } from '../../../reducer/department/action';

const useStyles = makeStyles((theme) =>
	createStyles({
		btnReload: {
			marginRight: theme.spacing(1)
		}
	})
);

function DepartmentButton(props) {
	const { getAllDepartments } = props;
	const classes = useStyles();
	const { setInsertingDepartment } = useContext(DepartmentContext);

	useEffect(() => {
		if (props.detail) getAllDepartments();
	}, [props.detail]);

	const handleReloadDepartments = () => {
		getAllDepartments();
	};

	return (
		<>
			<Grid container>
				<Grid item sm={6} xs={12}>
					<Typography variant="h5">Đơn vị</Typography>
				</Grid>
				<Grid item sm={6} xs={12}>
					<Box textAlign="right">
						<Button
							variant="outlined"
							color="default"
							className={classes.btnReload}
							startIcon={<Replay />}
							onClick={handleReloadDepartments}
						>
							Tải lại
						</Button>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Add />}
							onClick={() => setInsertingDepartment(true)}
						>
							Thêm đơn vị
						</Button>
					</Box>
				</Grid>
			</Grid>
			<DepartmentInsertModal />
		</>
	);
}

const mapStateToProps = (state) => ({
	detail: select(state, 'profileReducer', 'detail')
});
const mapDispatchToProps = (dispatch) => ({
	getAllDepartments: () => dispatch(getAllDepartments())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DepartmentButton));
