import moment from 'moment';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, Button, Collapse, MenuItem, TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
	coreCollection,
	rankR,
	rankQ,
	topRank,
	journalCategory,
	journalArea,
	classify
} from '../../../constants/data';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import { getFilteredJournal, changeFilterData } from '../../../reducer/journal/action';

const getCurrentYear = () => parseInt(moment().format('YYYY'));

function JournalButton(props) {
	const { filterOpen, setFilterOpen } = props;
	const { getFilteredJournal, changeFilterData } = props;

	const [values, setValues] = useState({
		areasArray: [],
		classify: [],
		categoriesArray: [],
		coreCollection: '',
		title: '',
		type: '',
		sjrBestQuartile: '',
		rank: '',
		topRank: '',
		sortQ: true,
		sortR: true,
		sortTopRank: true,
		year: 0
	});

	const handleChangeForm = (name, value) => setValues({ ...values, [name]: value });

	useEffect(() => {
		if (props.listJournal.length === 0) getFilteredJournal(1, 10, {});
	}, [getFilteredJournal, props.listJournal.length]);

	const handleReload = () => {
		getFilteredJournal(1, 10, props.filterData);
	};

	const handleFilterOpen = () => setFilterOpen(true);

	const handleFilterClose = () => setFilterOpen(false);

	const handleFilter = () => {
		// handleFilterClose();
		var sort = [];
		if (values.sortQ) sort.push({ field: 'sjrBestQuartile', order: 'asc' });
		if (values.sortR) sort.push({ field: 'rank', order: 'asc' });
		if (values.sortTopRank) sort.push({ field: 'topRank', order: 'asc' });

		changeFilterData({
			...values,
			areasArray: values?.areasArray.map((e) => e.value),
			classify: values?.classify.map((e) => e.value),
			categoriesArray: values?.categoriesArray.map((e) => e.value),
			sort
		});
		getFilteredJournal(1, 10, {
			...values,
			areasArray: values?.areasArray.map((e) => e.value),
			classify: values?.classify.map((e) => e.value),
			categoriesArray: values?.categoriesArray.map((e) => e.value),
			sort
		});
	};

	const handleClearFilter = () => {
		// handleFilterClose();
		setValues({
			areasArray: [],
			classify: [],
			categoriesArray: [],
			coreCollection: '',
			title: '',
			type: '',
			sjrBestQuartile: '',
			rank: '',
			topRank: '',
			sortQ: true,
			sortR: true,
			sortTopRank: true,
			year: 0
		});
		changeFilterData({
			areasArray: [],
			classify: [],
			categoriesArray: [],
			coreCollection: '',
			title: '',
			type: '',
			sjrBestQuartile: '',
			rank: '',
			topRank: '',
			sortQ: true,
			sortR: true,
			sortTopRank: true,
			year: 0
		});
		getFilteredJournal(1, 10, {});
	};

	const renderYears = () => {
		var years = [
			<MenuItem key={0} value={0}>
				Tất cả các năm
			</MenuItem>
		];
		for (var i = 2019; i <= getCurrentYear() + 2; i++)
			years.push(
				<MenuItem key={i} value={i}>
					{i}
				</MenuItem>
			);
		return years;
	};

	return (
		<>
			{/* Thanh công cụ tìm kiếm */}
			<Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 8 }}>
				<Grid item xs={4}>
					<TextField
						fullWidth
						label="Tên tạp chí"
						variant="outlined"
						value={values?.title || ''}
						onChange={(event) => handleChangeForm('title', event.target.value)}
						size="small"
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						select
						label="Năm"
						value={values?.year}
						variant="outlined"
						onChange={(event) => handleChangeForm('year', parseInt(event.target.value))}
						size="small"
					>
						{renderYears()}
					</TextField>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						select
						label="ISI/Scopus"
						value={values?.coreCollection || ''}
						variant="outlined"
						onChange={(event) => handleChangeForm('coreCollection', event.target.value)}
						size="small"
					>
						{coreCollection.map((e) => {
							return (
								<MenuItem key={e.value} value={e.value}>
									{e.acronym}
								</MenuItem>
							);
						})}
					</TextField>
				</Grid>
				<Grid item xs={1}>
					<Button color="primary" variant="contained" onClick={handleFilter} fullWidth>
						Tìm kiếm
					</Button>
				</Grid>
				<Grid item xs={1}>
					<Button color="primary" variant="outlined" onClick={handleClearFilter} fullWidth>
						Bỏ lọc
					</Button>
				</Grid>
				<Grid item xs={2}>
					<Button
						fullWidth
						variant="outlined"
						onClick={() => setFilterOpen(!filterOpen)}
						endIcon={
							<ExpandMore
								style={{
									transform: `rotate(${filterOpen ? 180 : 0}deg)`,
									transition: '0.4s'
								}}
							/>
						}
					>
						{filterOpen ? 'Thu nhỏ' : 'Mở rộng'}
					</Button>
				</Grid>
			</Grid>

			<Collapse in={filterOpen} timeout={0}>
				<Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 8 }}>
					<Grid item xs={3}>
						<Autocomplete
							multiple
							options={journalArea}
							getOptionLabel={(option) => option.text}
							filterSelectedOptions
							onChange={(event, val) => handleChangeForm('areasArray', val)}
							value={values?.areasArray || []}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" label="Ngành" placeholder="Ngành" />
							)}
							size="small"
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							multiple
							options={journalCategory}
							getOptionLabel={(option) => option.text}
							filterSelectedOptions
							onChange={(event, val) => handleChangeForm('categoriesArray', val)}
							value={values?.categoriesArray || []}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Chuyên Ngành "
									placeholder="Chuyên ngành"
								/>
							)}
							size="small"
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							multiple
							options={classify}
							getOptionLabel={(option) => option.text}
							filterSelectedOptions
							onChange={(event, val) => handleChangeForm('classify', val)}
							value={values?.classify || []}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Phân Loại"
									placeholder="Phân loại"
								/>
							)}
							size="small"
						/>
					</Grid>

					<Grid item xs={1}>
						<TextField
							fullWidth
							select
							label="Xếp loại Q"
							value={values?.sjrBestQuartile || ''}
							variant="outlined"
							onChange={(event) => handleChangeForm('sjrBestQuartile', event.target.value)}
							size="small"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{rankQ.map((e) => {
								return (
									<MenuItem key={e.value} value={e.value}>
										{e.text}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							fullWidth
							select
							label="Xếp loại R"
							value={values?.rank || ''}
							variant="outlined"
							onChange={(event) => handleChangeForm('rank', event.target.value)}
							size="small"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{rankR.map((e) => {
								return (
									<MenuItem key={e.value} value={e.value}>
										{e.text}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							fullWidth
							select
							label="Top ngành"
							value={values?.topRank || ''}
							variant="outlined"
							onChange={(event) => handleChangeForm('topRank', event.target.value)}
							size="small"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{topRank.map((e) => {
								return (
									<MenuItem key={e.value} value={e.value}>
										{e.text}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
				</Grid>
			</Collapse>
		</>
	);
}

const mapStateToProps = (state) => ({
	listJournal: select(state, 'journalReducer', 'listFiltered'),
	filterData: select(state, 'journalReducer', 'filterData')
});

const mapDispatchToProps = (dispatch) => ({
	getFilteredJournal: (page, pageSize, payload) =>
		dispatch(getFilteredJournal(page, pageSize, payload)),
	changeFilterData: (data) => dispatch(changeFilterData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalButton));
