import dayjs from 'dayjs';
import entities from 'entities';

/**
 * @description Paragraph ID
 * @param {number} paraId
 * @returns {string}
 */
function getLongHex(paraId) {
	return paraId.toString(16).padStart(8, '0');
}

export function createDocument(cv) {
	/**
	 * @description Paragraph ID, auto increment
	 * @type {number}
	 */
	let paraId = 0;

	let xml = '';

	// begin document
	xml += `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
  <w:document xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships"
    xmlns:m="http://schemas.openxmlformats.org/officeDocument/2006/math"
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:wp="http://schemas.openxmlformats.org/drawingml/2006/wordprocessingDrawing"
    xmlns:w10="urn:schemas-microsoft-com:office:word"
    xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main"
    xmlns:wne="http://schemas.microsoft.com/office/word/2006/wordml"
    xmlns:sl="http://schemas.openxmlformats.org/schemaLibrary/2006/main"
    xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main"
    xmlns:pic="http://schemas.openxmlformats.org/drawingml/2006/picture"
    xmlns:c="http://schemas.openxmlformats.org/drawingml/2006/chart"
    xmlns:lc="http://schemas.openxmlformats.org/drawingml/2006/lockedCanvas"
    xmlns:dgm="http://schemas.openxmlformats.org/drawingml/2006/diagram"
    xmlns:wps="http://schemas.microsoft.com/office/word/2010/wordprocessingShape"
    xmlns:wpg="http://schemas.microsoft.com/office/word/2010/wordprocessingGroup"
    xmlns:w14="http://schemas.microsoft.com/office/word/2010/wordml"
    xmlns:w15="http://schemas.microsoft.com/office/word/2012/wordml">
    <w:body>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rtl w:val="0"/>
          </w:rPr>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="40"/>
            <w:szCs w:val="40"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="40"/>
            <w:szCs w:val="40"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">LÝ LỊCH KHOA HỌC</w:t>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rtl w:val="0"/>
          </w:rPr>
        </w:r>
      </w:p>`;

	// begin basicinfo
	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table1"/>
    <w:tblW w:w="9101.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="1908"/>
    <w:gridCol w:w="7193"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="1908"/>
        <w:gridCol w:w="7193"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="2314" w:hRule="atLeast"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcBorders>
          <w:top w:color="000000" w:space="0" w:sz="4" w:val="dotted"/>
          <w:left w:color="000000" w:space="0" w:sz="4" w:val="dotted"/>
          <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="dotted"/>
          <w:right w:color="000000" w:space="0" w:sz="4" w:val="dotted"/>
        </w:tcBorders>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="40"/>
            <w:szCs w:val="40"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="66"/>
            <w:szCs w:val="66"/>
          </w:rPr>
          <w:drawing>
            <wp:inline distB="114300" distT="114300" distL="114300" distR="114300">
              <wp:extent cx="1057275" cy="1054100"/>
              <wp:effectExtent b="0" l="0" r="0" t="0"/>
              <wp:docPr id="1" name="image1.jpg"/>
              <a:graphic>
                <a:graphicData uri="http://schemas.openxmlformats.org/drawingml/2006/picture">
                  <pic:pic>
                    <pic:nvPicPr>
                      <pic:cNvPr id="0" name="image1.jpg"/>
                      <pic:cNvPicPr preferRelativeResize="0"/>
                    </pic:nvPicPr>
                    <pic:blipFill>
                      <a:blip r:embed="rId7"/>
                      <a:srcRect b="0" l="0" r="0" t="0"/>
                      <a:stretch>
                        <a:fillRect/>
                      </a:stretch>
                    </pic:blipFill>
                    <pic:spPr>
                      <a:xfrm>
                        <a:off x="0" y="0"/>
                        <a:ext cx="1057275" cy="1054100"/>
                      </a:xfrm>
                      <a:prstGeom prst="rect"/>
                      <a:ln/>
                    </pic:spPr>
                  </pic:pic>
                </a:graphicData>
              </a:graphic>
            </wp:inline>
          </w:drawing>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rtl w:val="0"/>
          </w:rPr>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcBorders>
          <w:left w:color="000000" w:space="0" w:sz="4" w:val="dotted"/>
        </w:tcBorders>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Họ và tên: </w:t>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="0"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${cv.basicInfo.name}</w:t>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rtl w:val="0"/>
          </w:rPr>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr/>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Ngày, tháng, năm sinh: </w:t>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="0"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${dayjs(cv.basicInfo.birth).format('DD/MM/YYYY')}</w:t>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">     Quốc tịch: ${cv.basicInfo.nationality}</w:t>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rtl w:val="0"/>
          </w:rPr>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Học hàm/ học vị cao nhất: ${cv.basicInfo.degree}</w:t>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Đơn vị công tác: ${entities.encodeXML(cv.basicInfo.unit)}</w:t>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Chức vụ: ${entities.encodeXML(cv.basicInfo.position)}</w:t>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="0"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Địa chỉ: ${entities.encodeXML(cv.basicInfo.address)}</w:t>
        </w:r>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rtl w:val="0"/>
          </w:rPr>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Email: ${entities.encodeXML(cv.basicInfo.email)}</w:t>
        </w:r>
      </w:p>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:pStyle w:val="Title"/>
          <w:widowControl w:val="1"/>
          <w:spacing w:after="120" w:before="120" w:lineRule="auto"/>
          <w:jc w:val="left"/>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:cs="Times New Roman" w:eastAsia="Times New Roman" w:hAnsi="Times New Roman"/>
            <w:b w:val="0"/>
            <w:color w:val="000000"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Điện thoại: ${entities.encodeXML(cv.basicInfo.phone)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
</w:tbl>
<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:bookmarkStart w:colFirst="0" w:colLast="0" w:name="_heading=h.gjdgxs" w:id="0"/>
  <w:bookmarkEnd w:id="0"/>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:rtl w:val="0"/>
    </w:rPr>
  </w:r>
</w:p>`;
	// end basicinfo

	// begin educations
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:keepNext w:val="1"/>
    <w:numPr>
      <w:ilvl w:val="0"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="240" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="720" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">QUÁ TRÌNH ĐÀO TẠO</w:t>
  </w:r>
</w:p>`;

	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table2"/>
    <w:tblW w:w="9622.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:left w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:right w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideH w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideV w:color="000000" w:space="0" w:sz="4" w:val="single"/>
    </w:tblBorders>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="562"/>
    <w:gridCol w:w="1470"/>
    <w:gridCol w:w="1065"/>
    <w:gridCol w:w="2565"/>
    <w:gridCol w:w="3960"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="562"/>
        <w:gridCol w:w="1470"/>
        <w:gridCol w:w="1065"/>
        <w:gridCol w:w="2565"/>
        <w:gridCol w:w="3960"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">TT</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Thời gian</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Học vị</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Chuyên ngành</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Tên cơ sở đào tạo</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

	cv.educations.forEach((educationElement, educationIndex) => {
		xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="257" w:hRule="atLeast"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${educationIndex + 1}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(educationElement.duration)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(educationElement.degree)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(educationElement.major)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(educationElement.unit)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;
	});
	xml += '</w:tbl>';
	// end educations

	// begin businesses
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:keepNext w:val="1"/>
    <w:numPr>
      <w:ilvl w:val="0"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="240" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="720" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">QUÁ TRÌNH CÔNG TÁC</w:t>
  </w:r>
</w:p>`;

	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table3"/>
    <w:tblW w:w="9640.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:left w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:right w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideH w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideV w:color="000000" w:space="0" w:sz="4" w:val="single"/>
    </w:tblBorders>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="621"/>
    <w:gridCol w:w="1534"/>
    <w:gridCol w:w="4335"/>
    <w:gridCol w:w="3150"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="621"/>
        <w:gridCol w:w="1534"/>
        <w:gridCol w:w="4335"/>
        <w:gridCol w:w="3150"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">TT</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Thời gian</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Đơn vị công tác</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Công việc đảm nhiệm</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

	cv.businesses.forEach((businessElement, businessIndex) => {
		xml += `<w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${businessIndex + 1}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(businessElement.duration)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(businessElement.unit)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(businessElement.role)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;
	});

	xml += `</w:tbl>`;
	// end businesses

	// begin language
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:keepNext w:val="1"/>
    <w:numPr>
      <w:ilvl w:val="0"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="240" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="720" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">NGOẠI NGỮ</w:t>
  </w:r>
</w:p>`;

	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table4"/>
    <w:tblW w:w="9625.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:left w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:right w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideH w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideV w:color="000000" w:space="0" w:sz="4" w:val="single"/>
    </w:tblBorders>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="1975"/>
    <w:gridCol w:w="2610"/>
    <w:gridCol w:w="2700"/>
    <w:gridCol w:w="2340"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="1975"/>
        <w:gridCol w:w="2610"/>
        <w:gridCol w:w="2700"/>
        <w:gridCol w:w="2340"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Language</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Đọc</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Viết</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Nói</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

	cv.languages.forEach((languageElement) => {
		xml += `<w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(languageElement.language)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(languageElement.reading)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(languageElement.writing)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(languageElement.speaking)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;
	});

	xml += `</w:tbl>`;
	// end language

	// begin scientificResearchs
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:keepNext w:val="1"/>
    <w:numPr>
      <w:ilvl w:val="0"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="240" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="720" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">QUÁ TRÌNH NGHIÊN CỨU KHOA HỌC</w:t>
  </w:r>
</w:p>`;

	// begin researchAreas
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="120" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="992" w:hanging="272"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Hướng nghiên cứu chính</w:t>
  </w:r>
</w:p>`;
	cv.scientificResearchs.researchAreas.forEach((researchAreaElement) => {
		xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
			++paraId
		)}">
    <w:pPr>
      <w:numPr>
        <w:ilvl w:val="0"/>
        <w:numId w:val="1"/>
      </w:numPr>
      <w:spacing w:before="120" w:line="259" w:lineRule="auto"/>
      <w:ind w:left="720" w:hanging="360"/>
      <w:rPr>
        <w:sz w:val="26"/>
        <w:szCs w:val="26"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:sz w:val="26"/>
        <w:szCs w:val="26"/>
        <w:rtl w:val="0"/>
      </w:rPr>
      <w:t xml:space="preserve">${entities.encodeXML(researchAreaElement)}</w:t>
    </w:r>
  </w:p>`;
	});
	// end researchAreas

	// begin researchProjects
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="120" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="992" w:hanging="272"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Các đề tài/dự án nghiên cứu khoa học</w:t>
  </w:r>
</w:p>`;

	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table5"/>
    <w:tblW w:w="9350.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:left w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:right w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideH w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideV w:color="000000" w:space="0" w:sz="4" w:val="single"/>
    </w:tblBorders>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="612"/>
    <w:gridCol w:w="3511"/>
    <w:gridCol w:w="2202"/>
    <w:gridCol w:w="1522"/>
    <w:gridCol w:w="1503"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="612"/>
        <w:gridCol w:w="3511"/>
        <w:gridCol w:w="2202"/>
        <w:gridCol w:w="1522"/>
        <w:gridCol w:w="1503"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">TT</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Tên đề tài/dự án</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Cơ quan tài trợ</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Thời gian thực hiện</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Vai trò</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

	cv.scientificResearchs.researchProjects.forEach(
		(researchProjectElement, researchProjectIndex) => {
			xml += `<w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${researchProjectIndex + 1}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${researchProjectElement.name}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(researchProjectElement.sponsor)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${researchProjectElement.duration}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${researchProjectElement.role}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;
		}
	);

	xml += `</w:tbl>`;

	// end researchProjects

	// begin isiScopusPublishments
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="120" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="992" w:hanging="272"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Công bố ISI/Scopus </w:t>
  </w:r>
</w:p>`;

	cv.scientificResearchs.isiScopusPublishments.forEach(
		(isiScopusPublishmentElement, isiScopusPublishmentIndex) => {
			xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
    <w:pPr>
      <w:spacing w:line="259" w:lineRule="auto"/>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
        <w:rtl w:val="0"/>
      </w:rPr>
      <w:t xml:space="preserve">${isiScopusPublishmentIndex + 1}. ${entities.encodeXML(isiScopusPublishmentElement.title)}</w:t>
    </w:r>
  </w:p>
  <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
    <w:pPr>
      <w:spacing w:line="259" w:lineRule="auto"/>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:rtl w:val="0"/>
      </w:rPr>
    </w:r>
  </w:p>`;
		}
	);
	// end isiScopusPublishments

	// begin publishments
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="120" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="992" w:hanging="272"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Công bố quốc tế khác</w:t>
  </w:r>
</w:p>`;

	cv.scientificResearchs.publishments.forEach((publishmentElement, publishmentIndex) => {
		xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
			++paraId
		)}">
    <w:pPr>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
        <w:rtl w:val="0"/>
      </w:rPr>
      <w:t xml:space="preserve">${publishmentIndex + 1}. ${entities.encodeXML(publishmentElement.title)}</w:t>
    </w:r>
  </w:p>
  <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
    <w:pPr>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:rtl w:val="0"/>
      </w:rPr>
    </w:r>
  </w:p>`;
	});
	// end publishments

	// begin domesticPublishments
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:line="259" w:lineRule="auto"/>
    <w:ind w:left="990" w:hanging="270"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Công bố trên các tạp chí khoa học quốc gia</w:t>
  </w:r>
</w:p>`;

	cv.scientificResearchs.domesticPublishments.forEach(
		(domesticPublishmentElement, domesticPublishmentIndex) => {
			xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
    <w:pPr>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
        <w:rtl w:val="0"/>
      </w:rPr>
      <w:t xml:space="preserve">${domesticPublishmentIndex + 1}. ${entities.encodeXML(domesticPublishmentElement.title)}</w:t>
    </w:r>
  </w:p>
  <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
    <w:pPr>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:rtl w:val="0"/>
      </w:rPr>
    </w:r>
  </w:p>`;
		}
	);

	// end domesticPublishments

	// begin patentsOrSolutionsOrMonographs
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:line="259" w:lineRule="auto"/>
    <w:ind w:left="993" w:hanging="273"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Bằng sáng chế/giải pháp hữu ích/Sách chuyên khảo</w:t>
  </w:r>
</w:p>
<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:tabs>
      <w:tab w:val="left" w:pos="3945"/>
    </w:tabs>
    <w:jc w:val="center"/>
    <w:rPr>
      <w:i w:val="1"/>
      <w:color w:val="4472c4"/>
      <w:sz w:val="22"/>
      <w:szCs w:val="22"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:rtl w:val="0"/>
    </w:rPr>
  </w:r>
</w:p>`;

	const { patents, solutions, monographs } = cv.scientificResearchs.patentsOrSolutionsOrMonographs;
	const mergedPatentsAndSolutionsAndMonographs = [...patents, ...solutions, ...monographs];
	mergedPatentsAndSolutionsAndMonographs.forEach(
		(patentOrSolutionOrMonographElement, patentOrSolutionOrMonographIndex) => {
			xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
    <w:pPr>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
        <w:rtl w:val="0"/>
      </w:rPr>
      <w:t xml:space="preserve">${patentOrSolutionOrMonographIndex + 1}. ${entities.encodeXML(
				patentOrSolutionOrMonographElement.title
			)}</w:t>
    </w:r>
  </w:p>
  <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
    <w:pPr>
      <w:jc w:val="both"/>
      <w:rPr>
        <w:sz w:val="24"/>
        <w:szCs w:val="24"/>
      </w:rPr>
    </w:pPr>
    <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
      <w:rPr>
        <w:rtl w:val="0"/>
      </w:rPr>
    </w:r>
  </w:p>`;
		}
	);

	// end patentsOrSolutionsOrMonographs

	// end scientificResearchs

	// begin experiences
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:keepNext w:val="1"/>
    <w:numPr>
      <w:ilvl w:val="0"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="240" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="720" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">KINH NGHIỆM TRONG ĐÀO TẠO</w:t>
  </w:r>
</w:p>`;

	// begin doctors
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="120" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="1080" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Đào tạo Tiến sĩ</w:t>
  </w:r>
</w:p>`;

	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table6"/>
    <w:tblW w:w="9343.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:left w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:right w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideH w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideV w:color="000000" w:space="0" w:sz="4" w:val="single"/>
    </w:tblBorders>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="612"/>
    <w:gridCol w:w="2115"/>
    <w:gridCol w:w="2745"/>
    <w:gridCol w:w="1695"/>
    <w:gridCol w:w="2176"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="612"/>
        <w:gridCol w:w="2115"/>
        <w:gridCol w:w="2745"/>
        <w:gridCol w:w="1695"/>
        <w:gridCol w:w="2176"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">TT</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Tên học viên</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Cơ sở đào tạo </w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Năm bảo vệ</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Vai trò</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

	cv.experiences.doctors.forEach((doctorElement, doctorIndex) => {
		xml += `<w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${doctorIndex + 1}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(doctorElement.name)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(doctorElement.unit)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${doctorElement.year}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:line="360" w:lineRule="auto"/>
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(doctorElement.role)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;
	});

	xml += `</w:tbl>`;

	// end doctors

	// begin masters
	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:numPr>
      <w:ilvl w:val="1"/>
      <w:numId w:val="2"/>
    </w:numPr>
    <w:spacing w:after="120" w:before="240" w:line="259" w:lineRule="auto"/>
    <w:ind w:left="1080" w:hanging="360"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Đào tạo Thạc sỹ </w:t>
  </w:r>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:i w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">(trong 5 năm gần nhất)</w:t>
  </w:r>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:rtl w:val="0"/>
    </w:rPr>
  </w:r>
</w:p>`;

	xml += `<w:tbl>
  <w:tblPr>
    <w:tblStyle w:val="Table5"/>
    <w:tblW w:w="9350.0" w:type="dxa"/>
    <w:jc w:val="left"/>
    <w:tblInd w:w="0.0" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:left w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:bottom w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:right w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideH w:color="000000" w:space="0" w:sz="4" w:val="single"/>
      <w:insideV w:color="000000" w:space="0" w:sz="4" w:val="single"/>
    </w:tblBorders>
    <w:tblLayout w:type="fixed"/>
    <w:tblLook w:val="0400"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="612"/>
    <w:gridCol w:w="3511"/>
    <w:gridCol w:w="2202"/>
    <w:gridCol w:w="1522"/>
    <w:gridCol w:w="1503"/>
    <w:tblGridChange w:id="0">
      <w:tblGrid>
        <w:gridCol w:w="612"/>
        <w:gridCol w:w="3511"/>
        <w:gridCol w:w="2202"/>
        <w:gridCol w:w="1522"/>
        <w:gridCol w:w="1503"/>
      </w:tblGrid>
    </w:tblGridChange>
  </w:tblGrid>
  <w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">TT</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Tên học viên</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Cơ sở đào tạo </w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Năm bảo vệ</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:b w:val="1"/>
            <w:sz w:val="24"/>
            <w:szCs w:val="24"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">Vai trò</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

	cv.experiences.masters.forEach((masterElement, masterIndex) => {
		xml += `<w:tr>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${masterIndex + 1}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(masterElement.name)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(masterElement.unit)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="both"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${masterElement.year}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:shd w:fill="auto" w:val="clear"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
				++paraId
			)}">
        <w:pPr>
          <w:tabs>
            <w:tab w:val="left" w:pos="3945"/>
          </w:tabs>
          <w:spacing w:after="60" w:before="60" w:lineRule="auto"/>
          <w:jc w:val="center"/>
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
          </w:rPr>
        </w:pPr>
        <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
          <w:rPr>
            <w:sz w:val="26"/>
            <w:szCs w:val="26"/>
            <w:rtl w:val="0"/>
          </w:rPr>
          <w:t xml:space="preserve">${entities.encodeXML(masterElement.role)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;
	});

	xml += `</w:tbl>`;
	// end masters

	// end experiences

	xml += `<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:tabs>
      <w:tab w:val="left" w:pos="3945"/>
    </w:tabs>
    <w:spacing w:after="240" w:before="240" w:lineRule="auto"/>
    <w:rPr>
      <w:i w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Xác nhận của Trưởng đơn vị</w:t>
    <w:t xml:space="preserve">                           </w:t>
  </w:r>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Hà Nội, </w:t>
  </w:r>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:i w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">${dayjs().format('[ngày] DD [tháng] MM [năm] YYYY')}</w:t>
  </w:r>
</w:p>
<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:tabs>
      <w:tab w:val="left" w:pos="3945"/>
    </w:tabs>
    <w:spacing w:after="240" w:before="240" w:lineRule="auto"/>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:i w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:tab/>
    <w:tab/>
    <w:tab/>
    <w:tab/>
    <w:tab/>
  </w:r>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:t xml:space="preserve">Người khai</w:t>
  </w:r>
</w:p>
<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:tabs>
      <w:tab w:val="left" w:pos="3945"/>
    </w:tabs>
    <w:spacing w:after="240" w:before="240" w:lineRule="auto"/>
    <w:rPr>
      <w:i w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:i w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
      <w:rtl w:val="0"/>
    </w:rPr>
    <w:tab/>
    <w:tab/>
    <w:tab/>
    <w:tab/>
    <w:t xml:space="preserve">     (Ký, ghi rõ họ tên)</w:t>
  </w:r>
</w:p>
<w:p w:rsidR="00000000" w:rsidDel="00000000" w:rsidP="00000000" w:rsidRDefault="00000000" w:rsidRPr="00000000" w14:paraId="${getLongHex(
		++paraId
	)}">
  <w:pPr>
    <w:rPr>
      <w:b w:val="1"/>
      <w:sz w:val="26"/>
      <w:szCs w:val="26"/>
    </w:rPr>
  </w:pPr>
  <w:bookmarkStart w:colFirst="0" w:colLast="0" w:name="_heading=h.1uxnr9bqdjte" w:id="1"/>
  <w:bookmarkEnd w:id="1"/>
  <w:r w:rsidDel="00000000" w:rsidR="00000000" w:rsidRPr="00000000">
    <w:rPr>
      <w:rtl w:val="0"/>
    </w:rPr>
  </w:r>
</w:p>
<w:sectPr>
  <w:pgSz w:h="15840" w:w="12240" w:orient="portrait"/>
  <w:pgMar w:bottom="1440" w:top="1440" w:left="1440" w:right="1440" w:header="720" w:footer="720"/>
  <w:pgNumType w:start="1"/>
</w:sectPr>`;

	// end document
	xml += `
    </w:body>
  </w:document>
  `;

	return xml;
}
