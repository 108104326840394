import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Button, Typography } from '@material-ui/core';
import { Save, Block, Check, Close, SkipNext, HighlightOffRounded } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import select from '../../../../utils/select';

import { KPTextform, KPTextfield } from '../../../../components/KPTComponents';

import {
	insertInternalResearch,
	updateInternalResearch,
	updateInternalResearchStatus,
	deleteInternalResearch
} from '../../../../reducer/internalResearch/action';
import { uploadMultiFile } from '../../../../reducer/file/action';

function DeleteForm(props) {
	const dispatch = useDispatch();

	const { deleteId, handleClose } = props;

	const handleDelete = () => {
		handleClose();
		dispatch(deleteInternalResearch(deleteId));
	};

	return (
		<Box display="flex" alignItems="center" flexDirection="column" position="relative">
			<HighlightOffRounded style={{ color: '#d32f2f', fontSize: 120 }} />
			<Typography variant="h5" style={{ marginBottom: 8 }}>
				<b>Xác nhận xóa đề tài?</b>
			</Typography>
			<Typography style={{ textAlign: 'center' }}>{props?.detail?.name}</Typography>
			<Grid container spacing={2} style={{ marginTop: 16 }}>
				<Grid item xs={6}>
					<Button fullWidth variant="outlined" onClick={handleClose} style={{ marginRight: 16 }}>
						Hủy
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						fullWidth
						className="error-button"
						color="primary"
						variant="contained"
						onClick={handleDelete}
					>
						Xóa
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DeleteForm;
