import moment from 'moment';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getFilteredPatent } from '../../../reducer/patent/action';

function ListPatent(props) {
	useEffect(() => {
		props.getFilteredPatent(1, -1, {
			// accepted: true,
			dashboardYear: props.chosenYear,
			departmentId: props.chosenDepartmentId,
			acceptedOne: true
		});
	}, [props.chosenYear]);

	const getPatentTitle = (info, index) => {
		return (
			<div key={`patent-${index}`} style={{ marginTop: 16 }}>
				<div>
					<b style={{ color: 'gray' }}>{index + 1}. Tên sáng chế: </b>
					<i style={{ color: 'red' }}> {info.patent.title}</i>
				</div>
				<div style={{ marginLeft: 20 }}>
					<b>- Tác giả: </b>
					{info.patent.authorTeam || ''}
					<br />
					<b>- Số đơn: </b>
					{info.patent.identificationNumber || ''}
					<br />
					<b>- Ngày nộp đơn: </b>
					{moment(info.patent.submissionDay).format('DD/MM/YYYY') || ''}
					<br />
					<b>- Ngày chấp nhận đơn: </b>
					{moment(info.patent.acceptedDay).format('DD/MM/YYYY') || ''}
					<br />
				</div>
			</div>
		);
	};

	return props.isFetching ? (
		<div style={{ textAlign: 'center' }}>
			<CircularProgress />
		</div>
	) : (
		<Box
			paddingRight={1}
			height="calc(100vh - 214px)"
			minWidth="calc(100% + 8px)"
			style={{ overflowY: 'auto' }}
		>
			{props.listPatentAccepted.map((item, index) => getPatentTitle(item, index))}
		</Box>
	);
}

const mapStateToProps = (state) => ({
	isFetching: select(state, 'patentReducer', 'isFetching'),
	listPatentAccepted: select(state, 'patentReducer', 'listFiltered')
});

const mapDispatchToProps = (dispatch) => ({
	getFilteredPatent: (page, pageSize, payload) =>
		dispatch(getFilteredPatent(page, pageSize, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ListPatent));
