import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Replay } from '@material-ui/icons';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import toJS from '../../../../hoc/toJS';
import { notification } from '../../../../reducer/home/action';
import { API_URLS } from '../../../../constants/api';
import { apiCall } from '../../../../utils/api';
import KPTable from '../../../../components/KPTable';

import { EmploymentContractInsertContext } from '../EmploymentContractInsertContext';
import IconButton from '@material-ui/core/IconButton';

function SelectUserStep(props) {
	const [allProfiles, setAllProfiles] = useState([]);
	const [loading, setLoading] = useState(true);

	const { setProfile, profile } = useContext(EmploymentContractInsertContext);

	useEffect(() => {
		if (loading) {
			(async () => {
				const { response, error } = await apiCall(API_URLS.PROFILE.getAllProfiles());
				if (!error && response.status === 200) {
					setAllProfiles(response.data.data);
				} else {
					props.notification({
						type: 'success',
						message: 'Lấy dữ liệu thất bại'
					});
				}
				setLoading(false);
			})();
		}
	}, [loading]);

	const handleSelectRow = (value, row) => {
		if (value === profile?.id) {
			setProfile(null);
		} else {
			setProfile(row);
		}
	};

	const rows = [];
	allProfiles?.forEach((e) =>
		rows.push({
			...e,
			checked: e.id === profile?.id
		})
	);

	return (
		<>
			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Typography>Click một dòng trong bảng để chọn nhân sự</Typography>
				<Button
					variant="outlined"
					color="default"
					disabled={loading}
					startIcon={loading ? <CircularProgress size={20} /> : <Replay />}
					onClick={() => setLoading(true)}
				>
					Tải lại
				</Button>
			</Box>
			<Box paddingTop={2}>
				<KPTable
					shadow="none"
					maxHeight={350}
					rowsPerPage={-1}
					columns={[
						{
							title: '',
							key: 'checked',
							width: '5%',
							render: (value, row) => {
								return (
									<IconButton onClick={() => handleSelectRow(value, row)}>
										{value ? <CheckBox /> : <CheckBoxOutlineBlank />}
										{/* {value === profile?.id ? (
                      <CheckBox />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )} */}
									</IconButton>
								);
							}
						},
						{
							title: <b>Tên</b>,
							key: 'name',
							width: '15%',
							searchable: true
						},
						{
							title: <b>Mã PU</b>,
							key: 'code',
							width: '10%',
							searchable: true
						},
						{
							title: <b>Ngày sinh</b>,
							key: 'birth',
							width: '10%',
							render: (value) => dayjs(value).format('YYYY/MM/DD')
						},
						{
							title: <b>Địa chỉ</b>,
							key: 'address',
							width: '20%'
						},
						{
							title: <b>Điện thoại</b>,
							key: 'phone',
							width: '10%'
						},
						{
							title: <b>Email</b>,
							key: 'mail',
							width: '20%'
						}
					]}
					rows={rows}
				/>
			</Box>
		</>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	notification: (payload) => dispatch(notification(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SelectUserStep));
