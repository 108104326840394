import { fromJS } from 'immutable';

import TYPES from '../../constants/type';

/**
 * @type {import('immutable').Map}
 */
const initialState = fromJS({
	allUsers: [],
	isFetching: false
});

function humanResourceReducer(state = initialState, action) {
	switch (action.type) {
		case TYPES.HUMAN_RESOURCE.GET_ALL_USERS_LOADING:
		case TYPES.HUMAN_RESOURCE.CREATE_USER_LOADING:
		case TYPES.HUMAN_RESOURCE.UPDATE_ROLE_LOADING:
		case TYPES.HUMAN_RESOURCE.DELETE_USER_LOADING: {
			return state.merge({
				isFetching: true
			});
		}

		case TYPES.HUMAN_RESOURCE.GET_ALL_USERS_FAILURE:
		case TYPES.HUMAN_RESOURCE.CREATE_USER_FAILURE:
		case TYPES.HUMAN_RESOURCE.UPDATE_ROLE_FAILURE:
		case TYPES.HUMAN_RESOURCE.DELETE_USER_FAILURE: {
			return state.merge({
				isFetching: false
			});
		}

		case TYPES.HUMAN_RESOURCE.GET_ALL_USERS_SUCCESS: {
			return state.merge({
				isFetching: false,
				allUsers: [...action.payload]
			});
		}

		case TYPES.HUMAN_RESOURCE.CREATE_USER_SUCCESS: {
			return state.merge({
				isFetching: false,
				allUsers: [...state.get('allUsers'), action.payload]
			});
		}

		case TYPES.HUMAN_RESOURCE.UPDATE_ROLE_SUCCESS: {
			return state.merge({
				isFetching: false,
				allUsers: state.get('allUsers').map((userElement) => {
					if (userElement.id === action.payload.id) {
						return {
							...userElement,
							...action.payload
						};
					}
					return userElement;
				})
			});
		}

		case TYPES.HUMAN_RESOURCE.DELETE_USER_SUCCESS: {
			const userId = action.payload;
			return state.merge({
				isFetching: false,
				allUsers: state.get('allUsers').filter((userElement) => {
					if (userElement.id === userId) {
						return false;
					}
					return true;
				})
			});
		}

		default: {
			return state;
		}
	}
}

export default humanResourceReducer;
