import { useState } from 'react';
import { Box, Button, Popover, TextField, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		roleSelectBox: {
			width: 200
		},
		btnCancel: {
			margin: theme.spacing(0, 1, 0, 0)
		}
	})
);

function DepartmentMemberRoleModal(props) {
	const { roleAnchorEl, setRoleAnchorEl, assignRole, currentRole } = props;

	const classes = useStyles();

	const [role, setRole] = useState(currentRole || 'member');

	const handleAssignRole = () => {
		assignRole(role);
		setRoleAnchorEl(null);
	};

	return (
		<Popover
			open={roleAnchorEl !== null}
			anchorEl={roleAnchorEl}
			onClose={() => setRoleAnchorEl(null)}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left'
			}}
		>
			<Box p={2}>
				<Box pb={1}>
					<TextField
						select
						size="small"
						variant="outlined"
						className={classes.roleSelectBox}
						value={role}
						onChange={(event) => setRole(event.target.value)}
					>
						<MenuItem value="member">Thành viên</MenuItem>
						<MenuItem value="leader">Trưởng đơn vị</MenuItem>
					</TextField>
				</Box>
				<Box textAlign="right">
					<Button
						size="small"
						variant="outlined"
						className={classes.btnCancel}
						onClick={() => setRoleAnchorEl(null)}
					>
						Hủy
					</Button>
					<Button size="small" variant="contained" color="primary" onClick={handleAssignRole}>
						Chọn
					</Button>
				</Box>
			</Box>
		</Popover>
	);
}

export default DepartmentMemberRoleModal;
