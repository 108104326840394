import { combineReducers } from 'redux-immutable';

import homeReducer from '../reducer/home/reducer';
import userReducer from '../reducer/user/reducer';
import fileReducer from '../reducer/file/reducer';
import rewardReducer from '../reducer/reward/reducer';
import patentReducer from '../reducer/patent/reducer';
import profileReducer from '../reducer/profile/reducer';
import journalReducer from '../reducer/journal/reducer';
import projectReducer from '../reducer/project/reducer';
import councilReducer from '../reducer/council/reducer';
import solutionReducer from '../reducer/solution/reducer';
import contractReducer from '../reducer/contract/reducer';
import rankTableReducer from '../reducer/rankTable/reducer';
import statisticReducer from '../reducer/statistic/reducer';
import monographReducer from '../reducer/monograph/reducer';
import conferenceReducer from '../reducer/conference/reducer';
import departmentReducer from '../reducer/department/reducer';
import notificationReducer from '../reducer/notification/reducer';
import earningsTableReducer from '../reducer/earningsTable/reducer';
import humanResourceReducer from '../reducer/humanResource/reducer';
import studentResearchReducer from '../reducer/studentResearch/reducer';
import journalDomesticReducer from '../reducer/journalDomestic/reducer';
import internalResearchReducer from '../reducer/internalResearch/reducer';
import rewardInformationReducer from '../reducer/rewardInformation/reducer';
import employmentContractReducer from '../reducer/employmentContract/reducer';
import scientificResearchReducer from '../reducer/scientificResearch/reducer';
import intellectualPropertyReducer from '../reducer/intellectualProperty/reducer';
import timeResearchInformationReducer from '../reducer/timeResearchInformation/reducer';
import scientificResearchOtherReducer from '../reducer/scientificResearchOther/reducer';
import scientificCouncilReducer from '../reducer/scientificCouncil/reducer';

const rootReducer = combineReducers({
	homeReducer,
	userReducer,
	fileReducer,
	rewardReducer,
	patentReducer,
	councilReducer,
	journalReducer,
	profileReducer,
	projectReducer,
	solutionReducer,
	contractReducer,
	statisticReducer,
	monographReducer,
	rankTableReducer,
	conferenceReducer,
	departmentReducer,
	notificationReducer,
	earningsTableReducer,
	humanResourceReducer,
	journalDomesticReducer,
	studentResearchReducer,
	internalResearchReducer,
	rewardInformationReducer,
	employmentContractReducer,
	scientificResearchReducer,
	intellectualPropertyReducer,
	scientificResearchOtherReducer,
	timeResearchInformationReducer,
	scientificCouncilReducer
});

const _rootReducer = (state, action) =>
	rootReducer(action.type === 'LOGOUT' ? undefined : state, action);

export default _rootReducer;
