import { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Add, Delete, GetApp, RemoveRedEye } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import select from '../../../../../utils/select';
import StudentGuidance from '../../StudentGuidance/StudentGuidance';
import ScientificCouncil from '../../ScientificCouncil/ScientificCouncil';

import { KPTable } from '../../../../../components/KPTComponents';
import { renderFloat } from '../../utils';
import { scientificCouncilAction } from '../../../../../reducer/scientificCouncil/action';
import { deleteStudentGuidance as _deleteStudentGuidance } from '../../../../../reducer/studentResearch/action';

function PersonalTab(props) {
	const dispatch = useDispatch();

	const { timeResearch, reload } = props;

	console.log('timeResearch', timeResearch);

	const { departments, profileDetail, isFetching, t___Research } = useSelector((state) => ({
		profileDetail: select(state, 'profileReducer', 'detail'),
		departments: select(state, 'departmentReducer', 'departments'),
		isFetching: select(state, 'profileReducer', 'isFetching'),
		t___Research: select(state, 'profileReducer', 'timeResearch')
	}));

	const [viewValues, setViewValues] = useState(null);
	const [insertingCouncil, setInsertingCouncil] = useState(false);
	const [insertingGuidance, setInsertingGuidance] = useState(false);

	const deleteScientificCouncil = (id) => {
		dispatch(scientificCouncilAction.deleteScientificCouncli(id)).then(() =>
			reload(profileDetail?.id)
		);
	};

	const deleteStudentGuidance = (id) => {
		dispatch(_deleteStudentGuidance(id)).then(() => reload(profileDetail?.id));
	};

	const listTable = [
		{
			title: 'Đề tài nghiên cứu các cấp',
			subTitle:
				'(khai báo các đề tài nghiên cứu tính giờ NCKH tại Công trình khoa học - Đề tài nghiên cứu)',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{ title: <b>Tên đề tài</b>, key: 'title' },
				{ title: <b>Loại đề tài</b>, key: 'type' },
				{ title: <b>Vai trò</b>, key: 'role' },
				{ title: <b>Số giờ được tính</b>, key: 'timeAmount' }
			],
			dataSource: timeResearch?.researchProjects,
			total:
				timeResearch?.researchProjects?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0
		},
		{
			title: 'Bài báo khoa học',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{ title: <b>Danh sách tác giả, bài báo, volume</b>, key: 'title' },
				{ title: <b>Loại bài báo</b>, key: 'type' },
				{ title: <b>Vai trò</b>, key: 'role' },
				{ title: <b>Số giờ được tính</b>, key: 'timeAmount' }
			],
			dataSource: timeResearch?.scientificResearchs,
			total:
				timeResearch?.scientificResearchs?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0
		},
		{
			title: 'Báo cáo, tham luận',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{ title: <b>Danh sách tác giả, bài báo, volume</b>, key: 'title' },
				{ title: <b>Loại báo cáo</b>, key: 'type' },
				{ title: <b>Vai trò</b>, key: 'role' },
				{ title: <b>Số giờ được tính</b>, key: 'timeAmount' }
			],
			dataSource: timeResearch?.scientificReports,
			total:
				timeResearch?.scientificReports?.reduce(
					(total, curr) => total + parseFloat(curr.timeAmount),
					0
				) || 0
		},
		{
			title: 'Sách chuyên khảo/tham khảo/giáo trình',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{ title: <b>Danh sách tác giả, tên sách, năm</b>, key: 'title' },
				{ title: <b>Loại sách</b>, key: 'type' },
				{ title: <b>Vai trò</b>, key: 'role' },
				{ title: <b>Số giờ được tính</b>, key: 'timeAmount' }
			],
			dataSource: timeResearch?.monographs,
			total:
				timeResearch?.monographs?.reduce((total, curr) => total + parseFloat(curr.timeAmount), 0) ||
				0
		},
		{
			title: 'Bằng sáng chế/Bằng giải pháp hữu ích',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{
					title: <b>Danh sách tác giả, Tên sáng chế/Giải pháp hữu ích, Năm cấp bằng</b>,
					key: 'info'
				},
				{ title: <b>Phân loại</b>, key: 'type' },
				{ title: <b>Số giờ được tính</b>, key: 'timeAmount' }
			],
			dataSource: timeResearch?.patents,
			total:
				timeResearch?.patents?.reduce((total, curr) => total + parseFloat(curr.timeAmount), 0) || 0
		},
		{
			title: 'Hướng dẫn sinh viên nghiên cứu khoa học',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{ title: <b>Tên đề tài</b>, width: '30%', key: 'title' },
				{ title: <b>Loại đề tài</b>, width: '30%', key: 'type' },
				{
					title: <b>Số giờ được tính</b>,
					key: 'timeAmount',
					render: (val, row) => (row.accepted ? val : 'Chưa được duyệt')
				},
				{
					title: <b>Tác vụ</b>,
					width: '10%',
					key: 'delete',
					align: 'center',
					render: (_, row) => (
						<>
							<IconButton
								size="small"
								disabled={!row.id}
								onClick={() => {
									const value = t___Research.studentGuidanceV2.find((e) => e.id === row.id);
									setViewValues({
										...value,
										department: departments.find((e) => e.id === row.departmentId)
									});
									setInsertingGuidance(true);
								}}
							>
								<RemoveRedEye fontSize="small" />
							</IconButton>
							<IconButton
								size="small"
								disabled={!row.id || row.accepted}
								onClick={() => deleteStudentGuidance(row.id)}
							>
								<Delete fontSize="small" />
							</IconButton>
						</>
					)
				}
			],
			onInsert: () => setInsertingGuidance(true),
			dataSource: timeResearch?.studentGuidance,
			total:
				timeResearch?.studentGuidance?.reduce(
					(total, curr) => total + (curr.accepted ? parseFloat(curr.timeAmount) : 0),
					0
				) || 0
		},
		{
			title: 'Thành viên hội đồng khoa học',
			columns: [
				{ title: <b>STT</b>, align: 'center', width: '5%', key: 'stt' },
				{ title: <b>Tên đối tượng</b>, key: 'title' },
				{ title: <b>Phân loại Hội đồng</b>, key: 'type' },
				{ title: <b>Vai trò</b>, key: 'role' },
				{
					title: <b>Số giờ được tính</b>,
					key: 'timeAmount',
					render: (val, row) => (row.accepted ? val : 'Chưa được duyệt')
				},
				{
					title: <b>Tác vụ</b>,
					width: '10%',
					key: 'delete',
					align: 'center',
					render: (_, row) => (
						<>
							<IconButton
								size="small"
								disabled={!row.id}
								onClick={() => {
									const value = t___Research.scientificCouncilV2s.find((e) => e.id === row.id);
									setViewValues({
										...value,
										department: departments.find((e) => e.id === row.departmentId)
									});
									setInsertingCouncil(true);
								}}
							>
								<RemoveRedEye fontSize="small" />
							</IconButton>
							<IconButton
								size="small"
								disabled={!row.id || row.accepted}
								onClick={() => deleteScientificCouncil(row.id)}
							>
								<Delete fontSize="small" />
							</IconButton>
						</>
					)
				}
			],
			onInsert: () => setInsertingCouncil(true),
			dataSource: timeResearch?.scientificCouncils,
			total:
				timeResearch?.scientificCouncils?.reduce(
					(total, curr) => total + (curr.accepted ? parseFloat(curr.timeAmount) : 0),
					0
				) || 0
		}
	];

	return (
		<Box style={{ overflow: 'auto' }}>
			{listTable.map((e, id) => (
				<Box marginTop={id ? 4 : 0}>
					<Typography variant="h6" color="primary">
						<b>{e.title}</b>
					</Typography>
					{e.subTitle && (
						<Typography variant="subtitle" color="primary">
							{e.subTitle}
						</Typography>
					)}
					<Box style={{ border: 'solid 1px #e0e0e0', marginTop: 8 }}>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								padding: '4px 8px'
							}}
						>
							<IconButton size="small" disabled>
								<GetApp />
							</IconButton>
							<Button
								size="small"
								variant="text"
								disabled={!e.onInsert}
								color="secondary"
								startIcon={<Add />}
								onClick={e.onInsert}
							>
								Thêm
							</Button>
						</Box>
						<KPTable
							loading={isFetching}
							columns={e.columns}
							rows={e.dataSource}
							page={null}
							disablePagination={true}
						/>
					</Box>
					<Box style={{ padding: '4px 0' }}>
						<Typography variant="body1">
							{e.total >= 0 && `Tổng số giờ: ${renderFloat(e.total)}`}
						</Typography>
					</Box>
				</Box>
			))}

			<ScientificCouncil
				viewValues={viewValues}
				open={insertingCouncil}
				reload={() => reload(profileDetail?.id)}
				handleClose={() => {
					setInsertingCouncil(false);
					setViewValues(null);
				}}
			/>

			<StudentGuidance
				viewValues={viewValues}
				open={insertingGuidance}
				reload={() => reload(profileDetail?.id)}
				handleClose={() => {
					setInsertingGuidance(false);
					setViewValues(null);
				}}
			/>
		</Box>
	);
}

export default PersonalTab;
