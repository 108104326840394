import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button
} from '@material-ui/core';
import dayjs from 'dayjs';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import { apiCall } from '../../../utils/api';
import { API_URLS } from '../../../constants/api';
import ROUTER from '../../../constants/router';

import HumanResourceUpdateProfileStep from '../HumanResourceInsertModal/HumanResourceUpdateProfileStep';

import { notification } from '../../../reducer/home/action';
import { updatePassword, logout } from '../../../reducer/user/action';
import { addProfileToDepartment } from '../../../reducer/department/action';
import KPTimePicker from '../../../components/KPTimePicker';

import { getAllDepartments } from '../../../reducer/department/action';

const dateFormat = (value) => {
	const dateForm = value?.replaceAll('/', '');
	const date = dateForm?.substr(0, 2);
	const month = dateForm?.substr(2, 2);
	const year = dateForm?.substr(-4);
	return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null;
};

function ProfileUpdateModal(props) {
	const history = useHistory();
	const [noti, setNoti] = useState(null);
	const [focus, setFocus] = useState(false);
	const [values, setValues] = useState(null);

	const dispatch = useDispatch();
	const { departments } = useSelector((state) => ({
		departments: select(state, 'departmentReducer', 'departments')
	}));

	useEffect(() => {
		if (props.profileDetail) setValues(props.profileDetail);
	}, [props.profileDetail]);

	useEffect(() => {
		if (props.profileDetail) dispatch(getAllDepartments());
	}, [props.profileDetail]);

	const handleChangeForm = (name, value) => setValues({ ...values, [name]: value });

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = {
			...values,
			// birth: dayjs(values.birth).isValid() ? dayjs(values.birth).format('YYYY-MM-DDThh:mm:ss[Z]') : '',
			birth: dateFormat(values.birth)
		};

		const profileId = props.profileDetail?.id;
		const apiUpdateProfile = API_URLS.PROFILE.updateProfile(profileId);
		const { response, error } = await apiCall({ ...apiUpdateProfile, payload });
		// values.departments.forEach(item => {
		//   dispatch(addProfileToDepartment({ profileId: profileId, departmentId: item.id }))
		// })

		if (!error && response.status === 200 && response.data.success) {
			notification({
				type: 'success',
				message: 'Cập nhật hồ sơ thành công'
			});
			try {
				values.departments.forEach((item) => {
					dispatch(
						addProfileToDepartment({
							profileId: profileId,
							departmentId: item.id
						})
					);
				});
			} catch (error) {
				console.error(error);
			} finally {
				window.location.reload();
			}
		} else {
			notification({
				type: 'error',
				message: 'Cập nhật hồ sơ thất bại'
			});
		}
	};

	const logout = () => {
		props.logout();
		history.push(ROUTER.LOGIN);
	};

	return (
		<Dialog fullWidth maxWidth="md" open={props.profileDetail?.name === ''}>
			<DialogTitle>Cập nhật hồ sơ</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent style={{ overflow: 'hidden' }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								required
								size="small"
								label="Họ và tên"
								variant="outlined"
								value={values?.name || ''}
								onChange={(event) => handleChangeForm('name', event.target.value)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								required
								fullWidth
								size="small"
								label="Mã PU"
								variant="outlined"
								value={values?.code || ''}
								onChange={(event) => handleChangeForm('code', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								select
								required
								fullWidth
								size="small"
								label="Giới tính"
								variant="outlined"
								value={values?.gender || ''}
								onChange={(event) => handleChangeForm('gender', event.target.value)}
								SelectProps={{ native: true }}
							>
								<option value={null} />
								<option value="male">Nam</option>
								<option value="female">Nữ</option>
								<option value="other">Khác</option>
							</TextField>
						</Grid>
						<Grid item xs={3}>
							<KPTimePicker
								fullWidth
								label="Ngày sinh *"
								value={values?.birth || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('birth', newValue)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								required
								fullWidth
								size="small"
								label="Số CMND"
								variant="outlined"
								value={values?.identityNumber || ''}
								onChange={(event) => handleChangeForm('identityNumber', event.target.value)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								required
								fullWidth
								size="small"
								label="Số điện thoại"
								variant="outlined"
								value={values?.phone || ''}
								onChange={(event) => handleChangeForm('phone', event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								multiline
								rows={2}
								fullWidth
								size="small"
								label="Địa chỉ"
								variant="outlined"
								value={values?.address || ''}
								onChange={(event) => handleChangeForm('address', event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								disabled
								required
								fullWidth
								type="email"
								size="small"
								label="Email"
								variant="outlined"
								value={values?.mail || ''}
								onChange={(event) => handleChangeForm('mail', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								select
								required
								fullWidth
								size="small"
								label="Bằng cấp"
								variant="outlined"
								value={values?.degree || ''}
								SelectProps={{ native: true }}
								onChange={(event) => handleChangeForm('degree', event.target.value)}
							>
								<option value={null} />
								<option value={'Tiến sĩ'}>Tiến sĩ</option>
								<option value={'Thạc sĩ'}>Thạc sĩ</option>
								<option value={'Cử nhân - Kỹ sư'}>Cử nhân - Kỹ sư</option>
								<option value={'Khác'}>Khác</option>
							</TextField>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								type="number"
								size="small"
								label="Năm tốt nghiệp PhD"
								variant="outlined"
								value={values?.graduatedYearPhd || ''}
								onChange={(event) =>
									handleChangeForm('graduatedYearPhd', parseInt(event.target.value))
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								label="Chuyên ngành"
								variant="outlined"
								value={values?.major || ''}
								onChange={(event) => handleChangeForm('major', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								label="Chuyên ngành hẹp"
								variant="outlined"
								value={values?.profession || ''}
								onChange={(event) => handleChangeForm('profession', event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								options={departments.filter((item) => item.id !== 0) ?? []}
								getOptionLabel={(option) => option.name}
								filterSelectedOptions
								onChange={(event, val) => handleChangeForm('departments', val)}
								value={values?.departments ?? []}
								size="small"
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Đơn vị"
										placeholder="Đơn vị"
										error={!(!!values?.departments && values?.departments?.length > 0)}
										helperText={
											!(!!values?.departments && values?.departments?.length > 0)
												? 'Vui lòng chọn tất cả các đơn vị của bạn'
												: null
										}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions style={{ paddingRight: 24 }}>
					<Button variant="contained" color="secondary" onClick={logout}>
						Đăng xuất
					</Button>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={!(!!values?.departments && values?.departments?.length > 0)}
					>
						Xác nhận
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	userId: select(state, 'userReducer', 'userId'),
	profileDetail: select(state, 'profileReducer', 'detail')
});

const mapDispatchToProps = (dispatch) => ({
	updatePassword: (payload) => dispatch(updatePassword(payload)),
	logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ProfileUpdateModal));
