import { Box, Grid, Typography, TextField, Paper, IconButton, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { FieldArray, FastField, useField, ErrorMessage } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme) =>
	createStyles({
		sectionTitle: {
			fontWeight: 'bold',
			textTransform: 'uppercase'
		},
		educationElement: {
			padding: theme.spacing(2, 1),
			margin: theme.spacing(1, 0)
		}
	})
);

function Education() {
	const classes = useStyles();

	const [educationsInput] = useField('educations');

	return (
		<>
			<Box py={2}>
				<Box>
					<Typography variant="h6" className={classes.sectionTitle}>
						1. Quá trình đào tạo
					</Typography>
				</Box>
			</Box>
			<FieldArray name="educations">
				{(arrayHelpers) => {
					const handleAddEducationElement = () => {
						arrayHelpers.push({
							duration: '',
							degree: '',
							major: '',
							unit: ''
						});
					};

					const handleDeleteEducationElement = (index) => {
						arrayHelpers.remove(index);
					};

					return (
						<>
							{educationsInput.value.map((_educationElement, educationIndex) => {
								return (
									<Paper
										variant="outlined"
										className={classes.educationElement}
										key={educationIndex}
									>
										<Box display="flex" flexDirection="row" alignItems="center">
											<Grid container spacing={2}>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`educations[${educationIndex}].duration`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Thời gian"
																	helperText={
																		<ErrorMessage name={`educations[${educationIndex}].duration`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`educations[${educationIndex}].degree`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Học vị"
																	helperText={
																		<ErrorMessage name={`educations[${educationIndex}].degree`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`educations[${educationIndex}].major`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Chuyên ngành"
																	helperText={
																		<ErrorMessage name={`educations[${educationIndex}].major`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`educations[${educationIndex}].unit`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Tên cơ sở đào tạo"
																	helperText={
																		<ErrorMessage name={`educations[${educationIndex}].unit`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
											</Grid>
											<Box flexShrink={1} paddingLeft={2}>
												<IconButton
													color="secondary"
													title="Xóa"
													onClick={() => handleDeleteEducationElement(educationIndex)}
												>
													<Delete />
												</IconButton>
											</Box>
										</Box>
									</Paper>
								);
							})}
							<Box paddingTop={1}>
								<Button
									variant="outlined"
									color="primary"
									startIcon={<Add />}
									onClick={handleAddEducationElement}
								>
									Thêm
								</Button>
							</Box>
						</>
					);
				}}
			</FieldArray>
		</>
	);
}

export default Education;
