import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
	Chip,
	Grid,
	Button,
	Select,
	Divider,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	FormHelperText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Tooltip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import dayjs from 'dayjs';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import KPTimePicker from '../../../components/KPTimePicker';
import FileUploadModal from '../../File/FileUploadModal';
import FilePreviewModal from '../../File/FilePreviewModal';
import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';

import { uploadFile } from '../../../reducer/file/action';
import { updateSolution } from '../../../reducer/solution/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import {
	getEmploymentContractById,
	getAllEmploymentContractByProfileId
} from '../../../reducer/employmentContract/action';

function SolutionUpdateModal(props) {
	const { employmentContractDetail, updating, setUpdating, updateSolution } = props;
	const [disabled, setDisabled] = useState(true);

	const [detail, setDetail] = useState(null);
	const [values, setValues] = useState(null);
	const [profileList, setProfileList] = useState([]);
	const [timeResearchYear, setTimeResearchYear] = useState(2023);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);

	useEffect(() => {
		if (values?.profileIdForKpi) props.getAllEmploymentContractByProfileId(values?.profileIdForKpi);
	}, [values?.profileIdForKpi]);

	useEffect(() => {
		if (updating) resetValues();
	}, [updating]);

	useEffect(() => {
		if (employmentContractDetail)
			setValues({
				...values,
				profileIdForKpi: employmentContractDetail?.profile?.id
			});
	}, [employmentContractDetail]);

	useEffect(() => {
		// setDetail(props?.employmentContractListById?.find((e) => e?.contract?.id === updating?.contractIdForKpi)?.contract);
		const contract = props?.employmentContractListById?.find(
			(e) => e?.contract?.id === updating?.contractIdForKpi
		)?.contract;
		setDetail(contract?.listKpi?.find((e) => e.id === updating.kpiIdForKpi));
	}, [props?.employmentContractListById, updating?.contractIdForKpi]);

	// useEffect(() => {
	//   setTimeResearchYear(props.detailYear?.year || 2021);
	// }, [props.detailYear]);
	useEffect(() => {
		if (values?.timeResearchDetailCode) {
			const selectedYear = props.timeResearchInformationList?.find(
				(info) =>
					!!info.timeResearchDetail.find((detail) => detail.code === values?.timeResearchDetailCode)
			)?.year;

			setTimeResearchYear(selectedYear);
		}
	}, [values?.timeResearchDetailCode]);

	useEffect(() => {
		if (values && props.isUploading === false && props.fileUrl !== '') {
			setValues({
				...values,
				fileUrl: props.fileUrl
			});
			updateSolution(values.id, {
				...values,
				solutionProfile: profileList,
				issueAt: dateFormat(values?.issueAt),
				acceptedDay: dateFormat(values?.acceptedDay),
				submissionDay: dateFormat(values?.submissionDay),
				fileUrl: props.fileUrl,
				dashboardYear: new Date(dateFormat(dateFormat(values?.acceptedDay))).getFullYear()
			});
		}
	}, [props.isUploading]);

	// Bỏ KPI bỏ luôn contract
	// useEffect(() => {
	//   if (!values?.profileIdForKpi) handleChangeForm('contractIdForKpi', null);
	// }, [values?.profileIdForKpi]);

	const dateFormat = (value) => {
		const dateForm = value?.replaceAll('/', '');
		const date = dateForm?.substr(0, 2);
		const month = dateForm?.substr(2, 2);
		const year = dateForm?.substr(-4);
		return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null;
	};

	const resetValues = () => {
		setValues({
			...updating,
			issueAt: dayjs(updating.issueAt)?.format('DD-MM-YYYY')?.replaceAll('-', '/'),
			acceptedDay: dayjs(updating.acceptedDay)?.format('DD-MM-YYYY')?.replaceAll('-', '/'),
			submissionDay: dayjs(updating.submissionDay)?.format('DD-MM-YYYY')?.replaceAll('-', '/')
		});
		setProfileList(updating.solutionProfile || []);
		if (!props.isFetchingEmploymentContract)
			props.getEmploymentContractById(updating.contractIdForKpi);
		// setDetail(props.employmentContractListById?.find((e) => e.contract.id === updating.contractIdForKpi)?.contract);
		const contract = props?.employmentContractListById?.find(
			(e) => e?.contract?.id === updating?.contractIdForKpi
		)?.contract;
		setDetail(contract?.listKpi?.find((e) => e.id === updating.kpiIdForKpi));

		const timeResearchYearId = parseInt(updating.timeResearchDetailCode?.split('-')[0]);
		const year =
			props.timeResearchInformationList?.find((e) => e.id === timeResearchYearId)?.year || 2022;
		setTimeResearchYear(year);
	};

	const handleChangeForm = (name, value) => {
		if (name === 'timeResearchDetailCode') {
			setValues({
				...values,
				[name]: value === 'null' ? null : value
			});
		} else if (name === 'purposeId' && value !== 1) {
			setValues({
				...values,
				[name]: value,
				profileIdForKpi: null,
				contractIdForKpi: null
			});
		} else {
			setValues({ ...values, [name]: value });
		}
	};

	const handleChangeProfileList = (index, name, value) => {
		const temp = profileList;
		const tempElm = profileList[index];
		temp.splice(index, 1, { ...tempElm, [name]: value });
		setProfileList([...temp]);
	};

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInSolution: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		if (profileList[index].profileId === values?.profileIdForKpi)
			handleChangeForm('profileIdForKpi', null);
		const temp = profileList;
		temp.splice(index, 1);
		setProfileList([...temp]);
	};

	const handleClose = () => {
		if (!disabled) {
			resetValues();
			setDisabled(true);
		} else {
			setUpdating(null);
			setDisabled(true);
		}
	};

	const handleSubmit = () => {
		if (disabled) {
			setDisabled(false);
		} else {
			updateSolution(values.id, {
				...values,
				solutionProfile: profileList,
				issueAt: dateFormat(values?.issueAt),
				acceptedDay: dateFormat(values?.acceptedDay),
				submissionDay: dateFormat(values?.submissionDay),
				dashboardYear: new Date(
					dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD'))
				).getFullYear()
			}).then((success) => {
				if (success) {
					props.statisticUnaccepted({
						departmentId: 0,
						profileId: props.profile?.id
					});
					if (props.role === 'sudo-admin')
						props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
				}
			});
			setUpdating(null);
			setDisabled(true);
		}
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);
	const handleOpenPreviewModal = () => setPreviewModalOpen(true);
	const handleClosePreviewModal = () => setPreviewModalOpen(false);

	const handleUpload = (file) => {
		props
			.uploadFile(values.id, 'solution', file)
			.then((success) => {
				if (success) setUploadModalOpen(false);
			})
			.catch();
	};

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	var timeResearchDetailList =
		props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
			?.timeResearchDetail || [];
	timeResearchDetailList = timeResearchDetailList.filter(
		(e) => !e.allowedUpdateNum && e.type?.includes('solution')
	);

	const yearList = [];
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	const renderLockButton = () => {
		if (!values?.locked) return null;
		return (
			<Chip
				variant="outlined"
				label={`Đã khóa tính ${
					(values?.locked === 'kpi' && 'KPI') ||
					(values?.locked === 'reward' && 'thưởng') ||
					(values?.locked === 'timeResearch' && 'giờ nghiên cứu')
				}`}
				color="primary"
				style={{ fontSize: 15, float: 'right', marginRight: 16 }}
			/>
		);
	};

	const handleSendFeedback = () => {
		props.history.push({
			pathname: ROUTER.NOTIFICATION,
			state: {
				sendTo: 'personal',
				title: 'Thông báo về sai sót thông tin trong CTKH',
				chosenProfiles: profileList.reduce((accumulator, currentValue) => {
					accumulator.push(currentValue.profileId);
					return accumulator;
				}, []),
				data: updating,
				dataType: 'solution'
			}
		});
	};

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth={values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 'xl' : 'md'}
			open={updating !== null}
			onClose={() => {
				setUpdating(null);
				setDisabled(true);
			}}
		>
			<DialogTitle>
				{disabled ? 'Chi tiết' : 'Cập nhật'}
				{renderLockButton()}
			</DialogTitle>
			<DialogContent style={{ display: 'flex', overflowX: 'hidden' }}>
				<div
					style={{
						display: 'flex',
						paddingRight: 16,
						alignItems: 'flex-start',
						minWidth:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? '50%' : '100%',
						maxWidth:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 960 : '100%'
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={9}>
							<TextField
								fullWidth
								disabled={disabled}
								size="small"
								variant="outlined"
								label="Tên giải pháp"
								value={values?.title || ''}
								onChange={(event) => handleChangeForm('title', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								fullWidth
								disabled={disabled}
								size="small"
								type="number"
								variant="outlined"
								label="Số lượng đồng sở hữu"
								value={values?.numOfOwner || ''}
								onChange={(event) => handleChangeForm('numOfOwner', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											values={values?.isPhenikaa}
											onChange={(event) => {
												handleChangeForm('isPhenikaa', event.target.checked);
											}}
										/>
									}
									label="Trường Đại học Phenikaa là Cơ quan chủ trì"
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<TextField
								rows={2}
								fullWidth
								disabled={disabled}
								multiline
								size="small"
								variant="outlined"
								label="Nhóm tác giả"
								value={values?.authorTeam || ''}
								onChange={(event) => handleChangeForm('authorTeam', event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								size="small"
								type="number"
								variant="outlined"
								label="Số lượng thành viên tham gia"
								value={values?.numOfMember || ''}
								onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={6}>
							<KPTimePicker
								fullWidth
								disabled={disabled}
								label="Ngày cấp bằng"
								value={values?.issueAt || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('issueAt', newValue)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								disabled={disabled}
								size="small"
								label="Cơ sở cấp"
								variant="outlined"
								value={values?.issueBy || ''}
								onChange={(event) => handleChangeForm('issueBy', event.target.value)}
							/>
						</Grid>
						<Grid item xs={4}>
							<KPTimePicker
								fullWidth
								disabled={disabled}
								label="Ngày nộp đơn"
								value={values?.submissionDay || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('submissionDay', newValue)}
							/>
						</Grid>
						<Grid item xs={4}>
							<KPTimePicker
								fullWidth
								disabled={disabled}
								label="Ngày chấp nhận đơn"
								value={values?.acceptedDay || 'dd/mm/yyyy'}
								setValue={(newValue) => handleChangeForm('acceptedDay', newValue)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								disabled={disabled}
								size="small"
								label="Số (Identification number)"
								variant="outlined"
								value={values?.identificationNumber || ''}
								onChange={(event) => handleChangeForm('identificationNumber', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								disabled={disabled}
								size="small"
								label="Số lượng trang"
								variant="outlined"
								value={values?.pageNumber || ''}
								onChange={(event) => handleChangeForm('pageNumber', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								disabled={disabled}
								size="small"
								type="number"
								label="Điểm GS-PGS"
								variant="outlined"
								value={values?.score?.toString() || ''}
								onChange={(event) => handleChangeForm('score', parseFloat(event.target.value))}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
								<InputLabel
									id="purpose-label"
									style={{ padding: '0 8px', backgroundColor: 'white' }}
								>
									Mục đích
								</InputLabel>
								<Select
									native
									fullWidth
									disabled={disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
									value={values?.purposeId ?? 'none'}
									onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value))}
								>
									{props.purposes.map((e) => (
										<option key={e.id} value={e.id}>
											{e.description}
										</option>
									))}
								</Select>
							</FormControl>
						</Grid>
						{values?.purposeId === 5 && (
							<>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										size="small"
										value={timeResearchYear}
										disabled={disabled}
										SelectProps={{ native: true }}
										label="Năm học tính giờ nghiên cứu"
										variant="outlined"
										onChange={(event) => setTimeResearchYear(parseInt(event.target.value))}
									>
										{yearList.map((e) => (
											<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
												{e} - {e + 1}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item xs={9}>
									<TextField
										select
										fullWidth
										size="small"
										disabled={disabled}
										label="Nhiệm vụ tính giờ nghiên cứu"
										variant="outlined"
										value={values?.timeResearchDetailCode || null}
										onChange={(event) =>
											handleChangeForm('timeResearchDetailCode', event.target.value)
										}
										InputLabelProps={{
											shrink: !(
												values?.timeResearchDetailCode === undefined ||
												values?.timeResearchDetailCode === null
											)
										}}
										error={!values?.timeResearchDetailCode}
									>
										{timeResearchDetailList?.map((e) => (
											<MenuItem
												key={e.code}
												value={e.code}
											>{`${e.description} - ${e.value}(h)`}</MenuItem>
										))}
									</TextField>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Ghi chú"
								variant="outlined"
								value={values?.note || ''}
								disabled={disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
								multiline
								rows={3}
								onChange={(event) => handleChangeForm('note', event.target.value)}
							/>
						</Grid>

						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>
						<Grid item xs={2}>
							<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
								Thành viên
							</Typography>
						</Grid>
						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>

						<Grid item xs={12}>
							{profileList.map((e, index) => (
								<Grid key={`list-${index}`} container spacing={2}>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 9 : 6}>
										<Autocomplete
											disableClearable
											disabled={disabled}
											options={props.profileList}
											getOptionLabel={(option) => `${option.code} - ${option.name}`}
											value={props.profileList?.find((profile) => profile.id === e.profileId)}
											onChange={(event, profile) => {
												if (profileList[index]?.profileId === values?.profileIdForKpi)
													handleChangeForm('profileIdForKpi', null);
												handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
											}}
											renderInput={(params) => (
												<TextField {...params} size="small" label="Thành viên" variant="outlined" />
											)}
										/>
									</Grid>
									{values?.purposeId === 1 && (
										<Grid item xs={3}>
											<FormControl
												required
												fullWidth
												disabled={disabled}
												error={!values?.profileIdForKpi}
											>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={
														disabled || values?.locked === 'kpi' || values?.locked === 'reward'
													}
													startIcon={
														typeof values?.profileIdForKpi === 'number' &&
														values?.profileIdForKpi === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														handleChangeForm(
															'profileIdForKpi',
															values?.profileIdForKpi !== e.profileId ? parseInt(e.profileId) : null
														);
													}}
												>
													Tính KPI
												</Button>
												{!values?.profileIdForKpi && !disabled && (
													<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									{values?.purposeId === 99999999 && (
										<Grid item xs={3}>
											<FormControl
												fullWidth
												disabled={disabled}
												error={!values?.profileIdForReward}
											>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={
														disabled || values?.locked === 'kpi' || values?.locked === 'reward'
													}
													startIcon={
														typeof values?.profileIdForReward === 'number' &&
														values?.profileIdForReward === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														handleChangeForm(
															'profileIdForReward',
															values?.profileIdForReward !== e.profileId
																? parseInt(e.profileId)
																: null
														);
													}}
												>
													Tính thưởng
												</Button>
												{!values?.profileIdForReward && !disabled && (
													<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									<Grid item xs={3}>
										<Button
											fullWidth
											disabled={disabled}
											color="secondary"
											variant="contained"
											style={{ height: 40 }}
											onClick={() => handleRemove(index)}
										>
											Xoá
										</Button>
									</Grid>
								</Grid>
							))}
						</Grid>

						<Grid item xs={12}>
							<Button fullWidth disabled={disabled} variant="outlined" onClick={handleInsert}>
								Thêm thành viên
							</Button>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						flexDirection: 'column',
						display:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 'block' : 'none'
					}}
				>
					<EmploymentContractTable
						contract={detail}
						disabled={disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
						setDetail={setDetail}
						contractList={contractList}
						kpiIdForKpi={values?.kpiIdForKpi}
						values={values}
						setValues={setValues}
						contractIdForKpi={values?.contractIdForKpi}
						handleChangeForm={handleChangeForm}
					/>
					<EmploymentContractDetailTable
						disabled={disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
						kpi={detail}
					/>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<Grid container justifyContent={'space-between'}>
					<div>
						<Button
							disabled={disabled}
							variant="outlined"
							color="primary"
							onClick={handleOpenUploadModal}
							style={{ marginRight: 4 }}
						>
							Upload file minh chứng
						</Button>
						<Button variant="contained" color="primary" onClick={handleOpenPreviewModal}>
							Preview file minh chứng
						</Button>
					</div>
					<div>
						{props.adminTab && (
							<Button variant="outlined" onClick={handleSendFeedback}>
								Gửi feedback
							</Button>
						)}
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleClose}
							style={{ margin: '0 4px' }}
						>
							Huỷ
						</Button>
						<Tooltip
							disableHoverListener={props.role === 'sudo-admin'}
							title="Liên hệ phòng KHCN để đề xuất chỉnh sửa CTKH"
						>
							<span>
								<Button
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									disabled={
										(!disabled &&
											((values?.purposeId === 1 &&
												!(values?.profileIdForKpi && values?.contractIdForKpi)) ||
												(values?.purposeId === 99999999 && !values?.profileIdForReward) ||
												(values?.purposeId === 5 && !values?.timeResearchDetailCode))) ||
										values?.locked ||
										(values?.accepted && props.role !== 'sudo-admin')
									}
								>
									{disabled ? 'Chỉnh sửa' : 'Xác nhận'}
								</Button>
							</span>
						</Tooltip>
					</div>
				</Grid>
			</DialogActions>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
			/>
			<FilePreviewModal
				open={previewModalOpen}
				handleClose={handleClosePreviewModal}
				url={values?.fileUrl}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	history: select(state, 'homeReducer', 'history'),
	profile: select(state, 'profileReducer', 'detail'),
	isFetchingTimeResearch: select(state, 'timeResearchInformationReducer', 'isFetching'),
	isFetchingEmploymentContract: select(state, 'employmentContractReducer', 'isFetching'),
	profileList: select(state, 'profileReducer', 'list'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	employmentContractDetail: select(state, 'employmentContractReducer', 'detail'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	isUploading: select(state, 'fileReducer', 'isFetching'),
	fileUrl: select(state, 'fileReducer', 'fileUrl')
});

const mapDispatchToProps = (dispatch) => ({
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	updateSolution: (id, payload) => dispatch(updateSolution(id, payload)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	getEmploymentContractById: (id) => dispatch(getEmploymentContractById(id)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(SolutionUpdateModal));
