import React, { useState } from 'react';
import { Card, Grid } from '@material-ui/core';

import InstitutionalProjectTable from '../../containers/InstitutionalProject/InstitutionalProjectTable';
import InstitutionalProjectButton from '../../containers/InstitutionalProject/InstitutionalProjectButton';

const [height, minHeight, maxHeight] = Array(3).fill('calc(100vh - 108px)');

function InstitutionalProject(props) {
	const [filterObj, setFilterObj] = useState(null);
	// const [year, setYear] = useState(new Date().getFullYear());
	const [year, setYear] = useState(2023);
	const [chosenDepartmentId, setChosenDepartmentId] = useState(0);

	return (
		<Card style={{ padding: '8px 16px', height, minHeight, maxHeight }}>
			<Grid container spacing={1}>
				<InstitutionalProjectButton
					year={year}
					type={props.type}
					setYear={setYear}
					setFilterObj={setFilterObj}
					chosenDepartmentId={chosenDepartmentId}
					setChosenDepartmentId={setChosenDepartmentId}
				/>
				<InstitutionalProjectTable year={year} type={props.type} filterObj={filterObj} />
			</Grid>
		</Card>
	);
}

export default InstitutionalProject;
