import React from 'react';
import { GetApp } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import styles from './index.module.css';
import { useSelector } from 'react-redux';
import select from '../../../../utils/select';
import { exportFile } from '../../utils/exportFile';
import { AcademicRankAbbrv, DegreeAbbrv } from '../../../../utils/abbreviation';

function DownloadButton({ type, name, generate }) {
	return (
		<IconButton size="small" color="primary" title="Tải xuống" disabled={!name} onClick={generate}>
			<GetApp size="small" />
		</IconButton>
	);
}

const sortFunc = (a, b) => {
	if (a.researchRole === 'main') return -1;
	if (b.researchRole === 'main') return 1;
	return a.id - b.id;
};

function ExportForm(props) {
	const list = useSelector((state) => select(state, 'internalResearchReducer', 'list')) || [];

	const generate = (type) => {
		switch (type) {
			case 'list':
				const columns = [
					{
						key: 'researchCode',
						title: { richText: [{ font: { bold: true }, text: 'Mã đề tài' }] },
						width: 10
					},
					{
						key: 'vietnameseName',
						title: { richText: [{ font: { bold: true }, text: 'Tên đề tài' }] },
						width: 50
					},
					{
						key: 'participantList',
						title: { richText: [{ font: { bold: true }, text: 'Cán bộ tham gia' }] },
						width: 30
					},
					{
						key: 'expectedResult',
						title: { richText: [{ font: { bold: true }, text: 'Dự kiến kết quả nghiên cứu' }] },
						width: 30
					},
					{
						key: 'cost',
						title: {
							richText: [{ font: { bold: true }, text: 'Dự toán kinh phí ' }, { text: '(đồng)' }]
						},
						width: 25
					},
					{
						key: 'note',
						title: { richText: [{ font: { bold: true }, text: 'Ghi chú' }] },
						width: 20
					}
				];
				const dataSource = list.map((e) => ({
					...e,
					participantList: {
						richText: e.participants.sort(sortFunc).map((p, id) => ({
							font: { bold: p.researchRole === 'main' },
							text: `${id > 0 ? '\n' : ''}${AcademicRankAbbrv(p?.academicRank)}${DegreeAbbrv(p?.degree)}${p?.name}`
						}))
					}
				}));
				exportFile('DANH SÁCH HỒ SƠ', columns, dataSource);
				break;
			default:
				return;
		}
	};

	return (
		<table style={{ borderCollapse: 'collapse', width: '100%' }}>
			<thead style={{ borderBottom: 'solid 1px #e0e0e0' }}>
				<tr className={styles.head}>
					<th style={{ textAlign: 'start', width: 'calc(100% - 490px)' }}>Văn bản xuất</th>
					<th style={{ textAlign: 'start', width: 150 }}>Tác vụ</th>
				</tr>
			</thead>
			<tbody>
				<tr className={styles.row}>
					<td>DANH SÁCH HỒ SƠ</td>
					<td>
						<DownloadButton type="list" name="DANH SÁCH HỒ SƠ" generate={() => generate('list')} />
					</td>
				</tr>
			</tbody>
		</table>
	);
}

export default ExportForm;
