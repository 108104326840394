import React from 'react';
import ReactExport from 'react-export-excel';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const multiDataSet = {
	columns: [
		{ value: 'Name', widthPx: 50 }, // width in pixels
		{ value: 'Salary', widthCh: 20 }, // width in charachters
		{ value: 'Sex', widthPx: 60, widthCh: 20 } // will check for width in pixels first
	],
	data: [
		['Johnson', 30000, 'Male'],
		['Monika', 355000, 'Female'],
		['Konstantina', 20000, 'Female'],
		['John', 250000, 'Male'],
		['Josef', 450500, 'Male']
	]
};

const convertData = (originalData) => {
	var newData = [];
	originalData.forEach((e) => {
		newData.push({
			name: e?.profile?.name || '',
			code: e?.profile?.code || '',
			birth: e?.profile?.birth?.split('T')[0] || '',
			gender: e?.profile?.gender || '',
			phone: e?.profile?.phone || '',
			email: e?.profile?.mail || '',
			duration: e?.contract?.duration || '',
			startAt: e?.contract?.startAt?.split('T')[0] || '',
			finishAt: e?.contract?.finishAt?.split('T')[0] || '',
			active: e?.contract?.active || '',
			kpiCompleted: e?.contract?.kpiCompleted || ''
		});
	});
	return newData;
};

export default class ExportExcel extends React.Component {
	render() {
		return (
			<>
				<ExcelFile
					element={
						<Button
							color="primary"
							variant="outlined"
							startIcon={<GetApp />}
							style={{ left: 16, bottom: 44 }}
						>
							Xuất file excel
						</Button>
					}
					filename={this.props.filename}
				>
					{this.props.data.map((sheetData) => (
						<ExcelSheet data={convertData(sheetData)} name="Danh sách báo cáo">
							<ExcelColumn label="Họ và tên" value="name" />
							<ExcelColumn label="Mã PU" value="code" />
							<ExcelColumn label="Ngày sinh" value="birth" />
							<ExcelColumn
								label="Giới tính"
								value="gender"
								value={(col) => {
									if (col.gender === 'male') return 'Nam';
									if (col.gender === 'female') return 'Nữ';
									return '';
								}}
							/>
							<ExcelColumn label="Số điện thoại" value="phone" />
							<ExcelColumn label="Email" value="email" />
							<ExcelColumn
								label="Thời hạn hợp đồng"
								value={(col) => {
									if (col.duration === 1000 || col.duration === '1000') return 'Vô hạn';
									return `${col.duration} năm`;
								}}
							/>
							<ExcelColumn label="Bắt đầu" value="startAt" />
							<ExcelColumn label="Kết thúc" value="finishAt" />
							<ExcelColumn
								label="Hợp đồng còn hiệu lực"
								value={(col) => (col.active ? 'Còn hiệu lực' : 'Hết hiệu lực')}
							/>
							<ExcelColumn
								label="Đã hoàn thành KPI"
								value={(col) => (col.kpiCompleted ? 'Hoàn thành' : 'Chưa hoàn thành')}
							/>
						</ExcelSheet>
					))}
				</ExcelFile>
			</>
		);
	}
}
