import React from 'react';
import { connect } from 'react-redux';
import { Button, Popover } from '@material-ui/core';

import toJs from '../../../hoc/toJS';

import { deleteJournalDomestic } from '../../../reducer/journalDomestic/action';

function JournalDomesticDeleteModal(props) {
	const { deleting, anchorEl, setAnchorEl, deleteJournalDomestic } = props;

	const handleSubmit = () => {
		setAnchorEl(null);
		deleteJournalDomestic(deleting);
	};

	return (
		<Popover
			open={anchorEl !== null}
			anchorEl={anchorEl}
			onClose={() => setAnchorEl(null)}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<div style={{ padding: 8 }}>
				<div>Xác nhận xoá?</div>
				<div style={{ float: 'right', padding: '8px 0' }}>
					<Button
						size="small"
						variant="outlined"
						color="secondary"
						onClick={() => setAnchorEl(null)}
						style={{ marginRight: 4 }}
					>
						Huỷ
					</Button>
					<Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
						Xác nhận
					</Button>
				</div>
			</div>
		</Popover>
	);
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	deleteJournalDomestic: (id) => dispatch(deleteJournalDomestic(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalDomesticDeleteModal));
