import { createContext, useState } from 'react';

/**
 *
 * @typedef {any} User
 * @typedef {any} Profile
 * @typedef {Object} HumanResourceInsertDataType
 * @property {User} HumanResourceInsertDataType.user
 * @property {Profile} HumanResourceInsertDataType.profile
 * @property {number} HumanResourceInsertDataType.currentStep
 * @property {(user: User | null) => any} HumanResourceInsertDataType.setUser
 * @property {(profile: Profile | null) => any} HumanResourceInsertDataType.setProfile
 * @property {React.Dispatch<React.SetStateAction<number>>} HumanResourceInsertDataType.setCurrentStep
 */

/**
 * @type {React.Context<HumanResourceInsertDataType>}
 */
export const HumanResourceInsertContext = createContext();

/**
 *
 * @param {React.PropsWithChildren<{}>} props
 */
export const HumanResourceInsertProvider = (props) => {
	const [user, setUser] = useState(null);
	const [profile, setProfile] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);

	return (
		<HumanResourceInsertContext.Provider
			value={{ user, profile, setUser, setProfile, currentStep, setCurrentStep }}
		>
			{props.children}
		</HumanResourceInsertContext.Provider>
	);
};
