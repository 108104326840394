import { fromJS } from 'immutable';
import { filter } from 'jszip';

import TYPE from '../../constants/type';

const initialState = fromJS({
	council: []
});

export default function councilReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.COUNCIL.GET_ALL_COUNCIL_LOADING:
		case TYPE.COUNCIL.FILTER_COUNCIL_LOADING:
		case TYPE.COUNCIL.INSERT_COUNCIL_LOADING:
		case TYPE.COUNCIL.UPDATE_COUNCIL_LOADING:
		case TYPE.COUNCIL.DELETE_COUNCIL_LOADING:
		case TYPE.COUNCIL.INSERT_INTERNAL_COUNCIL_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.COUNCIL.GET_ALL_COUNCIL_FAILURE:
		case TYPE.COUNCIL.FILTER_COUNCIL_FAILURE:
		case TYPE.COUNCIL.INSERT_COUNCIL_FAILURE:
		case TYPE.COUNCIL.UPDATE_COUNCIL_FAILURE:
		case TYPE.COUNCIL.DELETE_COUNCIL_FAILURE:
		case TYPE.COUNCIL.INSERT_INTERNAL_COUNCIL_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.COUNCIL.GET_ALL_COUNCIL_SUCCESS:
			return state.merge({
				council: [...action.payload],
				isFetching: false
			});

		case TYPE.COUNCIL.INSERT_INTERNAL_COUNCIL_SUCCESS:
			return state.merge({
				isFetching: false
			});

		case TYPE.COUNCIL.INSERT_COUNCIL_SUCCESS:
			return state.merge({
				council: [...state.get('council'), { ...action.payload }],
				isFetching: false
			});

		case TYPE.COUNCIL.UPDATE_COUNCIL_SUCCESS: {
			const temp = [...state.get('council')];
			const index = temp.findIndex((e) => e.id === action.payload.id);
			temp.splice(index, 1, { ...action.payload });
			return state.merge({
				council: temp,
				isFetching: false
			});
		}

		case TYPE.COUNCIL.DELETE_COUNCIL_SUCCESS:
			return state.merge({
				council: [...state.get('council')].filter((e) => e.id !== action.id),
				isFetching: false
			});

		case TYPE.COUNCIL.FILTER_COUNCIL_SUCCESS:
			return state.merge({
				council: [...action.payload],
				isFetching: false
			});

		default:
			return state;
	}
}
