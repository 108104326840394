import React from 'react';
import {
	Edit,
	Delete,
	TurnedIn,
	GroupAdd,
	Equalizer,
	Visibility,
	CheckCircle,
	MoreHoriz
} from '@material-ui/icons';
import { Button, Tooltip, MenuItem, Menu } from '@material-ui/core';
import { ReactComponent as UserCheck } from '../../../assets/font-awesome/user-check-solid.svg';

function ActionButton(props) {
	const { actions, row, setOpen } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpenMoreButtons = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMoreMenu = () => {
		setAnchorEl(null);
	};

	const render = (type, index) => {
		let tooltipTitle,
			icon,
			clName,
			color = 'primary';

		switch (type) {
			case 'detail':
			case 'detailCouncil':
				tooltipTitle = 'Chi tiết';
				icon = <Visibility fontSize="small" />;
				break;
			case 'edit':
				tooltipTitle = 'Chỉnh sửa';
				icon = <Edit fontSize="small" />;
				break;
			case 'disabled':
				tooltipTitle = 'disabled';
				icon = <Visibility style={{ color: '#ffffff00' }} fontSize="small" />;
				break;
			case 'delete':
				clName = 'error-button';
				tooltipTitle = 'Xóa';
				icon = <Delete fontSize="small" />;
				break;
			case 'acceptInfo':
				tooltipTitle = 'Duyệt đề tài';
				icon = <CheckCircle fontSize="small" />;
				break;
			case 'insertMember':
				clName = 'success-button';
				tooltipTitle = 'Thêm thành viên';
				icon = <GroupAdd style={{ fontSize: 22 }} />;
				break;
			case 'acceptMember':
				clName = 'success-button';
				tooltipTitle = 'Duyệt thành viên';
				icon = <UserCheck style={{ width: 18, height: 18, fill: '#fff' }} />;
				break;
			case 'acceptAll':
				tooltipTitle = 'Duyệt thực hiện đề tài';
				icon = <CheckCircle style={{ fontSize: 18 }} />;
				break;
			case 'insertCouncil':
				clName = 'success-button';
				tooltipTitle = 'Thêm hội đồng nghiệm thu';
				icon = <GroupAdd fontSize="small" />;
				break;
			case 'councilDetail':
				color = 'secondary';
				tooltipTitle = 'Xem lại hội đồng nghiệm thu';
				icon = <GroupAdd fontSize="small" />;
				break;
			case 'report':
				clName = 'success-button';
				tooltipTitle = 'Báo cáo tiến độ';
				icon = <Equalizer fontSize="small" />;
				break;
			case 'reportStop':
				clName = 'error-button';
				tooltipTitle = 'Chi tiết đơn xin dừng';
				icon = <Equalizer fontSize="small" />;
				break;
			case 'reportAccept':
				clName = 'success-button';
				tooltipTitle = 'Duyệt báo cáo tiến độ';
				icon = <Equalizer fontSize="small" />;
				break;
			case 'stopDetail':
				color = 'secondary';
				tooltipTitle = 'Chi tiết đơn xin dừng';
				icon = <Equalizer fontSize="small" />;
				break;
			case 'stopAccept':
				clName = 'success-button';
				tooltipTitle = 'Duyệt báo cáo tiến độ';
				icon = <Equalizer fontSize="small" />;
				break;
			case 'reportDetail':
				color = 'secondary';
				tooltipTitle = 'Chi tiết báo cáo tiến độ';
				icon = <Equalizer fontSize="small" />;
				break;
			case 'submit':
				clName = 'success-button';
				tooltipTitle = 'Nộp báo cáo';
				icon = <TurnedIn style={{ fontSize: 20 }} />;
				break;
			case 'submitDetailEdit':
				clName = 'success-button';
				tooltipTitle = 'Nộp báo cáo';
				icon = <TurnedIn style={{ fontSize: 20 }} />;
				break;
			case 'defaultDetail':
			case 'defaultDetailNotCouncil':
				color = 'secondary';
				tooltipTitle = 'Chi tiết báo cáo tổng kết';
				icon = <TurnedIn style={{ fontSize: 20 }} />;
				break;
			case 'submitAccept':
			case 'submitAcceptAgain':
				clName = 'success-button';
				tooltipTitle = 'Nghiệm thu đề tài';
				icon = <TurnedIn style={{ fontSize: 20 }} />;
				break;
			case 'submitDetail':
				color = 'secondary';
				tooltipTitle = 'Xem lại báo cáo tổng kết';
				icon = <TurnedIn style={{ fontSize: 20 }} />;
				break;
			default:
				break;
		}

		const renderButton = () => (
			<Button
				color={color}
				variant="contained"
				className={clName}
				disabled={type === 'disabled'}
				style={{
					width: 48,
					minWidth: 48,
					height: 32,
					padding: 0,
					marginLeft: index === 0 ? 0 : 8
				}}
				onClick={() => {
					setOpen({ [type]: true });
					if (type === 'detail' || type === 'reportDetail' || type === 'reportAccept') {
						const parent = row?.parentResearch;
						props.setDetail({
							...row,
							nameOld: parent?.name,
							nameChange: parent?.name !== row?.name,
							researchTargetOld: parent?.researchTarget,
							researchTargetChange: parent?.researchTarget !== row?.researchTarget,
							expectedResultOld: parent?.expectedResult,
							expectedResultChange: parent?.expectedResult !== row?.expectedResult,
							expectedCompetitionOld: parent?.expectedCompetition,
							expectedCompetitionChange: parent?.expectedCompetition !== row?.expectedCompetition,
							instructors: [
								...(row?.instructors?.map((instructor) => ({
									...instructor,
									isNew: !parent?.instructors?.find((e) => e.name === instructor.name)
								})) || []),
								...(parent?.instructors
									?.filter(
										(instructor) => !row?.instructors?.find((e) => e.name === instructor.name)
									)
									?.map((e) => ({ ...e, isStop: true })) || [])
							],
							researchMembers: row?.researchMembers?.map((member) => ({
								...member,
								isStop: member?.leaveReason !== '',
								isNew: !parent?.researchMembers?.find((e) => e.name === member.name)
							}))
						});
					} else if (type === 'detail' && row.status.find((e) => e.value === 'report_submitted')) {
						const parent = row.parentResearch;
						props.setDetail({ ...parent, status: row.status });
					} else {
						props.setDetail({ ...row });
					}
				}}
			>
				{icon}
			</Button>
		);

		const renderButtonCaseMore = (typeItem) => {
			switch (typeItem) {
				case 'editMember':
				case 'edit':
					tooltipTitle = 'Chỉnh sửa';
					icon = <Edit fontSize="small" />;
					break;
				case 'delete':
					clName = 'error-button';
					tooltipTitle = 'Xóa';
					icon = <Delete fontSize="small" />;
					break;
				default:
					break;
			}
			return (
				<Button
					color={color}
					variant="contained"
					className={clName}
					disabled={typeItem === 'disabled'}
					style={{
						width: 48,
						minWidth: 48,
						height: 32,
						padding: 0,
						marginLeft: index === 0 ? 0 : 8
					}}
					onClick={() => {
						setOpen({ [typeItem]: true });
						props.setDetail({ ...row });
					}}
				>
					{icon}
				</Button>
			);
		};

		const arrayButtonsRender = () => {
			return (
				<>
					<Button
						style={{
							width: 48,
							minWidth: 48,
							height: 32,
							padding: 0,
							marginLeft: index === 0 ? 0 : 8
						}}
						variant="contained"
						onClick={handleOpenMoreButtons}
					>
						<MoreHoriz />
					</Button>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleCloseMoreMenu}
					>
						{type.map((typeItem) => {
							return (
								<MenuItem onClick={handleCloseMoreMenu}>{renderButtonCaseMore(typeItem)}</MenuItem>
							);
						})}
					</Menu>
				</>
			);
		};

		const normalButtonRender = () => {
			return (
				<>
					{type === 'disabled' ? (
						renderButton()
					) : (
						<Tooltip title={tooltipTitle}>{renderButton()}</Tooltip>
					)}
				</>
			);
		};

		return <>{typeof type === 'string' ? normalButtonRender() : arrayButtonsRender()}</>;
	};

	return (
		<>
			{actions?.map((e, id) => (
				<React.Fragment key={`button-${id}`}>{render(e, id)}</React.Fragment>
			))}
		</>
	);
}

export default ActionButton;
