import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useField } from 'formik';
import dayjs from 'dayjs';

import GridCellExpand from './GridCellExpand';

const useStyles = makeStyles((theme) => {
	return {
		tableParent: {
			height: 400
		},
		idCell: {
			textAlign: 'center'
		},
		table: {
			'& .MuiDataGrid-colCellTitle': {
				fontWeight: 'bold'
			}
		},
		btnSelect: {
			margin: theme.spacing(0, 0, 0, 1)
		}
	};
});

function ResearchProjectModal(props) {
	const { open, handleClose, researchProjects } = props;

	const classes = useStyles();

	/**
	 * @type {import('@material-ui/data-grid').GridColDef[]}
	 */
	const columns = [
		{
			field: 'rowId',
			headerName: 'TT',
			headerAlign: 'center',
			width: 80,
			disableColumnMenu: true
		},
		{
			field: 'title',
			headerName: 'Tên đề tài/dự án',
			headerAlign: 'center',
			width: 200,
			disableColumnMenu: true,
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		},
		{
			field: 'sponsor',
			headerName: 'Cơ quan tài trợ',
			headerAlign: 'center',
			width: 200,
			disableColumnMenu: true,
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		},
		{
			field: 'duration',
			headerName: 'Thời gian thực hiện',
			headerAlign: 'center',
			width: 200,
			disableColumnMenu: true,
			valueGetter: (params) => {
				const startYear = dayjs(params.row.startAt).year();
				const finishYear = dayjs(params.row.finishAt).year();
				if (startYear === finishYear) {
					return `${finishYear}`;
				}
				return `${startYear} - ${finishYear}`;
			},
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		},
		{
			field: 'roleInProject',
			headerName: 'Vai trò',
			headerAlign: 'center',
			width: 150,
			valueGetter: (params) => {
				if (params.row.roleInProject === 'main') {
					return 'Chủ nhiệm đề tài';
				} else {
					if (params.row.roleInProject === 'member') {
						return 'Đồng chủ nhiệm đề tài';
					}
					return 'Khác';
				}
			},
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		}
	];

	const rows = researchProjects.map((researchProjectElement, researchProjectIndex) => {
		return { ...researchProjectElement.researchProject, rowId: researchProjectIndex + 1 };
	});

	const [researchProjectsInput, , researchProjectsHelper] = useField(
		'scientificResearchs.researchProjects'
	);
	const [selectedResearchProjectIds, setSelectedResearchProjectIds] = useState([]);

	useEffect(() => {
		if (researchProjectsInput.value.length > 0 && selectedResearchProjectIds.length === 0) {
			researchProjectsHelper.setValue(
				researchProjectsInput.value.map((selectedResearchProjectElement) => {
					const { id, title, startAt, finishAt, sponsor, roleInProject } =
						selectedResearchProjectElement;
					const startYear = dayjs(startAt).year();
					const finishYear = dayjs(finishAt).year();
					const duration =
						startYear === finishYear ? `${finishYear}` : `${startYear} - ${finishYear}`;
					const role = roleInProject === 'main' ? 'Chủ nhiệm đề tài' : 'Đồng chủ nhiệm đề tài';

					return {
						id,
						name: title,
						duration: duration,
						sponsor,
						role
					};
				})
			);
			setSelectedResearchProjectIds(
				researchProjectsInput.value.map((researchProjectElement) => researchProjectElement.id)
			);
		}
	}, [researchProjectsInput]);

	/**
	 * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
	 */
	const handleSelectionModelChange = (params) => {
		const selectedResearchProjects = researchProjects.filter((researchProjectElement) => {
			if (params.includes(researchProjectElement.researchProject.id)) {
				return true;
			}
			return false;
		});
		researchProjectsHelper.setValue(
			selectedResearchProjects.map((selectedResearchProjectElement) => {
				const { id, title, startAt, finishAt, sponsor, roleInProject } =
					selectedResearchProjectElement.researchProject;
				const startYear = dayjs(startAt).year();
				const finishYear = dayjs(finishAt).year();
				const duration =
					startYear === finishYear ? `${finishYear}` : `${startYear} - ${finishYear}`;
				const role = roleInProject === 'main' ? 'Chủ nhiệm đề tài' : 'Đồng chủ nhiệm đề tài';

				return {
					id,
					name: title,
					duration: duration,
					sponsor,
					role
				};
			})
		);
		setSelectedResearchProjectIds(params);
	};

	const handleSelectResearchProjects = () => {
		handleClose(false);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
			<DialogTitle>Các đề tài/dự án nghiên cứu khoa học</DialogTitle>
			<DialogContent>
				<Box className={classes.tableParent}>
					<DataGrid
						className={classes.table}
						columns={columns}
						rows={rows}
						checkboxSelection={true}
						selectionModel={selectedResearchProjectIds}
						onSelectionModelChange={handleSelectionModelChange}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box margin={2}>
					<Button variant="outlined" color="primary" onClick={() => handleClose(false)}>
						Hủy
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.btnSelect}
						onClick={handleSelectResearchProjects}
					>
						Chọn
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}

export default ResearchProjectModal;
