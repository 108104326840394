import { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Divider,
	Button,
	Typography,
	Box,
	MenuItem,
	IconButton,
	CircularProgress,
	TextField
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Replay } from '@material-ui/icons';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';

import {
	getAllVisibleDepartmentsByProfileId,
	getAllProfiles,
	getProfileByDepartmentId
} from '../../../reducer/department/action';

import DepartmentInsertModal from '../DepartmentInsertModal';
import { DepartmentContext } from '../DepartmentContext';
import DepartmentInsertMemberModal from '../DepartmentInsertMemberModal';

const useStyles = makeStyles((theme) =>
	createStyles({
		divider: {
			margin: theme.spacing(1, 0)
		},
		title: {
			lineHeight: '36px'
		},
		btnReload: {
			marginRight: theme.spacing(1)
		},
		select: {
			display: 'flex'
		},
		rootSelect: {
			width: '90%',
			marginRight: 8
		}
	})
);

function DepartmentMemberButton(props) {
	const {
		getAllVisibleDepartmentsByProfileId,
		fetching,
		getAllProfiles,
		getProfileByDepartmentId,
		userId,
		role,
		detail,
		departmentProfiles
	} = props;

	const classes = useStyles();

	const { selectedDepartmentId, setSelectedDepartmentId, setInsertingDepartmentMember } =
		useContext(DepartmentContext);

	const handleReloadDepartments = () => {
		if (detail) {
			getAllVisibleDepartmentsByProfileId(detail.id, role);
		}
	};

	const handleReloadProfiles = () => {
		if (selectedDepartmentId === 0) {
			if (role === 'sudo-admin') {
				getAllProfiles();
			}
		} else {
			getProfileByDepartmentId(selectedDepartmentId);
		}
	};

	const handleOpenInsertingDepartmentMemberModal = () => {
		if (selectedDepartmentId !== 0) {
			setInsertingDepartmentMember(true);
		}
	};

	const isAllowedInsertingMember = () => {
		if (role === 'sudo-admin') {
			return true;
		}
		var isLeader = false;
		departmentProfiles.forEach((departmentProfile) => {
			if (
				departmentProfile.roleInDepartment === 'leader' ||
				departmentProfile.roleInDepartment === 'Leader'
			) {
				if (departmentProfile.userId === userId) {
					isLeader = true;
				}
			}
		});
		return isLeader;
	};

	useEffect(() => {
		if (detail) {
			getAllVisibleDepartmentsByProfileId(detail.id, role);
		}
	}, [getAllVisibleDepartmentsByProfileId, detail, role]);

	useEffect(() => {
		if (selectedDepartmentId === 0) {
			if (role === 'sudo-admin') {
				getAllProfiles();
			}
		} else {
			getProfileByDepartmentId(selectedDepartmentId);
		}
	}, [selectedDepartmentId, getAllProfiles, getProfileByDepartmentId, role]);

	// useEffect(() => {
	//   console.log("reload");
	//   if (props.visibleDepartments?.length > 0)
	//     setSelectedDepartmentId(props.visibleDepartments[0]?.id)
	// }, [props.visibleDepartments]);

	return (
		<>
			<Grid container alignItems="center">
				<Grid item xs={4}>
					<Typography variant="h5" className={classes.title}>
						Thành viên
					</Typography>
				</Grid>
				<Grid item xs={4} className={classes.select}>
					<TextField
						classes={{ root: classes.rootSelect }}
						select
						size="small"
						variant="outlined"
						SelectProps={{
							MenuProps: {
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'center'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'center'
								},
								getContentAnchorEl: null
							}
						}}
						InputProps={{ style: { display: 'flex', flexDirection: 'row' } }}
						value={selectedDepartmentId || props.visibleDepartments[0]?.id || 0}
						onChange={(e) => setSelectedDepartmentId(e.target.value)}
					>
						{(props.visibleDepartments ?? []).map((visibleDepartmentElement) => (
							<MenuItem key={visibleDepartmentElement.id} value={visibleDepartmentElement.id}>
								{visibleDepartmentElement.name}
							</MenuItem>
						))}
					</TextField>
					<Button variant="outlined" disabled={fetching} onClick={handleReloadDepartments}>
						{fetching ? <CircularProgress size={20} /> : <Replay />}
					</Button>
				</Grid>
				<Grid item xs={4}>
					<Box textAlign="right">
						<Button
							variant="outlined"
							color="primary"
							className={selectedDepartmentId !== 0 ? classes.btnReload : ''}
							startIcon={<Replay />}
							onClick={handleReloadProfiles}
						>
							Tải lại danh sách
						</Button>
						{selectedDepartmentId !== 0 && isAllowedInsertingMember() && (
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add />}
								onClick={handleOpenInsertingDepartmentMemberModal}
							>
								Thêm thành viên
							</Button>
						)}
					</Box>
				</Grid>
			</Grid>
			<DepartmentInsertModal />
			<DepartmentInsertMemberModal />
		</>
	);
}

const mapStateToProps = (state) => ({
	userId: select(state, 'userReducer', 'userId'),
	detail: select(state, 'profileReducer', 'detail'),
	role: select(state, 'userReducer', 'role'),
	departmentProfiles: select(state, 'departmentReducer', 'departmentProfiles'),
	visibleDepartments: select(state, 'departmentReducer', 'visibleDepartments'),
	fetching: select(state, 'departmentReducer', 'isFetching')
});
const mapDispatchToProps = (dispatch) => ({
	getAllVisibleDepartmentsByProfileId: (profileId, role) =>
		dispatch(getAllVisibleDepartmentsByProfileId(profileId, role)),
	getAllProfiles: () => dispatch(getAllProfiles()),
	getProfileByDepartmentId: (departmentId, role) =>
		dispatch(getProfileByDepartmentId(departmentId, role))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DepartmentMemberButton));
