import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

function ScientifiResearchCoreCollectionStatistic(props) {
	const [scientifiResearchData, setScientifiResearchData] = useState({
		Q1: 0,
		Q2: 0,
		Q3: 0,
		Q4: 0,
		mainQ1: 0,
		mainQ2: 0,
		mainQ3: 0,
		mainQ4: 0,
		Domestic: 0,
		mainDomestic: 0,
		Scopus: 0,
		mainScopus: 0,
		Other: 0,
		mainOther: 0,
		mainTotal: 0,
		Conference: 0,
		mainConference: 0
	});

	const {
		Q1,
		Q2,
		Q3,
		Q4,
		Scopus,
		Conference,
		mainQ1,
		mainQ2,
		mainQ3,
		mainQ4,
		mainScopus,
		mainConference
	} = scientifiResearchData;

	const pieOptions = {
		title: {
			// text: `Phân loại công bố thuộc danh mục ISI/Scopus ${props.chosenYear === 0? 'tính từ năm 2017':`năm ${props.chosenYear}`}`,
			display: true,
			fontSize: 13,
			fontColor: '#000000'
		},
		legend: { position: 'left' }
	};

	const pieOptionsMain = {
		title: {
			// text: `Phân loại công bố thuộc danh mục ISI/Scopus có tác giả chính ${props.chosenYear === 0? 'tính từ năm 2017':`năm ${props.chosenYear}`}`,
			display: true,
			fontSize: 13,
			fontColor: '#000000'
		},
		legend: { position: 'left' }
	};

	useEffect(() => {
		let Q1 = 0;
		let Q2 = 0;
		let Q3 = 0;
		let Q4 = 0;
		let Scopus = 0;
		let Domestic = 0;
		let Other = 0;
		let Conference = 0;
		if (Array.isArray(props.scientificResearchs)) {
			props.scientificResearchs.forEach((scientificResearchElement) => {
				if (scientificResearchElement?.scientificResearch?.accepted) {
					if (
						scientificResearchElement?.scientificResearch?.type !== 'scientificResearchJournal' &&
						scientificResearchElement?.scientificResearch?.type !==
							'scientificResearchJournalDomestic'
					) {
						if (
							scientificResearchElement?.conference?.rank &&
							scientificResearchElement?.conference?.rank !== ''
						)
							Conference += 1;
						else Other += 1;
					} else {
						if (
							scientificResearchElement?.scientificResearch?.type === 'scientificResearchJournal'
						) {
							if (scientificResearchElement?.journal?.coreCollection !== 'Scopus') {
								switch (scientificResearchElement?.journal?.sjrBestQuartile) {
									case 'Q1':
										Q1 += 1;
										break;
									case 'Q2':
										Q2 += 1;
										break;
									case 'Q3':
										Q3 += 1;
										break;
									case 'Q4':
										Q4 += 1;
										break;
									default:
										break;
								}
							} else {
								Scopus += 1;
							}
						} else if (scientificResearchElement?.journalDomestic !== null) {
							Domestic += 1;
						}
					}
				}
			});

			let mainScientificResearchs = props.scientificResearchs.filter((item) => {
				let isMain = false;
				item.scientificResearch.scientificResearchProfile.forEach((author) => {
					if (author.roleInPaper === 'main') isMain = true;
				});
				if (isMain) return item;
			});

			let mainQ1 = 0;
			let mainQ2 = 0;
			let mainQ3 = 0;
			let mainQ4 = 0;
			let mainScopus = 0;
			let mainDomestic = 0;
			let mainOther = 0;
			let mainConference = 0;
			if (Array.isArray(mainScientificResearchs)) {
				mainScientificResearchs.forEach((scientificResearchElement) => {
					if (scientificResearchElement?.scientificResearch?.accepted) {
						if (
							scientificResearchElement?.scientificResearch?.type !== 'scientificResearchJournal' &&
							scientificResearchElement?.scientificResearch?.type !==
								'scientificResearchJournalDomestic'
						) {
							if (
								scientificResearchElement?.conference?.rank &&
								scientificResearchElement?.conference?.rank !== ''
							)
								mainConference += 1;
							else mainOther += 1;
						} else {
							if (
								scientificResearchElement?.scientificResearch?.type === 'scientificResearchJournal'
							) {
								if (scientificResearchElement?.journal?.coreCollection !== 'Scopus') {
									switch (scientificResearchElement?.journal?.sjrBestQuartile) {
										case 'Q1':
											mainQ1 += 1;
											break;
										case 'Q2':
											mainQ2 += 1;
											break;
										case 'Q3':
											mainQ3 += 1;
											break;
										case 'Q4':
											mainQ4 += 1;
											break;
										default:
											break;
									}
								} else {
									mainScopus += 1;
								}
							} else if (scientificResearchElement?.journalDomestic !== null) {
								mainDomestic += 1;
							} else if (
								scientificResearchElement?.conference?.rank &&
								scientificResearchElement?.conference?.rank !== ''
							) {
								mainConference += 1;
							}
						}
					}
				});
			}

			setScientifiResearchData({
				Q1,
				Q2,
				Q3,
				Q4,
				Scopus,
				Domestic,
				Other,
				Conference,
				mainQ1,
				mainQ2,
				mainQ3,
				mainQ4,
				mainScopus,
				mainDomestic,
				mainOther,
				mainConference
				// mainTotal,
			});
		}
	}, [props.scientificResearchs]);

	const backgroundColor = [
		'#32936f', // green
		'#243474', // blue
		'#f0c808', // yellow
		'#81171b', // red
		'#f46423', // orange
		'#b5446e' // violet
	];

	const pieData = {
		labels: ['ISI-Q1', 'ISI-Q2', 'ISI-Q3', 'ISI-Q4', 'Scopus', 'Công bố hội thảo'],
		datasets: [
			{
				data: [Q1, Q2, Q3, Q4, Scopus, Conference],
				backgroundColor
			}
		]
	};

	const pieDataMain = {
		labels: ['ISI-Q1', 'ISI-Q2', 'ISI-Q3', 'ISI-Q4', 'Scopus', 'Công bố hội thảo'],
		datasets: [
			{
				data: [mainQ1, mainQ2, mainQ3, mainQ4, mainScopus, mainConference],
				backgroundColor
			}
		]
	};

	return (
		<Grid container style={{ height: '100%' }}>
			<Grid item xs={6} style={{ height: '100%' }}>
				<Card
					style={{
						height: '100%',
						width: 'calc(100% - 20px)',
						padding: '0 8px',
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{props.isFetching ? (
						<CircularProgress />
					) : (
						<>
							<Box height={24}>
								<b style={{ fontSize: 13, lineHeight: '24px' }}>
									{`Phân loại công bố thuộc danh mục ISI/Scopus ${props.chosenYear === 0 ? 'tính từ năm 2017' : `năm ${props.chosenYear}`}`}
								</b>
							</Box>
							<Box height="calc(100% - 48px)">
								<Pie data={pieData} options={pieOptions} />
							</Box>
							<Box style={{ textAlign: 'center', fontSize: 13, lineHeight: '24px' }}>
								Tổng số công bố: {Q1 + Q2 + Q3 + Q4 + Scopus + Conference}
							</Box>
						</>
					)}
				</Card>
			</Grid>
			<Grid item xs={6} style={{ height: '100%' }}>
				<Card
					style={{
						height: '100%',
						marginLeft: 4,
						width: 'calc(100% - 20px)',
						padding: '0 8px',
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{props.isFetching ? (
						<CircularProgress />
					) : (
						<>
							<Box height={24}>
								<b style={{ fontSize: 13 }}>
									{`Phân loại công bố thuộc danh mục ISI/Scopus có tác giả chính ${props.chosenYear === 0 ? 'tính từ năm 2017' : `năm ${props.chosenYear}`}`}
								</b>
							</Box>
							<Box height="calc(100% - 48px)">
								<Pie data={pieDataMain} options={pieOptionsMain} />
							</Box>
							<Box
								style={{ textAlign: 'center', fontSize: 13, letterSpacing: -1, lineHeight: '24px' }}
							>
								Tổng số công bố có tác giả chính ở Phenikaa:{' '}
								{mainQ1 + mainQ2 + mainQ3 + mainQ4 + mainScopus + mainConference}
							</Box>
						</>
					)}
				</Card>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = (state) => ({
	isFetching: select(state, 'homeReducer', 'isFetching'),
	scientificResearchs: select(state, 'homeReducer', 'dataScientificResearch')
});

const mapDispatchToProps = () => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(toJs(ScientifiResearchCoreCollectionStatistic));
