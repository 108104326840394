import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider, Typography, Box } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import EmploymentContractInsertModal from '../EmploymentContractInsertModal';
import { EmploymentContractInsertProvider } from '../EmploymentContractInsertModal/EmploymentContractInsertContext';

import { getAllProject } from '../../../reducer/project/action';
import {
	getAllEmploymentContract,
	getEmploymentContractByLeaderProfileId
} from '../../../reducer/employmentContract/action';

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			lineHeight: '36px'
		},
		btnReload: {
			margin: theme.spacing(0, 1, 0, 0)
		},
		divider: {
			margin: theme.spacing(1, 0)
		}
	})
);

function EmploymentContractButton(props) {
	const {
		getAllProject,
		getAllEmploymentContract,
		getEmploymentContractByLeaderProfileId,
		role,
		detail
	} = props;

	const classes = useStyles();

	const [inserting, setInserting] = useState(false);

	// Admin luon nhin dc het cac hop dong
	// useEffect(() => {
	//   if (detail) {
	//     getEmploymentContractByLeaderProfileId(detail.id);
	//   }
	// }, [
	//   getAllEmploymentContract,
	//   props.listEmploymentContract.length,
	//   detail,
	//   getEmploymentContractByLeaderProfileId,
	// ]);

	useEffect(() => {
		if (props.listProject.length === 0) getAllProject();
	}, [getAllProject, props.listProject.length]);

	const handleReload = () => getAllEmploymentContract();
	const handleInsert = () => setInserting(true);

	return (
		<EmploymentContractInsertProvider>
			<Grid container>
				<Grid item xs={6}>
					<Typography variant="h5" className={classes.title}>
						Hợp đồng nhân sự
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Box textAlign="right">
						<Button
							variant="outlined"
							startIcon={<Replay />}
							onClick={handleReload}
							className={role === 'sudo-admin' || role === 'humanresource' ? classes.btnReload : ''}
						>
							Tải lại
						</Button>
						{(role === 'sudo-admin' || role === 'humanresource') && (
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add />}
								onClick={handleInsert}
							>
								Thêm
							</Button>
						)}
					</Box>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<EmploymentContractInsertModal inserting={inserting} setInserting={setInserting} />
		</EmploymentContractInsertProvider>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	detail: select(state, 'profileReducer', 'detail'),
	listProject: select(state, 'projectReducer', 'list'),
	listEmploymentContract: select(state, 'employmentContractReducer', 'list')
});

const mapDispatchToProps = (dispatch) => ({
	getAllProject: () => dispatch(getAllProject()),
	getAllEmploymentContract: () => dispatch(getAllEmploymentContract()),
	getEmploymentContractByLeaderProfileId: (profileId) =>
		dispatch(getEmploymentContractByLeaderProfileId(profileId))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(EmploymentContractButton));
