import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { notification } from '../home/action';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const sendTimerNotification = (payload) => async (dispatch) => {
	const api = API_URLS.NOTIFICATION.sendTimerNotification();
	dispatch({
		type: TYPE.NOTIFICATION.SEND_TIMER_NOTIFICATION_LOADING,
		meta: { prefix: [PREFIX.NOTIFICATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.NOTIFICATION.SEND_TIMER_NOTIFICATION_SUCCESS,
			meta: { prefix: [PREFIX.NOTIFICATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Hẹn thông báo thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.NOTIFICATION.SEND_TIMER_NOTIFICATION_FAILURE,
			meta: { prefix: [PREFIX.NOTIFICATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Hẹn thông báo thất bại!'
			})
		);
	}
};

export const sendEmail = (payload) => async (dispatch) => {
	const api = API_URLS.NOTIFICATION.sendEmail();
	dispatch({
		type: TYPE.NOTIFICATION.SEND_EMAIL_LOADING,
		meta: { prefix: [PREFIX.NOTIFICATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.NOTIFICATION.SEND_EMAIL_SUCCESS,
			meta: { prefix: [PREFIX.NOTIFICATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Gửi thông báo thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.NOTIFICATION.SEND_EMAIL_FAILURE,
			meta: { prefix: [PREFIX.NOTIFICATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Gửi thông báo thất bại!'
			})
		);
	}
};
