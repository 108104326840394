import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	list: [],
	listById: [],
	listFiltered: [],
	isFetching: false,
	isFetchingAccepted: false
});

export default function monographReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_LOADING:
		case TYPE.MONOGRAPH.GET_FILTERED_MONOGRAPH_LOADING:
		case TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_BY_PROFILE_ID_LOADING:
		case TYPE.MONOGRAPH.INSERT_MONOGRAPH_LOADING:
		case TYPE.MONOGRAPH.UPDATE_MONOGRAPH_LOADING:
		case TYPE.MONOGRAPH.DELETE_MONOGRAPH_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.MONOGRAPH.ACCEPT_MONOGRAPH_LOADING:
			return state.merge({
				isFetchingAccepted: true
			});

		case TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_FAILURE:
		case TYPE.MONOGRAPH.GET_FILTERED_MONOGRAPH_FAILURE:
		case TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_BY_PROFILE_ID_FAILURE:
		case TYPE.MONOGRAPH.INSERT_MONOGRAPH_FAILURE:
		case TYPE.MONOGRAPH.UPDATE_MONOGRAPH_FAILURE:
		case TYPE.MONOGRAPH.DELETE_MONOGRAPH_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.MONOGRAPH.ACCEPT_MONOGRAPH_FAILURE:
			return state.merge({
				isFetchingAccepted: false
			});

		case TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_SUCCESS:
			return state.merge({
				list: action.payload || [],
				isFetching: false
			});

		case TYPE.MONOGRAPH.GET_FILTERED_MONOGRAPH_SUCCESS:
			return state.merge({
				listFiltered: action.payload || [],
				isFetching: false
			});

		case TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_BY_PROFILE_ID_SUCCESS:
			return state.merge({
				listById: [...action.payload],
				isFetching: false
			});

		case TYPE.MONOGRAPH.INSERT_MONOGRAPH_SUCCESS: {
			const newlistById = state.get('listById');
			const index = action.payload.monographProfile.findIndex((e) => e.profileId === action.id);
			if (index >= 0) newlistById.push({ monograph: action.payload });
			return state.merge({
				listById: [...newlistById],
				listFiltered: [...state.get('listFiltered'), { monograph: action.payload }],
				isFetching: false
			});
		}

		case TYPE.MONOGRAPH.UPDATE_MONOGRAPH_SUCCESS: {
			const newList = state.get('listFiltered');
			const index = newList.findIndex((e) => e.monograph.id === action.id);
			newList.splice(index, 1, { monograph: action.payload });

			const newList1 = state.get('listById');
			const index1 = newList1.findIndex((e) => e.monograph.id === action.id);
			newList1.splice(index1, 1, { monograph: action.payload });

			return state.merge({
				listFiltered: [...newList],
				listById: [...newList1],
				isFetching: false
			});
		}

		case TYPE.MONOGRAPH.DELETE_MONOGRAPH_SUCCESS: {
			const newList = state.get('listFiltered');
			const index = newList.findIndex((e) => e.monograph.id === action.id);
			if (index !== -1) newList.splice(index, 1);

			const newList1 = state.get('listById');
			const index1 = newList1.findIndex((e) => e.monograph.id === action.id);
			if (index1 !== -1) newList1.splice(index1, 1);

			return state.merge({
				listFiltered: [...newList],
				listById: [...newList1],
				isFetching: false
			});
		}

		case TYPE.MONOGRAPH.ACCEPT_MONOGRAPH_SUCCESS: {
			const newList = state.get('listById');
			const index = newList.findIndex((e) => e.monograph.id === action.payload.id);
			const newMonograph = { ...newList[index], monograph: action.payload };
			newList.splice(index, 1, newMonograph);

			const newList1 = state.get('listFiltered');
			const index1 = newList1.findIndex((e) => e.monograph.id === action.payload.id);
			const newMonograph1 = { ...newList1[index1], monograph: action.payload };
			newList1.splice(index1, 1, newMonograph1);

			return state.merge({
				listById: [...newList],
				listFiltered: [...newList1],
				isFetchingAccepted: false
			});
		}

		default:
			return state;
	}
}
