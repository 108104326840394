import React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	IconButton,
	Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import dayjs from 'dayjs';

import KPTable from '../../KPTable';
import './EmploymentContractTable.css';

function EmploymentContractTable(props) {
	const { handleChangeForm, contractList, setDetail } = props;

	const tableHeaderRender = (title, width) => {
		const className = 'em-contract-header-item';
		const color = props.disabled ? '#bdbdbd' : 'black';
		return (
			<b className={className} style={{ width: width, color: color }}>
				{title}
			</b>
		);
	};

	const tableCellRender = (title, width) => {
		const className = 'em-contract-cell-item';
		const color = props.disabled ? '#bdbdbd' : 'black';
		return (
			<div className={className} style={{ width: width, color: color }}>
				{title}
			</div>
		);
	};

	const renderSortedByYear = (arr) => arr.sort((a, b) => (a.year > b.year ? 1 : -1));

	const columns = [
		{
			title: (
				<div id="em-contract-header-container">
					{[
						tableHeaderRender('Chi tiết', '12%'),
						tableHeaderRender('Thời hạn', '12%'),
						tableHeaderRender('Bắt đầu', '16%'),
						tableHeaderRender('Kết thúc', '16%'),
						tableHeaderRender('Hiệu lực', '12%'),
						tableHeaderRender('Tình trạng', '20%'),
						tableHeaderRender('Tính KPI', '10%')
					]}
				</div>
			),
			fullwidth: true,
			key: 'duration',
			render: (_, row) => (
				<Accordion
					className="em-contract-cell-container"
					square
					expanded={
						!props.divideKPI
							? row?.id === props.contractIdForKpi
							: props.KPIList.find((e) => e?.profileId === props.focusProfile)?.contractIdForKpi ===
								row?.id
					}
				>
					<AccordionSummary
						className="em-contract-cell-header"
						onClick={() => {
							if (!props.divideKPI) {
								handleChangeForm(
									'contractIdForKpi',
									props?.contractIdForKpi === row?.id ? null : row?.id
								);
							} else {
								props.setKPIList([
									...props.KPIList.filter((e) => e?.profileId !== props.focusProfile),
									{
										...props.KPIList.find((e) => e?.profileId === props.focusProfile),
										contractIdForKpi:
											props.KPIList.find((e) => e?.profileId === props.focusProfile)
												?.contractIdForKpi === row?.id
												? null
												: row?.id
									}
								]);
							}
							// handleChangeForm('contractIdForKpi', props?.contractIdForKpi === row?.id ? null : row?.id)
						}}
					>
						{[
							tableHeaderRender(''),
							tableCellRender(row.duration === 1000 ? 'Vô hạn' : `${row.duration} năm`, '12%'),
							tableCellRender(dayjs(row.startAt).format('DD-MM-YYYY'), '16%'),
							tableCellRender(dayjs(row.finishAt).format('DD-MM-YYYY'), '16%'),
							tableCellRender(row.active ? 'Có' : 'Không', '12%'),
							tableCellRender(`${row.kpiCompleted ? 'Đã' : 'Chưa'} hoàn thành`, '20%'),
							tableCellRender(
								<IconButton
									size="small"
									disabled={props.disabled}
									onClick={() => {
										if (!props.divideKPI) {
											handleChangeForm('contractIdForKpi', row?.id);
										} else {
											props.setKPIList([
												...props.KPIList.filter((e) => e?.profileId !== props.focusProfile),
												{
													...props.KPIList.find((e) => e?.profileId === props.focusProfile),
													contractIdForKpi: row?.id
												}
											]);
										}
									}}
								>
									{props?.contractIdForKpi === row?.id ? <ExpandLess /> : <ExpandMore />}
								</IconButton>
							)
						]}
					</AccordionSummary>
					<AccordionDetails
						style={{
							flexDirection: 'column',
							padding: '8px 0',
							lineHeight: '38px',
							borderTop: 'solid 1px rgb(220 220 220)'
						}}
					>
						{renderSortedByYear(row?.listKpi)?.map((kpi, index) => (
							<div style={{ display: 'flex', width: '100%' }} key={index}>
								{[
									tableHeaderRender(
										true ? (
											''
										) : (
											<Typography
												variant="caption"
												color="primary"
												onClick={() => {
													if (!props.disabled) setDetail(row?.kpi);
												}}
												style={{ color: props.disabled ? '#bdbdbd' : '#5D6CC1' }}
											>
												Hiển thị
											</Typography>
										),
										'12%'
									),
									tableCellRender('', '12%'),
									tableCellRender(`Năm ${kpi.year}`, '16%'),
									tableCellRender('', '16%'),
									tableCellRender('', '12%'),
									tableCellRender(`${row.kpiCompleted ? 'Đã' : 'Chưa'} hoàn thành`, '20%'),
									tableCellRender(
										<Checkbox
											size="small"
											label="Chọn"
											color="primary"
											disabled={props.disabled || row?.kpiCompleted}
											checked={
												!props.divideKPI
													? kpi?.id === props.kpiIdForKpi
													: props.KPIList.find((e) => e?.profileId === props.focusProfile)
															?.kpiIdForKpi === kpi?.id
											}
											onChange={() => {
												setDetail(kpi);

												if (!props.divideKPI) {
													handleChangeForm('kpiIdForKpi', kpi.id);
												} else {
													props.setKPIList([
														...props.KPIList.filter((e) => e?.profileId !== props.focusProfile),
														{
															...props.KPIList.find((e) => e?.profileId === props.focusProfile),
															kpiIdForKpi: kpi.id
														}
													]);
												}
											}}
										/>,
										'10%'
									)
								]}
							</div>
						))}
					</AccordionDetails>
				</Accordion>
			)
		}
	];

	return <KPTable size="small" rowsPerPage={-1} columns={columns} rows={contractList} />;
}

export default EmploymentContractTable;
