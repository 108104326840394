import React from 'react';

import JournalDomesticButton from '../../containers/JournalDomestic/JournalDomesticButton';
import JournalDomesticTable from '../../containers/JournalDomestic/JournalDomesticTable';

function JournalDomestic() {
	const [filterObj, setFilterObj] = React.useState(null);

	return (
		<>
			<JournalDomesticButton setFilterObj={setFilterObj} />
			<JournalDomesticTable filterObj={filterObj} />
		</>
	);
}

export default JournalDomestic;
