import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ProfileInfo from '../../ProfileInfo';

function HumanResourceProfileInfoModal(props) {
	const { handleCloseProfileInfoModal } = props;

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={typeof props.profileId === 'number'}
			onClose={handleCloseProfileInfoModal}
		>
			<DialogTitle>Lý lịch</DialogTitle>
			<DialogContent>
				<ProfileInfo profileId={props.profileId} />
			</DialogContent>
		</Dialog>
	);
}

export default HumanResourceProfileInfoModal;
