import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Badge,
	Button,
	Divider,
	MenuItem,
	TextField,
	Typography,
	Box,
	Menu
} from '@material-ui/core';
import { Add, DoubleArrow, FileCopy, Replay } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import './SciencificResearchButton.css';
import ScientificResearchInsertModal from '../ScientificResearchInsertModal';
import ScientificResearchCheckDuplicateModal from '../ScientificResearchCheckDuplicateModal';

import {
	setFilter,
	getAllPurpose,
	getAllScientificResearchById,
	getFilteredScientificResearch,
	getDuplicateScientificResearch
} from '../../../reducer/scientificResearch/action';
import { getAllProfile } from '../../../reducer/profile/action';
import {
	getAllScientificResearchOtherByProfileId,
	getAllScientificResearchOther
} from '../../../reducer/scientificResearchOther/action';
import { KPTextform } from '../../../components/KPTComponents';

import { exportExcel } from '../utils/exportExcel';

function ScientificResearchButton(props) {
	const {
		getAllProfile,
		getAllScientificResearchById,
		getFilteredScientificResearch,
		getAllScientificResearchOther,
		getAllScientificResearchOtherByProfileId,
		date,
		setDate
	} = props;

	const [inserting, setInserting] = useState(false);
	const [checking, setChecking] = useState(false);

	let profileId = props.selectedProfileId;
	if (!profileId) profileId = props.profile?.id;

	const listFilter = [
		{
			value: 'journalInternational',
			type: 'scientificResearchJournal',
			title: 'Tạp chí quốc tế',
			unacceptedNum: props.statisticUnacceptedDetailFilter?.numJournal,
			checkFunction: (e) =>
				e.scientificResearch.type === 'scientificResearchJournal' && !e.scientificResearch.accepted
		},
		{
			value: 'journalDomestic',
			type: 'scientificResearchJournalDomestic',
			title: 'Tạp chí trong nước',
			unacceptedNum: props.statisticUnacceptedDetailFilter?.numDomesticJournal,
			checkFunction: (e) => e.journalDomestic && !e.scientificResearch.accepted
		},
		{
			value: 'conference',
			type: 'scientificResearchConference',
			title: 'Hội thảo quốc tế',
			unacceptedNum: props.statisticUnacceptedDetailFilter?.numConference,
			// checkFunction: (e) =>
			//   e.conference?.level === "international" && !e.scientificResearch.accepted,
			checkFunction: (e) =>
				e.scientificResearch.type === 'scientificResearchConference' &&
				!e.scientificResearch.accepted
		},
		{
			value: 'conferenceDomestic',
			type: 'scientificResearchConferenceDomestic',
			title: 'Hội thảo trong nước',
			unacceptedNum: props.statisticUnacceptedDetailFilter?.numDomesticConference,
			// checkFunction: (e) =>
			//   e.conference?.level === "domestic" && !e.scientificResearch.accepted,
			checkFunction: (e) =>
				e.scientificResearch.type === 'scientificResearchConferenceDomestic' &&
				!e.scientificResearch.accepted
		},
		{
			value: 'chapter',
			type: 'chapterJournal',
			title: 'Chương sách',
			unacceptedNum: props.statisticUnacceptedDetailFilter?.numBook,
			checkFunction: (e) =>
				e.scientificResearch.type === 'chapterJournal' && !e.scientificResearch.accepted
		},
		{
			value: 'workingPaper',
			type: 'working',
			title: 'Working Paper',
			unacceptedNum: 0,
			checkFunction: (e) => false
		},
		{
			value: 'other',
			type: 'scientificResearchOther',
			title: 'Tạp chí khác',
			unacceptedNum: props.statisticUnacceptedDetailFilter?.numOther,
			checkFunction: (e) =>
				e.scientificResearch.type === 'scientificResearchOther' && !e.scientificResearch.accepted
		}
	];

	// Lấy mục đích
	useEffect(() => {
		if (props.purposes.length === 0 && !props.isFetchingPurposes) props.getAllPurpose();
	}, []);

	useEffect(() => {
		if (props.listProfile.length === 0 && !props.isFetchingProfile) getAllProfile();
	}, []);

	// Lấy dữ liệu khởi tạo
	// Chỉ lấy dữ liệu nếu là trang CTKH cá nhân
	useEffect(() => {
		if (props.type !== 'all') {
			if (props.profile && !props.isFetchingScientificResearch) {
				getAllScientificResearchById(profileId);
			}
			if (props.profile && !props.isFetchingScientificResearchOther) {
				getAllScientificResearchOtherByProfileId(profileId);
			}
		}
		// else {
		//   if (
		//     props.listFilteredScientificResearch.length === 0 &&
		//     !props.isFetchingScientificResearch
		//   ) {
		//     getFilteredScientificResearch(1, -1, {
		//       departmentId: props.department,
		//     });
		//   }
		//   if (props.listScientificResearchOther.length === 0) {
		//     getAllScientificResearchOther();
		//   }
		// }
	}, [props.profile, profileId]);

	// Đổi department thì lấy lại dữ liệu
	// Nếu chậy lần đầu department rỗng thì không get api ko thì sẽ get cả trường
	useEffect(() => {
		// // // console.log(props.department);
		if (props.department !== null && props.department !== undefined)
			getFilteredScientificResearch(1, -1, {
				departmentId: props.department
			});
	}, [props.department]);

	const handleReload = () => {
		if (props.type === 'all') {
			getFilteredScientificResearch(1, -1, { departmentId: props.department });
			props.handleReload();
		} else {
			props.handleReload();
			getAllScientificResearchById(profileId);
		}
	};
	const handleInsert = () => setInserting(true);
	const handleCheckDuplicate = () => {
		props.getDuplicateScientificResearch();
		setChecking(true);
	};

	let list = props.listScientificResearchById;
	if (props.type === 'all') list = props.listFilteredScientificResearch;

	return (
		<>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={5}>
					<Typography variant="h5" style={{ lineHeight: '36px' }}>
						Công trình nghiên cứu
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<TextField
						select
						fullWidth
						size="small"
						variant="outlined"
						SelectProps={{
							MenuProps: {
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'center'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'center'
								},
								getContentAnchorEl: null
							}
						}}
						InputProps={{ style: { display: 'flex', flexDirection: 'row' } }}
						value={props.filter}
						onChange={(e) => {
							props.setFilter(e.target.value);
						}}
					>
						{listFilter.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								<Badge
									className="sr-badge"
									color="error"
									badgeContent={
										!props?.adminTab
											? list.filter((e) => option.checkFunction(e)).length
											: option.unacceptedNum
									}
								>
									{option.title}
								</Badge>
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={5}>
					<div style={{ float: 'right' }}>
						<Button variant="outlined" startIcon={<Replay />} onClick={handleReload}>
							Tải lại
						</Button>
						{props.type === 'all' && props.role === 'sudo-admin' && (
							<Button
								variant="outlined"
								color="primary"
								startIcon={<FileCopy />}
								style={{ marginLeft: 8 }}
								onClick={handleCheckDuplicate}
							>
								Check trùng
							</Button>
						)}
						{props.type === 'all' && (
							<Button
								variant="contained"
								color="primary"
								startIcon={<FileCopy />}
								style={{ marginLeft: 8 }}
								onClick={(event) => props.setAnchorEl(event.currentTarget)}
							>
								Báo cáo
							</Button>
						)}
						{props.type !== 'all' && (
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add />}
								style={{ marginLeft: 8 }}
								onClick={handleInsert}
							>
								Thêm công bố
							</Button>
						)}
					</div>
				</Grid>
				{/* {isFilter && (
          <Grid item xs={9}>
            <KPTextform
              textForm={[
                { xs: 3, key: 'export', type: 'render', render: () => (
                  <Button variant="outlined">Xuất excel báo cáo</Button>
                ) },
                { xs: 2, key: 'startAt', type: 'date' },
                { xs: 1, key: 'to', type: 'render', render: () => (
                  <Box display="flex" justifyContent="center">
                    <DoubleArrow style={{ fontSize: 36, color: "rgba(0, 0, 0, 0.30)" }} />
                  </Box>
                ) },
                { xs: 2, key: 'finishAt', type: 'date' },
                { xs: 2, key: 'export', type: 'render', render: () => (
                  <Button fullWidth variant="outlined" style={{ height: 35 }}>Bỏ lọc</Button>
                ) },
                { xs: 2, key: 'export', type: 'render', render: () => (
                  <Button fullWidth color="primary" variant="contained" style={{ height: 35 }}>Áp dụng</Button>
                ) },
              ]}
              values={date}
              setValues={setDate}
            />
          </Grid>
        )} */}
			</Grid>
			<Divider style={{ margin: '8px 0' }} />
			<ScientificResearchInsertModal
				inserting={inserting}
				filter={props.filter}
				type={listFilter.find((e) => e.value === props.filter)?.type}
				setInserting={setInserting}
				other={props.other}
			/>
			<ScientificResearchCheckDuplicateModal
				open={checking}
				onClose={() => setChecking(false)}
				loading={props.isCheckingDuplicate}
				data={props.listDuplicate}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	isFetchingProfile: select(state, 'scientificResearchReducer', 'isFetching'),
	isFetchingPurposes: select(state, 'scientificResearchReducer', 'isFetchingPurposes'),
	isCheckingDuplicate: select(state, 'scientificResearchReducer', 'isCheckingDuplicate'),
	isFetchingScientificResearch: select(state, 'scientificResearchReducer', 'isFetching'),
	isFetchingScientificResearchOther: select(state, 'scientificResearchOtherReducer', 'isFetching'),

	filter: select(state, 'scientificResearchReducer', 'filter'),
	profile: select(state, 'profileReducer', 'detail'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	listProfile: select(state, 'profileReducer', 'list'),
	listDuplicate: select(state, 'scientificResearchReducer', 'listDuplicate'),
	reduxDepartment: select(state, 'profileReducer', 'department'),
	listScientificResearch: select(state, 'scientificResearchReducer', 'list'),
	listScientificResearchById: select(state, 'scientificResearchReducer', 'listById'),
	listScientificResearchOther: select(state, 'scientificResearchOtherReducer', 'list'),
	listFilteredScientificResearch: select(state, 'scientificResearchReducer', 'listFiltered'),
	listScientificResearchOtherById: select(state, 'scientificResearchOtherReducer', 'listById'),
	statisticUnacceptedDetailFilter: select(
		state,
		'profileReducer',
		'statisticUnacceptedDetailFilter'
	)
});

const mapDispatchToProps = (dispatch) => ({
	setFilter: (id) => dispatch(setFilter(id)),
	getAllPurpose: () => dispatch(getAllPurpose()),
	getAllProfile: () => dispatch(getAllProfile()),
	getAllScientificResearchById: (id) => dispatch(getAllScientificResearchById(id)),
	getFilteredScientificResearch: (page, pageSize, payload) =>
		dispatch(getFilteredScientificResearch(page, pageSize, payload)),
	getAllScientificResearchOtherByProfileId: (id) =>
		dispatch(getAllScientificResearchOtherByProfileId(id)),
	getAllScientificResearchOther: (id) => dispatch(getAllScientificResearchOther(id)),
	getDuplicateScientificResearch: () => dispatch(getDuplicateScientificResearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ScientificResearchButton));
