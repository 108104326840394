import { Save, Edit, Check, SkipNext } from '@material-ui/icons';
import { Step, Stepper, StepLabel } from '@material-ui/core';
import {
	DefaultForm,
	CouncilForm,
	StopForm,
	ReportForm,
	SettingForm,
	DeleteForm,
	SubmitForm,
	ExportForm,
	AcceptCouncilForm
} from '../InstitutionalProjectForms';

const color = {
	error: '#e00000',
	primary: '#243474',
	success: '#60ae7d',
	secondary: '#f46423'
};

const modalPropertiesDict = {
	insert: {
		title: 'Thêm đề tài NCKH cơ sở',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="insert-form" />,
		actionButtons: (_props) => [
			{ closeButton: true },
			{
				disabledText: !_props.files?.presentationFile
					? 'Không thể thêm vì thiếu File thuyết minh'
					: !_props.files?.statisticPlanFile
						? 'Không thể thêm vì thiếu File thông tin trích ngang'
						: null,
				props: {
					form: 'insert-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Thêm đề tài'
			}
		]
	},
	detail: {
		title: 'Chi tiết đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="detail-form" />,
		actionButtons: () => [{ closeButton: true }]
	},
	edit: {
		title: 'Chỉnh sửa đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="edit-form" />,
		actionButtons: (_props) => [
			{ closeButton: true },
			{
				disabledText:
					!_props.files?.presentationFile && !_props.values?.presentationFileLink
						? 'Không thể thêm vì thiếu File thuyết minh'
						: !_props.files?.statisticPlanFile && !_props.values?.statisticPlanFileLink
							? 'Không thể thêm vì thiếu File thông tin trích ngang'
							: null,
				props: {
					form: 'edit-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Chỉnh sửa đề tài'
			}
		]
	},
	editBasic: {
		title: 'Chỉnh sửa thông tin đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="edit-basic" />,
		actionButtons: (_props) => [
			{ closeButton: true },
			{
				props: {
					form: 'edit-basic',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Chỉnh sửa thông tin đề tài'
			}
		]
	},
	acceptInfo: {
		title: 'Duyệt thông tin đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="accept-info-form" />,
		actionButtons: (props) => [
			{ closeButton: true },
			{
				disabledText: !props.values?.editRequest ? 'Không thể yêu cầu do chưa nhập nhận xét' : null,
				props: {
					form: 'accept-info-form',
					type: 'submit',
					name: 'info_adjust_request',
					color: 'secondary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Yêu cầu điều chỉnh'
			},
			{
				props: {
					form: 'accept-info-form',
					type: 'submit',
					name: 'info_accepted',
					color: 'primary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Duyệt thông tin'
			}
		]
	},
	acceptInfoV2: {
		title: 'Duyệt thông tin đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="accept-info-v2-form" />,
		actionButtons: (props) => [
			{ closeButton: true },
			{
				disabledText: !props.values?.editRequest ? 'Không thể yêu cầu do chưa nhập nhận xét' : null,
				props: {
					form: 'accept-info-v2-form',
					type: 'submit',
					name: 'info_adjust_request',
					color: 'secondary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Yêu cầu điều chỉnh'
			},
			{
				props: {
					form: 'accept-info-v2-form',
					type: 'submit',
					name: 'info_accepted',
					color: 'primary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Duyệt thông tin'
			}
		]
	},
	acceptInfoAgain: {
		title: 'Duyệt thông tin đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="accept-info-again-form" />,
		actionButtons: (props) => [
			{ closeButton: true },
			{
				disabledText: !props.values?.editRequest ? 'Không thể hủy do chưa nhập nhận xét' : null,
				props: {
					form: 'accept-info-again-form',
					type: 'submit',
					name: 'info_rejected',
					className: 'error-button',
					color: 'secondary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Hủy đề tài'
			},
			{
				props: {
					form: 'accept-info-again-form',
					type: 'submit',
					name: 'info_accepted',
					color: 'primary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Duyệt thông tin'
			}
		]
	},
	acceptInfoAgainV2: {
		title: 'Duyệt thông tin đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="accept-info-again-v2-form" />,
		actionButtons: (props) => [
			{ closeButton: true },
			{
				disabledText: !props.values?.editRequest ? 'Không thể hủy do chưa nhập nhận xét' : null,
				props: {
					form: 'accept-info-again-v2-form',
					type: 'submit',
					name: 'info_rejected',
					className: 'error-button',
					color: 'secondary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Hủy đề tài'
			},
			{
				props: {
					form: 'accept-info-again-v2-form',
					type: 'submit',
					name: 'info_accepted',
					color: 'primary',
					variant: 'contained',
					startIcon: <Check />
				},
				content: 'Duyệt thông tin'
			}
		]
	},
	insertCouncil: {
		title: 'Thêm hội đồng nghiệm thu',
		form: (props) => <AcceptCouncilForm {...props} isAdd id="council-form" />,
		actionButtons: () => [
			{ closeButton: true },
			{
				props: {
					form: 'council-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Lưu hội đồng nghiệm thu'
			}
		]
	},
	insertReviewer: {
		title: 'Thành lập hội đồng xét chọn',
		style: { padding: '16px 24px' },
		form: (props) => <CouncilForm {...props} id="council-form" />,
		actionButtons: (props) => [
			{ closeButton: true },
			{
				props: {
					form: 'council-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Lưu hội đồng'
			}
		]
	},
	detailAdjust: {
		size: 'xl',
		title: 'Chỉnh sửa đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="edit-commiss-form" big={true} />,
		actionButtons: (_props) => [
			{ closeButton: true },
			{
				disabledText:
					!_props.files?.presentationFile && !_props.values?.presentationFileLink
						? 'Không thể thêm vì thiếu File thuyết minh'
						: !_props.files?.statisticPlanFile && !_props.values?.statisticPlanFileLink
							? 'Không thể thêm vì thiếu File thông tin trích ngang'
							: null,
				props: {
					form: 'edit-commiss-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Chỉnh sửa đề tài'
			}
		]
	},
	detailCouncil: {
		size: 'xl',
		title: 'Chi tiết đề tài',
		style: { padding: 0, overflow: 'hidden' },
		form: (props) => <DefaultForm {...props} id="detail-council-form" big={true} />,
		actionButtons: (props) => {
			if (props.type === 'personal') return [];
			return [
				{ closeButton: true },
				{
					props: {
						form: 'detail-council-form',
						name: 'adjust_council',
						type: 'submit',
						color: 'secondary',
						variant: 'contained',
						startIcon: <Edit />
					},
					content: 'Yêu cầu chỉnh sửa, bổ sung'
				},
				{
					props: {
						form: 'detail-council-form',
						name: 'conducted',
						type: 'submit',
						color: 'primary',
						variant: 'contained',
						startIcon: <Save />
					},
					content: 'Được duyệt thực hiện'
				}
			];
		}
	},
	report: {
		title: 'Báo cáo tiến độ',
		form: (props) => {
			if (props.step === 0) return <StopForm {...props} id="stop-form" />;
			return <ReportForm {...props} id="report-form" />;
		},
		actionButtons: (_props) => [
			{
				props: {
					color: 'secondary',
					variant: 'contained',
					startIcon: (
						<SkipNext
							style={{
								transition: '0.4s',
								transform: `rotate(${_props.step === 0 ? 0 : 180}deg)`
							}}
						/>
					),
					onClick: () => _props.setStep(1 - _props.step),
					style: { width: 116, justifyContent: 'space-between' }
				},
				content: _props.step === 0 ? 'Bỏ qua' : 'Quay lại'
			},
			{ props: { disabled: true, component: 'div', style: { flexGrow: 1 } } },
			{ closeButton: true },
			{
				disabledText:
					_props.step === 1 && !_props.files?.finishedWork
						? 'Không thể báo cáo vì thiếu File Các công việc đã hoàn thành'
						: null,
				props: {
					form: _props.step === 0 ? 'stop-form' : 'report-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
					// style: {
					//   left: 0
					// }
				},
				content: _props.step === 0 ? 'Xin dừng thực hiện' : 'Gửi báo cáo'
			}
		],
		reportHeader: (step) => (
			<Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
				<Step key={0}>
					<StepLabel error={step === 1}>
						<b>Xin dừng thực hiện</b>
						<br />
						{step === 0 ? '(Đang tiến hành)' : ' (Đã bỏ qua)'}
					</StepLabel>
				</Step>
				<Step key={1}>
					<StepLabel>
						<b>Báo cáo tiến độ</b>
						<br />
						{step === 1 ? '(Đang tiến hành)' : ' (Không thực hiện)'}
					</StepLabel>
				</Step>
			</Stepper>
		)
	},
	reportDetail: {
		title: 'Xem lại báo cáo tiến độ',
		form: (props) => <ReportForm {...props} id="report-form" />,
		actionButtons: (_props) => [{ closeButton: true }],
		reportHeader: (step) => (
			<Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
				<Step key={0}>
					<StepLabel error={step === 1}>
						<b>Xin dừng thực hiện</b>
						<br />
						{step === 0 ? '(Đang tiến hành)' : ' (Đã bỏ qua)'}
					</StepLabel>
				</Step>
				<Step key={1}>
					<StepLabel>
						<b>Báo cáo tiến độ</b>
						<br />
						{step === 1 ? '(Đang tiến hành)' : ' (Không thực hiện)'}
					</StepLabel>
				</Step>
			</Stepper>
		)
	},
	reportAccept: {
		title: 'Duyệt báo cáo tiến độ',
		form: (props) => <ReportForm {...props} id="report-accept-form" />,
		actionButtons: (_props) => [
			{ closeButton: true },
			{
				props: {
					form: 'report-accept-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Duyệt đảm bảo tiến độ'
			}
		],
		reportHeader: (step) => (
			<Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
				<Step key={0}>
					<StepLabel error={step === 1}>
						<b>Xin dừng thực hiện</b>
						<br />
						{step === 0 ? '(Đang tiến hành)' : ' (Đã bỏ qua)'}
					</StepLabel>
				</Step>
				<Step key={1}>
					<StepLabel>
						<b>Báo cáo tiến độ</b>
						<br />
						{step === 1 ? '(Đang tiến hành)' : ' (Không thực hiện)'}
					</StepLabel>
				</Step>
			</Stepper>
		)
	},
	delete: {
		title: 'Xóa đề tài',
		size: 'xs',
		form: (props) => (
			<DeleteForm
				handleClose={props.handleClose}
				deleteId={props.detail.id}
				id="report-accept-form"
			/>
		),
		actionButtons: () => []
	},
	setting: {
		title: 'Cấu hình hệ thống',
		size: 'sm',
		// form: (props) => <SettingForm {...props} handleClose={props.handleClose} year={props.year} id='setting-form' />,
		form: (props) => <SettingForm {...props} id="setting-form" />,
		actionButtons: () => [
			{ closeButton: true },
			{
				props: {
					form: 'setting-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Lưu cấu hình'
			}
		]
	},
	submit: {
		title: 'Nộp báo cáo tổng kết',
		form: (props) => <SubmitForm {...props} id="submit-form" />,
		actionButtons: () => [
			{ closeButton: true },
			{
				props: {
					form: 'submit-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Nộp báo cáo'
			}
		]
	},
	defaultDetail: {
		title: 'Chấm báo cáo tổng kết',
		form: (props) => <SubmitForm {...props} id="submit-accept-form" />,
		actionButtons: () => [
			{ closeButton: true },
			{
				props: {
					form: 'submit-accept-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Chấm báo cáo'
			}
		]
	},
	submitAccept: {
		title: 'Chấm báo cáo tổng kết',
		form: (props) => <SubmitForm {...props} id="submit-accept-form" />,
		actionButtons: (_props) => [
			{ closeButton: true },
			{
				disabledText: !_props.values?.editRequest ? 'Chưa điền Yêu cầu chỉnh sửa, bổ sung' : null,
				props: {
					form: 'submit-accept-form',
					name: 'edit',
					type: 'submit',
					color: 'secondary',
					variant: 'contained',
					startIcon: <Edit />
				},
				content: 'Yêu cầu chỉnh sửa, bổ sung'
			},
			{
				props: {
					form: 'submit-accept-form',
					name: 'accept',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Chấm báo cáo'
			}
		]
	},
	councilDetail: {
		title: 'Xem lại hội đồng nghiệm thu cấp Khoa',
		form: (props) => (
			<AcceptCouncilForm
				{...props}
				id="council-detail-form"
				values={props.values}
				council={props.values.internalCouncilInfos?.find((e) => e.name === 'accept-project')}
				councilApp={props.values.internalCouncilInfos?.find((e) => e.name === 'accept-application')}
			/>
		),
		actionButtons: () => [{ closeButton: true }]
	},
	submitDetail: {
		title: 'Xem lại',
		form: (props) => <SubmitForm {...props} id="submit-detail" />,
		actionButtons: () => [{ closeButton: true }]
	},
	submitDetailEdit: {
		title: 'Chỉnh sửa báo cáo tổng kết',
		form: (props) => <SubmitForm {...props} id="submit-edit-form" big={true} />,
		actionButtons: () => [
			{ closeButton: true },
			{
				props: {
					form: 'submit-edit-form',
					type: 'submit',
					color: 'primary',
					variant: 'contained',
					startIcon: <Save />
				},
				content: 'Nộp báo cáo'
			}
		]
	},
	export: {
		title: 'Xuất file báo cáo',
		size: 'sm',
		form: (props) => <ExportForm {...props} id="export-form" />,
		actionButtons: () => [{ closeButton: true }]
	}
};

const rowPropertiesDict = {
	info_default: {
		status: [
			{
				value: 'info_default',
				label: 'Đã gửi hồ sơ',
				bgcolor: color.primary
			}
		],
		buttons: {
			personal: ['detail', ['editBasic', 'delete']],
			admin: ['acceptInfo', 'delete'],
			mod: ['detail', ['editBasic', 'delete']]
		}
	},
	info_default_v2: {
		status: [
			{
				value: 'info_default_v2',
				label: 'Đã duyệt thông tin sơ bộ',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['acceptInfoV2', 'disabled']
		}
	},
	info_accepted: {
		status: [
			{
				value: 'info_accepted',
				label: 'Đã duyệt thông tin sơ bộ',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'insertReviewer'],
			mod: ['detail', 'insertReviewer']
		}
	},
	info_accepted_v2: {
		status: [
			{
				value: 'info_accepted',
				label: 'Đã duyệt thông tin',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'insertReviewer'],
			mod: ['detail', 'insertReviewer']
		}
	},
	info_adjust_request: {
		status: [
			{
				value: 'info_adjust_request',
				label: 'Yêu cầu chỉnh sửa, bổ sung',
				bgcolor: color.secondary
			}
		],
		buttons: {
			personal: ['edit', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'disabled']
		}
	},
	info_adjusted: {
		status: [
			{
				value: 'info_adjusted',
				label: 'Đã gửi chỉnh sửa, bổ sung',
				bgcolor: color.secondary
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['acceptInfoAgain', 'disabled'],
			mod: ['detail', 'disabled']
		}
	},
	info_adjusted_v2: {
		status: [
			{
				value: 'info_adjusted_v2',
				label: 'Đã gửi chỉnh sửa, bổ sung',
				bgcolor: color.secondary
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['acceptInfoAgainV2', 'disabled']
		}
	},
	info_rejected: {
		status: [
			{
				value: 'info_rejected',
				label: 'Đề tài bị hủy',
				bgcolor: color.error
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'disabled']
		}
	},
	info_council: {
		status: [
			{
				value: 'info_default',
				label: 'Đã duyệt thông tin sơ bộ',
				bgcolor: color.success
			},
			{
				value: 'info_council',
				label: 'Đã được thành lập hội đồng xét duyệt',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detailCouncil', 'disabled'],
			admin: ['detailCouncil', 'disabled'],
			mod: ['detailCouncil', 'disabled']
		}
	},
	content_adjust_request: {
		status: [
			{
				value: 'content_adjust_request',
				label: 'Yêu cầu chỉnh sửa, bổ sung',
				bgcolor: color.secondary
			}
		],
		buttons: {
			personal: ['detailAdjust', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detailAdjust', 'disabled']
		}
	},
	content_adjusted: {
		status: [
			{
				value: 'content_adjusted',
				label: 'Đã gửi chỉnh sửa, bổ sung',
				bgcolor: color.primary
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detailCouncil', 'disabled']
		}
	},
	conducted: {
		status: [
			{
				value: 'conducted',
				label: 'Được duyệt thực hiện',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'disabled']
		}
	},
	report_default: {
		status: [
			{
				value: 'report_default',
				label: 'Chưa gửi báo cáo tiến độ',
				bgcolor: color.primary
			}
		],
		buttons: {
			personal: ['detail', 'report'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'report']
		}
	},
	report_submitted: {
		status: [
			{
				value: 'report_submitted',
				label: 'Đã gửi báo cáo tiến độ',
				bgcolor: color.secondary
			}
		],
		buttons: {
			personal: ['detail', 'reportDetail'],
			admin: ['detail', 'reportDetail'],
			mod: ['detail', 'reportAccept']
		}
	},
	report_ensure: {
		status: [
			{
				value: 'report_ensure',
				label: 'Đảm bảo tiến độ',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'reportDetail'],
			admin: ['detail', 'reportDetail'],
			mod: ['detail', 'reportDetail']
		}
	},
	default_council_default: {
		status: [
			{
				value: 'default',
				label: 'Chưa nộp báo cáo',
				bgcolor: color.primary
			},
			{
				value: 'council_default',
				label: 'Chưa gán hội đồng',
				bgcolor: color.primary
			}
		],
		buttons: {
			personal: ['detail', 'submit'],
			admin: ['detail', 'insertCouncil'],
			mod: ['detail', 'submit']
		}
	},
	default_council_accepted: {
		status: [
			{
				value: 'default',
				label: 'Chưa nộp báo cáo',
				bgcolor: color.primary
			},
			{
				value: 'council_accepted',
				label: 'Đã gán hội đồng',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'submit'],
			admin: ['detail', 'councilDetail'],
			mod: ['detail', 'submit']
		}
	},
	submitted_council_default: {
		status: [
			{
				value: 'submitted',
				label: 'Đã nộp báo cáo',
				bgcolor: color.success
			},
			{
				value: 'council_default',
				label: 'Chưa gán hội đồng',
				bgcolor: color.primary
			}
		],
		buttons: {
			personal: ['detail', 'defaultDetailNotCouncil'],
			admin: ['detail', 'insertCouncil'],
			mod: ['detail', 'defaultDetailNotCouncil']
		}
	},
	submitted_council_accepted: {
		status: [
			{
				value: 'submitted',
				label: 'Đã nộp báo cáo',
				bgcolor: color.success
			},
			{
				value: 'council_accepted',
				label: 'Đã gán hội đồng',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'defaultDetail'],
			admin: ['detail', 'submitAccept'],
			mod: ['detail', 'defaultDetail']
		}
	},
	default: {
		status: [
			{
				value: 'default',
				label: 'Chưa nộp báo cáo',
				bgcolor: color.primary
			}
		],
		buttons: {
			personal: ['detail', 'submit'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'submit']
		}
	},
	submitted: {
		status: [
			{
				value: 'submitted',
				label: 'Đã nộp báo cáo',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'submitAccept']
		}
	},
	accepted: {
		status: [
			{
				value: 'submitted',
				label: 'Đã nghiệm thu',
				bgcolor: color.success
			}
		],
		buttons: {
			personal: ['detail', 'disabled'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'submitDetail']
		}
	},
	adjust_request: {
		status: [
			{
				value: 'adjust_request',
				label: 'Yêu cầu chỉnh sửa, bổ sung',
				bgcolor: color.secondary
			}
		],
		buttons: {
			personal: ['detail', 'submitDetailEdit'],
			admin: ['detail', 'disabled'],
			mod: ['detail', 'submitDetailEdit']
		}
	}
};

export const render = (open) => {
	const properties = [];
	for (const property in open) properties.push(property);
	return modalPropertiesDict[properties[0]];
};

export const rowRender = (status, type, infoPassed) => {
	var _status = status;
	if (status?.[0] === 'info_accepted' && infoPassed) _status = 'info_default_v2';
	if (status?.[0] === 'default') _status = `${status?.[0]}_${status?.[status.length - 1]}`;
	if (status?.[0] === 'submitted' && status.length > 1)
		_status = `${status?.[0]}_${status?.[status.length - 1]}`;
	if (status?.[0] === 'info_adjusted' && infoPassed) _status = 'info_adjusted_v2';
	if (status?.[1] === 'info_council') _status = status?.[1];
	if (_status === 'default_report_ensure') _status = 'default_council_default';

	return {
		...rowPropertiesDict[_status],
		buttons: rowPropertiesDict[_status].buttons[type]
	};
};
