import React from 'react';
import { Card } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import EmploymentContractTable from '../../containers/EmploymentContract/EmploymentContractTable';
import EmploymentContractButton from '../../containers/EmploymentContract/EmploymentContractButton';

const useStyles = makeStyles((theme) =>
	createStyles({
		employmentContractCard: {
			padding: theme.spacing(2),
			minHeight: 'calc(100vh - 124px)',
			maxHeight: 'calc(100vh - 124px)'
		}
	})
);

function EmploymentContract() {
	const classes = useStyles();

	return (
		<Card className={classes.employmentContractCard}>
			<EmploymentContractButton />
			<EmploymentContractTable />
		</Card>
	);
}

export default EmploymentContract;
