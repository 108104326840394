import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, Grid, Badge, Divider, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
	setDepartment,
	statisticUnaccepted,
	getProfileInfoByProfileId
} from '../../../reducer/profile/action';
import { getAllDepartments } from '../../../reducer/department/action';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import Patent from '../../../components/Patent';
import Project from '../../../components/Project';
import Solution from '../../../components/Solution';
import Monograph from '../../../components/Monograph';
import ScientificResearch from '../../../components/ScientificResearch';

function ManagementTab(props) {
	const [value, setValue] = useState(0);
	const [count, setCount] = useState({});
	const [department, setDepartment] = useState(null);

	const handleChangeDepartment = (val) => setDepartment(val);
	const handleChange = (event, newValue) => setValue(newValue);
	const handleReload = () => props.statisticUnaccepted({ departmentId: department, profileId: 0 });

	useEffect(() => props.getAllDepartments(), []);

	useEffect(() => {
		if (props.role) {
			if (props.role !== 'sudo-admin') {
				if (
					props.department !== null &&
					props.department !== undefined &&
					props.department.length > 0
				) {
					setDepartment(props.department[0].id);
					props.setDepartment(props.department[0].id);
				}
			} else {
				if (
					props.departments !== undefined &&
					props.departments !== null &&
					props.departments.length > 0
				) {
					setDepartment(props.departments[0].id);
					props.setDepartment(props.departments[0].id);
				}
			}
		}
	}, [props.department, props.departments]);

	useEffect(() => {
		const countScientificResearch =
			props.statisticUnacceptedDetailFilter?.numJournal +
			props.statisticUnacceptedDetailFilter?.numDomesticJournal +
			props.statisticUnacceptedDetailFilter?.numConference +
			props.statisticUnacceptedDetailFilter?.numDomesticConference +
			props.statisticUnacceptedDetailFilter?.numBook +
			props.statisticUnacceptedDetailFilter?.numOther;
		const countSolution = props.statisticUnacceptedDetailFilter?.numSolution || 0;
		const countPatent = props.statisticUnacceptedDetailFilter?.numPatent || 0;
		const countContract = props.statisticUnacceptedDetailFilter?.numProductContract || 0;
		const countProject = props.statisticUnacceptedDetailFilter?.numResearchProject || 0;
		const countMonograph = props.statisticUnacceptedDetailFilter?.numMonograph || 0;
		setCount({
			scientificResearch: countScientificResearch,
			solution: countSolution,
			patent: countPatent,
			contract: countContract,
			project: countProject,
			monograph: countMonograph
		});
	}, [props.statisticUnacceptedDetailFilter]);

	const TabPanel = (props) => {
		const { children, value, index, ...other } = props;
		return (
			<div
				{...other}
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
			>
				{value === index && children}
			</div>
		);
	};
	const typeTable = 'all';

	return (
		<>
			<Grid container justifyContent="space-between">
				<Grid item xs={10}>
					<Tabs
						value={value}
						textColor="secondary"
						indicatorColor="secondary"
						onChange={handleChange}
					>
						{props.tabList.map((tab) => (
							<Tab
								key={tab.key}
								label={
									<Badge
										className="work-tab-badge"
										color="error"
										badgeContent={count[tab.key] || 0}
									>
										{tab.title}
									</Badge>
								}
								style={{ padding: '0 24px' }}
							/>
						))}
					</Tabs>
				</Grid>
				<Grid item xs={2}>
					{(() => {
						const departmentList =
							props.role === 'sudo-admin' ? props?.departments : props?.department;

						if (!departmentList) return null;

						return (
							<Autocomplete
								options={departmentList || []}
								getOptionLabel={(option) => option.name}
								disableClearable
								filterSelectedOptions
								onChange={(_, val) => handleChangeDepartment(val?.id)}
								value={departmentList?.find((e) => e.id === department) || 'Cả trường'}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										label="Đơn vị"
										variant="outlined"
										placeholder="Cả trường"
										style={{ float: 'right', marginTop: 4, marginRight: 16 }}
									/>
								)}
							/>
						);
					})()}
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			<TabPanel value={value} index={0}>
				<ScientificResearch
					adminTab={true}
					type={typeTable}
					department={department}
					handleReload={handleReload}
				/>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Solution
					adminTab={true}
					type={typeTable}
					department={department}
					handleReload={handleReload}
				/>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Patent
					adminTab={true}
					type={typeTable}
					department={department}
					handleReload={handleReload}
				/>
			</TabPanel>
			{/* disabled product contract */}
			{/* <TabPanel value={value} index={3}>
        <Contract type={typeTable} department={department} handleReload={handleReload}/>
      </TabPanel> */}
			<TabPanel value={value} index={3}>
				<Project
					adminTab={true}
					type={typeTable}
					department={department}
					handleReload={handleReload}
				/>
			</TabPanel>
			<TabPanel value={value} index={4}>
				<Monograph
					adminTab={true}
					type={typeTable}
					department={department}
					handleReload={handleReload}
				/>
			</TabPanel>
		</>
	);
}

const mapStateToProps = (state) => ({
	isFetchingAcceptedPatent: select(state, 'patentReducer', 'isFetchingAccepted'),
	isFetchingAcceptedProject: select(state, 'projectReducer', 'isFetchingAccepted'),
	isFetchingAcceptedSolution: select(state, 'solutionReducer', 'isFetchingAccepted'),
	isFetchingAcceptedContract: select(state, 'contractReducer', 'isFetchingAccepted'),
	isFetchingAcceptedMonograph: select(state, 'monographReducer', 'isFetchingAccepted'),
	isFetchingAcceptedScientificResearch: select(
		state,
		'scientificResearchReducer',
		'isFetchingAccepted'
	),
	isFetchingAcceptedScientificResearchOther: select(
		state,
		'scientificResearchOtherReducer',
		'isFetchingAccepted'
	),

	role: select(state, 'userReducer', 'role'),
	profileId: select(state, 'userReducer', 'userId'),
	// list: select(state, "journalReducer", "listFiltered"),
	department: select(state, 'profileReducer', 'departments'),
	// defaultDepartment: select(state, "profileReducer", "department"),
	departments: select(state, 'departmentReducer', 'departments'),
	statisticUnacceptedDetailFilter: select(
		state,
		'profileReducer',
		'statisticUnacceptedDetailFilter'
	)
});

const mapDispatchToProps = (dispatch) => ({
	setDepartment: (id) => dispatch(setDepartment(id)),
	getAllDepartments: () => dispatch(getAllDepartments()),
	getProfileInfoByProfileId: (id) => dispatch(getProfileInfoByProfileId(id)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload))
});

const areEqual = (prevProps, nextProps) => {
	return (
		// prevProps.department === nextProps.department &&
		prevProps.departments.length === nextProps.departments.length &&
		// prevProps.handleChangeDepartment === nextProps.handleChangeDepartment &&
		prevProps.statisticUnacceptedDetailFilter?.numJournal ===
			nextProps.statisticUnacceptedDetailFilter?.numJournal &&
		prevProps.statisticUnacceptedDetailFilter?.numDomesticJournal ===
			nextProps.statisticUnacceptedDetailFilter?.numDomesticJournal &&
		prevProps.statisticUnacceptedDetailFilter?.numConference ===
			nextProps.statisticUnacceptedDetailFilter?.numConference &&
		prevProps.statisticUnacceptedDetailFilter?.numDomesticConference ===
			nextProps.statisticUnacceptedDetailFilter?.numDomesticConference &&
		prevProps.statisticUnacceptedDetailFilter?.numBook ===
			nextProps.statisticUnacceptedDetailFilter?.numBook &&
		prevProps.statisticUnacceptedDetailFilter?.numOther ===
			nextProps.statisticUnacceptedDetailFilter?.numOther &&
		prevProps.statisticUnacceptedDetailFilter?.numSolution ===
			nextProps.statisticUnacceptedDetailFilter?.numSolution &&
		prevProps.statisticUnacceptedDetailFilter?.numPatent ===
			nextProps.statisticUnacceptedDetailFilter?.numPatent &&
		prevProps.statisticUnacceptedDetailFilter?.numProductContract ===
			nextProps.statisticUnacceptedDetailFilter?.numProductContract &&
		prevProps.statisticUnacceptedDetailFilter?.numResearchProject ===
			nextProps.statisticUnacceptedDetailFilter?.numResearchProject &&
		prevProps.statisticUnacceptedDetailFilter?.numMonograph ===
			nextProps.statisticUnacceptedDetailFilter?.numMonograph &&
		prevProps.isFetchingAcceptedScientificResearch ===
			nextProps.isFetchingAcceptedScientificResearch &&
		prevProps.isFetchingAcceptedScientificResearchOther ===
			nextProps.isFetchingAcceptedScientificResearchOther &&
		prevProps.isFetchingAcceptedSolution === nextProps.isFetchingAcceptedSolution &&
		prevProps.isFetchingAcceptedPatent === nextProps.isFetchingAcceptedPatent &&
		prevProps.isFetchingAcceptedContract === nextProps.isFetchingAcceptedContract &&
		prevProps.isFetchingAcceptedProject === nextProps.isFetchingAcceptedProject &&
		prevProps.isFetchingAcceptedMonograph === nextProps.isFetchingAcceptedMonograph
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(toJs(React.memo(ManagementTab, areEqual)));
