import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button, Box, Divider } from '@material-ui/core';
import { Replay, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getAllUsers } from '../../../reducer/humanResource/action';

// import HumanResourceInsertModal from '../HumanResourceInsertModal';
import { HumanResourceInsertProvider } from '../HumanResourceInsertModal/HumanResourceInsertContext';
import AutoInsertModal from '../AutoInsertModal';
import axios from 'axios';

const useStyles = makeStyles((theme) => {
	return {
		btnReload: {
			margin: theme.spacing(0, 1, 0, 0)
		},
		divider: {
			margin: theme.spacing(1, 0)
		}
	};
});

function HumanResourceButton(props) {
	const { getAllUsers, listProfile } = props;

	const handleReload = getAllUsers;

	const classes = useStyles();

	const [inserting, setInserting] = useState(false);
	const [listNewProfile, setListNewProfile] = useState([]);

	useEffect(() => {
		const getDataFromHRM = async () => {
			const hrmUrl =
				'https://hrm.phenikaa-uni.edu.vn/hrm/api/v1/profiles/apis?page=1&pageSize=-1&username=apis&password=ewdjkl213kSD22k3@k41JDa';
			const res = await axios.get(hrmUrl);
			if (res.status === 200) {
				const listProfileInHrm = res.data.data.listProfile;

				const dictEmail = {};
				listProfile.forEach((p) => (dictEmail[p.mail] = p));
				console.log(listProfileInHrm);
				const tempListNewProfile = [];
				listProfileInHrm.forEach((p) => {
					const existedInDb = p.email in dictEmail;
					if (!existedInDb && p.status === 'work') {
						tempListNewProfile.push({
							code: p.code,
							name: p.name,
							mail: p.email,
							birth: p.dateOfBirth
						});
					}
					// http://o-nckh-dev.piditi.com/api/v1/user POST
				});

				setListNewProfile(tempListNewProfile);
			}
		};

		if (listProfile.length > 0) getDataFromHRM();
	}, [listProfile]);

	return (
		<HumanResourceInsertProvider>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Typography variant="h5">
						<b>Quản lý nhân sự</b>
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
						<Button
							color="default"
							variant="outlined"
							className={classes.btnReload}
							startIcon={<Replay />}
							onClick={handleReload}
						>
							Tải lại
						</Button>
						<Button
							color="primary"
							variant="contained"
							startIcon={<Add />}
							onClick={() => setInserting(true)}
						>
							Thêm
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<AutoInsertModal
				open={inserting}
				setOpen={setInserting}
				listNewProfile={listNewProfile}
				setListNewProfile={setListNewProfile}
			/>
			{/* <HumanResourceInsertModal inserting={inserting} setInserting={setInserting} /> */}
		</HumanResourceInsertProvider>
	);
}

const mapStateToProps = (state) => ({
	listProfile: select(state, 'profileReducer', 'list')
});
const mapDispatchToProps = (dispatch) => ({
	getAllUsers: () => dispatch(getAllUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(HumanResourceButton));
