import React from 'react';
import { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Box,
	Button,
	Typography,
	Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useField } from 'formik';

import GridCellExpand from './GridCellExpand';

const useStyles = makeStyles((theme) => {
	return {
		tableParent: {
			height: 300
		},
		table: {
			'& .MuiDataGrid-colCellTitle': {
				fontWeight: 'bold'
			}
		},
		tableName: {
			fontWeight: 'bold'
		},
		btnSelect: {
			margin: theme.spacing(0, 0, 0, 1)
		}
	};
});

function PatentsOrSolutionsOrMonographsModal(props) {
	const { open, handleClose, patents, solutions, monographs } = props;

	const classes = useStyles();

	const [patentInput, , patentHelper] = useField(
		'scientificResearchs.patentsOrSolutionsOrMonographs.patents'
	);
	const [solutionInput, , solutionHelper] = useField(
		'scientificResearchs.patentsOrSolutionsOrMonographs.solutions'
	);
	const [monographInput, , monographHelper] = useField(
		'scientificResearchs.patentsOrSolutionsOrMonographs.monographs'
	);

	const [patentIds, setPatentIds] = useState(patentInput.value.map((element) => element.id));
	const [solutionIds, setSolutionIds] = useState(solutionInput.value.map((element) => element.id));
	const [monographIds, setMonographIds] = useState(
		monographInput.value.map((element) => element.id)
	);

	useEffect(() => {
		if (patentInput.value.length > 0 && patentIds.length === 0) {
			setPatentIds(patentInput.value.map((element) => element.id));
		}
		if (solutionInput.value.length > 0 && solutionIds.length === 0) {
			setSolutionIds(solutionInput.value.map((element) => element.id));
		}
		if (monographInput.value.length > 0 && monographIds.length === 0) {
			setMonographIds(monographInput.value.map((element) => element.id));
		}
	}, [solutionInput]);

	/**
	 * @type {import('@material-ui/data-grid').GridColDef[]}
	 */
	const patentColumns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 100,
			hide: true
		},
		{
			field: 'title',
			headerName: 'Tên sáng chế',
			width: 700,
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		}
	];

	/**
	 * @type {import('@material-ui/data-grid').GridColDef[]}
	 */
	const solutionColumns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 100,
			hide: true
		},
		{
			field: 'title',
			headerName: 'Tên giải pháp',
			width: 700
		}
	];

	/**
	 * @type {import('@material-ui/data-grid').GridColDef[]}
	 */
	const monographColumns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 100,
			hide: true
		},
		{
			field: 'title',
			headerName: 'Tên sách chuyên khảo',
			width: 700,
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		}
	];

	const patentRows = patents.map((patentElement) => {
		const id = patentElement.patent.id;
		const authors = patentElement.patent.authorTeam ?? 'Không tìm thấy nhóm tác giả';
		const patentTitle = patentElement.patent.title ?? 'Không tìm thấy tiêu đề';
		const year = new Date(patentElement.patent.acceptedDay).getFullYear() ?? 0;
		const identificationNumber =
			patentElement.patent.identificationNumber ?? 'Không tìm thấy mã số xác nhận';
		const issueBy = patentElement.patent.issueBy ?? 'Không tìm thấy cơ quan cấp';

		const title = `${authors} (${year}). ${patentTitle}. ${identificationNumber}. ${issueBy}`;
		return { id, title, year };
	});
	const solutionRows = solutions.map((solutionElement) => solutionElement.solution);
	const monographRows = monographs.map((monographElement) => {
		// // // console.log(monographElement)
		const id = monographElement.monograph.id;
		const authors = (monographElement.monograph.listMember ?? []).join(', ');
		const monographTitle = monographElement.monograph.title ?? 'Không tìm thấy tiêu đề';
		const year = monographElement.monograph.year ?? 0;
		const publisherTitle = monographElement.monograph.publisher ?? 'Không tìm thấy nhà xuất bản';
		const numOfPage = `${monographElement.monograph.pageNumber ?? 'N/A'} pages`;

		const title = `${authors}. ${monographTitle}. ${publisherTitle} (${year}). ${numOfPage}`;
		return { id, title, year };
		// return(monographElement.monograph)
	});

	/**
	 *
	 * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
	 */
	const handleChangePatentSelectionModal = (params) => {
		// select patents
		const selectedPatents = patentRows.filter((rowElement) => {
			if (params.includes(rowElement.id)) {
				return true;
			}
			return false;
		});
		// // // console.log('patent')
		patentHelper.setValue(selectedPatents);
		setPatentIds(params);
	};
	/**
	 *
	 * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
	 */
	const handleChangeSolutionSelectionModal = (params) => {
		// select solutions
		const selectedSolutions = solutionRows.filter((rowElement) => {
			if (params.includes(rowElement.id)) {
				return true;
			}
			return false;
		});
		// // // console.log('solution')
		solutionHelper.setValue(selectedSolutions);
		setSolutionIds(params);
	};
	/**
	 *
	 * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
	 */
	const handleChangeMonographSelectionModal = (params) => {
		// select monographs
		const selectedMonographs = monographRows.filter((rowElement) => {
			if (params.includes(rowElement.id)) {
				return true;
			}
			return false;
		});
		// // // console.log('mono')
		monographHelper.setValue(selectedMonographs);
		setMonographIds(params);
	};

	const handleSelect = () => {
		handleClose(false);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
			<DialogTitle>Công bố quốc tế khác</DialogTitle>
			<DialogContent>
				<Box paddingY={2}>
					<Typography className={classes.tableName}>Bằng sáng chế</Typography>
				</Box>
				<Box className={classes.tableParent}>
					<DataGrid
						checkboxSelection={true}
						className={classes.table}
						rows={patentRows}
						columns={patentColumns}
						selectionModel={patentIds}
						onSelectionModelChange={handleChangePatentSelectionModal}
					/>
				</Box>

				<Divider />

				<Box paddingY={2}>
					<Typography className={classes.tableName}>Giải pháp hữu ích</Typography>
				</Box>
				<Box className={classes.tableParent}>
					<DataGrid
						checkboxSelection={true}
						className={classes.table}
						rows={solutionRows}
						columns={solutionColumns}
						selectionModel={solutionIds}
						onSelectionModelChange={handleChangeSolutionSelectionModal}
					/>
				</Box>

				<Divider />

				<Box paddingY={2}>
					<Typography className={classes.tableName}>Sách chuyên khảo</Typography>
				</Box>
				<Box className={classes.tableParent}>
					<DataGrid
						checkboxSelection={true}
						className={classes.table}
						rows={monographRows}
						columns={monographColumns}
						selectionModel={monographIds}
						onSelectionModelChange={handleChangeMonographSelectionModal}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={() => handleClose(false)}>
					Hủy
				</Button>
				<Button
					variant="contained"
					color="primary"
					className={classes.btnSelect}
					onClick={handleSelect}
				>
					Chọn
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default PatentsOrSolutionsOrMonographsModal;
