import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getScientificResearchStatistic } from '../../../reducer/statistic/action';

const testOptions = {
	responsive: true,
	maintainAspectRatio: false,
	legend: { display: false, position: 'bottom' },
	title: { display: true, text: 'Số lượng công bố theo năm' },
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true
				}
			}
		]
	}
};

function ScientificResearchStatistic(props) {
	const ref = useRef(null);

	const thisDepartmentProfile = props.departmentList.find(
		(e) => e?.id === props?.chosenDepartmentId
	)?.departmentProfile;

	const checkBelongsToDepartment = (profileList) => {
		if (props?.chosenDepartmentId === 0) return true;

		for (var i = 0; i < profileList.length; i++) {
			const check = thisDepartmentProfile?.find((e) => e?.profileId === profileList[i]?.profileId);
			if (check) return true;
		}

		return false;
	};

	useEffect(() => {
		var year = [];
		for (var i = 2015; i <= parseInt(moment().format('YYYY')); i++) year.push(i);
		// if (props.profile)
		props.getScientificResearchStatistic({
			dashboardYear: year,
			// accepted: true,
			purposeId: ['0', '1', '2', '3'],
			type: [
				'chapterJournal',
				'chapterConference',
				'scientificResearchJournal',
				'scientificResearchConference',
				'chapterJournalDomestic',
				'chapterConferenceDomestic',
				'scientificResearchJournalDomestic',
				'scientificResearchConferenceDomestic'
			],
			acceptedOne: true
		});
	}, []);

	const renderStatistic = () => {
		var statisticNumber = {
			labels: [],
			datasets: [
				{
					label: 'Số lượng công bố theo năm',
					data: [],
					borderWidth: 1,
					backgroundColor: '#f4642320',
					borderColor: '#f46423'
				}
			]
		};

		for (var i = 2015; i <= parseInt(moment().format('YYYY')); i++) statisticNumber.labels.push(i);

		props.scientificResearchStatistic?.forEach((e) => {
			const cc = e.scientificResearchResponse?.filter((e) =>
				checkBelongsToDepartment(e?.scientificResearch?.scientificResearchProfile)
			);
			statisticNumber.datasets[0].data.push(cc.length);
		});

		return <Line data={statisticNumber} options={testOptions} />;
	};

	return (
		<div
			ref={ref}
			style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
		>
			{props.isFetching ? <CircularProgress /> : <>{renderStatistic()}</>}
		</div>
	);
}

const mapStateToProps = (state) => ({
	profile: select(state, 'profileReducer', 'detail'),
	departmentList: select(state, 'departmentReducer', 'departments'),
	scientificResearchStatistic: select(state, 'statisticReducer', 'scientificResearchStatistic'),
	isFetching: select(state, 'statisticReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	getScientificResearchStatistic: (payload) => dispatch(getScientificResearchStatistic(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ScientificResearchStatistic));
