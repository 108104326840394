import React, { useState, useEffect, useRef, memo } from 'react';
import { Paper, Typography, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { isOverflown } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => {
	return {
		// Copied from https://material-ui.com/components/data-grid/rendering/#render-cell
		root: {
			alignItems: 'center',
			lineHeight: '24px',
			width: '100%',
			height: '100%',
			position: 'relative',
			display: 'flex',
			'& .cellValue': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}
		}
	};
});

/**
 * @description
 * Copied from https://material-ui.com/components/data-grid/rendering/#render-cell
 *
 * Add zIndex (> 1300) to Popper
 */
const GridCellExpand = memo(function GridCellExpand(props) {
	const { width, value } = props;
	const wrapper = useRef(null);
	const cellDiv = useRef(null);
	const cellValue = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useStyles();
	const [showFullCell, setShowFullCell] = useState(false);
	const [showPopper, setShowPopper] = useState(false);

	const handleMouseEnter = () => {
		// const isCurrentlyOverflown = isOverflown(cellValue.current);
		setShowPopper(true);
		setAnchorEl(cellDiv.current);
		setShowFullCell(true);
	};

	const handleMouseLeave = () => {
		setShowFullCell(false);
	};

	useEffect(() => {
		if (!showFullCell) {
			return undefined;
		}

		function handleKeyDown(nativeEvent) {
			// IE11, Edge (prior to using Bink?) use 'Esc'
			if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
				setShowFullCell(false);
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [setShowFullCell, showFullCell]);

	return (
		<div
			ref={wrapper}
			className={classes.root}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				ref={cellDiv}
				style={{
					height: 1,
					width,
					display: 'block',
					position: 'absolute',
					top: 0
				}}
			/>
			<div ref={cellValue} className="cellValue">
				{value}
			</div>
			{showPopper && (
				<Popper
					open={showFullCell && anchorEl !== null}
					anchorEl={anchorEl}
					style={{ width, marginLeft: -17, zIndex: 100000 }}
				>
					<Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
						<Typography variant="body2" style={{ padding: 8 }}>
							{value}
						</Typography>
					</Paper>
				</Popper>
			)}
		</div>
	);
});

export default GridCellExpand;
