import { useState, createContext } from 'react';

/**
 * @typedef {Object} DepartmentContextType
 * @property {number} DepartmentContextType.selectedDepartmentId
 * @property {React.SetStateAction<number>} DepartmentContextType.setSelectedDepartmentId
 * @property {boolean} DepartmentContextType.insertingDepartment
 * @property {React.SetStateAction<boolean>} DepartmentContextType.setInsertingDepartment
 * @property {boolean} DepartmentContextType.insertingDepartmentMember
 * @property {React.SetStateAction<boolean>} DepartmentContextType.setInsertingDepartmentMember
 */

/**
 * @type {React.Context<DepartmentContextType>}
 */
export const DepartmentContext = createContext();

/**
 *
 * @param {React.PropsWithChildren<{}>} props
 */
export const DepartmentProvider = (props) => {
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
	const [insertingDepartment, setInsertingDepartment] = useState(false);
	const [insertingDepartmentMember, setInsertingDepartmentMember] = useState(false);

	return (
		<DepartmentContext.Provider
			value={{
				selectedDepartmentId,
				setSelectedDepartmentId,
				insertingDepartment,
				setInsertingDepartment,
				insertingDepartmentMember,
				setInsertingDepartmentMember
			}}
		>
			{props.children}
		</DepartmentContext.Provider>
	);
};
