import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	makeStyles,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Collapse,
	Box,
	IconButton
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { deleteScientificResearch } from '../../../reducer/scientificResearch/action';

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	}
});

function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.title}
				</TableCell>
				<TableCell align="right">{row.numberOfDuplicate}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								Các công bố trùng
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>Tiêu đề</TableCell>
										<TableCell>Tạp chí/Hội thảo</TableCell>
										<TableCell>Nhà xuất bản</TableCell>
										<TableCell>Tác giả</TableCell>
										<TableCell align="right">Tác vụ</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.listDuplicate.map((duplicate) => (
										<TableRow key={duplicate.scientificResearch.id}>
											<TableCell component="th" scope="row">
												{duplicate.scientificResearch.title}
											</TableCell>
											<TableCell>
												{duplicate?.journal?.title ?? 'Không có tên tạp chí/hội thảo'}
											</TableCell>
											<TableCell>
												{duplicate?.journal?.publisher ?? 'Không có nhà xuất bản'}
											</TableCell>
											<TableCell>
												{duplicate.scientificResearch?.listAuthor?.join(', ') ??
													'Không có danh sách tác giả'}
											</TableCell>
											<TableCell align="right">
												<Typography
													className="table-action-buton"
													variant="caption"
													color="primary"
													onClick={() => props.handleDetail(duplicate)}
												>
													Chi tiết
												</Typography>
												<Typography
													className="table-action-buton"
													variant="caption"
													color="secondary"
													onClick={() => props.handleDelete(duplicate)}
												>
													Xoá
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

function ScientificResearchCheckDupicateModal(props) {
	const [detail, setDetail] = useState(null);

	const rowsData = props.data.map((item, index) => {
		return {
			key: index + 1,
			title: item.duplicateScientificResearch[0].scientificResearch.title,
			numberOfDuplicate: item.duplicateScientificResearch.length,
			listDuplicate: item.duplicateScientificResearch
		};
	});

	const handleDetail = (row) => {
		setDetail(row);
	};

	const handleDelete = (row) => {
		props.deleteScientificResearch(row.scientificResearch.id);
	};

	return (
		<div>
			<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
				<DialogTitle id="alert-dialog-title">{'Danh sách công bố trùng theo tiêu đề'}</DialogTitle>
				<DialogContent>
					{props.loading ? (
						<CircularProgress />
					) : (
						<TableContainer component={Paper}>
							<Table aria-label="collapsible table">
								<TableHead>
									<TableRow>
										<TableCell />
										<TableCell>Tiêu đề công bố trùng</TableCell>
										<TableCell align="right">Số lượng công bố trùng</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rowsData.map((row) => (
										<Row
											key={row.key}
											row={row}
											handleDetail={handleDetail}
											handleDelete={handleDelete}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={props.onClose} color="primary" autoFocus>
						Đóng
					</Button>
				</DialogActions>
			</Dialog>

			{/* Modal chi tiết công bố  */}
			<Dialog open={detail !== null} onClose={() => setDetail(null)} fullWidth maxWidth="lg">
				<DialogTitle id="alert-dialog-title">{'Chi tiết công bố'}</DialogTitle>
				<DialogContent>
					<Typography variant="h5">Tên công bố</Typography>
					<Typography variant="h6" gutterBottom>
						{detail?.scientificResearch?.title ?? 'Không tìm thấy tiêu đề'}
					</Typography>

					<Typography variant="h6">Tạp chí/Hội thảo - Nhà xuất bản</Typography>
					<Typography>
						{detail?.journal?.title ??
							detail?.journalDomestic?.title ??
							detail?.conference?.title ??
							// detail?.scientificResearch?.otherTitle ??
							'Không tìm thấy tạp chí/hội thảo'}{' '}
						-{' '}
						{detail?.journal?.publisher ??
							detail?.journalDomestic?.publisher ??
							detail?.conference?.publisher ??
							'Không tìm thấy nhà xuất bản'}
					</Typography>
					<Typography gutterBottom>
						ISSN/ISBN: <b>{detail?.scientificResearch?.issn ?? 'mã số ISSN'}</b>
						{' - '}
						Issue: <b>{detail?.scientificResearch?.issue ?? 'N/A'}</b>
						{' - '}
						Volume: <b>{detail?.scientificResearch?.volume ?? 'N/A'}</b>
						{' - '}
						Page: <b>{detail?.scientificResearch?.page ?? 'N/A'}</b>
					</Typography>

					<Typography variant="h6">Danh sách tác giả</Typography>
					<Typography>
						Số lượng tác giả: <b>{detail?.scientificResearch?.numOfMember ?? 'N/A'}</b>
						{' - '}
						Số lượng tác giả chính: <b>{detail?.scientificResearch?.numberMainAuthor ?? 'N/A'}</b>
					</Typography>
					<Typography gutterBottom>
						{detail?.scientificResearch?.listAuthor.join(', ') ??
							'Không tìm thấy danh sách tác giả'}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDetail(null)} color="primary" autoFocus>
						Đóng
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const mapStateToProps = (state) => ({
	// role: select(state, "userReducer", "role"),
});

const mapDispatchToProps = (dispatch) => ({
	// pushNoti: (payload) => dispatch(notification(payload)),
	deleteScientificResearch: (id) => dispatch(deleteScientificResearch(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(toJs(ScientificResearchCheckDupicateModal));
