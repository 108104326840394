import React from 'react';
import { Box, Typography, Grid, Paper, IconButton, Button, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { FieldArray, FastField, ErrorMessage, useField } from 'formik';

const useStyles = makeStyles((theme) =>
	createStyles({
		sectionTitle: {
			fontWeight: 'bold',
			textTransform: 'uppercase'
		},
		languageElement: {
			padding: theme.spacing(2, 1),
			margin: theme.spacing(1, 0)
		}
	})
);

function Languages(props) {
	const classes = useStyles();

	const [languageInput] = useField('languages');

	return (
		<>
			<Box py={2}>
				<Box>
					<Typography variant="h6" className={classes.sectionTitle}>
						3. Ngoại ngữ
					</Typography>
				</Box>
			</Box>
			<FieldArray name="languages">
				{(arrayHelpers) => {
					const handleAddLanguageElement = () => {
						arrayHelpers.push({
							language: '',
							reading: '',
							writing: '',
							speaking: ''
						});
					};

					const handleDeleteLanguageElement = (index) => {
						arrayHelpers.remove(index);
					};

					return (
						<>
							{languageInput.value.map((_languageElement, languageIndex) => {
								return (
									<Paper variant="outlined" className={classes.languageElement} key={languageIndex}>
										<Box display="flex" flexDirection="row" alignItems="center">
											<Grid container spacing={2}>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`languages[${languageIndex}].language`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Ngoại ngữ"
																	helperText={
																		<ErrorMessage name={`languages[${languageIndex}].language`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`languages[${languageIndex}].reading`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Đọc"
																	helperText={
																		<ErrorMessage name={`languages[${languageIndex}].reading`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`languages[${languageIndex}].writing`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Viết"
																	helperText={
																		<ErrorMessage name={`languages[${languageIndex}].writing`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={3} md={6} sm={12}>
													<FastField name={`languages[${languageIndex}].speaking`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Nói"
																	helperText={
																		<ErrorMessage name={`languages[${languageIndex}].speaking`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
											</Grid>
											<Box flexShrink={1} paddingLeft={2}>
												<IconButton
													color="secondary"
													title="Xóa"
													onClick={() => handleDeleteLanguageElement(languageIndex)}
												>
													<Delete />
												</IconButton>
											</Box>
										</Box>
									</Paper>
								);
							})}
							<Box paddingTop={1}>
								<Button
									variant="outlined"
									color="primary"
									startIcon={<Add />}
									onClick={handleAddLanguageElement}
								>
									Thêm
								</Button>
							</Box>
						</>
					);
				}}
			</FieldArray>
		</>
	);
}

export default Languages;
