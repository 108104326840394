import React, { useState } from 'react';
import { Group, RateReview, TimeToLeave } from '@material-ui/icons';
import { Box, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';
import { updateInternalResearch } from '../../../../reducer/internalResearch/action';
import theme from '../../../../theme';
import select from '../../../../utils/select';

const RESEARCH_TYPE = {
	APPLICATIONAL: 'applicational',
	BASIC: 'basic'
};

function InstitutionalProjectCouncilForm(props) {
	const dispatch = useDispatch();

	const { values, setValues, council, setCouncil, councilApp, setCouncilApp, isAdd } = props;

	const disabled = props.id === 'council-detail-form';
	const isAddCouncil = values?.researchType !== RESEARCH_TYPE.APPLICATIONAL && isAdd;

	const handleChangeForm = (key, val, type) => {
		if (type === 'file') {
			setCouncil({ ...council, [key]: val?.[0] });
			return;
		} else if (type === 'autoComplete') {
			const roleKey = key.replace('Name', '');
			const isPhenikaa = council?.[`${roleKey}IsPhenikaa`] || council?.[`${roleKey}Pid`];

			setCouncil({
				...council,
				[`${roleKey}Name`]: val?.name,
				[`${roleKey}Degree`]: val?.degree,
				[`${roleKey}Unit`]: isPhenikaa ? 'Trường Đại học Phenikaa' : val?.cvInfo?.department,
				[`${roleKey}Pid`]: val?.id
			});
			return;
		}
		setCouncil({ ...council, [key]: val });
	};

	const getCommissionerData = (data) => [
		{
			key: 'chairman',
			title: 'Chủ tịch',
			name: data.chairmanName,
			degree: data.chairmanDegree,
			unit: data.chairmanUnit,
			required: true
		},
		{
			key: 'secretary',
			title: 'Thư ký',
			name: data.secretaryName,
			degree: data.secretaryDegree,
			unit: data.secretaryUnit,
			required: true
		},
		{
			key: 'firstReviewer',
			title: 'Phản biện 1',
			name: data.firstReviewerName,
			degree: data.firstReviewerDegree,
			unit: data.firstReviewerUnit,
			required: false
		},
		{
			key: 'secondReviewer',
			title: 'Phản biện 2',
			name: data.secondReviewerName,
			degree: data.secondReviewerDegree,
			unit: data.secondReviewerUnit,
			required: false
		},
		{
			key: 'firstCommissioner',
			title: 'Ủy viên 1',
			name: data.firstCommissionerName,
			degree: data.firstCommissionerDegree,
			unit: data.firstCommissionerUnit,
			required: false
		},
		{
			key: 'secondCommissioner',
			title: 'Ủy viên 2',
			name: data.secondCommissionerName,
			degree: data.secondCommissionerDegree,
			unit: data.secondCommissionerUnit,
			required: false
		},
		{
			key: 'thirdCommissioner',
			title: 'Ủy viên 3',
			name: data.thirdCommissionerName,
			degree: data.thirdCommissionerDegree,
			unit: data.thirdCommissionerUnit,
			required: false
		},
		{
			key: 'fourthCommissioner',
			title: 'Ủy viên 4',
			name: data.fourthCommissionerName,
			degree: data.fourthCommissionerDegree,
			unit: data.fourthCommissionerUnit,
			required: false
		},
		{
			key: 'fifthCommissioner',
			title: 'Ủy viên 5',
			name: data.fifthCommissionerName,
			degree: data.fifthCommissionerDegree,
			unit: data.fifthCommissionerUnit,
			required: false
		},
		{
			key: 'sixthCommissioner',
			title: 'Ủy viên 6',
			name: data.sixthCommissionerName,
			degree: data.sixthCommissionerDegree,
			unit: data.sixthCommissionerUnit,
			required: false
		},
		{
			key: 'seventhCommissioner',
			title: 'Ủy viên 7',
			name: data.seventhCommissionerName,
			degree: data.seventhCommissionerDegree,
			unit: data.seventhCommissionerUnit,
			required: false
		},
		{
			key: 'eighthCommissioner',
			title: 'Ủy viên 8',
			name: data.eighthCommissionerName,
			degree: data.eighthCommissionerDegree,
			unit: data.eighthCommissionerUnit,
			required: false
		},
		{
			key: 'ninthCommissioner',
			title: 'Ủy viên 9',
			name: data.ninthCommissionerName,
			degree: data.ninthCommissionerDegree,
			unit: data.ninthCommissionerUnit,
			required: false
		}
	];
	const commissioners = getCommissionerData(council);
	const profileList = useSelector((state) => select(state, 'profileReducer', 'list'));

	const textForm = [
		{
			key: 'box1',
			xs: 12,
			type: 'render',
			render: () => (
				<Box
				// maxHeight='calc(100vh - 216px)'
				>
					<Box border="solid 1px #bcbcbc" borderRadius={4}>
						<Box
							display="flex"
							bgcolor="#ececec"
							padding="8px 16px"
							alignItems="center"
							borderBottom="solid 1px #bcbcbc"
							style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
						>
							<Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
							<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
								<b>{`DANH SÁCH HỘI ĐỒNG NGHIỆM THU ${values?.researchType === RESEARCH_TYPE.APPLICATIONAL ? 'SẢN PHẨM' : ''}`}</b>
							</Typography>
						</Box>
						<Box padding={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid
										container
										spacing={2}
										alignItems="center"
										style={{
											padding: '12px 0',
											backgroundColor: '#fafafa',
											borderBottom: 'solid 1px #e0e0e0'
										}}
									>
										<Grid item xs={2}>
											<Typography variant="body1">
												<b></b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Tên thành viên</b>
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography variant="body1">
												<b>Học hàm, học vị</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Đơn vị</b>
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography variant="body1">
												<b>Vai trò</b>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									{commissioners.map((role) => {
										const { key, title, required } = role;
										const isPhenikaa = council?.[`${key}IsPhenikaa`] || council?.[`${key}Pid`];

										return isAddCouncil ? (
											<React.Fragment key={key}>
												<Grid
													item
													sx={12}
													style={{
														display: 'flex'
													}}
												>
													<Grid item xs={5}>
														<Box display="flex">
															<Box width="43%" marginRight={1}>
																<KPTextfield
																	value={isPhenikaa || false}
																	id={`${key}IsPhenikaa`}
																	type="checkbox"
																	name="GV trong trường"
																	handleChangeForm={handleChangeForm}
																/>
															</Box>

															<Box width="57%">
																<KPTextfield
																	type={isPhenikaa ? 'autoComplete' : null}
																	options={isPhenikaa ? profileList : null}
																	getOptionLabel={(option) => {
																		if (!option) return '';
																		const code = option.code || '';
																		const name = option.name || '';
																		if (code && name) {
																			return `${code} - ${name}`;
																		} else if (code) {
																			return code;
																		} else if (name) {
																			return name;
																		} else {
																			return '';
																		}
																	}}
																	value={
																		isPhenikaa
																			? profileList.find((e) => e.id === council?.[`${key}Pid`])
																			: council?.[`${key}Name`]
																	}
																	id={`${key}Name`}
																	handleChangeForm={handleChangeForm}
																	required={required}
																/>
															</Box>
														</Box>
													</Grid>
													<Grid item xs={2}>
														<KPTextfield
															value={council?.[`${key}Degree`]}
															id={`${key}Degree`}
															handleChangeForm={handleChangeForm}
															required={required}
															disabled={isPhenikaa}
														/>
													</Grid>
													<Grid item xs={3}>
														<KPTextfield
															id={`${key}Unit`}
															value={council?.[`${key}Unit`]}
															handleChangeForm={handleChangeForm}
															disabled={isPhenikaa}
															required={required}
														/>
													</Grid>
													<Grid item xs={2}>
														<KPTextfield value={title} disabled required={required} />
													</Grid>
												</Grid>
											</React.Fragment>
										) : (
											<React.Fragment key={key}>
												<Grid
													item
													sx={12}
													style={{
														display: 'flex'
													}}
												>
													<Grid item xs={5}>
														<Box display="flex">
															<Box width="43%" marginRight={1}>
																<KPTextfield
																	value={isPhenikaa || false}
																	id={`${key}IsPhenikaa`}
																	type="checkbox"
																	name="GV trong trường"
																	handleChangeForm={handleChangeForm}
																	disabled
																/>
															</Box>

															<Box width="57%">
																<KPTextfield
																	type={isPhenikaa ? 'autoComplete' : null}
																	options={isPhenikaa ? profileList : null}
																	getOptionLabel={(option) => {
																		if (!option) return '';
																		const code = option.code || '';
																		const name = option.name || '';
																		if (code && name) {
																			return `${code} - ${name}`;
																		} else if (code) {
																			return code;
																		} else if (name) {
																			return name;
																		} else {
																			return '';
																		}
																	}}
																	value={
																		isPhenikaa
																			? profileList.find((e) => e.id === council?.[`${key}Pid`])
																			: council?.[`${key}Name`]
																	}
																	id={`${key}Name`}
																	handleChangeForm={handleChangeForm}
																	required={true}
																	disabled
																/>
															</Box>
														</Box>
													</Grid>
													<Grid item xs={2}>
														<KPTextfield
															value={role?.[`degree`]}
															id={`${key}Degree`}
															handleChangeForm={handleChangeForm}
															required={true}
															disabled
														/>
													</Grid>
													<Grid item xs={3}>
														<KPTextfield value={role?.[`unit`]} disabled />
													</Grid>
													<Grid item xs={2}>
														<KPTextfield value={title} disabled />
													</Grid>
												</Grid>
											</React.Fragment>
										);
									})}
								</Grid>
							</Grid>
						</Box>
					</Box>

					<Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
						<Box
							display="flex"
							bgcolor="#ececec"
							padding="8px 16px"
							alignItems="center"
							borderBottom="solid 1px #bcbcbc"
							style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
						>
							<RateReview
								style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }}
							/>
							<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
								<b>{`DANH SÁCH PHẢN BIỆN ${values?.researchType === RESEARCH_TYPE.APPLICATIONAL ? '(HỘI ĐỒNG NGHIỆM THU SẢN PHẨM)' : ''}`}</b>
							</Typography>
						</Box>
						<Box padding={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid
										container
										spacing={2}
										style={{
											padding: '12px 0',
											backgroundColor: '#fafafa',
											borderBottom: 'solid 1px #e0e0e0'
										}}
									>
										<Grid item xs={4}>
											<Typography variant="body1">
												<b>Tên thành viên</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Học hàm, học vị</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Đơn vị</b>
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography variant="body1">
												<b>Vai trò</b>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<KPTextfield
												id="council.firstReviewerName"
												disabled={disabled}
												value={council?.firstReviewerName}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="council.firstReviewerDegree"
												disabled={disabled}
												value={council?.firstReviewerDegree}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="council.firstReviewerUnit"
												disabled={disabled}
												value={council?.firstReviewerUnit}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Phản biện 1" disabled={true} required={true} />
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												id="council.secondReviewerName"
												disabled={disabled}
												value={council?.secondReviewerName}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="council.secondReviewerDegree"
												disabled={disabled}
												value={council?.secondReviewerDegree}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="council.secondReviewerUnit"
												disabled={disabled}
												value={council?.secondReviewerUnit}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Phản biện 2" disabled={true} />
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												id="council.thirdReviewerName"
												disabled={disabled}
												value={council?.thirdReviewerName}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="council.thirdReviewerDegree"
												disabled={disabled}
												value={council?.thirdReviewerDegree}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="council.thirdReviewerUnit"
												disabled={disabled}
												value={council?.thirdReviewerUnit}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Phản biện 3" disabled={true} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			)
		}
	];

	if (values?.researchType === RESEARCH_TYPE.APPLICATIONAL) {
		textForm.push({
			key: 'box3',
			xs: 12,
			type: 'render',
			render: () => (
				<Box
				// maxHeight='calc(100vh - 216px)'
				>
					<Box border="solid 1px #bcbcbc" borderRadius={4} marginTop={2}>
						<Box
							display="flex"
							bgcolor="#ececec"
							padding="8px 16px"
							alignItems="center"
							borderBottom="solid 1px #bcbcbc"
							style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
						>
							<Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
							<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
								<b>DANH SÁCH HỘI ĐỒNG NGHIỆM THU ĐỀ TÀI</b>
							</Typography>
						</Box>
						<Box padding={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid
										container
										spacing={2}
										alignItems="center"
										style={{
											padding: '12px 0',
											backgroundColor: '#fafafa',
											borderBottom: 'solid 1px #e0e0e0'
										}}
									>
										<Grid item xs={4}>
											<Typography variant="body1">
												<b>Tên thành viên</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Học hàm, học vị</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Đơn vị</b>
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography variant="body1">
												<b>Vai trò</b>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<KPTextfield
												id="councilApp.chairmanName"
												disabled={disabled}
												value={councilApp?.chairmanName}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.chairmanDegree"
												disabled={disabled}
												value={councilApp?.chairmanDegree}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.chairmanUnit"
												disabled={disabled}
												value={councilApp?.chairmanUnit}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Chủ tịch" disabled={true} required={true} />
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												id="councilApp.secretaryName"
												disabled={disabled}
												value={councilApp?.secretaryName}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.secretaryDegree"
												disabled={disabled}
												value={councilApp?.secretaryDegree}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.secretaryUnit"
												disabled={disabled}
												value={councilApp?.secretaryUnit}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Thư ký" disabled={true} required={true} />
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												id="councilApp.commissionerName"
												disabled={disabled}
												value={councilApp?.commissionerName}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.commissionerDegree"
												disabled={disabled}
												value={councilApp?.commissionerDegree}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.commissionerUnit"
												disabled={disabled}
												value={councilApp?.commissionerUnit}
												handleChangeForm={handleChangeForm}
												required={true}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Uỷ viên" disabled={true} required={true} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>

					<Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
						<Box
							display="flex"
							bgcolor="#ececec"
							padding="8px 16px"
							alignItems="center"
							borderBottom="solid 1px #bcbcbc"
							style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
						>
							<RateReview
								style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }}
							/>
							<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
								<b>DANH SÁCH PHẢN BIỆN (HỘI ĐỒNG NGHIỆM THU ĐỀ TÀI)</b>
							</Typography>
						</Box>
						<Box padding={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid
										container
										spacing={2}
										style={{
											padding: '12px 0',
											backgroundColor: '#fafafa',
											borderBottom: 'solid 1px #e0e0e0'
										}}
									>
										<Grid item xs={4}>
											<Typography variant="body1">
												<b>Tên thành viên</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Học hàm, học vị</b>
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1">
												<b>Đơn vị</b>
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography variant="body1">
												<b>Vai trò</b>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<KPTextfield
												id="councilApp.firstCommissionerName"
												disabled={disabled}
												value={councilApp?.firstCommissionerName}
												handleChangeForm={handleChangeForm}
												required={false}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.firstCommissionerDegree"
												disabled={disabled}
												value={councilApp?.firstCommissionerDegree}
												handleChangeForm={handleChangeForm}
												required={false}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.firstCommissionerUnit"
												disabled={disabled}
												value={councilApp?.firstCommissionerUnit}
												handleChangeForm={handleChangeForm}
												required={false}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Phản biện 1" disabled={true} required={true} />
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												id="councilApp.secondCommissionerName"
												disabled={disabled}
												value={councilApp?.secondCommissionerName}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.secondCommissionerDegree"
												disabled={disabled}
												value={councilApp?.secondCommissionerDegree}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.secondCommissionerUnit"
												disabled={disabled}
												value={councilApp?.secondCommissionerUnit}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Phản biện 2" disabled={true} />
										</Grid>
										<Grid item xs={4}>
											<KPTextfield
												id="councilApp.thirdCommissionerName"
												disabled={disabled}
												value={councilApp?.thirdCommissionerName}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.thirdCommissionerDegree"
												disabled={disabled}
												value={councilApp?.thirdCommissionerDegree}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={3}>
											<KPTextfield
												id="councilApp.thirdCommissionerUnit"
												disabled={disabled}
												value={councilApp?.thirdCommissionerUnit}
												handleChangeForm={handleChangeForm}
											/>
										</Grid>
										<Grid item xs={2}>
											<KPTextfield value="Phản biện 3" disabled={true} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			)
		});
	}

	const handleSubmit = () => {
		props.handleClose();
		var statusList = props.detail.status.map((e) => e.value);
		statusList[1] = 'council_accepted';

		dispatch(
			updateInternalResearch({
				...props.detail,
				internalCouncilInfos:
					props.detail.researchType === RESEARCH_TYPE.BASIC
						? [
								...props.detail.internalCouncilInfos?.filter(
									(e) => e?.name !== 'project' && e?.name !== 'application'
								),
								{ ...council, name: 'accept-project' }
							]
						: [
								...props.detail.internalCouncilInfos?.filter(
									(e) => e?.name !== 'project' && e?.name !== 'application'
								),
								{ ...council, name: 'accept-project' },
								{ ...councilApp, name: 'accept-application' }
							],
				status: statusList.join(',')
			})
		);
	};

	return (
		<KPTextform
			id={props.id}
			textForm={textForm}
			values={values}
			setValues={setValues}
			handleChangeForm={handleChangeForm}
			handleSubmit={handleSubmit}
		/>
	);
}

export default InstitutionalProjectCouncilForm;
