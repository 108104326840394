import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	TextField,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button
} from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import KPTable from '../../../../components/KPTable';

import {
	updateEarningsTable,
	deleteEarningsTable,
	getEarningsTable
} from '../../../../reducer/earningsTable/action';

function RewardInformationTable(props) {
	const [editting, setEditting] = useState(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [deleteId, setDeleteId] = useState(null);

	const valueFormat = (value) =>
		value.replace(/\D/g, '').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.');
	console.log(11111, props);
	const handleSubmit = async () => {
		try {
			await props.updateEarningsTable(editting.id, editting);
			const year = props.year;
			await props.getEarningsTable(year);
			setEditting(null);
		} catch (error) {
			console.error('Cập nhật hoặc lấy dữ liệu thất bại:', error);
		}
	};

	const handleChange = (value) =>
		setEditting({
			...editting,
			value: parseInt(value.replaceAll('.', ''))
		});

	const handleOpenDeleteDialog = (id) => {
		setDeleteId(id);
		setDeleteDialogOpen(true);
	};

	const handleCloseDeleteDialog = () => {
		setDeleteDialogOpen(false);
		setDeleteId(null);
	};

	const handleConfirmDelete = async () => {
		try {
			const year = props.year || 2022;
			await props.deleteEarningsTable(deleteId);
			await props.getEarningsTable(year);
			handleCloseDeleteDialog();
		} catch (error) {
			console.error('Xóa hoặc lấy dữ liệu thất bại:', error);
		}
	};

	return (
		<>
			<KPTable
				shadow="none"
				rowsPerPage={100}
				maxHeight="calc(100vh - 288px)"
				columns={[
					{
						title: <b>ID</b>,
						key: 'id',
						width: '10%'
					},
					{
						title: <b>Loại</b>,
						key: 'type',
						width: '20%',
						filters: [
							{
								text: 'Bài báo khoa học',
								value: 'scientificResearch'
							},
							{
								text: 'Bằng sáng chế',
								value: 'patent'
							},
							{
								text: 'Giải pháp hữu ích',
								value: 'solution'
							}
						],
						onFilter: (value, row) => row.filter((e) => e.type === value),
						render: (value) => {
							if (value === 'scientificResearch') return 'Bài báo khoa học';
							if (value === 'patent') return 'Bằng sáng chế';
							if (value === 'solution') return 'Giải pháp hữu ích';
							return value;
						}
					},
					{
						title: <b>Chỉ tiêu đăng ký</b>,
						key: 'condition',
						width: '20%',
						searchable: true
					},
					{
						title: <b>Mức thu nhập hàng tháng</b>,
						key: 'value',
						width: '20%',
						render: (value, row) => {
							if (editting?.id === row.id) {
								return (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
											size="small"
											variant="outlined"
											value={valueFormat(editting?.value.toString() || '')}
											onChange={(event) => handleChange(event.target.value)}
										/>
									</form>
								);
							}
							return valueFormat(value.toString());
						}
					},
					{
						title: <b>Tác vụ</b>,
						key: 'action',
						width: '20%',
						render: (value, row) => (
							<>
								{editting?.id !== row.id ? (
									<>
										<Typography
											className="table-action-buton"
											variant="caption"
											color="primary"
											onClick={() => setEditting(row)}
										>
											Sửa
										</Typography>
										<Typography
											className="table-action-buton"
											variant="caption"
											color="secondary"
											onClick={() => handleOpenDeleteDialog(row.id)}
										>
											Xoá
										</Typography>
									</>
								) : (
									<>
										<Typography
											className="table-action-buton"
											variant="caption"
											color="primary"
											onClick={handleSubmit}
										>
											Lưu
										</Typography>
										<Typography
											className="table-action-buton"
											variant="caption"
											color="secondary"
											onClick={() => setEditting(null)}
										>
											Huỷ
										</Typography>
									</>
								)}
							</>
						)
					}
				]}
				rows={props.list.filter(
					(e) =>
						e.condition !== 'chapterEnglish' &&
						e.condition !== 'chapterOther' &&
						e.condition !== 'monographEnglish' &&
						e.condition !== 'monographOther'
				)}
			/>
			<Dialog
				open={deleteDialogOpen}
				onClose={handleCloseDeleteDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Xác nhận xoá?'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Bạn có chắc chắn muốn xoá mục này không?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteDialog} color="primary">
						HUỶ
					</Button>
					<Button onClick={handleConfirmDelete} color="primary" autoFocus>
						XÁC NHẬN
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const mapStateToProps = (state) => ({
	list: select(state, 'earningsTableReducer', 'list'),
	year: select(state, 'earningsTableReducer', 'year') || 2022
});

const mapDispatchToProps = (dispatch) => ({
	updateEarningsTable: (id, payload) => dispatch(updateEarningsTable(id, payload)),
	deleteEarningsTable: (id) => dispatch(deleteEarningsTable(id)),
	getEarningsTable: (year) => dispatch(getEarningsTable(year))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(RewardInformationTable));
