import React from 'react';

import JournalButton from '../../containers/Journal/JournalButton';
import JournalTable from '../../containers/Journal/JournalTable';

function Journal() {
	const [filterOpen, setFilterOpen] = React.useState(false);

	return (
		<>
			<JournalButton filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
			<JournalTable filterOpen={filterOpen} />
		</>
	);
}

export default Journal;
