import React, { useState } from 'react';

import { valueFormat } from '../../utils/valueFormat';

import ScientificResearchTable from '../../containers/ScientificResearch/ScientificResearchTable';
import ScientificResearchButton from '../../containers/ScientificResearch/ScientificResearchButton';

function ScientificResearch(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [date, setDate] = useState(null);

	const list = window.location.href.split('/');
	const lastValueList = list[list.length - 1].split('=');
	const selectedProfileId =
		lastValueList.length > 1 ? lastValueList[lastValueList.length - 1] : null;

	return (
		<div style={{ padding: 8, minHeight: 'calc(100vh - 200px)', maxHeight: 'calc(100vh - 200px)' }}>
			<ScientificResearchButton
				adminTab={props.adminTab}
				department={props.department}
				type={props.type}
				selectedProfileId={selectedProfileId}
				handleReload={props.handleReload}
				other={props.other}
				setAnchorEl={setAnchorEl}
			/>
			<ScientificResearchTable
				adminTab={props.adminTab}
				department={props.department}
				type={props.type}
				selectedProfileId={selectedProfileId}
				handleReload={props.handleReload}
				other={props.other}
				reportAnchorEl={anchorEl}
				dateString={date}
				setDateString={setDate}
				setReportAnchorEl={setAnchorEl}
				date={{
					startAt: valueFormat.stringToDate(date?.startAt),
					finishAt: valueFormat.stringToDate(date?.finishAt)
				}}
			/>
		</div>
	);
}

export default ScientificResearch;
