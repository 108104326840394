import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	Box,
	Tab,
	Tabs,
	Card,
	Grid,
	Select,
	Divider,
	MenuItem,
	TextField,
	InputLabel,
	FormControl
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { getAllDepartments } from '../../reducer/department/action';

import toJs from '../../hoc/toJS';
import select from '../../utils/select';
import TimeResearchTable from '../../containers/Report/TimeResearchTable/TimeResearchTable';
import ReviewContractTable from '../../containers/Report/ReviewContractTable/ReviewContractTable';
import ContractRewardTable from '../../containers/Report/ContractRewardTable/ContractRewardTable';

const getCurrentYear = () => parseInt(moment().format('YYYY'));

function Report(props) {
	const [tab, setTab] = useState(0);
	const [year, setYear] = useState(getCurrentYear());
	const [department, setDepartment] = useState(0);

	useEffect(() => {
		if (props.departments.length === 0) props.getAllDepartments();
	}, [props.getAllDepartments, props.departments.length]);

	const handleChangeTab = (_, value) => setTab(value);

	const handleChangeYear = (event) => setYear(event.target.value);

	const handleChangeDepartment = (event) => setDepartment(event.target.value);

	const renderTab = () => {
		switch (tab) {
			case 0:
				return <TimeResearchTable year={year} department={department} />;
			case 1:
				return <ContractRewardTable year={year} department={department} />;
			case 2:
				return <ReviewContractTable year={year} department={department} />;
			default:
				return <div>Không tìm thấy trang này!</div>;
		}
	};

	const renderDepartment = () => {
		var departmentOptions = [];
		props.profileDepartments?.forEach((department) => {
			if (
				department.departmentProfile?.filter((item) => item?.profileId === props.profile?.id)[0]
					?.roleInDepartment === 'leader'
			)
				departmentOptions.push(department);
		});
		if (department === 0) setDepartment(departmentOptions[0]?.id);
		return departmentOptions.map((item) => {
			return (
				<MenuItem key={item.id} value={item.id}>
					{item.name}
				</MenuItem>
			);
		});
	};

	const renderYears = () => {
		var years = [];
		for (var i = 2015; i <= getCurrentYear() + 2; i++)
			years.push(
				<MenuItem key={i} value={i}>
					{tab !== 0 ? i : `${i} - ${i + 1}`}
				</MenuItem>
			);
		return years;
	};

	return (
		<Card
			style={{
				minHeight: 'calc(100vh - 92px)',
				maxHeight: 'calc(100vh - 92px)',
				position: 'relative'
			}}
		>
			<Grid container justifyContent={'space-between'}>
				<Grid item xs={8}>
					<Tabs
						value={tab}
						scrollButtons="auto"
						variant="scrollable"
						textColor="secondary"
						onChange={handleChangeTab}
					>
						<Tab label="Báo cáo giờ nghiên cứu" />
						<Tab label="Chốt hợp đồng" />
						<Tab label="Hợp đồng đến kỳ review" />
					</Tabs>
				</Grid>
				<Grid item xs={4}>
					<Box style={{ float: 'right', marginTop: 7 }}>
						{tab !== 3 && (
							<FormControl variant={'outlined'} size="small">
								<InputLabel>Đơn vị</InputLabel>
								<Select
									label={'Đơn vị'}
									value={department}
									onChange={handleChangeDepartment}
									MenuProps={{
										getContentAnchorEl: null,
										anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
										transformOrigin: { vertical: 'top', horizontal: 'center' }
									}}
									SelectDisplayProps={{ style: { fontSize: 14, paddingTop: 8, paddingBottom: 8 } }}
								>
									{props.role === 'sudo-admin'
										? props.departments?.map((item) => {
												return (
													<MenuItem key={item.id} value={item.id}>
														{item.name}
													</MenuItem>
												);
											})
										: renderDepartment()}
								</Select>
							</FormControl>
						)}
						<FormControl style={{ margin: '0 8px' }} variant={'outlined'} size="small">
							<InputLabel>{tab !== 0 ? 'Năm' : 'Năm học'}</InputLabel>
							<Select
								label={tab !== 0 ? 'Năm' : 'Năm học'}
								value={year}
								onChange={handleChangeYear}
								MenuProps={{
									getContentAnchorEl: null,
									anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
									transformOrigin: { vertical: 'top', horizontal: 'center' }
								}}
								SelectDisplayProps={{ style: { fontSize: 14, paddingTop: 8, paddingBottom: 8 } }}
							>
								{renderYears()}
							</Select>
						</FormControl>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid item xs={12} style={{ margin: 8 }}>
				{renderTab()}
			</Grid>
		</Card>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	departments: select(state, 'departmentReducer', 'departments'),
	profileDepartments: select(state, 'profileReducer', 'departments')
});

const mapDispatchToProps = (dispatch) => ({
	getAllDepartments: () => dispatch(getAllDepartments())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(Report));
