import React, { useState, useEffect, createContext } from 'react';

/**
 * @typedef {Object} BasicInfo
 * @property {string} BasicInfo.name
 * @property {string} BasicInfo.birth
 * @property {string} BasicInfo.nationality
 * @property {string} BasicInfo.degree
 * @property {string} BasicInfo.unit
 * @property {string} BasicInfo.role
 * @property {string} BasicInfo.address
 * @property {string} BasicInfo.email
 * @property {string} BasicInfo.phone
 * @property {string} BasicInfo.base64
 */

/**
 *
 * 1 Quá trình đào tạo
 * @typedef {Object} Education
 * @property {string} Education.duration
 * @property {string} Education.degree
 * @property {string} Education.major
 * @property {string} Education.unit
 */

/**
 *
 * 2 Quá trình công tác
 * @typedef {Object} Business
 * @property {string} Business.duration
 * @property {string} Business.unit
 * @property {string} Business.role
 */

/**
 *
 * 3 Ngoại ngữ
 * @typedef {Object} Language
 * @property {string} Language.name
 * @property {string} Language.reading
 * @property {string} Language.writing
 * @property {string} Language.speaking
 */

/**
 *
 * 4b Các đề tài/dự án nghiên cứu khoa học
 * @typedef {Object} ResearchProject
 * @property {number} ResearchProject.id
 * @property {string} ResearchProject.name
 * @property {string} ResearchProject.host
 * @property {string} ResearchProject.duration
 * @property {string} ResearchProject.role
 */

/**
 * 4c Công bố ISI/Scopus
 * @typedef {Object} IsiScopusPublishment
 * @property {number} IsiScopusPublishment.id
 * @property {string} IsiScopusPublishment.title
 */

/**
 * 4d Công bố quốc tế khác
 * @typedef {Object} Publishment
 * @property {number} Publishment.id
 * @property {string} Publishment.title
 */

/**
 * 4e Công bố trên các tạp chí khoa học quốc gia
 * @typedef {Object} DomesticPublishment
 * @property {number} DomesticPublishment.id
 * @property {string} DomesticPublishment.title
 */

/**
 * @typedef {Object} Patent
 * @property {number} Patent.id
 * @property {string} Patent.title
 *
 * @typedef {Object} Solution
 * @property {number} Solution.id
 * @property {string} Solution.title
 *
 * @typedef {Object} Monograph
 * @property {number} Monograph.id
 * @property {string} Monograph.title
 */

/**
 * 4f Bằng sáng chế/Giải pháp hữu ích/Sách chuyên khảo
 * @typedef {Object} PatentOrSolutionOrMonograph
 * @property {Patent[]} PatentOrSolutionOrMonograph.patents
 * @property {Solution[]} PatentOrSolutionOrMonograph.solutions
 * @property {Monograph[]} PatentsOrSolutionOrMonograph.monographs
 */

/**
 * 4 QUÁ TRÌNH NGHIÊN CỨU KHOA HỌC
 * @typedef {Object} ScientificResearch
 * @property {string[]} ScientificResearch.researchAreas
 * @property {ResearchProject[]} ScientificResearch.researchProjects
 * @property {IsiScopusPublishment[]} ScientificResearch.isiScopusPublishments
 * @property {Publishment[]} ScientificResearch.publishments
 * @property {DomesticPublishment[]} ScientificResearch.domesticPublishments
 * @property {PatentOrSolutionOrMonograph} ScientificResearch.patentsOrSolutionsOrMonographs
 */

/**
 * 5a Đào tạo Tiến sĩ
 * @typedef {Object} Doctor
 * @property {string} Doctor.name
 * @property {string} Doctor.unit
 * @property {string} Doctor.year
 * @property {string} Doctor.role
 */

/**
 * 5b Đào tạo Thạc sĩ
 * @typedef {Object} Master
 * @property {string} Master.name
 * @property {string} Master.unit
 * @property {string} Master.year
 * @property {string} Master.role
 */

/**
 * 5 KINH NGHIỆM TRONG ĐÀO TẠO
 * @typedef {Object} Experience
 * @property {Doctor[]} Experience.doctors
 * @property {Master[]} Experience.masters
 */

/**
 * @typedef {Object} ScientificProfile
 * @property {BasicInfo} ScientificProfile.basicInfo
 * @property {Education[]} ScientificProfile.educations
 * @property {Business[]} ScientificProfile.businesses
 * @property {Language[]} ScientificProfile.languages
 * @property {ScientificResearch} ScientificProfile.scientificResearchs
 * @property {Experience} ScientificResearch.experiences
 * @property {React.Dispatch<React.SetStateAction<BasicInfo>>} ScientificProfile.setBasicInfo
 * @property {React.Dispatch<React.SetStateAction<Education[]>>} ScientificProfile.setEducation
 * @property {React.Dispatch<React.SetStateAction<Education[]>>} ScientificProfile.setEducation
 * @property {React.Dispatch<React.SetStateAction<Business[]>>} ScientificProfile.setBusiness
 * @property {React.Dispatch<React.SetStateAction<Language[]>>} ScientificProfile.setLanguage
 * @property {React.Dispatch<React.SetStateAction<ScientificResearch>>} ScientificProfile.setScientificResearch
 * @property {React.Dispatch<React.SetStateAction<Experience>>} ScientificProfile.setExperience
 */

/**
 * @type {React.Context<ScientificProfile>>}
 */
export const ScientificProfileContext = createContext();

/**
 *
 * @type {React.FC<React.PropsWithChildren<{}>>}
 */
export const ScientificProfileProvider = (props) => {
	/**
	 * @type {[BasicInfo, React.Dispatch<React.SetStateAction<BasicInfo>>]}
	 */
	const [basicInfoState, setBasicInfoState] = useState({
		address: '',
		birth: '',
		degree: '',
		email: '',
		name: '',
		nationality: '',
		phone: '',
		role: '',
		unit: ''
	});
	const [educationState, setEducationState] = useState([]);
	const [businessState, setBusinessState] = useState([]);
	const [languageState, setLanguageState] = useState([]);
	/**
	 * @type {[ScientificResearch, React.Dispatch<React.SetStateAction<ScientificResearch>>]}
	 */
	const [scientificResearchState, setScientificResearchState] = useState({
		domesticPublishments: [],
		isiScopusPublishments: [],
		patentsOrSolutionsOrMonographs: {
			patents: [],
			solutions: [],
			monographs: []
		},
		publishments: [],
		researchAreas: [],
		researchProjects: []
	});
	/**
	 * @type {[Experience, React.Dispatch<React.SetStateAction<Experience>>]}
	 */
	const [experienceState, setExperienceState] = useState({
		doctors: [],
		masters: []
	});

	useEffect(() => {}, []);

	return (
		<ScientificProfileContext.Provider
			value={{
				basicInfo: basicInfoState,
				educations: educationState,
				businesses: businessState,
				languages: languageState,
				scientificResearchs: scientificResearchState,
				experiences: experienceState,
				setBasicInfo: setBasicInfoState,
				setEducation: setEducationState,
				setBusiness: setBusinessState,
				setLanguage: setLanguageState,
				setScientificResearch: setScientificResearchState,
				setExperience: setExperienceState
			}}
		>
			{props.children}
		</ScientificProfileContext.Provider>
	);
};
