import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getKpiRewardInfo } from '../../../reducer/employmentContract/action';

import './style.css';

function KPIRewardTable(props) {
	const numberWithDots = (num) => {
		return num
			.toString()
			.replace(/\./g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	useEffect(() => {
		const { id, year } = props?.kpiInfo?.kpi || {};
		if (id && year) {
			props.getKpiRewardInfo(id, year);
		}
	}, [props?.kpiInfo?.kpi?.id, props?.kpiInfo?.kpi?.year]);
	const getCondition = (condition) => {
		switch (condition) {
			case 'A*':
			case 'A':
			case 'B':
			case 'C':
			case 'D':
				return `Hội thảo rank ${condition}`;
			case 'chapterEnglish':
				return 'Chương sách tiếng Anh';
			case 'chapterOther':
				return 'Chương sách ngôn ngữ khác';
			case 'monographEnglish':
				return 'Sách tiếng Anh';
			case 'monographOther':
				return 'Sách ngôn ngữ khác';
			default:
				return condition;
		}
	};

	return (
		<>
			{!props.isFetchingKpi && !!props.rewardInfo ? (
				<div>
					{props.rewardInfo.length === 0 ? (
						<div>Chưa có công trình đăng ký tính KPI hoặc có lỗi xảy ra, vui lòng thử lại sau!</div>
					) : (
						<table className="table">
							<tr className="tr">
								<th className="th">Loại</th>
								<th className="th">Đăng ký</th>
								<th className="th">Đã làm</th>
								<th className="th">Còn lại</th>
								<th className="th">Thưởng</th>
							</tr>
							{props.rewardInfo.map((item) => (
								<tr className="tr">
									<td className="td">{getCondition(item?.condition)}</td>
									<td className="td">
										{Math.round((item.numRegister + Number.EPSILON) * 100) / 100}
									</td>
									<td className="td">
										{Math.round((item.numCompleted + Number.EPSILON) * 100) / 100}
									</td>
									<td className="td">{item.numRemain}</td>
									<td className="td">{numberWithDots(item?.reward)}</td>
								</tr>
							))}
						</table>
					)}
				</div>
			) : (
				<div>
					<CircularProgress />
				</div>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	isFetchingKpi: select(state, 'employmentContractReducer', 'isFetchingKPI'),
	rewardInfo: select(state, 'employmentContractReducer', 'rewardInfo')
});
const mapDispatchToProps = (dispatch) => ({
	getKpiRewardInfo: (id, year) => dispatch(getKpiRewardInfo(id, year))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(KPIRewardTable));
