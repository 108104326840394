import React, { useEffect, useState } from 'react';
import {
	Button,
	Tooltip,
	makeStyles,
	withStyles,
	Chip,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import KPTable from '../../../components/KPTable';

import { getAllDepartments } from '../../../reducer/department/action';
import {
	getTimeResearchInformation,
	getTimeResearchStatistic,
	calculateTimeResearchAllProfile,
	lockAllTimeResearch,
	lockTimeResearch
} from '../../../reducer/timeResearchInformation/action';
import { EXPORT_TIME_RESEARCH_TO_EXCEL } from '../../../reducer/statistic/action';

const useStyles = makeStyles({
	button: {
		'&:disabled': {
			backgroundColor: '#fff',
			color: '#3c52b2'
		}
	}
});

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9'
	}
}))(Tooltip);

function TimeResearchTable(props) {
	const classes = useStyles();
	const [detail, setDetail] = useState(null);

	useEffect(() => {
		props.getAllDepartments();
	}, [props.getAllDepartments]);

	const columns = [
		{
			title: <b>Tên thành viên</b>,
			key: 'name',
			width: '10%',
			searchable: true,
			sorter: (a, b) => a.id - b.id,
			render: (value, row) => {
				return (
					<a
						href={`${ROUTER.WORK}/profile-id=${row.profileId}`}
						style={{ color: 'black', textDecoration: 'none' }}
					>
						<b>{value}</b>
					</a>
				);
			}
			// name: (
			//   <Link
			//     to={`${ROUTER.WORK}/profile-id=${e.profile?.id}`}
			//     style={{ color: "black", textDecoration: "none" }}
			//   >
			//     <b>{e.profile?.name}</b>
			//   </Link>
			// ),
		}
	];

	useEffect(() => {
		props.getTimeResearchInformation(0, 2021);
	}, []);

	useEffect(() => {
		props.calculateTimeResearchAllProfile(props.year).then(() => {
			props.getTimeResearchStatistic({
				departmentId: [props.department],
				year: [parseInt(props.year)]
			});
		});
	}, [props.year, props.department]);

	const renderTitleByIndex = (index, title) => {
		switch (index) {
			case 2:
			case 5:
			case 14:
			case 15:
				return <b>{title}</b>;
			case 9:
				return <b>ISI</b>;
			case 10:
				return <b>Scopus</b>;
			default:
				return <b>({index + 1})*</b>;
		}
	};

	props.list[0]?.timeResearchDetail.forEach((e, index) => {
		if (index + 1 === 9 || index + 1 === 10)
			columns.push({
				title: (
					<HtmlTooltip title={<p style={{ fontSize: 14 }}>{e.description}</p>} arrow>
						{/* <b>{e.description.substr(0, 3) + '...'}</b> */}
						{renderTitleByIndex(index + 1, e.description)}
					</HtmlTooltip>
				),
				key: e.stt - 1,
				width: '5%'
			});
	});

	columns.push(
		{
			key: 'total',
			width: '5%',
			sorter: (a, b) => a.total - b.total,
			title: <b>Tổng</b>
		},
		{
			key: 'locked',
			width: '5%',
			title: <b>Khóa</b>,
			filters: [
				{
					text: 'Đã khóa',
					value: true
				},
				{
					text: 'Chưa khóa',
					value: false
				}
			],
			onFilter: (value, row) => row.filter((e) => e.locked === value),
			render: (value) => {
				return (
					<Chip label={value ? 'Đã khóa' : 'Chưa khóa'} color={value ? 'primary' : 'secondary'} />
				);
			}
		},
		{
			title: <b>Tác vụ</b>,
			key: 'action',
			width: '5%',
			render: (value, row) => (
				<>
					<Typography
						className="table-action-buton"
						variant="caption"
						color="primary"
						onClick={() => setDetail(row)}
					>
						Chi tiết
					</Typography>
					<Typography
						className="table-action-buton"
						variant="caption"
						color="primary"
						onClick={() => {
							props.lockTimeResearch(row.id, !row.locked);
						}}
					>
						{row.locked ? 'Hủy khóa' : 'Khóa'}
					</Typography>
				</>
			)
		}
	);

	let data = [];
	if (
		props.statistic !== null &&
		props.statistic[0]?.departmentInfo !== null &&
		props.statistic[0]?.departmentInfo[0]?.timeResearchInformation !== 'undefined'
	) {
		var totalDepartment = 0;
		props.statistic[0]?.departmentInfo[0]?.timeResearchInformation.forEach((e) => {
			// // // console.log(e)
			data.push({
				id: e.id,
				profileId: e.profileId,
				name: e.profile.name || '',
				0: e.timeResearchDetail[0]?.total ?? 'N/A',
				1: e.timeResearchDetail[1]?.total ?? 'N/A',
				2: e.timeResearchDetail[2]?.total ?? 'N/A',
				3: e.timeResearchDetail[3]?.total ?? 'N/A',
				4: e.timeResearchDetail[4]?.total ?? 'N/A',
				5: e.timeResearchDetail[5]?.total ?? 'N/A',
				6: e.timeResearchDetail[6]?.total ?? 'N/A',
				7: e.timeResearchDetail[7]?.total ?? 'N/A',
				8: e.timeResearchDetail[8]?.total ?? 'N/A',
				9: e.timeResearchDetail[9]?.total ?? 'N/A',
				10: e.timeResearchDetail[10]?.total ?? 'N/A',
				11: e.timeResearchDetail[11]?.total ?? 'N/A',
				12: e.timeResearchDetail[12]?.total ?? 'N/A',
				13: e.timeResearchDetail[13]?.total ?? 'N/A',
				14: e.timeResearchDetail[14]?.total ?? 'N/A',
				15: e.timeResearchDetail[15]?.total ?? 'N/A',
				// "16": e.timeResearchDetail[16]?.total ?? 'N/A',
				total: e.total ?? 'N/A',
				locked: e.locked,
				des0: e.timeResearchDetail[0]?.description ?? 'N/A',
				des1: e.timeResearchDetail[1]?.description ?? 'N/A',
				des2: e.timeResearchDetail[2]?.description ?? 'N/A',
				des3: e.timeResearchDetail[3]?.description ?? 'N/A',
				des4: e.timeResearchDetail[4]?.description ?? 'N/A',
				des5: e.timeResearchDetail[5]?.description ?? 'N/A',
				des6: e.timeResearchDetail[6]?.description ?? 'N/A',
				des7: e.timeResearchDetail[7]?.description ?? 'N/A',
				des8: e.timeResearchDetail[8]?.description ?? 'N/A',
				des9: e.timeResearchDetail[9]?.description ?? 'N/A',
				des10: e.timeResearchDetail[10]?.description ?? 'N/A',
				des11: e.timeResearchDetail[11]?.description ?? 'N/A',
				des12: e.timeResearchDetail[12]?.description ?? 'N/A',
				des13: e.timeResearchDetail[13]?.description ?? 'N/A',
				des14: e.timeResearchDetail[14]?.description ?? 'N/A',
				des15: e.timeResearchDetail[15]?.description ?? 'N/A'
			});
			totalDepartment += e.total ?? 0;
		});
	}
	// // // console.log(data)

	const renderDetailTimeResearch = (detail) => {
		return (
			<>
				<Typography style={{ padding: 10 }}>
					1. {detail.des0}: <b>{detail[0]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					2. {detail.des1}: <b>{detail[1]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					3. {detail.des2}: <b>{detail[2]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					4. {detail.des3}: <b>{detail[3]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					5. {detail.des4}: <b>{detail[4]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					6. {detail.des5}: <b>{detail[5]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					7. {detail.des6}: <b>{detail[6]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					8. {detail.des7}: <b>{detail[7]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					9. {detail.des8}: <b>{detail[8]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					10. {detail.des9}: <b>{detail[9]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					11. {detail.des10}: <b>{detail[10]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					12. {detail.des11}: <b>{detail[11]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					13. {detail.des12}: <b>{detail[12]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					14. {detail.des13}: <b>{detail[13]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					15. {detail.des14}: <b>{detail[14]}</b> giờ
				</Typography>
				<Typography style={{ padding: 10 }}>
					16. {detail.des15}: <b>{detail[15]}</b> giờ
				</Typography>
			</>
		);
	};

	const handleLockAllTimeResearch = (locked) => {
		props.lockAllTimeResearch(props.year, locked);
	};

	const handleExportExcel = () => {
		EXPORT_TIME_RESEARCH_TO_EXCEL(data);
	};

	return (
		<div>
			<KPTable
				shadow="none"
				rowsPerPage={10}
				maxHeight="calc(100vh - 228px)"
				columns={columns}
				rows={data}
				loading={props.isFetching}
			/>

			{data.filter((item) => !item.locked).length > 0 && (
				<Button
					color="primary"
					variant="contained"
					style={{ left: 16, bottom: 44, marginRight: 10 }}
					onClick={() => handleLockAllTimeResearch(true)}
				>
					Khóa giờ nghiên cứu cho năm {props.year}
				</Button>
			)}

			{data.filter((item) => !item.locked).length === 0 && (
				<Button
					color="primary"
					variant="contained"
					style={{ left: 16, bottom: 44, marginRight: 10 }}
					onClick={() => handleLockAllTimeResearch(false)}
				>
					Bỏ khóa tất cả
				</Button>
			)}

			<Button className={classes.button} color="primary" disabled style={{ left: 16, bottom: 44 }}>
				{`${props.departments.filter((item) => item.id === props.department)[0]?.name || 'Không tìm thấy đơn vị'}: ${totalDepartment ?? 'N/A'} giờ`}
			</Button>

			<Button
				variant="outlined"
				onClick={() => handleExportExcel()}
				style={{ left: 16, bottom: 44 }}
			>
				Export excel
			</Button>

			{detail && (
				<Dialog open={detail !== null} onClose={() => setDetail(null)}>
					<DialogTitle>{'Chi tiết giờ nghiên cứu'}</DialogTitle>
					<DialogContent>{renderDetailTimeResearch(detail)}</DialogContent>
					<DialogActions>
						<Button onClick={() => setDetail(null)} color="primary" autoFocus>
							Đóng
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	departments: select(state, 'departmentReducer', 'departments'),
	list: select(state, 'timeResearchInformationReducer', 'list'),
	statistic: select(state, 'timeResearchInformationReducer', 'statistic'),
	isFetching: select(state, 'timeResearchInformationReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	getTimeResearchInformation: (profileId, year) =>
		dispatch(getTimeResearchInformation(profileId, year)),
	getTimeResearchStatistic: (payload) => dispatch(getTimeResearchStatistic(payload)),
	getAllDepartments: () => dispatch(getAllDepartments()),
	calculateTimeResearchAllProfile: (year) => dispatch(calculateTimeResearchAllProfile(year)),
	lockTimeResearch: (id, locked) => dispatch(lockTimeResearch(id, locked)),
	lockAllTimeResearch: (year, locked) => dispatch(lockAllTimeResearch(year, locked))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(TimeResearchTable));
