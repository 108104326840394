import { useState } from 'react';
import { connect } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	TextField,
	Button,
	Box
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import toJS from '../../../hoc/toJS';

import { updateDepartment } from '../../../reducer/department/action';

const departmentSchema = yup.object().shape({
	name: yup.string().required('Không được để trống'),
	code: yup.string(),
	location: yup.string(),
	description: yup.string(),
	researchArea: yup.string(),
	website: yup.string(),
	foundedAt: yup.date('Ngày không hợp lệ').required('Không được để trống')
});

const useStyles = makeStyles((theme) =>
	createStyles({
		btnCancel: {
			marginRight: theme.spacing(1)
		}
	})
);

function DepartmentUpdateModal(props) {
	const { updating, setUpdating, updateDepartment } = props;

	const [disabled, setDisabled] = useState(true);

	const classes = useStyles();

	const handleCloseModal = () => {
		setUpdating(null);
		setDisabled(true);
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				id: updating?.id,
				name: updating?.name ?? '',
				code: updating?.code ?? '',
				location: updating?.location ?? '',
				description: updating?.description ?? '',
				researchArea: updating?.researchArea ?? '',
				website: updating?.website ?? '',
				foundedAt: dayjs(updating?.foundedAt).isValid()
					? dayjs(updating?.foundedAt).format('YYYY-MM-DD')
					: ''
			}}
			validationSchema={departmentSchema}
			onSubmit={(values) => {
				const payload = {
					...values,
					foundedAt: dayjs(values.foundedAt).format('YYYY-MM-DDThh:mm:ss[Z]')
				};
				updateDepartment(updating?.id, payload, handleCloseModal);
			}}
		>
			{(formikProps) => {
				const { isSubmitting, handleSubmit } = formikProps;

				return (
					<Dialog
						fullWidth
						keepMounted
						maxWidth="md"
						open={updating !== null}
						onClose={handleCloseModal}
					>
						<DialogTitle>Chi tiết</DialogTitle>
						<DialogContent>
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Field name="name">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														disabled={disabled}
														variant="outlined"
														label="Tên đơn vị"
														helperText={<ErrorMessage name="name" />}
														FormHelperTextProps={{ error: true }}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={12}>
										<Field name="code">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														disabled={disabled}
														variant="outlined"
														label="Mã đơn vị"
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={12}>
										<Field name="location">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														disabled={disabled}
														variant="outlined"
														label="Địa điểm"
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={12} className={classes.fieldContainer}>
										<Field name="researchArea">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														type="text"
														disabled={disabled}
														variant="outlined"
														label="Hướng nghiên cứu chính"
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={12} className={classes.fieldContainer}>
										<Field name="foundedAt">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														type="date"
														disabled={disabled}
														variant="outlined"
														label="Ngày thành lập"
														helperText={<ErrorMessage name="foundedAt" />}
														FormHelperTextProps={{ error: true }}
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={12}>
										<Field name="website">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														type="text"
														disabled={disabled}
														variant="outlined"
														label="Website"
													/>
												);
											}}
										</Field>
									</Grid>
									<Grid item xs={12}>
										<Field name="description">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														size="small"
														multiline={true}
														rows={2}
														disabled={disabled}
														variant="outlined"
														label="Mô tả"
													/>
												);
											}}
										</Field>
									</Grid>
								</Grid>
							</Form>
						</DialogContent>
						<DialogActions>
							<Box px={2}>
								<Button
									variant="outlined"
									color="secondary"
									className={classes.btnCancel}
									onClick={handleCloseModal}
								>
									Hủy
								</Button>
								{disabled ? (
									<Button
										type="submit"
										variant="contained"
										color="primary"
										onClick={() => setDisabled(false)}
									>
										Chỉnh sửa
									</Button>
								) : (
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={isSubmitting}
										onClick={handleSubmit}
									>
										Xác nhận
									</Button>
								)}
							</Box>
						</DialogActions>
					</Dialog>
				);
			}}
		</Formik>
	);
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
	updateDepartment: (id, payload, cleanUp) => dispatch(updateDepartment(id, payload, cleanUp))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DepartmentUpdateModal));
