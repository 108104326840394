import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Add, Replay, ArrowUpward } from '@material-ui/icons';
import { Grid, Button, TextField, MenuItem } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import JournalDomesticInsertModal from '../JournalDomesticInsertModal';
import { getAllJournalDomestic } from '../../../reducer/journalDomestic/action';

function JournalDomesticButton(props) {
	const { getAllJournalDomestic } = props;

	const [inserting, setInserting] = useState(false);
	const [sortUp, setSortUp] = useState(true);
	const [sortElm, setSortElm] = useState(null);
	const [searchElm, setSearchElm] = useState(null);
	const [isSearching, setIsSearching] = useState(false);

	useEffect(() => {
		let newObj;
		if (sortElm) newObj = { ...newObj, sort: { sortElm, sortUp } };
		if (isSearching) newObj = { ...newObj, search: isSearching ? searchElm : null };

		if (newObj) props.setFilterObj(newObj);
	}, [sortElm, sortUp, searchElm, isSearching]);

	useEffect(() => {
		if (props.list.length === 0) getAllJournalDomestic();
	}, [getAllJournalDomestic, props.list.length]);

	const handleReload = () => getAllJournalDomestic();
	const handleInsert = () => setInserting(true);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				setIsSearching(true);
			}}
		>
			<Grid container spacing={6} style={{ paddingTop: 8, paddingBottom: 8 }}>
				<Grid item xs={3}>
					<TextField
						select
						size="small"
						label="Sắp xếp theo"
						SelectProps={{
							MenuProps: {
								getContentAnchorEl: null,
								anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
								transformOrigin: { vertical: 'top', horizontal: 'center' }
							}
						}}
						variant="outlined"
						value={sortElm || ''}
						style={{ width: 'calc(100% - 72px)' }}
						onChange={(event) => setSortElm(event.target.value)}
					>
						<MenuItem value="title">Tên tạp chí</MenuItem>
						<MenuItem value="score">Điểm GS-PGS</MenuItem>
					</TextField>
					<Button
						color="primary"
						variant="outlined"
						disabled={!sortElm}
						style={{ height: 40, marginLeft: 8 }}
						onClick={() => setSortUp(!sortUp)}
					>
						<ArrowUpward
							style={{ transition: '0.4s', transform: `rotate(${sortUp ? 0 : 180}deg)` }}
						/>
					</Button>
				</Grid>

				<Grid item xs={6}>
					<TextField
						size="small"
						label="Tìm kiếm tạp chí"
						variant="outlined"
						value={searchElm || ''}
						onChange={(e) => {
							setIsSearching(false);
							setSearchElm(e.target.value);
						}}
						style={{ width: 'calc(100% - 216px)' }}
					/>
					<Button
						variant="outlined"
						disabled={!searchElm && !sortElm}
						style={{ height: 40, width: 90, margin: '0 8px' }}
						onClick={() => {
							setSortUp(true);
							setSortElm(null);
							setSearchElm(null);
							setIsSearching(false);
							props.setFilterObj(null);
						}}
					>
						Bỏ lọc
					</Button>
					<Button
						type="submit"
						color="primary"
						variant="contained"
						disabled={!searchElm}
						style={{ height: 40, width: 110 }}
					>
						Tìm kiếm
					</Button>
				</Grid>
				<Grid item xs={3}>
					<div style={{ float: 'right' }}>
						<Button
							variant="outlined"
							startIcon={<Replay />}
							onClick={handleReload}
							style={{ marginRight: 8, height: 40 }}
						>
							Tải lại
						</Button>
						{props.role === 'sudo-admin' && (
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add />}
								onClick={handleInsert}
								style={{ height: 40 }}
							>
								Thêm
							</Button>
						)}
					</div>
				</Grid>
			</Grid>
			<JournalDomesticInsertModal inserting={inserting} setInserting={setInserting} />
		</form>
	);
}

const mapStateToProps = (state) => ({
	list: select(state, 'journalDomesticReducer', 'list'),
	role: select(state, 'userReducer', 'role')
});

const mapDispatchToProps = (dispatch) => ({
	getAllJournalDomestic: () => dispatch(getAllJournalDomestic())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalDomesticButton));
