import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Typography, InputAdornment } from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';
import ROUTER from '../../../../constants/router';

import KPTable from '../../../../components/KPTable';

import './RankTableTable.css';

import { updateRankTable } from '../../../../reducer/rankTable/action';

function RankTableTable(props) {
	const [editting, setEditting] = useState(null);

	const valueFormat = (value) => Math.round(value * 10000) / 100;

	useEffect(() => {
		if (props.history !== null && props.role !== 'sudo-admin') props.history.push(ROUTER.HOME);
	}, []);

	useEffect(() => {
		setEditting(null);
	}, [props.timeResearchInformationList]);

	const handleChangeForm = (name, value) => {
		setEditting({ ...editting, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		props.updateRankTable(editting.id, { ...editting });

		setEditting(null);
	};

	const handleChange = (value) =>
		setEditting({
			...editting,
			value: value
		});

	return (
		<KPTable
			shadow="none"
			rowsPerPage={-1}
			columns={[
				{
					title: <b>TT</b>,
					key: 'id',
					width: '5%'
				},
				{
					title: <b>Tổng số tạp chí theo subject và category</b>,
					key: 'sum',
					width: '20%',
					render: (value, row) =>
						editting?.id !== row.id ? (
							row.numJournalTo === 999999999 ? (
								`≥ ${row.numJournalFrom}`
							) : row.numJournalFrom === 0 ? (
								`< ${row.numJournalTo}`
							) : (
								`${row.numJournalFrom} - ${row.numJournalTo}`
							)
						) : row.numJournalTo === 999999999 ? (
							<Grid item xs={12}>
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									value={editting.numJournalFrom || ''}
									InputProps={{
										startAdornment: <InputAdornment position="start">≥</InputAdornment>
									}}
									onChange={(event) =>
										handleChangeForm('numJournalFrom', parseFloat(event.target.value / 100))
									}
								/>
							</Grid>
						) : row.numJournalFrom === 0 ? (
							<Grid item xs={12}>
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									value={editting.numJournalTo || ''}
									InputProps={{
										startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
									}}
									onChange={(event) =>
										handleChangeForm('numJournalTo', parseFloat(event.target.value / 100))
									}
								/>
							</Grid>
						) : (
							<Grid container spacing={1}>
								<Grid item xs={5}>
									<TextField
										autoFocus
										size="small"
										type="number"
										variant="outlined"
										value={editting.numJournalFrom}
										onChange={(event) =>
											handleChangeForm('numJournalFrom', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={2} style={{ textAlign: 'center', lineHeight: '35px' }}>
									-
								</Grid>
								<Grid item xs={5}>
									<TextField
										size="small"
										type="number"
										variant="outlined"
										value={editting.numJournalTo}
										onChange={(event) =>
											handleChangeForm('numJournalTo', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
							</Grid>
						)
				},
				{
					title: (
						<Grid container spacing={1} style={{ padding: 16 }}>
							<Grid className="rank-table-text" item xs={12} style={{ textAlign: 'center' }}>
								Phân hạng tạp chí ISI
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								TCN + Q1
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R1 + Q1
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R2 + Q1
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R3 + Q2
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R4 + Q2
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R5 + Q2
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R6 + Q3
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R7 + Q3
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R8 + Q3
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R9 + Q4
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								R10 + Q4
							</Grid>
							<Grid className="rank-table-text" item xs={1}>
								Tác vụ
							</Grid>
						</Grid>
					),
					width: '75%',
					fullwidth: true,
					key: 'stt',
					render: (value, row) =>
						editting?.id !== row.id ? (
							<Grid container spacing={1}>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioTCN)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR1)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR2)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR3)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR4)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR5)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR6)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR7)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR8)}%`}</Grid>
								<Grid item xs={1}>{`< ${valueFormat(row.ratioR9)}%`}</Grid>
								<Grid item xs={1}>{`≥ ${valueFormat(row.ratioR9)}%`}</Grid>
								<Grid item xs={1}>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="primary"
										onClick={(event) => setEditting(row)}
									>
										Sửa
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid container spacing={1}>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioTCN)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioTCN', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR1)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR1', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR2)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR2', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR3)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR3', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR4)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR4', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR5)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR5', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR6)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR6', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR7)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR7', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR8)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR8', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR9)}
										InputProps={{
											startAdornment: <InputAdornment position="start">{'<'}</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR9', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									<TextField
										disabled
										fullwidth
										size="small"
										type="number"
										variant="outlined"
										value={valueFormat(editting.ratioR9)}
										InputProps={{
											startAdornment: <InputAdornment position="start">≥</InputAdornment>
										}}
										onChange={(event) =>
											handleChangeForm('ratioR9', parseFloat(event.target.value / 100))
										}
									/>
								</Grid>
								<Grid item xs={1}>
									{editting?.id !== row.id ? (
										<Typography
											className="table-action-buton"
											variant="caption"
											color="primary"
											onClick={(event) => setEditting(row)}
										>
											Sửa
										</Typography>
									) : (
										<>
											<Typography
												className="table-action-buton"
												variant="caption"
												color="primary"
												onClick={handleSubmit}
											>
												Lưu
											</Typography>
											<Typography
												className="table-action-buton"
												variant="caption"
												color="secondary"
												onClick={() => setEditting(null)}
											>
												Huỷ
											</Typography>
										</>
									)}
								</Grid>
							</Grid>
						)
				}
			]}
			rows={props.rankTableList.sort((a, b) => b.numJournalFrom - a.numJournalFrom)}
		/>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	history: select(state, 'homeReducer', 'history'),
	rankTableList: select(state, 'rankTableReducer', 'list')
});

const mapDispatchToProps = (dispatch) => ({
	updateRankTable: (id, payload) => dispatch(updateRankTable(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(RankTableTable));
