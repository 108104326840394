import React, { useEffect, useState } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import KPTable from '../../../components/KPTable';
import ExportExcel from '../ExportExcel';

import { getReviewContractStatistic } from '../../../reducer/statistic/action';
import EmploymentContractUpdateModal2 from '../../EmploymentContract/EmploymentContractUpdateModal2';

function FinishContractTable(props) {
	const [updating, setUpdating] = useState(null);

	const columns = [
		{
			title: <b>Tên</b>,
			key: 'name',
			width: '10%',
			searchable: true,
			sorter: (a, b) => a - b,
			render: (value, row) => (
				<a
					href={`${ROUTER.WORK}/profile-id=${row.profile?.id}`}
					style={{ color: 'black', textDecoration: 'none' }}
				>
					<Typography>
						<b>{value}</b>
					</Typography>
				</a>
			)
		},
		{
			title: <b>Thời hạn hợp đồng</b>,
			key: 'contract',
			width: '10%',
			sorter: (a, b) => a - b,
			render: (value) => (value.duration !== 1000 ? `${value.duration} năm` : 'Vô hạn')
		},
		{
			title: <b>Thời gian bắt đầu</b>,
			key: 'contract',
			width: '10%',
			sorter: (a, b) => a - b,
			render: (value) => moment(value?.startAt)?.format('DD/MM/YYYY') ?? 'N/A'
		},
		{
			title: <b>Đã đạt KPI</b>,
			key: 'contract',
			width: '10%',
			render: (value) =>
				value?.listKpi[0]?.completed ? (
					<Chip label="Đã hoàn thành" variant="outlined" color="primary" />
				) : (
					<Chip label="Chưa hoàn thành" variant="outlined" color="secondary" />
				),
			filters: [
				{
					text: 'Đã hoàn thành',
					value: true
				},
				{
					text: 'Chưa hoàn thành',
					value: false
				}
			],
			onFilter: (value, row) => row.filter((e) => e?.contract?.listKpi[0]?.completed === value)
		},
		{
			title: <b>Tác vụ</b>,
			key: 'action',
			width: '10%',
			render: (value, row) => (
				<Typography
					className="table-action-buton"
					variant="caption"
					color="primary"
					onClick={() => setUpdating(row)}
				>
					Chi tiết
				</Typography>
			)
		}
	];

	useEffect(() => {
		props.getReviewContractStatistic({ departmentId: props.department });
	}, [props.year, props.department]);

	return (
		<>
			<div>
				<KPTable
					shadow="none"
					rowsPerPage={10}
					maxHeight="calc(100vh - 214px)"
					columns={columns}
					rows={props.statistic.map((item) => {
						return {
							...item,
							name: item?.profile?.name ?? ''
						};
					})}
					loading={props.isFetching}
				/>
				<ExportExcel
					filename="Báo cáo danh sách chuẩn bị đến hạn review hợp đồng"
					data={[props.statistic]}
				/>
			</div>
			{updating !== null && (
				<EmploymentContractUpdateModal2 updating={updating} setUpdating={setUpdating} />
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	statistic: select(state, 'statisticReducer', 'reviewContractStatistic'),
	isFetching: select(state, 'statisticReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	getReviewContractStatistic: (payload) => dispatch(getReviewContractStatistic(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(FinishContractTable));
