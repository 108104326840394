import React, { useEffect, useState, useRef } from 'react';
import {
	Grid,
	Paper,
	Button,
	Popover,
	Checkbox,
	Typography,
	FormControl,
	OutlinedInput,
	FormControlLabel,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableHead,
	TableContainer,
	TablePagination,
	CircularProgress
} from '@material-ui/core';
import { Drafts, Search, VolumeMute, ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { matchSorter } from 'match-sorter';
import Highlighter from 'react-highlight-words';

import './KPTable.css';

function KPTable(props) {
	const inputRef = useRef(null);
	const setFocus = () => {
		setTimeout(() => {
			inputRef.current && inputRef.current.focus();
		}, 250);
	};

	const [input, setInput] = useState('');
	const [filterInput, setFilterInput] = useState('');
	const [checklist, setChecklist] = useState([]);
	const [searchIndex, setSearchIndex] = useState(-1);
	const [filterIndex, setFilterIndex] = useState(-1);
	const [anchorElSearch, setAnchorElSearch] = useState(null);
	const [anchorElFilter, setAnchorElFilter] = useState(null);

	// ----- Table - Columns ----- //
	const [columns, setColumns] = useState([]);

	useEffect(() => {
		const tempColumns = [];
		const tempChecklist = [];
		props.columns.forEach((e, index) => {
			/**
			 * Nếu switch sang bảng mới thì reset mấy
			 * cái giá trị sort siếc, filter fil tủng ik
			 */
			if (props.columns.length === columns.length) {
				tempColumns.push({
					...e,
					sortType: (columns.length > index && columns[index].sortType) || (e.sorter ? 0 : null),
					search: (columns.length > index && columns[index].search) || (e.searchable ? '' : null),
					filters:
						(columns.length > index && columns[index].filters) ||
						(e.onFilter ? e.filters.map((e) => ({ ...e, checked: false })) : null)
				});
			} else {
				tempColumns.push({
					...e,
					sortType: e.sorter ? 0 : null,
					search: e.searchable ? '' : null,
					filters: e.onFilter ? e.filters.map((e) => ({ ...e, checked: false })) : null
				});
			}
			tempChecklist.push(e.onFilter ? e.filters.map((e) => ({ ...e, checked: false })) : null);
		});
		setColumns(tempColumns);
		setChecklist([...tempChecklist]);
	}, [props.columns]);

	// ----- Table - Pagination ----- //
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		if (props?.pageName) setPage(parseInt(sessionStorage.getItem(props?.pageName) || 0));
	}, []);

	useEffect(() => {
		if (props.rowsPerPage) {
			if (props.rowsPerPage > 0) setRowsPerPage(props.rowsPerPage);
			else setRowsPerPage(props.rows.length);
		}
	}, [props.rows.length, props.rowsPerPage]);

	useEffect(() => {
		if (!isNaN(props.page)) setPage(props.page);
	}, [props.page]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		if (props?.pageName) sessionStorage.setItem(props?.pageName, newPage);
		if (props.onPageChange) props.onPageChange(newPage + 1, rowsPerPage, props.filterData);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleSort = (index) => {
		if (columns[index].sortType === null) return;
		const tempColumns = [];
		columns.forEach((column, id) =>
			tempColumns.push({
				...column,
				sortType: index === id ? (column.sortType + 1) % 3 : column.sortType !== null ? 0 : null
			})
		);
		setColumns(tempColumns);
	};

	const handleSearch = (key, value) => {
		const tempColumns = columns;
		if (key === 'input') {
			setInput(value);
		} else {
			tempColumns[searchIndex] = { ...tempColumns[searchIndex], search: value };
			setColumns([...tempColumns]);
		}
	};

	const handleFilter = (key, keyValue, value) => {
		const tempColumns = checklist;
		const index = tempColumns[filterIndex].findIndex((e) => e.value === keyValue);
		if (key === 'list') {
			tempColumns[filterIndex].splice(index, 1, {
				...tempColumns[filterIndex][index],
				checked: value
			});
			setChecklist([...tempColumns]);
		} else {
			const tempColumns = columns;
			const tempChecklist = [];
			checklist.forEach((e) => tempChecklist.push(e));
			tempColumns[filterIndex] = {
				...tempColumns[filterIndex],
				filters: [...checklist[filterIndex]]
			};
			setColumns([...tempColumns]);
		}
	};

	var rows = [];
	props.rows?.forEach((e) => rows.push(e));

	function removeAccents(str) {
		if (typeof str === 'string' || str instanceof String)
			return str
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.replace(/đ/g, 'd')
				.replace(/Đ/g, 'D')
				.toLowerCase();
		else if (typeof str === 'number' || str instanceof Number)
			return str
				.toString()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.replace(/đ/g, 'd')
				.replace(/Đ/g, 'D')
				.toLowerCase();
		else return '';
	}

	columns.forEach((column) => {
		// if (column.search) rows = matchSorter(rows, column.search, { keys: [column.key] });
		if (column.search)
			rows = rows.filter((row) =>
				removeAccents(row[column.key]).includes(removeAccents(column.search))
			);
	});

	columns.forEach((column) => {
		const listCheck = [];
		if (column.filters) {
			if (column?.type === 'include') {
				column.filters?.forEach((e) => {
					if (e.checked) {
						listCheck.push(e.value);
					}
				});
				column.filters?.forEach((e) => {
					if (e.checked) rows = column.onFilter(listCheck, rows);
				});
			} else {
				column.filters?.forEach((e) => {
					if (e.checked) rows = column.onFilter(e.value, rows);
				});
			}
		}
	});

	columns.forEach((column) => {
		if (column.sortType === 1) rows.sort(column.sorter);
		if (column.sortType === 2) rows.sort(column.sorter).reverse();
	});

	return (
		<Paper style={{ position: 'relative', boxShadow: props.shadow && props.shadow }}>
			<TableContainer style={{ maxHeight: props.maxHeight || 'auto' }}>
				<Table stickyHeader style={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							{columns?.map((column, index) => (
								<TableCell
									key={column.key}
									className={`kpt-header-cell ${props.size === 'small' ? 'kpt-cell-small' : ''}`}
									style={{ width: column.width }}
								>
									<Grid container alignItems="stretch">
										{/* ----- SORT ----- */}
										<Grid item className="kpt-sort-icon" onClick={() => handleSort(index)}>
											<Grid container alignItems="center">
												<Grid
													item
													style={{
														padding: column.fullwidth ? 0 : props.size === 'small' ? 4 : 16,
														paddingRight: column.fullwidth ? 0 : 2,
														textAlign: 'center',
														width: column.fullwidth ? '100%' : 'auto'
													}}
												>
													{column.title}
												</Grid>
												<Grid item>
													<Grid
														container
														direction="column"
														style={{
															marginBottom: 10,
															display: column.sortType !== null ? 'flex' : 'none'
														}}
													>
														<Grid item style={{ height: 10 }}>
															<ArrowDropUp
																fontSize="small"
																color={column.sortType === 1 ? 'primary' : 'disabled'}
															/>
														</Grid>
														<Grid item style={{ height: 10 }}>
															<ArrowDropDown
																fontSize="small"
																color={column.sortType === 2 ? 'primary' : 'disabled'}
															/>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* ----- SEARCH ----- */}
										<Grid
											item
											className="kpt-search-icon"
											style={{ display: column?.search !== null ? 'flex' : 'none' }}
											onClick={(event) => {
												setSearchIndex(index);
												setAnchorElSearch(event.currentTarget);
												handleSearch('submit', false);
												setFocus();
											}}
										>
											<Grid container alignItems="center">
												<Grid item style={{ height: 20 }}>
													<Search
														fontSize="small"
														color={column.search ? 'primary' : 'disabled'}
														style={{ lineHeight: '20px' }}
													/>
												</Grid>
											</Grid>
										</Grid>

										{/* ----- FILTER ----- */}
										<Grid
											item
											className="kpt-search-icon"
											style={{ display: column?.filters !== null ? 'flex' : 'none' }}
											onClick={(event) => {
												setFilterIndex(index);
												setAnchorElFilter(event.currentTarget);
											}}
										>
											<Grid container alignItems="center">
												<Grid item style={{ height: 20 }}>
													<VolumeMute
														fontSize="small"
														color={column.filters?.find((e) => e.checked) ? 'primary' : 'disabled'}
														style={{ lineHeight: '20px', transform: 'rotate(270deg)' }}
													/>
												</Grid>
											</Grid>
										</Grid>

										<Popover
											open={searchIndex >= 0}
											anchorEl={anchorElSearch}
											anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
											transformOrigin={{ vertical: 'top', horizontal: 'right' }}
											onClose={() => {
												setSearchIndex(-1);
												setAnchorElSearch(null);
											}}
										>
											<form
												style={{ padding: 8, paddingTop: 0, width: 200 }}
												onReset={(event) => {
													event.preventDefault();
													handleSearch('input', '');
													handleSearch('submit', '');
													setSearchIndex(-1);
													setAnchorElSearch(null);
												}}
												onSubmit={(event) => {
													event.preventDefault();
													handleSearch('submit', input);
													setSearchIndex(-1);
													setAnchorElSearch(null);
													setPage(0);
												}}
											>
												<FormControl style={{ width: '100%', padding: '12px 0' }}>
													<OutlinedInput
														inputRef={inputRef}
														placeholder="Tìm kiếm..."
														value={input}
														inputProps={{ style: { fontSize: 14, paddingLeft: 4, marginLeft: 4 } }}
														style={{ height: 32 }}
														onChange={(event) => handleSearch('input', event.target.value)}
													/>
												</FormControl>
												<FormControl style={{ display: 'inline', width: '100%' }}>
													<Button
														variant="outlined"
														size="small"
														type="reset"
														style={{ width: '48%' }}
													>
														Huỷ
													</Button>
													<Button
														variant="contained"
														size="small"
														color="primary"
														type="submit"
														style={{ width: '48%', float: 'right' }}
													>
														Tìm kiếm
													</Button>
												</FormControl>
											</form>
										</Popover>

										<Popover
											open={anchorElFilter}
											anchorEl={anchorElFilter}
											anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
											transformOrigin={{ vertical: 'top', horizontal: 'right' }}
											onClose={() => {
												setTimeout(() => setFilterIndex(-1), 300);
												setAnchorElFilter(null);
											}}
										>
											<FormControl
												style={{
													padding: 8,
													display: columns[filterIndex]?.filterSearch ? 'block' : 'none'
												}}
											>
												<OutlinedInput
													fullWidth
													placeholder="Tìm kiếm"
													value={filterInput}
													startAdornment={<Search position="end" />}
													inputProps={{ style: { fontSize: 14, paddingLeft: 10, marginLeft: 10 } }}
													style={{ height: 32 }}
													onChange={(event) => setFilterInput(event.target.value)}
												/>
											</FormControl>
											<form
												style={{ minWidth: 200 }}
												onReset={(event) => {
													event.preventDefault();
													checklist[filterIndex]?.forEach((e, index) =>
														handleFilter('list', e.value, false)
													);
													handleFilter('submit', false);
													setTimeout(() => setFilterIndex(-1), 300);
													setAnchorElFilter(null);
													if (columns[filterIndex]?.filterSearch) setFilterInput('');
												}}
												onSubmit={(event) => {
													event.preventDefault();
													handleFilter('submit', true);
													setFilterIndex(-1);
													setAnchorElFilter(null);
													setPage(0);
												}}
											>
												<div style={{ padding: '4px 16px', maxHeight: 152, overflow: 'auto' }}>
													{(columns[filterIndex]?.filterSearch
														? matchSorter(
																filterIndex >= 0 ? checklist[filterIndex] : [],
																filterInput,
																{ keys: ['value'] }
															).splice(0, 20)
														: checklist[filterIndex]
													)?.map((e) => (
														<FormControlLabel
															key={e.value}
															control={
																<Checkbox
																	size="small"
																	value={e.checked}
																	checked={e.checked}
																	onChange={() => handleFilter('list', e.value, !e.checked)}
																/>
															}
															label={e.text}
															style={{ display: 'block' }}
														/>
													))}
												</div>
												<div style={{ padding: '8px 16px' }}>
													<FormControl style={{ display: 'inline', width: '100%' }}>
														<Button
															variant="outlined"
															size="small"
															type="reset"
															style={{ width: '48%' }}
														>
															Huỷ
														</Button>
														<Button
															variant="contained"
															size="small"
															color="primary"
															type="submit"
															style={{ width: '48%', float: 'right' }}
														>
															Lọc
														</Button>
													</FormControl>
												</div>
											</form>
										</Popover>
									</Grid>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.length !== 0 ? (
							rows
								?.slice(
									props.onPageChange ? 0 : rowsPerPage * page,
									props.onPageChange ? rowsPerPage : rowsPerPage * (page + 1)
								)
								.map((row, row_id) => (
									<TableRow key={`row-${row_id}`}>
										{columns?.map((column, column_id) => {
											if (column.render)
												return (
													<TableCell
														key={`column-${column_id}`}
														className={props.size === 'small' ? 'kpt-cell-small' : null}
													>
														{column.render(row[column.key], row)}
													</TableCell>
												);
											return (
												<TableCell
													key={`column-${column_id}`}
													className={props.size === 'small' ? 'kpt-cell-small' : null}
												>
													<Highlighter
														searchWords={column.search ? [column.search] : []}
														autoEscape={true}
														textToHighlight={String(row[column.key])}
													/>
												</TableCell>
											);
										})}
									</TableRow>
								))
						) : (
							<TableRow>
								<TableCell colSpan={props.columns?.length} style={{ textAlign: 'center' }}>
									<Drafts color="disabled" style={{ fontSize: 128 }} />
									<Typography variant="h5" style={{ color: 'rgba(0, 0, 0, 0.26)' }}>
										Không có dữ liệu
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{props.rowsPerPage > 0 ? (
				<TablePagination
					page={page}
					component="div"
					// count={props.onPageChange ? -1 : (props.totalData || props.rows.length)}
					count={props.totalData ?? rows.length}
					rowsPerPage={props.rowsPerPage}
					rowsPerPageOptions={[props.rowsPerPage]}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			) : null}

			{props.loading && (
				<div
					style={{
						top: 0,
						left: 0,
						zIndex: 100,
						width: '100%',
						height: '100%',
						display: 'flex',
						position: 'absolute',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'rgba(255, 255, 255, 0.5)'
					}}
				>
					<CircularProgress />
				</div>
			)}
		</Paper>
	);
}

export default KPTable;
