import React, { useEffect } from 'react';
import KPTable from '../../../components/KPTable';
import { Accordion, AccordionSummary } from '@material-ui/core';

const ScientificResearchTimeTable = (props) => {
	const { profiles, totalHour, projectMode, mainProfileIds } = props;
	const tableHeaderRender = (title, width) => {
		const className = 'em-contract-header-item';
		const color = props.disabled ? '#bdbdbd' : 'black';
		return (
			<b className={className} style={{ width: width, color: color }}>
				{title}
			</b>
		);
	};
	const tableCellRender = (title, width) => {
		const className = 'em-contract-cell-item';
		const color = props.disabled ? '#bdbdbd' : 'black';
		return (
			<div className={className} style={{ width: width, color: color }}>
				{title}
			</div>
		);
	};
	const getRoleLabel = (roleInPaper) => {
		if (projectMode === 'project') {
			if (roleInPaper === 'main') return 'Chủ nhiệm';
			if (roleInPaper === 'member') return 'Thành viên';
			if (roleInPaper === 'mainOther') return 'Chủ nhiệm tính hộ';
			if (roleInPaper === 'memberOther') return 'Thành viên tính hộ';
			return '';
		}
		if (roleInPaper === 'main') return 'Tác giả chính';
		if (roleInPaper === 'member') return 'Đồng tác giả';
		if (roleInPaper === 'mainOther') return 'Tác giả chính tính hộ';
		if (roleInPaper === 'memberOther') return 'Đồng tác giả tính hộ';
		return '';
	};

	const getHour = (profile) => {
		if (!profiles || profiles?.length === 0) return 0;
		let remainHour = totalHour;
		if (mainProfileIds && mainProfileIds.length > 0) {
			remainHour = totalHour - (totalHour * 1) / 3;
		}
		if (!profile) {
			return remainHour / profiles.length;
		}
		if (profile.roleInPaper === 'main' || profile.roleInProject === 'main') {
			return (totalHour * 1) / (3 * mainProfileIds?.length) + remainHour / profiles.length;
		}
		return remainHour / profiles.length;
	};

	const columns = [
		{
			title: (
				<div id="em-contract-header-container">
					{[
						tableHeaderRender('', '10%'),
						tableHeaderRender('Thành viên', '40%'),
						tableHeaderRender('Vai trò', '30%'),
						tableHeaderRender('Giờ nghiên cứu', '20%')
					]}
				</div>
			),
			fullwidth: true,
			key: 'id',
			render: (_, row) => {
				return (
					<Accordion className="em-contract-cell-container" square>
						<AccordionSummary className="em-contract-cell-header">
							{[
								tableCellRender('', '10%'),
								tableCellRender(
									`${row.isExternalTeacher ? '' : row.code + ' - '}${row.name}`,
									'40%'
								),
								tableCellRender(getRoleLabel(row.roleInPaper ?? row.roleInProject), '30%'),
								tableCellRender(getHour(row).toFixed(2), '20%')
							]}
						</AccordionSummary>
					</Accordion>
				);
			}
		}
	];

	return <KPTable size="small" rowsPerPage={-1} columns={columns} rows={profiles} />;
};

export default ScientificResearchTimeTable;
