/* eslint-disable no-loop-func */
import {
	Box,
	Grid,
	Button,
	Divider,
	Typography,
	TextField,
	Slide,
	IconButton,
	Tooltip,
	FormControlLabel,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Collapse
} from '@material-ui/core';
import {
	Add,
	School,
	Delete,
	DeleteForever,
	HighlightOff,
	Replay,
	DoubleArrow,
	Info,
	OfflinePin,
	Group,
	Person,
	Comment,
	RateReview
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
// import { TextField } from 'formik-material-ui';
import React, { useState, useEffect } from 'react';
// import { Autocomplete } from 'formik-material-ui-lab';
import { Autocomplete } from '@material-ui/lab';
import styles from './index.module.css';

import fileXinDung from '../../../../assets/files/DeTaiSinhVien/BMSVNCKH-07B ĐXDTHĐT.docx';

import theme from '../../../../theme';
import select from '../../../../utils/select';

import { uploadMultiFile } from '../../../../reducer/file/action';
import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';
import { Styles } from 'docx';
import { STUDENT_RESEARCH } from '../../../../constants/selectOptions';
import {
	updateStudentResearch,
	updateStudentResearchStatus
} from '../../../../reducer/studentResearch/action';

function ReportTable(props) {
	const dispatch = useDispatch();

	const [detailMembers, setDetailMembers] = useState([]);

	const handleClick = (index) => {
		const _index = detailMembers.findIndex((e) => e === index);
		if (_index < 0) {
			setDetailMembers([...detailMembers, index]);
		} else {
			const _detailMembers = [...detailMembers];
			_detailMembers.splice(_index, 1);
			setDetailMembers(_detailMembers);
		}
	};

	const handleSubmit = () =>
		dispatch(updateStudentResearchStatus(props.detail.id, 'report_ensure'));

	const researchMembers = props.detail.researchMembers.filter(
		(e) => !e.leaveReason || e.leaveReason === ''
	);
	const parentResearchMembers = props.detail.parentResearch.researchMembers;

	return (
		<form id={props.id} onSubmit={handleSubmit}>
			<Box border="solid 1px #bcbcbc" borderRadius={4} marginTop={1}>
				<Box
					display="flex"
					bgcolor="#ececec"
					padding="8px 16px"
					alignItems="center"
					borderBottom="solid 1px #bcbcbc"
					style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
				>
					<Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
					<Typography variant="h6" style={{ color: theme.palette.primary.main }}>
						<b>THÔNG TIN CƠ BẢN</b>
					</Typography>
				</Box>
				<Box>
					<Table size="small">
						<TableHead style={{ backgroundColor: '#fafafa' }}>
							<TableRow>
								<TableCell width="14%"></TableCell>
								<TableCell className={styles.cell} variant="head" width="43%" colSpan={3}>
									<b>Trước điều chỉnh</b>
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="43%" colSpan={3}>
									<b>Sau điều chỉnh</b>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody style={{ whiteSpace: 'pre-line' }}>
							<TableRow>
								<TableCell width="14%">Tên đề tài</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.parentResearch?.name}
								</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.name}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell width="14%">Mục tiêu nghiên cứu</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.parentResearch?.researchTarget}
								</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.researchTarget}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell width="14%">Dự kiến sản phẩm, kết quả</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.parentResearch?.expectedResult}
								</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.expectedResult}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell width="14%">
									Dự kiến tham gia giải thưởng sinh viên NCKH các cấp
								</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.parentResearch?.expectedCompetition}
								</TableCell>
								<TableCell className={styles.cell} width="43%" colSpan={3}>
									{props.detail?.expectedCompetition}
								</TableCell>
							</TableRow>
							<TableRow style={{ backgroundColor: '#fafafa' }}>
								<TableCell
									width="14%"
									rowSpan={
										Math.max(
											props.detail.parentResearch.instructors.length,
											props.detail.instructors.length
										) + 1
									}
									style={{ backgroundColor: '#fff' }}
								>
									Giảng viên hướng dẫn
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="17%">
									<b>Tên giảng viên</b>
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="15%">
									<b>Email</b>
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="8%" align="right">
									<b>Vai trò</b>
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="17%">
									<b>Tên giảng viên</b>
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="15%">
									<b>Email</b>
								</TableCell>
								<TableCell className={styles.cell} variant="head" width="8%" align="right">
									<b>Vai trò</b>
								</TableCell>
							</TableRow>
							{(() => {
								const render = [];
								for (
									var i = 0;
									i <
									Math.max(
										props.detail.parentResearch.instructors.length,
										props.detail.instructors.length
									);
									i++
								) {
									render.push(
										<TableRow>
											<TableCell
												className={styles.cell}
												width="17%"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{props.detail.parentResearch.instructors?.[i]?.name}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="15%"
												align="center"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{props.detail.parentResearch.instructors?.[i]?.email}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="8%"
												align="right"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{
													STUDENT_RESEARCH.researchRoleInstructorOptions.find(
														(e) =>
															e.value === props.detail.parentResearch.instructors?.[i]?.researchRole
													)?.title
												}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="17%"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{props.detail.instructors?.[i]?.name}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="15%"
												align="center"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{props.detail.instructors?.[i]?.email}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="8%"
												align="right"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{
													STUDENT_RESEARCH.researchRoleInstructorOptions.find(
														(e) => e.value === props.detail.instructors?.[i]?.researchRole
													)?.title
												}
											</TableCell>
										</TableRow>
									);
								}
								return render;
							})()}
						</TableBody>
						<TableRow style={{ backgroundColor: '#fafafa' }}>
							<TableCell
								width="14%"
								rowSpan={2 * Math.max(parentResearchMembers.length, researchMembers.length) + 1}
								style={{ backgroundColor: '#fff' }}
							>
								Thành viên đề tài
							</TableCell>
							<TableCell className={styles.cell} variant="head" width="17%">
								<b>Tên thành viên</b>
							</TableCell>
							<TableCell className={styles.cell} variant="head" width="15%" align="center">
								<b>Vai trò</b>
							</TableCell>
							<TableCell className={styles.cell} variant="head" width="8%" align="right" />
							<TableCell className={styles.cell} variant="head" width="17%">
								<b>Tên thành viên</b>
							</TableCell>
							<TableCell className={styles.cell} variant="head" width="15%" align="center">
								<b>Vai trò</b>
							</TableCell>
							<TableCell className={styles.cell} variant="head" width="8%" align="right" />
						</TableRow>
						{(() => {
							const render = [];
							for (
								var i = 0;
								i < Math.max(parentResearchMembers.length, researchMembers.length);
								i++
							) {
								const index = i;
								render.push(
									<>
										<TableRow>
											<TableCell
												className={styles.cell}
												width="17%"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{parentResearchMembers?.[i]?.name}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="15%"
												align="center"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{
													STUDENT_RESEARCH.researchRoleMemberOptions.find(
														(e) => e.value === parentResearchMembers?.[i]?.researchRole
													)?.title
												}
											</TableCell>
											<TableCell className={styles.cell} width="8%" align="right">
												{i < parentResearchMembers.length && (
													<Typography
														className="table-action-buton"
														variant="subtitle2"
														color="secondary"
														onClick={() => handleClick(index)}
													>
														<b>Chi tiết</b>
													</Typography>
												)}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="17%"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{researchMembers?.[i]?.name}
											</TableCell>
											<TableCell
												className={styles.cell}
												width="15%"
												align="center"
												style={{
													fontWeight:
														parentResearchMembers?.[i]?.researchRole === 'main' ? 'bold' : 'auto'
												}}
											>
												{
													STUDENT_RESEARCH.researchRoleMemberOptions.find(
														(e) => e.value === researchMembers?.[i]?.researchRole
													)?.title
												}
											</TableCell>
											<TableCell className={styles.cell} width="8%" align="right">
												{i < researchMembers.length && (
													<Typography
														className="table-action-buton"
														variant="subtitle2"
														color="secondary"
														onClick={() => handleClick(index)}
													>
														<b>Chi tiết</b>
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell className={styles.denseCell} colSpan={7}>
												<Collapse in={!isNaN(detailMembers.find((e) => e === i))}>
													<Box display="flex">
														<Box width="calc(50% - 32px)" padding="6px 16px" colSpan={3}>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>Họ và tên:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.name}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>SĐT:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.phone}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>Email:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.email}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>Lớp:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.class}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>GPA:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.gpa}
																</Typography>
															</Box>
														</Box>
														<Box
															className={styles.cell}
															width="calc(50% - 32px)"
															padding="6px 16px"
															colSpan={3}
														>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>Họ và tên:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{researchMembers?.[i]?.name}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>SĐT:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.phone}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>Email:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.email}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>Lớp:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.class}
																</Typography>
															</Box>
															<Box display="flex">
																<Typography variant="subtitle2" style={{ flexGrow: 1 }}>
																	<b>GPA:</b>
																</Typography>
																<Typography variant="subtitle2">
																	{parentResearchMembers?.[i]?.gpa}
																</Typography>
															</Box>
														</Box>
													</Box>
												</Collapse>
											</TableCell>
										</TableRow>
									</>
								);
							}
							return render;
						})()}
					</Table>
				</Box>
			</Box>

			<Box border="solid 1px #bcbcbc" borderRadius={4} marginTop={4}>
				<Box
					display="flex"
					bgcolor="#ececec"
					padding="8px 16px"
					alignItems="center"
					borderBottom="solid 1px #bcbcbc"
					style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
				>
					<Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
					<Typography
						variant="h6"
						style={{ color: theme.palette.primary.main, textTransform: 'uppercase' }}
					>
						<b>Các công việc đã hoàn thành</b>
					</Typography>
				</Box>
				<Box padding={2}>
					<Box width="50%" margin="auto">
						<KPTextfield type="dragzone" required={true} value={props.detail.finishedWork} />
					</Box>
				</Box>
			</Box>
		</form>
	);
}

export default ReportTable;
