import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useField } from 'formik';

import GridCellExpand from './GridCellExpand';

const useStyles = makeStyles((theme) => {
	return {
		tableParent: {
			height: 400
		},
		table: {
			'& .MuiDataGrid-colCellTitle': {
				fontWeight: 'bold'
			}
		},
		btnSelect: {
			margin: theme.spacing(0, 0, 0, 1)
		}
	};
});

function ISIScopusPublishmentModal(props) {
	const { open, handleClose, scientificResearchs } = props;

	const classes = useStyles();

	const [isiScopusPublishmentInput, , isiScopusPublishmentHelper] = useField(
		'scientificResearchs.isiScopusPublishments'
	);
	const [isiScopusIds, setIsiScopusIds] = useState([]);

	useEffect(() => {
		if (isiScopusPublishmentInput.value.length > 0 && isiScopusIds.length === 0) {
			setIsiScopusIds(isiScopusPublishmentInput.value.map((element) => element.id));
		}
	}, [isiScopusPublishmentInput]);

	/**
	 * @type {import('@material-ui/data-grid').GridColDef[]}
	 */
	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 100,
			hide: true
		},
		{
			field: 'title',
			headerName: 'Tiêu đề',
			width: 800,
			renderCell: (params) => {
				return <GridCellExpand value={params.value} width={params.colDef.width} />;
			}
		}
	];

	const rows = scientificResearchs
		.filter((scientificResearchElement) => {
			return (
				scientificResearchElement.scientificResearch.type === 'scientificResearchJournal' || //Need fix hieunt
				scientificResearchElement.scientificResearch.type === 'chapterJournal' ||
				scientificResearchElement.scientificResearch.type === 'scientificResearchConference' ||
				scientificResearchElement.scientificResearch.type === 'chapterConference'
			);
		})
		.map((isiScopusElement, index) => {
			let title = 'Thông tin về công bố';

			// journal
			if (isiScopusElement.journal) {
				const id = isiScopusElement.scientificResearch.id;
				const authors = (isiScopusElement.scientificResearch.listAuthor ?? []).join(', ');
				const paperTitle = isiScopusElement.scientificResearch.title;
				const year = isiScopusElement.scientificResearch.year;
				const journalTitle =
					isiScopusElement?.journal?.title ??
					isiScopusElement?.scientificResearch?.otherTitle ??
					'';
				const volume = isiScopusElement.scientificResearch.volume;
				const page = isiScopusElement.scientificResearch.page;
				const Qranking = `${isiScopusElement?.journal?.sjrBestQuartile ?? 'Not'} ranking on Scimago`;

				title = `${authors} (${year}). ${paperTitle}. ${journalTitle}. Volume ${volume}, pp ${page}. (${Qranking})`;
				return { id, title, year };
			}

			// conference
			if (isiScopusElement.conference) {
				const id = isiScopusElement.scientificResearch.id;
				const authors = (isiScopusElement.scientificResearch.listAuthor ?? []).join(', ');
				const paperTitle = isiScopusElement.scientificResearch.title;
				const year = isiScopusElement.scientificResearch.year;
				const conferenceTitle =
					isiScopusElement?.conference?.title ??
					isiScopusElement?.scientificResearch?.otherTitle ??
					'';
				const ranking = `${isiScopusElement?.conference?.rank ?? 'Not'} ranking on CORE, Australia`;

				title = `${authors} (${year}). ${paperTitle}. ${conferenceTitle}. (${ranking})`;
				return { id, title, year };
			}

			// not have conference or journal
			const id = isiScopusElement.scientificResearch.id;
			const authors = (isiScopusElement.scientificResearch.listAuthor ?? []).join(', ');
			const paperTitle = isiScopusElement.scientificResearch.title;
			const year = isiScopusElement.scientificResearch.year;
			const journalTitle = isiScopusElement?.scientificResearch?.otherTitle ?? '';

			title = `${authors} (${year}). ${paperTitle}. ${journalTitle}..`;
			return { id, title, year };
		});

	/**
	 *
	 * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
	 */
	const handleSelectionModelChange = (params) => {
		const selectedRows = rows.filter((rowElement) => {
			if (params.includes(rowElement.id)) {
				return true;
			}
			return false;
		});
		isiScopusPublishmentHelper.setValue(selectedRows);
		setIsiScopusIds(params);
	};

	const handleSelect = () => {
		handleClose(false);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
			<DialogTitle>Công bố ISI/Scopus</DialogTitle>
			<DialogContent>
				<Box className={classes.tableParent}>
					<DataGrid
						className={classes.table}
						columns={columns}
						rows={rows}
						checkboxSelection={true}
						selectionModel={isiScopusIds}
						onSelectionModelChange={handleSelectionModelChange}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={() => handleClose(false)}>
					Hủy
				</Button>
				<Button
					variant="contained"
					color="primary"
					className={classes.btnSelect}
					onClick={handleSelect}
				>
					Chọn
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ISIScopusPublishmentModal;
