import { STUDENT_RESEARCH } from '../../../../constants/selectOptions';

export const CalculateStudentResearchStep = (detail) => {
	let giaiDoan = 0;

	const statusIndex = STUDENT_RESEARCH.status.findIndex(
		(e) => e.key === detail?.status?.[0]?.value
	);

	for (let i = 1; i < 6; i++) {
		const giaiDoanPreIndex = STUDENT_RESEARCH.status.findIndex(
			(e) => e.key === `giai-doan-${i - 1}`
		);
		const giaiDoanCurIndex = STUDENT_RESEARCH.status.findIndex((e) => e.key === `giai-doan-${i}`);

		if (giaiDoanPreIndex < statusIndex && statusIndex <= giaiDoanCurIndex) {
			giaiDoan = i;
			break;
		}
	}

	return giaiDoan;
};
