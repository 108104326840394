import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	list: [],
	detail: null,
	listById: [],
	contractInfo: {},
	rewardInfo: [],
	isFetching: false,
	isFetchingKPI: false,
	canGetReward: false
});

export default function employmentContractReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.INSERT_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.DELETE_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.ACTIVATE_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.EMPLOYMENT_CONTRACT.CREATE_KPI_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.DELETE_KPI_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_LOADING:
		case TYPE.EMPLOYMENT_CONTRACT.GET_KPI_REWARD_INFO_LOADING:
			return state.merge({
				isFetchingKPI: true
			});

		case TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.INSERT_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.DELETE_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.ACTIVATE_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_FAILURE:
			return state.merge({
				isFetching: false,
				contractInfo: {},
				rewardInfo: []
			});

		case TYPE.EMPLOYMENT_CONTRACT.CREATE_KPI_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.DELETE_KPI_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_FAILURE:
		case TYPE.EMPLOYMENT_CONTRACT.GET_KPI_REWARD_INFO_FAILURE:
			return state.merge({
				isFetchingKPI: false
			});

		case TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_SUCCESS:
		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_SUCCESS:
		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_SUCCESS:
			return state.merge({
				list: action.payload ?? [],
				isFetching: false
			});

		case TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS:
			return state.merge({
				listById: action.payload ?? [],
				isFetching: false
			});

		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS:
			return state.merge({
				detail: { ...action.payload },
				isFetching: false
			});

		case TYPE.EMPLOYMENT_CONTRACT.INSERT_EMPLOYMENT_CONTRACT_SUCCESS:
			return state.merge({
				list: [...state.get('list'), action.payload],
				isFetching: false
			});

		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_SUCCESS: {
			const newList = state.get('list');
			const index = newList.findIndex((e) => e.contract.id === action.payload.contract.id);
			newList.splice(index, 1, action.payload);
			return state.merge({
				list: [...newList],
				isFetching: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.DELETE_EMPLOYMENT_CONTRACT_SUCCESS: {
			const newList = state.get('list');
			return state.merge({
				list: newList.filter((element) => element.contract.id !== action.payload),
				isFetching: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.ACTIVATE_EMPLOYMENT_CONTRACT_SUCCESS: {
			return state.merge({
				list: state.get('list').map((item) => {
					if (item.contract.id === action.payload.id) {
						return {
							...item,
							contract: { ...item.contract, active: action.payload.active }
						};
					}
					return item;
				}),
				isFetching: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_SUCCESS: {
			return state.merge({
				list: state.get('list').map((item) => {
					if (item.contract.id === action.payload.id) {
						return {
							...item,
							contract: { ...item.contract, kpiCompleted: action.payload.completed }
						};
					}
					return item;
				}),
				isFetching: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_SUCCESS: {
			return state.merge({
				contractInfo: action.payload,
				isFetching: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_V2_SUCCESS: {
			const newList = state.get('list');
			const index = newList.findIndex((e) => e.contract.id === action.payload.id);
			newList.splice(index, 1, { ...newList[index], contract: action.payload });
			return state.merge({
				list: [...newList],
				isFetching: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.CREATE_KPI_SUCCESS: {
			const tempContractInfo = state.get('contractInfo');
			const tempKpiInfo = tempContractInfo?.kpiInfo ?? [];

			return state.merge({
				contractInfo: {
					...tempContractInfo,
					kpiInfo: [
						...tempKpiInfo,
						{
							kpi: action.payload,
							value: 0,
							patent: [],
							solution: [],
							productContract: [],
							scientificResearch: []
						}
					]
				},
				isFetchingKPI: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_SUCCESS: {
			const tempContractInfo = state.get('contractInfo');
			const tempKpiInfo = tempContractInfo.kpiInfo.map((item) => {
				if (item.kpi.id === action.payload.id)
					return {
						...item,
						kpi: action.payload
					};
				else return item;
			});

			return state.merge({
				contractInfo: {
					...tempContractInfo,
					kpiInfo: tempKpiInfo
				},
				isFetchingKPI: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_SUCCESS: {
			const tempContractInfo = state.get('contractInfo');
			const tempKpiInfo = tempContractInfo.kpiInfo.map((item) => {
				if (item.kpi.id === action.id)
					return {
						...item,
						kpi: {
							...item.kpi,
							completed: !item.kpi.completed
						}
					};
				else return item;
			});

			return state.merge({
				contractInfo: {
					...tempContractInfo,
					kpiInfo: tempKpiInfo
				},
				isFetchingKPI: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.DELETE_KPI_SUCCESS: {
			const tempContractInfo = state.get('contractInfo');
			let tempKpiInfo = [];
			tempContractInfo.kpiInfo.forEach((item) => {
				if (item.kpi.id !== action.id) tempKpiInfo.push(item);
			});
			return state.merge({
				contractInfo: {
					...tempContractInfo,
					kpiInfo: tempKpiInfo
				},
				isFetchingKPI: false
			});
		}

		case TYPE.EMPLOYMENT_CONTRACT.GET_KPI_REWARD_INFO_SUCCESS:
			return state.merge({
				rewardInfo: action.payload,
				isFetchingKPI: false
			});

		default:
			return state;
	}
}
