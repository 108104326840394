import ListTable2022 from './ListTable2022';

function ListTable(props) {
	const { year } = props;

	const render = () => {
		switch (year) {
			case 2022:
				return <ListTable2022 year={2022} />;
			case 2023:
				return <ListTable2022 year={2023} />;
			case 2024:
				return <ListTable2022 year={2024} />;
			default:
				return null;
		}
	};

	return render();
}

export default ListTable;
