import React from 'react';
import { Box, Typography, TextField, IconButton, Button, Grid, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { FieldArray, ErrorMessage, useField, FastField } from 'formik';

const useStyles = makeStyles((theme) =>
	createStyles({
		sectionTitle: {
			fontWeight: 'bold',
			textTransform: 'uppercase'
		},
		subsectionTitle: {
			fontWeight: 'bold'
		},
		subsectionTitleNote: {
			fontWeight: 'normal',
			fontStyle: 'italic'
		},
		doctorElement: {
			padding: theme.spacing(2, 1),
			margin: theme.spacing(1, 0)
		},
		masterElement: {
			padding: theme.spacing(2, 1),
			margin: theme.spacing(1, 0)
		}
	})
);

function Experience() {
	const classes = useStyles();

	const [doctorInput] = useField('experiences.doctors');
	const [masterInput] = useField('experiences.masters');

	return (
		<>
			<Box py={2}>
				<Box>
					<Typography variant="h6" className={classes.sectionTitle}>
						5. Kinh nghiệm trong đào tạo
					</Typography>
				</Box>

				<Box paddingY={1}>
					<Typography className={classes.subsectionTitle}>Đào tạo Tiến sĩ</Typography>
				</Box>
				<FieldArray name="experiences.doctors">
					{(arrayHelpers) => {
						const handleAddDoctorElement = () => {
							arrayHelpers.push({ name: '', unit: '', year: '', role: '' });
						};
						const handleDeleteDoctorElement = (index) => {
							arrayHelpers.remove(index);
						};

						return (
							<>
								{doctorInput.value.map((_doctorElement, doctorIndex) => {
									return (
										<Paper key={doctorIndex} variant="outlined" className={classes.doctorElement}>
											<Box display="flex" flexDirection="row" alignItems="center">
												<Grid container spacing={2}>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.doctors[${doctorIndex}].name`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		label="Tên học viên"
																		helperText={
																			<ErrorMessage
																				name={`experiences.doctors[${doctorIndex}].name`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.doctors[${doctorIndex}].unit`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		label="Cơ sở đào tạo"
																		helperText={
																			<ErrorMessage
																				name={`experiences.doctors[${doctorIndex}].unit`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.doctors[${doctorIndex}].year`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		label="Năm bảo vệ"
																		helperText={
																			<ErrorMessage
																				name={`experiences.doctors[${doctorIndex}].year`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.doctors[${doctorIndex}].role`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		label="Vai trò"
																		helperText={
																			<ErrorMessage
																				name={`experiences.doctors[${doctorIndex}].role`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
												</Grid>
												<Box paddingLeft={1}>
													<IconButton
														color="secondary"
														onClick={() => handleDeleteDoctorElement(doctorIndex)}
													>
														<Delete />
													</IconButton>
												</Box>
											</Box>
										</Paper>
									);
								})}
								<Box paddingY={1}>
									<Button
										variant="outlined"
										color="primary"
										startIcon={<Add />}
										onClick={handleAddDoctorElement}
									>
										Thêm
									</Button>
								</Box>
							</>
						);
					}}
				</FieldArray>

				<Box paddingY={1}>
					<Typography className={classes.subsectionTitle}>
						Đào tạo Thạc sỹ <em className={classes.subsectionTitleNote}>(trong 5 năm gần nhất)</em>
					</Typography>
				</Box>
				<FieldArray name="experiences.masters">
					{(arrayHelpers) => {
						const handleAddMasterElement = () => {
							arrayHelpers.push({ name: '', unit: '', year: '', role: '' });
						};

						const handleDeleteMasterElement = (index) => {
							arrayHelpers.remove(index);
						};

						return (
							<>
								{masterInput.value.map((_masterElement, masterIndex) => {
									return (
										<Paper key={masterIndex} variant="outlined" className={classes.masterElement}>
											<Box display="flex" flexDirection="row" alignItems="center">
												<Grid container spacing={2}>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.masters[${masterIndex}].name`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		color="primary"
																		label="Tên học viên"
																		helperText={
																			<ErrorMessage
																				name={`experiences.masters[${masterIndex}].name`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.masters[${masterIndex}].unit`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		color="primary"
																		label="Cơ sở đào tạo"
																		helperText={
																			<ErrorMessage
																				name={`experiences.masters[${masterIndex}].unit`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.masters[${masterIndex}].year`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		color="primary"
																		label="Năm bảo vệ"
																		helperText={
																			<ErrorMessage
																				name={`experiences.masters[${masterIndex}].year`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
													<Grid item lg={3} md={6} sm={12}>
														<FastField name={`experiences.masters[${masterIndex}].role`}>
															{(fieldProps) => {
																return (
																	<TextField
																		{...fieldProps.field}
																		fullWidth
																		variant="outlined"
																		size="small"
																		color="primary"
																		label="Vai trò"
																		helperText={
																			<ErrorMessage
																				name={`experiences.masters[${masterIndex}].role`}
																			/>
																		}
																		FormHelperTextProps={{ error: true }}
																		InputLabelProps={{ shrink: true }}
																	/>
																);
															}}
														</FastField>
													</Grid>
												</Grid>
												<Box paddingLeft={1}>
													<IconButton
														color="secondary"
														onClick={() => handleDeleteMasterElement(masterIndex)}
													>
														<Delete />
													</IconButton>
												</Box>
											</Box>
										</Paper>
									);
								})}
								<Box paddingY={1}>
									<Button
										variant="outlined"
										color="primary"
										startIcon={<Add />}
										onClick={handleAddMasterElement}
									>
										Thêm
									</Button>
								</Box>
							</>
						);
					}}
				</FieldArray>
			</Box>
		</>
	);
}

export default Experience;
