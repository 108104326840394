import {
	WidthType,
	AlignmentType,
	VerticalAlign,
	Document,
	Paragraph,
	TextRun,
	Table,
	TableRow,
	TableCell,
	PageOrientation
} from 'docx';

import { AcademicRankAbbrv, DegreeAbbrv } from '../../utils/abbreviation';

const textProperties = {
	bold: true,
	size: 13 * 2
};

export class StudentProjectListDocx {
	headerCreator = () => {
		const header = [
			{
				children: [new TextRun({ ...textProperties, size: 11 * 2, text: 'TT' })],
				width: 5
			},
			{
				children: [new TextRun({ ...textProperties, size: 11 * 2, text: 'Tên đề tài' })],
				width: 10
			},
			{
				children: [
					new TextRun({ ...textProperties, size: 11 * 2, text: 'Sinh viên thực hiện' }),
					new TextRun({ text: '', break: 1 }),
					new TextRun({ ...textProperties, size: 11 * 2, text: 'Khóa, Lớp' })
				],
				width: 25
			},
			{
				children: [new TextRun({ ...textProperties, size: 11 * 2, text: 'Cán bộ hướng dẫn' })],
				width: 15
			},
			{
				children: [
					new TextRun({ ...textProperties, size: 11 * 2, text: 'Mục tiêu nghiên cứu ' }),
					new TextRun({ text: '', break: 1 }),
					new TextRun({ size: 11 * 2, text: '(Tóm tắt ngắn gọn bằng các gạch đầu dòng)' })
				],
				width: 15
			},
			{
				children: [
					new TextRun({
						...textProperties,
						size: 11 * 2,
						text: 'Dự kiến sản phẩm, kết quả nghiên cứu '
					}),
					new TextRun({ text: '', break: 1 }),
					new TextRun({ size: 11 * 2, text: '(Tóm tắt ngắn gọn bằng các gạch đầu dòng)' })
				],
				width: 15
			},
			{
				children: [
					new TextRun({
						...textProperties,
						size: 11 * 2,
						text: 'Dự kiến tham gia giải thưởng sinh viên NCKH các cấp'
					})
				],
				width: 10
			}
		];

		const children = header.map(
			(e) =>
				new TableCell({
					children: [new Paragraph({ children: e.children, alignment: AlignmentType.CENTER })],
					width: { size: e.width, type: WidthType.PERCENTAGE },
					verticalAlign: VerticalAlign.CENTER
				})
		);

		return new TableRow({ children, tableHeader: true });
	};

	rowsCreator = (data) => {
		const columns = [
			{ key: 'id', render: (_, __, index) => `Đề tài ${index + 1}`, mergeRow: true },
			{ key: 'name', mergeRow: true },
			{
				key: 'name',
				mergeRow: false,
				alignment: 'center',
				render: (value, member) =>
					member.researchRole === 'main'
						? `${value}-${member.academicYear},${member.class}\nChủ nhiệm đề tài\nĐiện thoại: ${member.phoneNumber}\nEmail: ${member.email}`
						: `${value}-${member.academicYear},${member.class}`
			},
			// {
			//   key: 'class',
			//   mergeRow: false,
			//   render: (value, item) => `Lớp: ${value}\nKhóa: ${item.academicYear}`
			// },
			{
				key: 'instructors',
				render: (val) =>
					val
						.map((e) => `${AcademicRankAbbrv(e?.academicRank)}${DegreeAbbrv(e?.degree)}${e?.name}`)
						.join(', '),
				mergeRow: true
			},
			// { key: 'gpa', mergeRow: false },
			{ key: 'researchTarget', mergeRow: true },
			{ key: 'expectedResult', mergeRow: true },
			{ key: 'expectedCompetition', mergeRow: true }
			// { key: 'cc', mergeRow: true },
		];

		const res = [];

		data.forEach((item, index) => {
			item.researchMembers.forEach((member, memberId) => {
				const children = [];

				columns.forEach((column, columnId) => {
					let text = column.mergeRow
						? column?.render
							? column?.render(item?.[column.key], item, index)
							: item?.[column.key]
						: column?.render
							? column?.render(member?.[column.key], member, index)
							: member?.[column.key];
					let rowSpan = 1;
					let skip = false;

					if (column.mergeRow) {
						if (memberId === 0) {
							rowSpan = item.researchMembers.length;
						} else skip = true;
					}

					var separateLines = null;
					if (typeof text === 'string') separateLines = text?.split('\n');

					if (!skip)
						children.push(
							new TableCell({
								children: separateLines
									? separateLines.map(
											(e) =>
												new Paragraph({
													children: [new TextRun({ size: 11 * 2, text: e })]
												})
										)
									: [new Paragraph({ children: [new TextRun({ size: 11 * 2, text })] })],
								rowSpan
							})
						);
				});

				const row = new TableRow({ children });

				res.push(row);
			});
		});

		return res;
	};

	create = (data) => {
		const document = new Document({
			sections: [
				{
					children: [
						new Paragraph({
							children: [
								new TextRun({
									...textProperties,
									text: `DANH SÁCH SINH VIÊN ĐĂNG KÝ THỰC HIỆN ĐỀ TÀI NCKH NĂM HỌC`
								})
							],
							alignment: AlignmentType.CENTER
						}),
						new Paragraph({
							children: [
								new TextRun({
									...textProperties,
									text: `Khoa/Viện/Trung tâm`
								})
							],
							alignment: AlignmentType.CENTER
						}),
						new Table({
							rows: [this.headerCreator(), ...this.rowsCreator(data)]
						})
					],
					properties: {
						page: {
							size: {
								orientation: PageOrientation.LANDSCAPE
							}
						}
					}
				}
			]
		});

		return document;
	};
}
