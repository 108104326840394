import { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Grid,
	TextField,
	Typography,
	Button,
	CircularProgress,
	MenuItem
} from '@material-ui/core';
import { WarningOutlined, Replay } from '@material-ui/icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import toJS from '../../../hoc/toJS';
import { apiCall } from '../../../utils/api';
import { API_URLS } from '../../../constants/api';

import { HumanResourceInsertContext } from './HumanResourceInsertContext';
import { notification } from '../../../reducer/home/action';

const profileSchema = yup.object().shape({
	address: yup.string(),
	birth: yup.date('Ngày sinh không hợp lệ').required('Không được để trống'),
	code: yup.string(),
	degree: yup.string().required('Không được để trống'),
	gender: yup.string(),
	graduatedYearPhd: yup.number().min(0),
	identityNumber: yup.string(),
	mail: yup.string().email('Email không hợp lệ'),
	major: yup.string(),
	name: yup.string().required('Không được để trống'),
	phone: yup.string(),
	profession: yup.string()
});

function HumanResourceUpdateProfileStep(props) {
	const { notification, handleCloseHumanResourceInsertModal } = props;
	const { user, profile, setProfile } = useContext(HumanResourceInsertContext);
	const [fetchProfileError, setFetchProfileError] = useState(false);

	const handleReloadProfile = useCallback(() => {
		if (typeof user?.id === 'number') {
			(async () => {
				const apiGetProfile = API_URLS.PROFILE.getProfileFromUserId(user?.id);
				const { response, error } = await apiCall(apiGetProfile);
				if (!error && response.status === 200) {
					notification({
						type: 'success',
						message: 'Lấy hồ sơ thành công'
					});
					setFetchProfileError(false);
					setProfile({
						...response.data.data,
						birth: dayjs(response.data.data?.birth ?? '').format('YYYY-MM-DD')
					});
				} else {
					notification({
						type: 'success',
						message: 'Lấy hồ sơ thất bại'
					});
					setFetchProfileError(true);
					setProfile(null);
				}
			})();
		}
	}, [notification, setProfile, setFetchProfileError, user]);

	useEffect(() => {
		handleReloadProfile();
	}, [handleReloadProfile]);

	if (user === null) {
		return (
			<Box textAlign="center">
				<WarningOutlined color="error" />
				<Typography>Chưa tạo nhân sự</Typography>
			</Box>
		);
	}

	if (fetchProfileError) {
		return (
			<Box textAlign="center">
				<WarningOutlined color="error" />
				<Typography>Lấy hồ sơ thất bại</Typography>
			</Box>
		);
	}
	return (
		<Box>
			<Grid container justifyContent="center">
				<Grid item xs={12}>
					<Box textAlign="right">
						<Button variant="outlined" startIcon={<Replay />} onClick={handleReloadProfile}>
							Tải lại
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid container justifyContent="center">
				<Grid item xs={12}>
					<Formik
						enableReinitialize={true}
						initialValues={{
							address: profile?.address ?? '',
							birth: dayjs(profile?.birth).isValid()
								? dayjs(profile?.birth).format('YYYY-MM-DD')
								: '',
							code: profile?.code ?? '',
							degree: profile?.degree ?? '',
							gender: profile?.gender ?? '',
							graduatedYearPhd: profile?.graduatedYearPhd ?? '',
							identityNumber: profile?.identityNumber ?? '',
							mail: profile?.mail ?? '',
							major: profile?.major ?? '',
							name: profile?.name ?? '',
							phone: profile?.phone ?? '',
							profession: profile?.profession ?? ''
						}}
						validationSchema={profileSchema}
						onSubmit={async (values) => {
							const payload = {
								address: values.address,
								birth: dayjs(values.birth).isValid()
									? dayjs(values.birth).format('YYYY-MM-DDThh:mm:ss[Z]')
									: '',
								code: values.code,
								degree: values.degree,
								gender: values.gender,
								graduatedYearPhd: values.graduatedYearPhd,
								identityNumber: values.identityNumber,
								mail: values.mail,
								major: values.major,
								name: values.name,
								phone: values.phone,
								profession: values.profession,
								id: profile.id,
								userId: profile.userId
							};
							setProfile(payload);

							const profileId = profile.id;
							const apiUpdateProfile = API_URLS.PROFILE.updateProfile(profileId);
							const { response, error } = await apiCall({ ...apiUpdateProfile, payload });

							if (!error && response.status === 200 && response.data.success) {
								notification({
									type: 'success',
									message: 'Cập nhật hồ sơ thành công'
								});
								handleCloseHumanResourceInsertModal();
							} else {
								notification({
									type: 'error',
									message: 'Cập nhật hồ sơ thất bại'
								});
							}
						}}
					>
						{(formikProps) => {
							const { isSubmitting, handleSubmit } = formikProps;

							return (
								<Form>
									<Box py={1}>
										<Typography variant="h6">Hồ sơ</Typography>
									</Box>
									<Box py={1}>
										<Field name="name">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="text"
														label="Họ và tên"
														helperText={<ErrorMessage name="name" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="code">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														variant="outlined"
														size="small"
														label="Mã PU"
														helperText={<ErrorMessage name="code" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="birth">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="date"
														label="Ngày sinh"
														helperText={<ErrorMessage name="birth" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="gender">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														select
														variant="outlined"
														size="small"
														label="Giới tính"
														helperText={<ErrorMessage name="gender" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													>
														<MenuItem value={'male'}>Nam</MenuItem>
														<MenuItem value={'female'}>Nữ</MenuItem>
														<MenuItem value={'other'}>Khác</MenuItem>
													</TextField>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="identityNumber">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														variant="outlined"
														size="small"
														label="Số CMND"
														helperText={<ErrorMessage name="identityNumber" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="address">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="text"
														label="Địa chỉ"
														helperText={<ErrorMessage name="address" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="phone">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="text"
														label="Số điện thoại"
														helperText={<ErrorMessage name="phone" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="mail">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled
														variant="outlined"
														size="small"
														type="email"
														label="Email"
														helperText={<ErrorMessage name="mail" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="degree">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="text"
														label="Bằng cấp"
														helperText={<ErrorMessage name="degree" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="graduatedYearPhd">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="number"
														label="Năm tốt nghiệp PhD"
														helperText={<ErrorMessage name="graduatedYearPhd" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="profession">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="text"
														label="Chuyên môn"
														helperText={<ErrorMessage name="profession" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Field name="major">
											{(fieldProps) => {
												return (
													<TextField
														{...fieldProps.field}
														fullWidth
														disabled={profile === null}
														variant="outlined"
														size="small"
														type="text"
														label="Chuyên ngành"
														helperText={<ErrorMessage name="major" />}
														FormHelperTextProps={{ error: true }}
														InputLabelProps={{ shrink: true }}
													/>
												);
											}}
										</Field>
									</Box>
									<Box py={1}>
										<Button
											fullWidth
											type="submit"
											variant="contained"
											color="primary"
											onClick={handleSubmit}
											disabled={profile === null || isSubmitting}
										>
											{isSubmitting && <CircularProgress size={20} />}
											Cập nhật hồ sơ
										</Button>
									</Box>
								</Form>
							);
						}}
					</Formik>
				</Grid>
			</Grid>
		</Box>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	notification: (payload) => dispatch(notification(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(HumanResourceUpdateProfileStep));
