import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	list: [],
	feeTypes: [],
	isFetching: false
});

const sortFunc = (a, b) => {
	if (!a?.id || !b?.id) {
		return 0;
	}
	return a.id - b.id;
};

export default function intellectualPropertyReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.INTELLECTUAL_PROPERTY.GET_ALL_INTELLECTUAL_PROPERTY_LOADING:
		case TYPE.INTELLECTUAL_PROPERTY.INSERT_INTELLECTUAL_PROPERTY_LOADING:
		case TYPE.INTELLECTUAL_PROPERTY.UPDATE_INTELLECTUAL_PROPERTY_LOADING:
		case TYPE.INTELLECTUAL_PROPERTY.UPDATE_APPROVE_INTELLECTUAL_PROPERTY_LOADING:
		case TYPE.INTELLECTUAL_PROPERTY.DELETE_INTELLECTUAL_PROPERTY_LOADING:
		case TYPE.INTELLECTUAL_PROPERTY.GET_ALL_FEE_TYPE_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.INTELLECTUAL_PROPERTY.GET_ALL_INTELLECTUAL_PROPERTY_FAILURE:
		case TYPE.INTELLECTUAL_PROPERTY.INSERT_INTELLECTUAL_PROPERTY_FAILURE:
		case TYPE.INTELLECTUAL_PROPERTY.UPDATE_INTELLECTUAL_PROPERTY_FAILURE:
		case TYPE.INTELLECTUAL_PROPERTY.UPDATE_APPROVE_INTELLECTUAL_PROPERTY_FAILURE:
		case TYPE.INTELLECTUAL_PROPERTY.DELETE_INTELLECTUAL_PROPERTY_FAILURE:
		case TYPE.INTELLECTUAL_PROPERTY.GET_ALL_FEE_TYPE_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.INTELLECTUAL_PROPERTY.GET_ALL_INTELLECTUAL_PROPERTY_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [...(action.payload || [])].sort(sortFunc)
			});

		case TYPE.INTELLECTUAL_PROPERTY.INSERT_INTELLECTUAL_PROPERTY_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [...state.get('list'), action.payload].sort(sortFunc)
			});

		case TYPE.INTELLECTUAL_PROPERTY.UPDATE_INTELLECTUAL_PROPERTY_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [
					...state.get('list').filter((e) => e?.id !== action.payload?.id),
					action.payload
				].sort(sortFunc)
			});

		case TYPE.INTELLECTUAL_PROPERTY.DELETE_INTELLECTUAL_PROPERTY_SUCCESS:
			return state.merge({
				isFetching: false,
				list: [...state.get('list')].filter((e) => e.id !== action.id)
			});

		case TYPE.INTELLECTUAL_PROPERTY.GET_ALL_FEE_TYPE_SUCCESS:
			return state.merge({
				isFetching: false,
				feeTypes: [...(action.payload || [])]
			});

		default:
			return state;
	}
}
