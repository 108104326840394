import React from 'react';
import { Box, Typography, Grid, Button, IconButton, TextField, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { useField, ErrorMessage, FieldArray, FastField } from 'formik';

const useStyles = makeStyles((theme) =>
	createStyles({
		sectionTitle: {
			fontWeight: 'bold',
			textTransform: 'uppercase'
		},
		businessElement: {
			padding: theme.spacing(2, 1),
			margin: theme.spacing(1, 0)
		}
	})
);

function Business() {
	const classes = useStyles();

	const [businessInput] = useField('businesses');

	return (
		<>
			<Box py={2}>
				<Box>
					<Typography variant="h6" className={classes.sectionTitle}>
						2. Quá trình công tác
					</Typography>
				</Box>
			</Box>
			<FieldArray name="businesses">
				{(arrayHelpers) => {
					const handleAddBusinessElement = () => {
						arrayHelpers.push({
							duration: '',
							unit: '',
							role: ''
						});
					};

					const handleDeleteBusinessElement = (index) => {
						arrayHelpers.remove(index);
					};

					return (
						<>
							{businessInput.value.map((_businessElement, businessIndex) => {
								return (
									<Paper variant="outlined" className={classes.businessElement} key={businessIndex}>
										<Box display="flex" flexDirection="row" alignItems="center">
											<Grid container spacing={2}>
												<Grid item lg={3} md={12}>
													<FastField name={`businesses[${businessIndex}].duration`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Thời gian"
																	helperText={
																		<ErrorMessage name={`businesses[${businessIndex}].duration`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={5} md={12}>
													<FastField name={`businesses[${businessIndex}].unit`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Đơn vị công tác"
																	helperText={
																		<ErrorMessage name={`businesses[${businessIndex}].unit`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
												<Grid item lg={4} md={12}>
													<FastField name={`businesses[${businessIndex}].job`}>
														{(fieldProps) => {
															return (
																<TextField
																	{...fieldProps.field}
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Công việc đảm nhiệm"
																	helperText={
																		<ErrorMessage name={`businesses[${businessIndex}].job`} />
																	}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																/>
															);
														}}
													</FastField>
												</Grid>
											</Grid>
											<Box flexShrink={1} paddingLeft={2}>
												<IconButton
													color="secondary"
													title="Xóa"
													onClick={() => handleDeleteBusinessElement(businessIndex)}
												>
													<Delete />
												</IconButton>
											</Box>
										</Box>
									</Paper>
								);
							})}
							<Box paddingTop={1}>
								<Button
									variant="outlined"
									color="primary"
									startIcon={<Add />}
									onClick={handleAddBusinessElement}
								>
									Thêm
								</Button>
							</Box>
						</>
					);
				}}
			</FieldArray>
		</>
	);
}

export default Business;
