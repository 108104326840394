import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	list: [],
	listFiltered: [],
	listById: [],
	isFetching: false,
	isFetchingAccepted: false,
	isFetchingDOI: false,
	isNeedReload: false,
	DOI: {},
	purposes: []
});

export default function scientificResearchOtherOtherReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_LOADING:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_LOADING:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_FILTERED_SCIENTIFIC_RESEARCH_OTHER_LOADING:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.INSERT_SCIENTIFIC_RESEARCH_OTHER_LOADING:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.UPDATE_SCIENTIFIC_RESEARCH_OTHER_LOADING:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.DELETE_SCIENTIFIC_RESEARCH_OTHER_LOADING:
			return state.merge({
				isFetching: true
			});
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.DOI_LOADING: {
			return state.merge({
				isFetchingDOI: true
			});
		}
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.ACCEPT_SCIENTIFIC_RESEARCH_OTHER_LOADING:
			return state.merge({
				isFetchingAccepted: true
			});
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_PURPOSE_LOADING:
			return state;

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_FAILURE:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_FILTERED_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.INSERT_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.UPDATE_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.DELETE_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
			return state.merge({
				isFetching: false
			});
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.DOI_FAILURE: {
			return state.merge({
				isFetchingDOI: false,
				isNeedReload: !state.get('isNeedReload')
			});
		}
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.ACCEPT_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
			return state.merge({
				isFetchingAccepted: false
			});
		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_PURPOSE_FAILURE:
			return state;

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
			return state.merge({
				list: action.payload || [],
				isFetching: false
			});

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_FILTERED_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
			return state.merge({
				listFiltered: action.payload || [],
				isFetching: false
			});

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_SUCCESS: {
			return state.merge({
				listById: action.payload || [],
				isFetching: false
			});
		}

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.INSERT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
			return state.merge({
				listById: action.payload
					? [...state.get('listById'), { scientificResearchOther: action.payload }]
					: state.get('listById'),
				list: action.payload
					? [...state.get('list'), { scientificResearchOther: action.payload }]
					: state.get('list'),
				DOI: {},
				isFetching: false
			});

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.UPDATE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS: {
			const newList = state.get('listById');
			if (action.payload !== null) {
				const index = newList.findIndex((e) => e.scientificResearchOther.id === action.payload.id);
				const newScientificResearchOther = {
					...newList[index],
					scientificResearchOther: action.payload
				};
				newList.splice(index, 1, newScientificResearchOther);
			}

			const newList1 = state.get('list');
			if (action.payload !== null) {
				const index1 = newList1.findIndex(
					(e) => e.scientificResearchOther.id === action.payload.id
				);
				const newScientificResearchOther1 = {
					...newList1[index1],
					scientificResearchOther: action.payload
				};
				newList1.splice(index1, 1, newScientificResearchOther1);
			}

			return state.merge({
				listById: [...newList],
				list: [...newList1],
				isFetching: false
			});
		}

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.DELETE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS: {
			const newList = state.get('listById');
			const index = newList.findIndex((e) => e.scientificResearchOther.id === action.id);
			if (index !== -1) newList.splice(index, 1);

			const newList1 = state.get('list');
			const index1 = newList1.findIndex((e) => e.scientificResearchOther.id === action.id);
			if (index1 !== -1) newList1.splice(index1, 1);

			return state.merge({
				listById: [...newList],
				list: [...newList1],
				isFetching: false
			});
		}

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.ACCEPT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS: {
			const newList = state.get('listById');
			const index = newList.findIndex((e) => e.scientificResearchOther.id === action.payload.id);
			const newScientificResearchOther = {
				...newList[index],
				scientificResearchOther: action.payload
			};
			newList.splice(index, 1, newScientificResearchOther);

			const newList1 = state.get('list');
			const index1 = newList1.findIndex((e) => e.scientificResearchOther.id === action.payload.id);
			const newScientificResearchOther1 = {
				...newList1[index1],
				scientificResearchOther: action.payload
			};
			newList1.splice(index1, 1, newScientificResearchOther1);

			return state.merge({
				listById: [...newList],
				list: [...newList1],
				isFetchingAccepted: false
			});
		}

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.DOI_SUCCESS: {
			return state.merge({
				DOI: action.doi,
				isFetchingDOI: false,
				isNeedReload: !state.get('isNeedReload')
			});
		}

		case TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_PURPOSE_SUCCESS: {
			return state.merge({
				purposes: action.payload
			});
		}

		default:
			return state;
	}
}
