import axios from 'axios';
import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { notification } from '../home/action';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllScientificResearch = () => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.getAllScientificResearch();
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getFilteredScientificResearch = (page, pageSize, payload) => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.getFilteredScientificResearch(page, pageSize);
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.GET_FILTERED_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_FILTERED_SCIENTIFIC_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_FILTERED_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getAllScientificResearchById = (id) => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.getAllScientificResearchById(id);
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const insertScientificResearch = (payload) => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.insertScientificResearch();
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.INSERT_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.INSERT_SCIENTIFIC_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm dữ liệu thành công!'
			})
		);
		return { success: true, data: response.data.data };
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.INSERT_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Thêm dữ liệu thất bại hoặc bài báo đã tồn tại!'
			})
		);
		return false;
	}
};

export const updateScientificResearch = (id, payload) => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.updateScientificResearch(id);
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.UPDATE_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.UPDATE_SCIENTIFIC_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Điều chỉnh dữ liệu thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.UPDATE_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Điều chỉnh dữ liệu thất bại!'
			})
		);
		return false;
	}
};

export const deleteScientificResearch = (id) => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.deleteScientificResearch(id);
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.DELETE_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.DELETE_SCIENTIFIC_RESEARCH_SUCCESS,
			id,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Xóa dữ liệu thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.DELETE_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Xóa dữ liệu thất bại!'
			})
		);
		return false;
	}
};

export const acceptScientificResearch = (id, accept, filter) => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.acceptScientificResearch(id, accept);
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.ACCEPT_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.ACCEPT_SCIENTIFIC_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
		// let filterId;
		// switch (filter) {
		//   case 'journalInternational': {
		//     filterId = 'numJournal';
		//     break;
		//   }
		//   case 'journalDomestic': {
		//     filterId = 'numDomesticJournal';
		//     break;
		//   }
		//   case 'conference': {
		//     filterId = 'numConference';
		//     break;
		//   }
		//   case 'conferenceDomestic': {
		//     filterId = 'numDomesticConference';
		//     break;
		//   }
		//   case 'chapter': {
		//     filterId = 'numBook';
		//     break;
		//   }
		//   case 'workingPaper': {
		//     filterId = 'numWorkingPaper';
		//     break;
		//   }
		//   default: {
		//     break;
		//   }
		// }
		dispatch(
			notification({
				type: 'success',
				message: 'Xác nhận thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.ACCEPT_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Xác nhận thất bại!'
			})
		);
		return false;
	}
};

export const getDOI = (url) => async (dispatch) => {
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.DOI_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	let response = null;
	let error = null;
	try {
		const result = await axios({
			method: 'GET',
			url,
			headers: {
				Accept: 'application/json; charset=UTF-8'
			}
		});
		response = result;
	} catch (e) {
		error = e.request;
	}
	if (!error && response?.status === 200) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.DOI_SUCCESS,
			doi: response.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.DOI_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getAllPurpose = () => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.getAllPurpose();
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_PURPOSE_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_PURPOSE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_ALL_PURPOSE_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const setFilter = (id) => async (dispatch) => {
	dispatch({
		type: 'SCIENTIFIC_RESEARCH_SET_FILTER',
		id,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
};

export const getDuplicateScientificResearch = () => async (dispatch) => {
	const api = API_URLS.SCIENTIFIC_RESEARCH.getDuplicateScientificResearch();
	dispatch({
		type: TYPE.SCIENTIFIC_RESEARCH.GET_DUPLICATE_SCIENTIFIC_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_DUPLICATE_SCIENTIFIC_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Lấy dữ liệu công bố trùng thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.SCIENTIFIC_RESEARCH.GET_DUPLICATE_SCIENTIFIC_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu công bố trùng thất bại!'
			})
		);
	}
};
