import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Chip,
	Grid,
	Button,
	Tooltip,
	MenuItem,
	TextField,
	Typography,
	IconButton,
	Divider
} from '@material-ui/core';
import { Add, Edit, Delete, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import {
	updateKPI,
	updateKPICompleted,
	deleteKPI
} from '../../../reducer/employmentContract/action';
import { HEADERS } from '../../../constants/api';
import { apiCall } from '../../../utils/api';

const kpiSchema = yup.object().shape({
	completed: yup.boolean(),
	type: yup.string().required('Không được để trống'),
	kpiScientificResearch: yup.array().when('type', {
		is: 'R',
		then: yup.array().of(
			yup.object().shape({
				deleted: yup.boolean().nullable(),
				num: yup
					.string()
					.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
					.required('Không được để trống'),
				numFloat: yup
					.string()
					.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
					.required('Không được để trống'),
				typeQ: yup
					.string()
					.oneOf(['Q1', 'Q2', 'Q3', 'Q4', 'Scopus'])
					.required('Không được để trống'),
				typeR: yup.string().when('typeQ', {
					is: 'Scopus',
					then: yup.string(),
					otherwise: yup
						.string()
						.oneOf(['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'TCN'])
						.required('Không được để trống')
				}),
				roleInPaper: yup.string().oneOf(['main', 'member']).required('Không được để trống')
			})
		),
		otherwise: yup.array().of(
			yup.object().shape({
				deleted: yup.boolean().nullable(),
				num: yup
					.string()
					.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
					.required('Không được để trống'),
				numFloat: yup
					.string()
					.matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
					.required('Không được để trống'),
				typeQ: yup
					.string()
					.oneOf(['Q1', 'Q2', 'Q3', 'Q4', 'Scopus'])
					.required('Không được để trống'),
				typeR: yup.string(),
				roleInPaper: yup.string().oneOf(['main', 'member']).required('Không được để trống')
			})
		)
	}),
	// kpiScientificResearch: yup.array().of(
	//   yup.object().shape({
	//     deleted: yup.boolean().nullable(),
	//     num: yup
	//       .string()
	//       .matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
	//       .required('Không được để trống'),
	//     numFloat: yup
	//       .string()
	//       .matches(/^\d+(\.\d*[1-9])?$/, 'Nhập số thực ngăn cách phần thập phân bằng dấu chấm')
	//       .required('Không được để trống'),
	//     typeQ: yup.string().oneOf(['Q1', 'Q2', 'Q3', 'Q4']).required('Không được để trống'),
	//     typeR: yup.string().oneOf(['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'TCN']).required('Không được để trống'),
	//     roleInPaper: yup.string().oneOf(['main', 'member']).required('Không được để trống'),
	//     // value: yup
	//     //   .string()
	//     //   .matches(/^[1-9]/, 'Nhập số nguyên lớn hơn không')
	//     //   .required('Không được để trống'),
	//   })
	// ),
	kpiSolution: yup.array().of(
		yup.object().shape({
			value: yup
				.string()
				.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
				.required('Không được để trống'),
			num: yup
				.string()
				.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
				.required('Không được để trống')
		})
	),
	kpiPatent: yup.array().of(
		yup.object().shape({
			type: yup
				.string()
				.oneOf(
					[
						'USPTO (Utility)-TGC',
						'USPTO (Design)-TGC',
						'USPTO (Plant)-TGC',
						'EPO-TGC',
						'Bằng sáng chế (VN)-TGC'
					],
					'Chọn một trong 5 loại đã cho'
				)
				.required('Không được để trống'),
			num: yup
				.string()
				.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn hoặc bằng không')
				.required('Không được để trống'),
			value: yup
				.string()
				.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
				.required('Không được để trống')
		})
	),
	kpiProductContract: yup.array().of(
		yup.object().shape({
			value: yup
				.string()
				.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
				.required('Không được để trống'),
			num: yup
				.string()
				.matches(/^[1-9]\d*$/, 'Nhập số nguyên lớn hơn không')
				.required('Không được để trống')
		})
	)
});

const salaryCaculation = [
	{
		name: 'Lương NCKH theo tháng',
		value: true
	},
	{
		name: 'Thưởng NCKH năm (trả sau)',
		value: false
	}
];

function KPIForm(props) {
	const [disabled, setDisabled] = useState(true);
	const [editTitleNote, setEditTitleNote] = useState(null);
	const [quantitySetting, setQuantitySetting] = useState([]);
	const [kpiSalary, setKpiSalary] = useState([]);

	const numberWithDots = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	const updateTitleNote = () => {
		const kpi = props.kpiInfo?.kpi;
		props.updateKPI(kpi?.id, { ...kpi, titleNote: editTitleNote });
		setEditTitleNote(null);
	};

	useEffect(async () => {
		const url = `/hrm/api/v1/contract/get-by-year-and-profile-code?profileCode=${props.profile?.code}&year=${props.kpiInfo?.kpi?.year}`;
		const body =
			'{"filter":"{\\"otherFilter\\":{\\"listCode\\":[\\"scholarship\\",\\"kpiSalary\\"]}}","ignoreAssociation":false,"isIncludeSoftDelete":false,"isUnscoped":false,"isPaginateDB":true,"page":1,"pageSize":-1,"modelType":"systemSettings"}}';

		const { response } = await apiCall({
			endPoint: url,
			method: 'GET',
			payload: body,
			headers: HEADERS.header()
		});

		if (response) {
			const kpiResearchSalary = response.data.data[0].SubContract.KpiResearchSalary;
			setKpiSalary(kpiResearchSalary);
		}
	}, []);

	useEffect(async () => {
		const url = '/hrm/api/v1/filters/filter/notoken';
		const body = {
			filter:
				'{"otherFilter":{"listCode":["positionSettings","positionDetailSettings","kpiSalary"]}}',
			ignoreAssociation: false,
			isIncludeSoftDelete: false,
			isUnscoped: false,
			isPaginateDB: true,
			page: 1,
			pageSize: -1,
			modelType: 'systemSettings'
		};

		const { response } = await apiCall({
			endPoint: url,
			method: 'POST',
			payload: body,
			headers: HEADERS.header()
		});
		if (response) {
			const kpiSalaryData = response.data.data.filter((data) => data.code === 'kpiSalary');
			const quantitySetting = JSON.parse(kpiSalaryData[0].value);
			setQuantitySetting(quantitySetting.data);
		}
	}, []);

	return (
		<>
			{props.kpiInfo === undefined ? (
				<>Chưa get được dữ liệu</>
			) : (
				<Grid container spacing={2}>
					{/* <Grid item xs={8}>
            <Typography variant="h6">Giá trị sản phẩm đã đăng ký: {numberWithDots(props.kpiInfo?.kpi?.value)} đ</Typography>
          </Grid> */}

					<Grid item xs={12}>
						<Formik
							enableReinitialize={true}
							initialValues={props.kpiInfo?.kpi}
							// validationSchema={kpiSchema}
							onSubmit={async (values) => {
								// // // console.log(values)
								// // // console.log(
								await props.updateKPI(values.id, {
									...values,
									kpiSolution: values.kpiSolution.map((element) => {
										return {
											...element, // this is required
											kpiId: values.id,
											num: Number(element.num),
											value: Number(element.value),
											numFloat: Number(element.numFloat)
										};
									}),
									kpiScientificResearch: values.kpiScientificResearch.map((element) => {
										return {
											...element, // this is required
											id: element.id,
											num: Number(Math.round(element.numFloat)),
											kpiId: values.id,
											roleInPaper: element.roleInPaper,
											typeQ: element.typeQ,
											typeR: element.typeR,
											value: 0,
											numFloat: Number(element.numFloat)
										};
									}),
									kpiPatent: values.kpiPatent.map((element) => {
										return {
											...element, // this is required
											id: element.id,
											num: Number(element.num),
											kpiId: values.id,
											type: element.type,
											value: Number(element.value),
											numFloat: Number(element.numFloat)
										};
									}),
									kpiProductContract: values.kpiProductContract.map((element) => {
										return {
											...element, // this is required
											id: element.id,
											num: Number(element.num),
											kpiId: values.id,
											value: Number(element.value),
											numFloat: Number(element.numFloat)
										};
									})
								});
								setDisabled(true);
							}}
						>
							{(formikProps) => {
								const { values, handleSubmit } = formikProps;

								return (
									<>
										<Box display="flex" flexDirection="row">
											<Grid container spacing={2}>
												{props.role === 'sudo-admin' && (
													<Grid item xs={12}>
														<Box display="flex" lineHeight="48px">
															<b>Chú thích ở tên hợp đồng: </b>
															{editTitleNote === null ? (
																<>
																	<b style={{ marginLeft: 8 }}>
																		{props.kpiInfo?.kpi?.titleNote || 'Không có chú thích'}
																	</b>
																	<Tooltip title="Sửa chú thích tên hợp đồng">
																		<IconButton
																			onClick={() => setEditTitleNote(props.kpiInfo?.kpi.titleNote)}
																			style={{ marginLeft: 8 }}
																		>
																			<Edit />
																		</IconButton>
																	</Tooltip>
																</>
															) : (
																<>
																	<TextField
																		size="small"
																		variant="outlined"
																		value={editTitleNote || ''}
																		onChange={(event, newValue) => {
																			// // console.log(event.target.value)
																			setEditTitleNote(event.target.value);
																		}}
																		style={{ margin: '0 8px', flexGrow: 1 }}
																	/>
																	<Button
																		color="primary"
																		size="small"
																		variant="outlined"
																		onClick={() => updateTitleNote()}
																		style={{ height: 40, margin: '0 8px', color: '' }}
																	>
																		Lưu
																	</Button>
																	<Button
																		color="secondary"
																		size="small"
																		variant="outlined"
																		onClick={() => setEditTitleNote(null)}
																		style={{ height: 40, margin: '0 8px' }}
																	>
																		Hủy
																	</Button>
																</>
															)}
														</Box>
													</Grid>
												)}
												<Grid item xs={12}>
													<Typography>Lương KPI nghiên cứu</Typography>
												</Grid>
												{kpiSalary?.map((e, index) => (
													<>
														<Grid container spacing={2}>
															<Grid item xs={4} style={{ marginLeft: 8 }}>
																<TextField
																	select
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Chất lượng"
																	disabled
																	value={e.quality}
																>
																	{quantitySetting?.map((e) => (
																		<MenuItem key={e.index} value={e.code}>
																			{e.description}
																		</MenuItem>
																	))}
																</TextField>
															</Grid>
															<Grid item xs={3}>
																<TextField
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Số lượng"
																	disabled
																	value={e.quantity}
																></TextField>
															</Grid>
															<Grid item xs={4}>
																<TextField
																	select
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Tính lương"
																	disabled
																	value={e.salaryCalculation}
																>
																	{salaryCaculation.map((ele, index) => (
																		<MenuItem key={index} value={ele.value}>
																			{ele.name}
																		</MenuItem>
																	))}
																</TextField>
															</Grid>
														</Grid>
														<Grid item xs={12}>
															<Typography>
																Tiền lương:&nbsp;
																{new Intl.NumberFormat('vi-VN', {
																	style: 'currency',
																	currency: 'VND'
																}).format(e.amount)}
															</Typography>
														</Grid>
														<Grid item xs={12}>
															<Divider />
														</Grid>
													</>
												))}

												<Grid item xs={6}>
													<Field type="checkbox" name="type">
														{(fieldProps) => {
															return (
																<TextField
																	select
																	fullWidth
																	variant="outlined"
																	size="small"
																	label="Loại hợp đồng"
																	{...fieldProps.field}
																	helperText={<ErrorMessage name="type" />}
																	FormHelperTextProps={{ error: true }}
																	InputLabelProps={{ shrink: true }}
																	disabled={disabled}
																>
																	<MenuItem key="Q" value="Q">
																		Hợp đồng chỉ tính theo Q
																	</MenuItem>
																	<MenuItem key="R" value="R">
																		Hợp đồng tính theo Q và R
																	</MenuItem>
																</TextField>
															);
														}}
													</Field>
												</Grid>
												<Grid item xs={6}>
													<Chip
														label={
															props.kpiInfo?.kpi?.completed ? 'Đã hoàn thành' : 'Chưa hoàn thành'
														}
														color={props.kpiInfo?.kpi?.completed ? 'primary' : 'secondary'}
														style={{ float: 'right' }}
													/>
												</Grid>
												{/* <Grid item xs={12}>
                          <Typography variant='h6'>Số lượng sản phẩm đã đăng ký</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Chip
                            label={`Tổng giá trị: ${new Intl.NumberFormat('vi-VN', {
                              style: 'currency',
                              currency: 'VND',
                            }).format(values.value || 0)}`}
                            color='primary'
                          />
                        </Grid> */}

												{/* CTKH form */}
												<>
													<Grid item xs={12}>
														<Typography>Bài báo xếp hạng Q, R</Typography>
													</Grid>

													<FieldArray
														name="kpiScientificResearch"
														render={(arrayHelpers) => {
															return (
																<Grid item xs={12}>
																	{values.kpiScientificResearch.map(
																		(kpiScientificResearch, kpiScientificResearchIndex) => {
																			const handleRemoveKpiScientificResearch = (index) => {
																				// không xóa mềm mà xóa luôn
																				arrayHelpers.remove(index);
																			};

																			if (kpiScientificResearch.deleted) {
																				return null;
																			}

																			return (
																				<Grid
																					container
																					spacing={2}
																					key={kpiScientificResearchIndex}
																				>
																					<Grid item xs={3}>
																						<Field
																							name={`kpiScientificResearch[${kpiScientificResearchIndex}].typeQ`}
																						>
																							{(fieldProps) => {
																								return (
																									<TextField
																										select
																										fullWidth
																										size="small"
																										label="Xếp hạng Q"
																										variant="outlined"
																										disabled={disabled}
																										{...fieldProps.field}
																										helperText={
																											<ErrorMessage
																												name={`kpiScientificResearch[${kpiScientificResearchIndex}].typeQ`}
																											/>
																										}
																										FormHelperTextProps={{ error: true }}
																									>
																										<MenuItem value="Q1">Q1</MenuItem>
																										<MenuItem value="Q2">Q2</MenuItem>
																										<MenuItem value="Q3">Q3</MenuItem>
																										<MenuItem value="Q4">Q4</MenuItem>
																										<MenuItem value="Scopus">Scopus</MenuItem>
																									</TextField>
																								);
																							}}
																						</Field>
																					</Grid>
																					{values.type === 'R' &&
																						kpiScientificResearch?.typeQ !== 'Scopus' && (
																							<Grid item xs={3}>
																								<Field
																									name={`kpiScientificResearch[${kpiScientificResearchIndex}].typeR`}
																								>
																									{(fieldProps) => {
																										return (
																											<TextField
																												select
																												fullWidth
																												size="small"
																												label="Xếp hạng R"
																												variant="outlined"
																												disabled={disabled}
																												{...fieldProps.field}
																												helperText={
																													<ErrorMessage
																														name={`kpiScientificResearch[${kpiScientificResearchIndex}].typeR`}
																													/>
																												}
																												FormHelperTextProps={{ error: true }}
																											>
																												<MenuItem value="TCN">TCN</MenuItem>
																												<MenuItem value="R1">R1</MenuItem>
																												<MenuItem value="R2">R2</MenuItem>
																												<MenuItem value="R3">R3</MenuItem>
																												<MenuItem value="R4">R4</MenuItem>
																												<MenuItem value="R5">R5</MenuItem>
																												<MenuItem value="R6">R6</MenuItem>
																												<MenuItem value="R7">R7</MenuItem>
																												<MenuItem value="R8">R8</MenuItem>
																												<MenuItem value="R9">R9</MenuItem>
																												<MenuItem value="R10">R10</MenuItem>
																											</TextField>
																										);
																									}}
																								</Field>
																							</Grid>
																						)}
																					<Grid item xs={3}>
																						<Field
																							name={`kpiScientificResearch[${kpiScientificResearchIndex}].roleInPaper`}
																						>
																							{(fieldProps) => {
																								return (
																									<TextField
																										select
																										fullWidth
																										variant="outlined"
																										label="Vai trò tác giả"
																										size="small"
																										disabled={disabled}
																										{...fieldProps.field}
																										helperText={
																											<ErrorMessage
																												name={`kpiScientificResearch[${kpiScientificResearchIndex}].roleInPaper`}
																											/>
																										}
																										FormHelperTextProps={{ error: true }}
																									>
																										<MenuItem value="main">Tác giả chính</MenuItem>
																										<MenuItem value="member">Đồng tác giả</MenuItem>
																										{/* <MenuItem value='mainOther'>Tác giả chính tính hộ</MenuItem>
                                                    <MenuItem value='memberOther'>Đồng tác giả tính hộ</MenuItem> */}
																									</TextField>
																								);
																							}}
																						</Field>
																					</Grid>
																					<Grid item xs={2}>
																						<Field
																							name={`kpiScientificResearch[${kpiScientificResearchIndex}].numFloat`}
																						>
																							{(fieldProps) => {
																								return (
																									<TextField
																										fullWidth
																										variant="outlined"
																										label="Số lượng"
																										size="small"
																										disabled={disabled}
																										{...fieldProps.field}
																										helperText={
																											<ErrorMessage
																												name={`kpiScientificResearch[${kpiScientificResearchIndex}].numFloat`}
																											/>
																										}
																										FormHelperTextProps={{ error: true }}
																										InputLabelProps={{ shrink: true }}
																									/>
																								);
																							}}
																						</Field>
																					</Grid>
																					{/* <Grid item xs={3}>
                                            <Field name={`kpiScientificResearch[${kpiScientificResearchIndex}].value`}>
                                              {(fieldProps) => {
                                                return (
                                                  <TextField
                                                    fullWidth
                                                    variant='outlined'
                                                    label='Giá trị cho 1 công bố'
                                                    size='small'
                                                    disabled={disabled}
                                                    {...fieldProps.field}
                                                    helperText={
                                                      <ErrorMessage
                                                        name={`kpiScientificResearch[${kpiScientificResearchIndex}].value`}
                                                      />
                                                    }
                                                    FormHelperTextProps={{ error: true }}
                                                    InputLabelProps={{ shrink: true }}
                                                  />
                                                );
                                              }}
                                            </Field>
                                          </Grid> */}
																					<Grid item xs={1}>
																						{/* <Button
                                              fullWidth
                                              disabled={disabled}
                                              color='secondary'
                                              size='small'
                                              variant='outlined'
                                              style={{ height: 40 }}
                                              onClick={() => {
                                                handleRemoveKpiScientificResearch(kpiScientificResearchIndex);
                                              }}
                                            >
                                              <Delete />
                                            </Button> */}
																						<IconButton
																							color="secondary"
																							disabled={disabled}
																							onClick={() => {
																								handleRemoveKpiScientificResearch(
																									kpiScientificResearchIndex
																								);
																							}}
																						>
																							<Delete />
																						</IconButton>
																					</Grid>
																				</Grid>
																			);
																		}
																	)}
																	{!disabled && (
																		<Grid item xs={12}>
																			<Box paddingTop={2}>
																				<Button
																					fullWidth
																					variant="outlined"
																					color="primary"
																					size="medium"
																					disabled={disabled}
																					startIcon={<Add />}
																					onClick={() =>
																						arrayHelpers.push({
																							kpiId: values.id,
																							num: 0,
																							numFloat: '',
																							typeQ: '',
																							typeR: '',
																							value: 0,
																							roleInPaper: 'main'
																						})
																					}
																				>
																					Thêm bài báo
																				</Button>
																			</Box>
																		</Grid>
																	)}
																</Grid>
															);
														}}
													/>

													<Grid item xs={12}>
														<Typography>Sáng chế</Typography>
													</Grid>

													<FieldArray
														name="kpiPatent"
														render={(arrayHelpers) => {
															return (
																<>
																	{values.kpiPatent.map((kpiPatent, kpiPatentIndex) => {
																		const handleRemoveKpiPatent = () => {
																			// không xóa mềm mà xóa luôn
																			arrayHelpers.remove(kpiPatentIndex);
																		};

																		if (kpiPatent.deleted) {
																			return null;
																		}

																		return (
																			<React.Fragment key={kpiPatentIndex}>
																				<Grid item xs={5}>
																					<Field name={`kpiPatent[${kpiPatentIndex}].type`}>
																						{(fieldProps) => {
																							return (
																								<TextField
																									select
																									fullWidth
																									size="small"
																									variant="outlined"
																									disabled={disabled}
																									{...fieldProps.field}
																									helperText={
																										<ErrorMessage
																											name={`kpiPatent[${kpiPatentIndex}].type`}
																										/>
																									}
																									FormHelperTextProps={{
																										error: true
																									}}
																								>
																									<MenuItem value="USPTO (Utility)-TGC">
																										USPTO (Utility)-TGC
																									</MenuItem>
																									<MenuItem value="USPTO (Design)-TGC">
																										USPTO (Design)-TGC
																									</MenuItem>
																									<MenuItem value="USPTO (Plant)-TGC">
																										USPTO (Plant)-TGC
																									</MenuItem>
																									<MenuItem value="EPO-TGC">EPO-TGC</MenuItem>
																									<MenuItem value="Bằng sáng chế (VN)-TGC">
																										Bằng sáng chế (VN)-TGC
																									</MenuItem>
																								</TextField>
																							);
																						}}
																					</Field>
																				</Grid>
																				{/* <Grid item xs={3}>
                                          <Field name={`kpiPatent[${kpiPatentIndex}].value`}>
                                            {(fieldProps) => {
                                              return (
                                                <TextField
                                                  fullWidth
                                                  variant='outlined'
                                                  label='Giá trị cho 1 sản phẩm'
                                                  size='small'
                                                  disabled={disabled}
                                                  {...fieldProps.field}
                                                  helperText={
                                                    <ErrorMessage name={`kpiPatent[${kpiPatentIndex}].value`} />
                                                  }
                                                  FormHelperTextProps={{ error: true }}
                                                  InputLabelProps={{ shrink: true }}
                                                />
                                              );
                                            }}
                                          </Field>
                                        </Grid> */}
																				<Grid item xs={5}>
																					<Field name={`kpiPatent[${kpiPatentIndex}].numFloat`}>
																						{(fieldProps) => {
																							return (
																								<TextField
																									fullWidth
																									variant="outlined"
																									label="Số lượng"
																									size="small"
																									disabled={disabled}
																									{...fieldProps.field}
																									helperText={
																										<ErrorMessage
																											name={`kpiPatent[${kpiPatentIndex}].numFloat`}
																										/>
																									}
																									FormHelperTextProps={{ error: true }}
																									InputLabelProps={{ shrink: true }}
																								/>
																							);
																						}}
																					</Field>
																				</Grid>
																				<Grid item xs={2}>
																					<Button
																						fullWidth
																						disabled={disabled}
																						color="secondary"
																						size="small"
																						variant="outlined"
																						style={{ height: 40 }}
																						onClick={handleRemoveKpiPatent}
																					>
																						<Delete />
																					</Button>
																				</Grid>
																			</React.Fragment>
																		);
																	})}
																	{!disabled && (
																		<Grid item xs={12}>
																			<Button
																				fullWidth
																				variant="outlined"
																				color="primary"
																				size="medium"
																				disabled={disabled}
																				startIcon={<Add />}
																				onClick={() =>
																					arrayHelpers.push({
																						num: 1,
																						type: 'Bằng sáng chế (VN)-TGC',
																						value: 10_000_000
																					})
																				}
																			>
																				Thêm sáng chế
																			</Button>
																		</Grid>
																	)}
																</>
															);
														}}
													/>

													<Grid item xs={12}>
														<Typography>Giải pháp hữu ích</Typography>
													</Grid>

													<FieldArray
														name="kpiSolution"
														render={(arrayHelpers) => {
															return (
																<>
																	{values.kpiSolution.map(
																		(kpiSolutionElement, kpiSolutionIndex) => {
																			const handleRemoveKpiSolution = () => {
																				// không xóa mềm mà xóa luôn
																				arrayHelpers.remove(kpiSolutionIndex);
																			};

																			if (kpiSolutionElement.deleted) {
																				return null;
																			}

																			return (
																				<React.Fragment key={kpiSolutionIndex}>
																					<Grid item xs={5}>
																						<Field name={`kpiSolution[${kpiSolutionIndex}].value`}>
																							{(fieldProps) => {
																								return (
																									<TextField
																										fullWidth
																										variant="outlined"
																										// label='Giá trị cho 1 sản phẩm'
																										size="small"
																										disabled={true}
																										value="Giải pháp hữu ích-TGC"
																										// {...fieldProps.field}
																										helperText={
																											<ErrorMessage
																												name={`kpiSolution[${kpiSolutionIndex}].value`}
																											/>
																										}
																										FormHelperTextProps={{ error: true }}
																										InputLabelProps={{ shrink: true }}
																									/>
																								);
																							}}
																						</Field>
																					</Grid>
																					<Grid item xs={5}>
																						<Field
																							name={`kpiSolution[${kpiSolutionIndex}].numFloat`}
																						>
																							{(fieldProps) => {
																								return (
																									<TextField
																										fullWidth
																										variant="outlined"
																										label="Số lượng"
																										size="small"
																										disabled={disabled}
																										{...fieldProps.field}
																										helperText={
																											<ErrorMessage
																												name={`kpiSolution[${kpiSolutionIndex}].numFloat`}
																											/>
																										}
																										FormHelperTextProps={{ error: true }}
																										InputLabelProps={{ shrink: true }}
																									/>
																								);
																							}}
																						</Field>
																					</Grid>
																					<Grid item xs={2}>
																						<Button
																							fullWidth
																							disabled={disabled}
																							color="secondary"
																							size="small"
																							variant="outlined"
																							style={{ height: 40 }}
																							onClick={handleRemoveKpiSolution}
																						>
																							<Delete />
																						</Button>
																					</Grid>
																				</React.Fragment>
																			);
																		}
																	)}
																	{!disabled && (
																		<Grid item xs={12}>
																			<Button
																				fullWidth
																				variant="outlined"
																				color="primary"
																				size="medium"
																				startIcon={<Add />}
																				disabled={disabled}
																				onClick={() =>
																					arrayHelpers.push({
																						num: 1,
																						value: 10_000_000
																					})
																				}
																			>
																				Thêm giải pháp hữu ích
																			</Button>
																		</Grid>
																	)}
																</>
															);
														}}
													/>
												</>
												{/* end of content---------------------------------------------------------------------------- */}

												{!props.disabled && (
													<Grid item xs={12}>
														<Box paddingTop={5} />
														<Button
															variant="outlined"
															color="primary"
															startIcon={values.completed ? <CheckBox /> : <CheckBoxOutlineBlank />}
															onClick={(event) =>
																props.updateKPICompleted(values.id, !values?.completed)
															}
														>
															Hoàn thành KPI
														</Button>
														{disabled ? (
															<>
																<Button
																	style={{ float: 'right' }}
																	variant="contained"
																	color="primary"
																	onClick={() => setDisabled(false)}
																>
																	Chỉnh sửa KPI
																</Button>
																<Button
																	style={{ float: 'right', marginRight: 10 }}
																	variant="outlined"
																	color="secondary"
																	onClick={() => props.deleteKPI(values.id)}
																>
																	Xóa KPI
																</Button>
															</>
														) : (
															<>
																<Button
																	style={{ float: 'right' }}
																	variant="contained"
																	color="primary"
																	onClick={handleSubmit}
																>
																	Xác nhận
																</Button>
																<Button
																	style={{ float: 'right', marginRight: 10 }}
																	variant="outlined"
																	color="secondary"
																	onClick={() => setDisabled(true)}
																>
																	Huỷ
																</Button>
															</>
														)}
													</Grid>
												)}
											</Grid>
										</Box>
									</>
								);
							}}
						</Formik>
					</Grid>
				</Grid>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role')
});

const mapDispatchToProps = (dispatch) => ({
	updateKPI: (id, payload) => dispatch(updateKPI(id, payload)),
	updateKPICompleted: (id, completed) => dispatch(updateKPICompleted(id, completed)),
	deleteKPI: (id) => dispatch(deleteKPI(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(KPIForm));
