import { useContext, useState } from 'react';
import { connect } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
	Collapse,
	Grid,
	Button,
	Input
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';

import { DepartmentContext } from '../DepartmentContext';
import {
	addProfileToDepartment,
	getAllProfiles,
	getProfileByDepartmentId
} from '../../../reducer/department/action';
import { Replay } from '@material-ui/icons';

const useStyles = makeStyles(() =>
	createStyles({
		btnAction: {
			cursor: 'pointer'
		}
	})
);

function MemberProfile(props) {
	const { profile } = props;

	return (
		<Grid container alignItems="center">
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Họ tên:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.name}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Mã PU:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.code}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Số CMND:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.userId}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Ngày sinh:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{dayjs(profile.birth).isValid() ? dayjs(profile.birth).format('DD/MM/YYYY') : ''}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Giới tính:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.gender}
			</Grid>

			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Địa chỉ:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.address}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Điện thoại:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.phone}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Bằng cấp:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.degree}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Chuyên ngành:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.major}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Năm tốt nghiệp PhD:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.graduatedYearPhd}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Chuyên môn:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.profession}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Số sinh viên hướng dẫn cấp trường:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.numGuideStudentSchool}
			</Grid>
			<Grid item xs={4}>
				<Box textAlign="right" pr={2}>
					<strong>Số sinh viên hướng dẫn cấp bộ:</strong>
				</Box>
			</Grid>
			<Grid item xs={8}>
				{profile.numGuideStudentMinistry}
			</Grid>
		</Grid>
	);
}

function DepartmentInsertMemberModal(props) {
	const { allProfiles, addProfileToDepartment, getProfileByDepartmentId, departmentProfiles } =
		props;
	const classes = useStyles();

	// selected profile id
	const [selectedProfileId, setSelectedProfileId] = useState(0);
	const [searchName, setSearchName] = useState('');
	const [searchCode, setSearchCode] = useState('');

	const { selectedDepartmentId, insertingDepartmentMember, setInsertingDepartmentMember } =
		useContext(DepartmentContext);

	const handleCloseModal = () => {
		setInsertingDepartmentMember(false);
	};

	const toggleDetails = (profileId) => {
		if (profileId === selectedProfileId) {
			setSelectedProfileId(0);
		} else {
			setSelectedProfileId(profileId);
		}
	};

	const handleReloadDepartmentProfiles = () => {
		if (selectedDepartmentId !== 0) {
			getProfileByDepartmentId(selectedDepartmentId);
		}
	};

	const handleAddProfileToDepartment = (profile) => {
		if (selectedDepartmentId !== 0 && profile) {
			addProfileToDepartment({
				departmentId: selectedDepartmentId,
				profileId: profile.id
			});
			setInsertingDepartmentMember(false);
		}
	};

	const removeAccents = (str) => {
		return str
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/đ/g, 'd')
			.replace(/Đ/g, 'D')
			.trim()
			.toLowerCase();
	};

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth="md"
			open={insertingDepartmentMember}
			onClose={handleCloseModal}
		>
			<DialogTitle>Thêm thành viên</DialogTitle>
			<DialogContent>
				<Box textAlign="left">
					<Input
						onChange={(e) => setSearchName(e.target.value)}
						placeholder="Tên thành viên"
						style={{ marginRight: 10 }}
					/>
					<Input
						onChange={(e) => setSearchCode(e.target.value)}
						placeholder="Mã PU"
						style={{ marginRight: 10 }}
					/>
					<Button
						variant="outlined"
						color="default"
						startIcon={<Replay />}
						onClick={handleReloadDepartmentProfiles}
					>
						Tải lại
					</Button>
				</Box>
				<div style={{ height: 750, width: '100%' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<strong>Mã PU</strong>
								</TableCell>
								<TableCell>
									<strong>Họ và tên</strong>
								</TableCell>
								<TableCell>
									<strong>Bằng cấp</strong>
								</TableCell>
								<TableCell>
									<strong>Chuyên ngành</strong>
								</TableCell>
								<TableCell>
									<strong>Tác vụ</strong>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(allProfiles ?? []).map((profile) => {
								var inDepartment = departmentProfiles.findIndex((e) => e.id === profile.id);
								if (
									removeAccents(profile.name).includes(removeAccents(searchName)) &&
									removeAccents(profile.code).includes(removeAccents(searchCode)) &&
									inDepartment === -1
								)
									return (
										<TableRow key={profile.id}>
											<TableCell>{profile.code}</TableCell>
											<TableCell>{profile.name}</TableCell>
											<TableCell>{profile.degree}</TableCell>
											<TableCell>{profile.major}</TableCell>
											<TableCell>
												<Box>
													<Typography
														variant="caption"
														className={classes.btnAction}
														color="initial"
														onClick={() => toggleDetails(profile.id)}
													>
														Xem chi tiết
													</Typography>
													<Typography variant="caption"> | </Typography>
													<Typography
														variant="caption"
														className={classes.btnAction}
														color="primary"
														onClick={() => handleAddProfileToDepartment(profile)}
													>
														Thêm
													</Typography>
												</Box>
												<Collapse in={profile.id === selectedProfileId} unmountOnExit>
													<MemberProfile profile={profile} />
												</Collapse>
											</TableCell>
										</TableRow>
									);
							})}
						</TableBody>
					</Table>
				</div>
			</DialogContent>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	allProfiles: select(state, 'departmentReducer', 'allProfiles'),
	departmentProfiles: select(state, 'departmentReducer', 'departmentProfiles')
});
const mapDispatchToProps = (dispatch) => ({
	getAllProfiles: () => dispatch(getAllProfiles()),
	getProfileByDepartmentId: (departmentId) => dispatch(getProfileByDepartmentId(departmentId)),
	addProfileToDepartment: (payload) => dispatch(addProfileToDepartment(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DepartmentInsertMemberModal));
