import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
	list: [],
	isFetching: false
});

export default function journalDomesticReducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.JOURNAL_DOMESTIC.GET_ALL_JOURNAL_DOMESTIC_LOADING:
			return state.merge({
				isFetching: true
			});
		case TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_LOADING:
		case TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_LOADING:
		case TYPE.JOURNAL_DOMESTIC.DELETE_JOURNAL_DOMESTIC_LOADING:
			return state.merge({
				isFetching: true
			});

		case TYPE.JOURNAL_DOMESTIC.GET_ALL_JOURNAL_DOMESTIC_FAILURE:
			return state.merge({
				isFetching: false
			});
		case TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_FAILURE:
		case TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_FAILURE:
		case TYPE.JOURNAL_DOMESTIC.DELETE_JOURNAL_DOMESTIC_FAILURE:
			return state.merge({
				isFetching: false
			});

		case TYPE.JOURNAL_DOMESTIC.GET_ALL_JOURNAL_DOMESTIC_SUCCESS:
			return state.merge({
				list: [...action.payload],
				isFetching: false
			});

		case TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_SUCCESS:
			return state.merge({
				list: [...state.get('list'), action.payload],
				isFetching: false
			});

		case TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_SUCCESS: {
			const newList = state.get('list');
			const index = newList.findIndex((e) => e.id === action.payload.id);
			newList.splice(index, 1, action.payload);
			return state.merge({
				list: [...newList],
				isFetching: false
			});
		}

		case TYPE.JOURNAL_DOMESTIC.DELETE_JOURNAL_DOMESTIC_SUCCESS: {
			const newList = state.get('list');
			const index = newList.findIndex((e) => e.id === action.id);
			newList.splice(index, 1);
			return state.merge({
				list: [...newList],
				isFetching: false
			});
		}

		default:
			return state;
	}
}
