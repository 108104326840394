import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllTimeResearchInformation = () => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.getAllTimeResearchInformation();
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.GET_ALL_TIME_RESEARCH_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_ALL_TIME_RESEARCH_INFORMATION_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_ALL_TIME_RESEARCH_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getTimeResearchInformation = (profileId) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.getTimeResearchInformation();
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload: { profileId } });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		// dispatch(
		//   notification({
		//     type: 'error',
		//     message: 'Lấy dữ liệu thất bại!',
		//   })
		// );
		return false;
	}
};

export const calculateTimeResearch = (profileId, year) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.calculateTimeResearch(profileId, year);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		if (response.data.data === null) {
			dispatch(
				notification({
					type: 'success',
					message: response.data.message
				})
			);
		} else {
			dispatch(
				notification({
					type: 'success',
					message: 'Lấy thời gian nghiên cứu thời gian khoa học thành công'
				})
			);
		}
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: `Đã xảy ra lỗi khi lấy thời gian nghiên cứu khoa học năm ${year}`
			})
		);
	}
};

export const getTimeResearchStatistic = (payload) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.getTimeResearchStatistic();
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_STATISTIC_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_STATISTIC_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		if (response.data.data[0].departmentInfo === null)
			dispatch(
				notification({
					type: 'error',
					message: 'Không tìm thấy dữ liệu tính giờ nghiên cứu nào, vui lòng xem lại bảng mẫu!'
				})
			);
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_STATISTIC_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const getTimeResearchInformationById = (id) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.getTimeResearchInformationById(id);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_BY_ID_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_BY_ID_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.GET_TIME_RESEARCH_INFORMATION_BY_ID_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
	}
};

export const insertTimeResearchInformation = (payload) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.insertTimeResearchInformation();
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm dữ liệu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: `${response.data.message}`
			})
		);
	}
};

export const calculateTimeResearchAllProfile = (year) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.calculateTimeResearchAllProfile(year);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_ALL_PROFILE_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_ALL_PROFILE_SUCCESS,
			// payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.CALCULATE_TIME_RESEARCH_ALL_PROFILE_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		return false;
	}
};

/** Của anh Dương */
// export const insertTimeResearchInformation = (profileId, year) => async (dispatch) => {
//   const apiCreateTimeResearch = API_URLS.TIME_RESEARCH_INFORMATION.insertTimeResearchInformation();
//   dispatch({
//     type: TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_LOADING,
//     meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] },
//   });

//   const { response: createTimeResearchResponse, error: errorCreateTimeResearch } = await apiCall({
//     ...apiCreateTimeResearch,
//     payload: {
//       profileId: profileId,
//       year: year,
//     },
//   });
//   if (
//     !errorCreateTimeResearch &&
//     createTimeResearchResponse.status === 200 &&
//     createTimeResearchResponse.data.success
//   ) {
//     dispatch({
//       type: TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_SUCCESS,
//       meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] },
//       payload: createTimeResearchResponse.data.data,
//     });
//   } else {
//     dispatch({
//       type: TYPE.TIME_RESEARCH_INFORMATION.INSERT_TIME_RESEARCH_INFORMATION_FAILURE,
//       meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] },
//     });
//     dispatch(
//       notification({
//         type: 'error',
//         message: `Đã xảy ra lỗi khi tạo bảng thời gian nghiên cứu khoa học cho năm ${year}`,
//       })
//     );
//   }
// };

export const createTimeResearchInformationFromSample = (year, sampleYear) => async (dispatch) => {
	const apiGetTimeResearch = API_URLS.TIME_RESEARCH_INFORMATION.getTimeResearchInformation();
	const { response: responseTimeResearch, error: errorTimeResearch } = await apiCall({
		...apiGetTimeResearch,
		payload: { year: sampleYear, profileId: 0 }
	});

	if (
		!errorTimeResearch &&
		responseTimeResearch.status === 200 &&
		responseTimeResearch.data.success &&
		responseTimeResearch.data.data?.length
	) {
		const payload = {
			active: true,
			profileId: 0,
			year: year,
			timeResearchDetail: (responseTimeResearch.data.data[0].timeResearchDetail ?? []).map(
				(element) => {
					return {
						description: element.description,
						value: element.value,
						num: 0,
						stt: element.stt,
						allowedUpdateNum: element.allowedUpdateNum,
						rate: element.rate,
						total: element.total,
						defaultCode: element.code
					};
				}
			)
		};
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_LOADING,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
		});
		const apiCreateTimeResearchInformationFromSample =
			API_URLS.TIME_RESEARCH_INFORMATION.createTimeResearchInformationFromSample();

		const { response, error } = await apiCall({
			...apiCreateTimeResearchInformationFromSample,
			payload: payload
		});
		if (!error && response.status === 200 && response.data.success) {
			dispatch({
				type: TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_SUCCESS,
				meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
			});
			dispatch(
				notification({
					type: 'success',
					message: `Đã tạo bảng mẫu cho năm ${sampleYear}`
				})
			);
		} else {
			dispatch({
				type: TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_FAILURE,
				meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
			});
			dispatch(
				notification({
					type: 'error',
					message: `Đã xảy ra lỗi khi tạo bảng mẫu cho năm ${sampleYear}`
				})
			);
		}
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: `Đã xảy ra lỗi khi lấy bảng thời gian nghiên cứu năm ${sampleYear}`
			})
		);
	}
};

export const updateTimeResearchInformation = (id, payload) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.updateTimeResearchInformation(id);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.UPDATE_TIME_RESEARCH_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS,
			id: id,
			payload: response.data.data,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.UPDATE_TIME_RESEARCH_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
	}
};

export const filterYear = (year) => async (dispatch) => {
	dispatch({
		type: 'YEAR_FILTER',
		id: year,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
};

export const deleteTimeResearchInformation = (id) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.deleteTimeResearchInformation(id);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.DELETE_TIME_RESEARCH_INFORMATION_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.DELETE_TIME_RESEARCH_INFORMATION_SUCCESS,
			id,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Xoá dữ liệu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.DELETE_TIME_RESEARCH_INFORMATION_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Xoá dữ liệu thất bại!'
			})
		);
	}
};

export const lockTimeResearch = (id, locked) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.lockTimeResearch(id, locked);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.LOCK_TIME_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.LOCK_TIME_RESEARCH_SUCCESS,
			id,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Khóa giờ nghiên cứu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.LOCK_TIME_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Khóa giờ nghiên cứu thất bại!'
			})
		);
	}
};

export const lockAllTimeResearch = (year, locked) => async (dispatch) => {
	const api = API_URLS.TIME_RESEARCH_INFORMATION.lockAllTimeResearch(year, locked);
	dispatch({
		type: TYPE.TIME_RESEARCH_INFORMATION.LOCK_ALL_TIME_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.LOCK_ALL_TIME_RESEARCH_SUCCESS,
			locked,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Khóa giờ nghiên cứu thành công!'
			})
		);
	} else {
		dispatch({
			type: TYPE.TIME_RESEARCH_INFORMATION.LOCK_ALL_TIME_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Khóa giờ nghiên cứu thất bại!'
			})
		);
	}
};
