import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import store from './redux/store';

import ROUTER from './constants/router';

import AppLayout from './components/AppLayout';
import Login from './containers/AppLayout/Login';

import './index.css';
import theme from './theme';

const App = () => (
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<Router>
				<Switch>
					<Route exact path={ROUTER.LOGIN} component={Login} />
					<Route path={ROUTER.HOME} component={AppLayout} />
				</Switch>
			</Router>
		</Provider>
	</ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
