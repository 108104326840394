import React from 'react';
import { InsertPhoto } from '@material-ui/icons';
import { FastField, ErrorMessage, useField } from 'formik';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		profileImageContainer: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: '200px',
			textAlign: 'center'
		},
		fileInputContainer: {
			border: '1px dashed #d8d7d7',
			padding: theme.spacing(1)
		},
		fileInput: {
			display: 'none'
		},
		fileInputLabel: {
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		fileInputIcon: {
			fontSize: '200%',
			color: 'grey'
		},
		profileImage: {
			width: '200px',
			[theme.breakpoints.down('xs')]: {
				width: '100%'
			}
		},
		sectionTitle: {
			fontWeight: 'bold',
			textTransform: 'uppercase'
		},
		profileImageErrorMessage: {
			color: theme.palette.error.main
		}
	})
);

function BasicInfo() {
	const classes = useStyles();

	const [base64Input, , base64Helpers] = useField('basicInfo.base64');

	/**
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	const handleSelectImage = (event) => {
		if (event.target.files && event.target.files.length) {
			const VALID_TYPES = ['image/jpeg', 'image/png'];
			const { type } = event.target.files[0];
			if (VALID_TYPES.includes(type)) {
				const fileReader = new FileReader();
				fileReader.onloadend = (loadEndEvent) => {
					base64Helpers.setValue(loadEndEvent.target.result);
				};
				fileReader.readAsDataURL(event.target.files[0]);
			} else {
				alert('Vui lòng chọn ảnh JPEG hoặc PNG');
			}
		}
	};

	return (
		<>
			<Box pt={2}>
				<Box className={classes.profileImageContainer} py={2}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						className={classes.fileInputContainer}
					>
						<label className={classes.fileInputLabel}>
							{base64Input.value && (
								<img alt="Ảnh hồ sơ" src={base64Input.value} className={classes.profileImage} />
							)}
							{!base64Input.value && <InsertPhoto className={classes.fileInputIcon} />}
							{base64Input.value ? 'Chọn ảnh khác' : 'Chọn ảnh'}
							<input
								type="file"
								className={classes.fileInput}
								onChange={handleSelectImage}
								accept="image/jpeg, image/png"
							/>
						</label>
						<Box textAlign="center" className={classes.profileImageErrorMessage}>
							<ErrorMessage name="basicInfo.base64" />
						</Box>
					</Box>
				</Box>
				<Box>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6" className={classes.sectionTitle}>
								Thông tin cơ bản
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<FastField name="basicInfo.name">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Tên"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.name" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item md={4} sm={7} xs={6}>
							<FastField name="basicInfo.birth">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Ngày, tháng, năm sinh"
											variant="outlined"
											size="small"
											type="date"
											helperText={<ErrorMessage name="basicInfo.birth" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item md={8} sm={5} xs={6}>
							<FastField name="basicInfo.nationality">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Quốc tịch"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.nationality" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item xs={12}>
							<FastField name="basicInfo.degree">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Học hàm/học vị cao nhất"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.degree" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item xs={12}>
							<FastField name="basicInfo.unit">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Đơn vị công tác"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.unit" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item xs={12}>
							<FastField name="basicInfo.position">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Vai trò"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.position" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item xs={12}>
							<FastField name="basicInfo.address">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											fullWidth
											label="Địa chỉ"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.address" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item xs={6}>
							<FastField name="basicInfo.email">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											type="email"
											fullWidth
											label="Email"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.email" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
						<Grid item xs={6}>
							<FastField name="basicInfo.phone">
								{(fieldProps) => {
									return (
										<TextField
											{...fieldProps.field}
											type="tel"
											fullWidth
											label="Số điện thoại"
											variant="outlined"
											size="small"
											helperText={<ErrorMessage name="basicInfo.phone" />}
											FormHelperTextProps={{ error: true }}
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							</FastField>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

export default BasicInfo;
