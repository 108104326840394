import React from 'react';
import { Card, Typography } from '@material-ui/core';

import construction from '../../assets/images/construction.png';

function Construction() {
	return (
		<Card
			style={{
				padding: 8,
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: 'calc(100vh - 108px)'
			}}
		>
			<img src={construction} alt="construction" style={{ height: '33vh' }} />
			<Typography variant="h5" style={{ marginTop: 16 }}>
				<b>Tính năng đang trong quá trình phát triển!</b>
			</Typography>
		</Card>
	);
}

export default Construction;
