import React, { useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	TextField,
	Typography,
	Box,
	Select,
	MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import { postEarningsTable, getEarningsTable } from '../../../../reducer/earningsTable/action';
import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

function EarningsTableInsertModal(props) {
	const { open, onClose, postEarningsTable, getEarningsTable, year } = props;
	const [inputs, setInputs] = useState({
		type: '',
		condition: '',
		value: ''
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: value
		}));
	};

	const handleSelectChange = (event) => {
		setInputs((prevInputs) => ({
			...prevInputs,
			type: event.target.value
		}));
	};

	const handleCancel = () => {
		setInputs({
			type: '',
			condition: '',
			value: ''
		});
		onClose();
	};

	const handleSubmit = async () => {
		try {
			const { type, condition, value } = inputs;
			const payload = {
				type,
				condition,
				value: parseInt(value.replace(/\D/g, '')),
				year: year || 2022
			};
			console.log('Payload:', { ...payload });

			await postEarningsTable(payload);
			await getEarningsTable(year);

			handleCancel();
		} catch (error) {
			console.error('Thêm hoặc lấy dữ liệu thất bại:', error);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Thêm Bảng Lương</DialogTitle>
			<DialogContent>
				<Box p={3}>
					<Typography variant="h5" gutterBottom>
						Thêm Bảng Lương
					</Typography>
					<Box display="flex" flexDirection="column" mt={2}>
						<Select
							label="Loại chỉ tiêu"
							name="type"
							value={inputs.type}
							onChange={handleSelectChange}
							variant="outlined"
							fullWidth
							margin="normal"
							style={{ marginBottom: '16px' }}
						>
							<MenuItem value="scientificResearch">Nghiên cứu khoa học</MenuItem>
							<MenuItem value="solution">Giải pháp hữu </MenuItem>
							<MenuItem value="patent">Bằng sáng chế</MenuItem>
							<MenuItem value="monograph">Chuyên khảo</MenuItem>
							<MenuItem value="member">Đồng tác giả</MenuItem>
						</Select>
						<TextField
							label="Đăng kí"
							variant="outlined"
							name="condition"
							value={inputs.condition}
							onChange={handleInputChange}
							margin="normal"
							style={{ marginBottom: '16px' }}
						/>
						<TextField
							label="Mức thu nhập hàng tháng"
							variant="outlined"
							name="value"
							value={inputs.value}
							onChange={handleInputChange}
							margin="normal"
							style={{ marginBottom: '16px' }}
							type="number"
						/>
						<Box display="flex" alignItems="center">
							<Button
								variant="outlined"
								color="default"
								onClick={handleCancel}
								style={{ marginRight: '8px' }}
							>
								HỦY
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={!inputs.type || !inputs.condition || !inputs.value}
							>
								XÁC NHẬN
							</Button>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Đóng
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	list: select(state, 'earningsTableReducer', 'list')
});

const mapDispatchToProps = (dispatch) => ({
	postEarningsTable: (payload) => dispatch(postEarningsTable(payload)),
	getEarningsTable: (year) => dispatch(getEarningsTable(year))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(EarningsTableInsertModal));
