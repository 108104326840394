import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Box,
	Badge,
	Tooltip,
	MenuList,
	MenuItem,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Drawer
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
	Group,
	Person,
	School,
	ExpandMore,
	FindInPage,
	EmojiObjects,
	AccountBalance,
	Settings,
	PlaylistAddCheck,
	AssignmentTurnedIn,
	MailOutlined,
	FileCopyOutlined,
	ChromeReaderMode
} from '@material-ui/icons';

import styles from './index.module.css';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import './Sidebar.css';
import nckh from '../../../assets/o-nckh.png';
import footer from '../../../assets/footer.png';
import footerMini from '../../../assets/pnk-light.png';
import { ReactComponent as TubeIcon } from '../../../assets/svg/tube.svg';
import { ReactComponent as ConferenceIcon } from '../../../assets/svg/conference.svg';
import { ReactComponent as ContractIcon } from '../../../assets/svg/file-contract.svg';
import { ReactComponent as HumanResourceIcon } from '../../../assets/svg/building-user.svg';
import { ReactComponent as HumanResourceMainIcon } from '../../../assets/svg/people-group.svg';
import { ReactComponent as AdminIcon } from '../../../assets/svg/screwdriver-wrench-solid.svg';

import { statisticUnaccepted } from '../../../reducer/profile/action';

const useStyles = makeStyles((theme) =>
	createStyles({
		menuItem: { padding: 0 },
		menuItemLink: {
			display: 'flex',
			alignItems: 'center',
			textDecoration: 'none',
			color: '#fff',
			width: '100%',
			whiteSpace: 'pre-wrap',
			padding: theme.spacing(1, 2, 1, 2),
			'&:hover': { backgroundColor: '#f59b73' }
		},
		activeLink: {
			color: '#fff',
			backgroundColor: '#f16628',
			'&:hover': { backgroundColor: '#f59b73' }
		}
	})
);

function CustomMenuItem(props) {
	const classes = useStyles();

	const url = window.location.href;
	const isInstitutional = url.includes(ROUTER.NCKH_CO_SO);
	const isStudentProjectRoute = url.includes(ROUTER.NCKH_SINH_VIEN);
	const isResearch = url.includes(ROUTER.WORK) || url.includes(ROUTER.MANAGEMENT);

	const [openTab, setOpenTab] = useState({
		studentProject: isStudentProjectRoute,
		institutional: isInstitutional,
		workSummary: isResearch
	});

	const renderElm = (item) => (
		<Tooltip key={item.key} disableHoverListener={props.open} title={item.title} placement="right">
			<MenuItem className={classes.menuItem}>
				<NavLink to={item.to} className={classes.menuItemLink} activeClassName={classes.activeLink}>
					<Box style={{ marginLeft: 12 }}>{item.icon('#fff')}</Box>
					{props.open && props.animText && (
						<Typography style={{ color: '#fff', marginLeft: 20, fontSize: 13 }}>
							{item.title}
						</Typography>
					)}
				</NavLink>
			</MenuItem>
		</Tooltip>
	);

	return (
		<>
			{props.menu.map((e) => (
				<React.Fragment key={e.key || e.summary.key}>
					{!e.menuList ? (
						renderElm(e)
					) : (
						<Accordion className={styles.accordion} expanded={openTab?.[e.summary.key] || false}>
							<AccordionSummary
								id="accordionSummary"
								expandIcon={<ExpandMore style={{ fill: '#fff' }} />}
								onClick={() => {
									let newObj = {};
									for (let item in openTab) newObj[item] = false;
									newObj = {
										...newObj,
										[e.summary.key]: openTab?.[e.summary.key] ? !openTab?.[e.summary.key] : true
									};
									setOpenTab(newObj);
								}}
							>
								<Box marginLeft="12px" display="flex" alignItems="center">
									{e.summary.icon('#fff')}
									{props.open && props.animText && (
										<Typography
											variant="inherit"
											style={{ marginLeft: 20, fontSize: 13, color: '#fff' }}
										>
											{e.summary.title}
										</Typography>
									)}
								</Box>
							</AccordionSummary>
							<AccordionDetails className={styles.accordionDetails}>
								<Box width="100%">
									<MenuList style={{ padding: 0 }}>
										{e.menuList.map((elm) => renderElm(elm))}
									</MenuList>
								</Box>
							</AccordionDetails>
						</Accordion>
					)}
				</React.Fragment>
			))}
			;
		</>
	);
}

function Sidebar(props) {
	const { open } = props;

	const url = window.location.href;
	const isGuestRoute = url.split('/').slice(-1)[0].toLowerCase().includes('guest');

	React.useEffect(() => {
		if (props.profile?.id)
			props.statisticUnaccepted({ departmentId: 0, profileId: props.profile?.id });
	}, [props.profile?.id]);

	React.useEffect(() => {
		if (props.role === 'sudo-admin') props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
	}, [props.role]);

	const getCount = (obj) => {
		let count = false;
		for (let elm in obj) count = count || obj[elm] > 0;
		return count;
	};

	const checkTruongDonVi = (profile) => {
		var isLeader = false;
		if (
			props.role !== 'sudo-admin' &&
			props.profileDepartments &&
			props.profileDepartments.length > 0
		) {
			for (var i = 0; i < props.profileDepartments.length; i++) {
				const department = props.profileDepartments[i];
				const matchProfile = department.departmentProfile?.find(
					(item) => item.profileId === profile.id
				);
				if (matchProfile.roleInDepartment.toLowerCase() === 'leader') {
					if (department?.isFaculty) return 'faculty';
					isLeader = true;
				}
			}
		}
		return isLeader ? 'department' : null;
	};

	const menuItem = {
		search: {
			key: 'search',
			title: 'Tra cứu tạp chí/hội thảo',
			to: ROUTER.SEARCH,
			icon: (color) => <FindInPage style={{ color }} />
		},
		work: {
			summary: {
				key: 'workSummary',
				title: 'Công trình khoa học',
				icon: (color) => (
					<Badge
						overlap="rectangular"
						color="error"
						variant="dot"
						invisible={
							(props.role === 'researcher' || props.role === 'sudo-admin') && !count && !countFilter
						}
					>
						<Box width={24} height={24} marginLeft="1px">
							<TubeIcon fill={color} width={20} height={20} />
						</Box>
					</Badge>
				)
			},
			menuList: [
				{
					key: 'work',
					to: ROUTER.WORK,
					title: 'CTKH cá nhân',
					icon: (color) => (
						<Badge
							color="error"
							variant="dot"
							overlap="rectangular"
							invisible={(props.role === 'researcher' || props.role === 'sudo-admin') && !count}
						>
							<Person style={{ color }} />
						</Badge>
					)
				},
				{
					key: 'management',
					to: ROUTER.MANAGEMENT,
					title: 'CTKH đơn vị',
					icon: (color) => (
						<Badge
							color="error"
							variant="dot"
							overlap="rectangular"
							invisible={
								props.role === 'researcher' || (props.role === 'sudo-admin' && !countFilter)
							}
						>
							<AssignmentTurnedIn style={{ color }} />
						</Badge>
					)
				}
			]
		},
		humanResources: {
			summary: {
				key: 'humanResources',
				title: 'Quản lý nhân sự',
				icon: (color) => (
					<Box width={24} height={24} marginLeft="1px">
						<HumanResourceMainIcon fill={color} width={20} height={20} />
					</Box>
				)
			},
			menuList: [
				{
					key: 'work-personal',
					to: ROUTER.HUMANRESOURCE,
					title: 'Quản lý nhân sự',
					icon: (color) => <Group style={{ color }} />
				},
				{
					key: 'work-admin',
					to: ROUTER.CONTRACT,
					title: 'Hợp đồng nhân sự',
					icon: (color) => (
						<Box width={24} height={24} marginLeft="1px">
							<ContractIcon fill={color} width={20} height={20} />
						</Box>
					)
				},
				{
					key: 'work-mod',
					to: ROUTER.DEPARTMENT,
					title: 'Quản  lý đơn vị',
					icon: (color) => (
						<Box width={24} height={24} marginLeft="1px">
							<HumanResourceIcon fill={color} width={20} height={20} />
						</Box>
					)
				}
			]
		},
		admin: {
			summary: {
				key: 'admin',
				title: 'Quản trị viên',
				icon: (color) => (
					<Box width={24} height={24} marginLeft="1px">
						<AdminIcon fill={color} width={20} height={20} />
					</Box>
				)
			},
			menuList: [
				{
					key: 'report',
					title: 'Báo cáo',
					to: ROUTER.REPORT,
					icon: (color) => <FileCopyOutlined style={{ color: color }} />
				},
				{
					key: 'notification',
					title: 'Gửi Email',
					to: ROUTER.NOTIFICATION,
					icon: (color) => <MailOutlined style={{ color: color }} />
				},
				{
					key: 'admin-setting',
					title: 'Cấu hình hệ thống',
					to: ROUTER.ADMIN_SETTING,
					icon: (color) => <Settings style={{ color: color }} />
				}
			]
		},
		studentProject: {
			summary: {
				key: 'studentProject',
				title: 'Đề tài NCKH sinh viên',
				icon: (color) => <School style={{ color }} />
			},
			menuList: [
				{
					key: 'studentProject-personal',
					to: `${ROUTER.NCKH_SINH_VIEN}/personal`,
					title: 'Đề tài cá nhân',
					icon: (color) => <Person style={{ color }} />
				},
				{
					key: 'studentProject-admin',
					to: `${ROUTER.NCKH_SINH_VIEN}/admin`,
					title: 'Duyệt đề tài',
					icon: (color) => <AssignmentTurnedIn style={{ color }} />
				},
				{
					key: 'studentProject-mod',
					to: `${ROUTER.NCKH_SINH_VIEN}/mod`,
					title: 'Danh mục đề tài',
					icon: (color) => <PlaylistAddCheck style={{ color }} />
				},
				{
					key: 'studentProject-conference',
					to: `${ROUTER.NCKH_SINH_VIEN}/conference`,
					title: 'Hội nghị NCKH',
					icon: (color) => <ChromeReaderMode style={{ color }} />
				}
			]
		},
		institutional: {
			summary: {
				key: 'institutional',
				title: 'Đề tài NCKH cơ sở',
				icon: (color) => <AccountBalance style={{ color }} />
			},
			menuList: [
				{
					key: 'institutional-personal',
					to: `${ROUTER.NCKH_CO_SO}/personal`,
					title: 'Đề tài cá nhân',
					icon: (color) => <Person style={{ color }} />
				},
				{
					key: 'institutional-admin',
					to: `${ROUTER.NCKH_CO_SO}/admin`,
					title: 'Duyệt đề tài',
					icon: (color) => <AssignmentTurnedIn style={{ color }} />
				},
				{
					key: 'institutional-mod',
					to: `${ROUTER.NCKH_CO_SO}/mod`,
					title: 'Danh mục đề tài',
					icon: (color) => <PlaylistAddCheck style={{ color }} />
				}
			]
		},
		conference: {
			key: 'conference',
			title: 'Hội nghị/hội thảo',
			to: ROUTER.PHENIKAA_CONFERENCE,
			icon: (color) => (
				<Box width={24} height={24} marginLeft="1px">
					<ConferenceIcon fill={color} width={20} height={20} />
				</Box>
			)
		},
		intelligenceProperty: {
			key: 'ip',
			title: 'Sở hữu trí tuệ',
			to: ROUTER.INTELLECTUAL_PROPERTY,
			icon: (color) => <EmojiObjects style={{ color }} />
		}
	};

	let personal = [
		menuItem.search,
		{ ...menuItem.work.summary, to: menuItem.work.menuList[0].to },
		{ ...menuItem.studentProject.summary, to: menuItem.studentProject.menuList[0].to },
		{ ...menuItem.institutional.summary, to: menuItem.institutional.menuList[0].to },
		menuItem.conference,
		menuItem.intelligenceProperty
	];

	if (props.role === 'humanresource') {
		personal.push({
			...menuItem.humanResources,
			menuList: [menuItem.humanResources.menuList[0], menuItem.humanResources.menuList[1]]
		});
	}
	if (props.role === 'sudo-admin') {
		personal[1] = menuItem.work;
		personal[2] = menuItem.studentProject;
		personal[3] = menuItem.institutional;
		personal.push(menuItem.humanResources);
		personal.push(menuItem.admin);
	}
	const checkTruongDonViVariable = checkTruongDonVi(props.profile);
	if (checkTruongDonViVariable) {
		personal[1] = menuItem.work;
		personal[3] = {
			...menuItem.institutional,
			menuList: [menuItem.institutional.menuList[0], menuItem.institutional.menuList[1]]
		};
		personal.push({
			...menuItem.humanResources.summary,
			to: menuItem.humanResources.menuList[2].to
		});
	}
	if (checkTruongDonViVariable === 'faculty') {
		personal[2] = {
			...menuItem.studentProject,
			menuList: [menuItem.studentProject.menuList[0], menuItem.studentProject.menuList[1]]
		};
		personal[3] = {
			...menuItem.institutional,
			menuList: [menuItem.institutional.menuList[0], menuItem.institutional.menuList[1]]
		};
	}

	const count = getCount(props.statisticUnacceptedDetail);
	const countFilter = getCount(props.statisticUnacceptedDetailFilter);

	if (isGuestRoute && !props.profile) return null;

	return (
		<>
			<Box id="sidebar" display={{ xs: 'none', md: 'flex' }} style={{ width: open ? 250 : 80 }}>
				<MenuList id="menu">
					<Box id="logo-text">
						<NavLink to="/" style={{ textDecoration: 'none' }}>
							<img src={nckh} alt="logo" width={64} />
						</NavLink>
					</Box>
					<CustomMenuItem open={open} animText={props.animText} menu={personal} />
				</MenuList>
				<Box id="open-btn" className={styles.footerWrapper}>
					{open ? (
						<img className={props.animText} alt="footer" src={footer} />
					) : (
						<img className={styles.animText} alt="footer" src={footerMini} />
					)}
				</Box>
			</Box>

			<Drawer open={open && window.innerWidth < 960} onClose={() => props.setOpen(false)}>
				<Box id="sidebar-mobile" style={{ width: 250 }}>
					<MenuList id="menu">
						<Box id="logo-text">
							<NavLink to="/" style={{ textDecoration: 'none' }}>
								<img src={nckh} alt="logo" width={64} />
							</NavLink>
						</Box>
						<CustomMenuItem open={true} animText={true} menu={personal} />
					</MenuList>
					<Box id="open-btn">
						<img alt="footer" src={footer} />
					</Box>
				</Box>
			</Drawer>
		</>
	);
}

const mapStateToProps = (state) => ({
	isFetchingAccepted: select(state, 'profileReducer', 'isFetchingAccepted'),
	isFetchingAcceptedPatent: select(state, 'patentReducer', 'isFetchingAccepted'),
	isFetchingAcceptedProject: select(state, 'projectReducer', 'isFetchingAccepted'),
	isFetchingAcceptedContract: select(state, 'contractReducer', 'isFetchingAccepted'),
	isFetchingAcceptedSolution: select(state, 'solutionReducer', 'isFetchingAccepted'),
	isFetchingAcceptedMonograph: select(state, 'monographReducer', 'isFetchingAccepted'),
	isFetchingAcceptedScientificResearch: select(
		state,
		'scientificResearchReducer',
		'isFetchingAccepted'
	),
	isFetchingAcceptedScientificResearchOther: select(
		state,
		'scientificResearchOtherReducer',
		'isFetchingAccepted'
	),

	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	profileDepartments: select(state, 'profileReducer', 'departments'),
	statisticUnacceptedDetail: select(state, 'profileReducer', 'statisticUnacceptedDetail'),
	statisticUnacceptedDetailFilter: select(
		state,
		'profileReducer',
		'statisticUnacceptedDetailFilter'
	)
});

const mapDispatchToProps = (dispatch) => ({
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(Sidebar));
