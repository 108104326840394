import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	Snackbar,
	FormControl,
	OutlinedInput,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Lock, Autorenew } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import { updatePassword } from '../../../reducer/user/action';

function ChangPasswordModal(props) {
	const { updatingPassword, setUpdatingPassword } = props;

	const [noti, setNoti] = useState(null);
	const [focus, setFocus] = useState(false);
	const [values, setValues] = useState(null);

	const handleReset = () => setValues(null);
	const handleChangeForm = (name, value) => setValues({ ...values, [name]: value });

	const handleSubmit = (e) => {
		e.preventDefault();
		props.updatePassword({
			oldPassword: values?.oldPassword,
			newPassword: values?.newPassword,
			userId: props.userId
		});
		setUpdatingPassword(false);
		handleReset();
	};

	return (
		<>
			<Dialog open={updatingPassword} keepMounted onClose={() => setUpdatingPassword(false)}>
				<DialogTitle style={{ backgroundColor: '#151B26', color: '#cbd4db' }}>
					Đổi mật khẩu
				</DialogTitle>
				<DialogContent style={{ overflowY: 'unset' }}>
					<TextField
						fullWidth
						size="small"
						type="password"
						label="Mật khẩu cũ"
						variant="outlined"
						value={values?.oldPassword}
						style={{ marginTop: 8 }}
						onChange={(event) => handleChangeForm('oldPassword', event.target.value)}
					/>
					<TextField
						fullWidth
						size="small"
						type="password"
						variant="outlined"
						label="Mật khẩu mới"
						value={values?.newPassword}
						style={{ marginTop: 8 }}
						onChange={(event) => handleChangeForm('newPassword', event.target.value)}
					/>
					<TextField
						fullWidth
						size="small"
						type="password"
						variant="outlined"
						error={focus && values?.newPassword !== values?.confirmPassword}
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
						label="Xác nhận mật khẩu"
						value={values?.confirmPassword}
						style={{ marginTop: 8 }}
						onChange={(event) => handleChangeForm('confirmPassword', event.target.value)}
					/>
				</DialogContent>
				<DialogActions style={{ padding: '8px 24px' }}>
					<Button variant="outlined" color="secondary" onClick={() => setUpdatingPassword(false)}>
						Huỷ
					</Button>
					<Button
						disabled={!values?.newPassword || values?.newPassword !== values?.confirmPassword}
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Đồng ý
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar open={noti !== null} autoHideDuration={6000} onClose={() => setNoti(null)}>
				<Alert variant="filled" severity={noti?.type} onClose={() => setNoti(null)}>
					{noti?.message}
				</Alert>
			</Snackbar>
		</>
	);
}

const mapStateToProps = (state) => ({
	userId: select(state, 'userReducer', 'userId')
});

const mapDispatchToProps = (dispatch) => ({
	updatePassword: (payload) => dispatch(updatePassword(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ChangPasswordModal));
