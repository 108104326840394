import dayjs from 'dayjs';
import { Grid, Button, TextField, CardActions, CardContent } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import KPTimePicker from '../../../components/KPTimePicker';
import { updateProfile } from '../../../reducer/profile/action';

function ProfileUpdateForm(props) {
	const [values, setValues] = useState(null);

	const dateFormat = (value) => {
		const dateForm = value?.replaceAll('/', '');
		const date = dateForm?.substr(0, 2);
		const month = dateForm?.substr(2, 2);
		const year = dateForm?.substr(-4);
		return value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null;
	};

	const handleReset = useCallback(() => {
		if (props.profile)
			setValues({
				...props.profile,
				birth: moment(props.profile.birth).format('DD/MM/YYYY')
			});
	}, [props.profile]);

	const handleChangeForm = (name, value) => {
		setValues({ ...values, [name]: value });
	};

	const handleSubmit = () => {
		props.updateProfile(props.profile.id, {
			...values,
			birth: dateFormat(values.birth),
			graduatedYearPhd: parseInt(values.graduatedYearPhd),
			numGuideStudentSchool: parseInt(values.numGuideStudentSchool),
			numGuideStudentMinistry: parseInt(values.numGuideStudentMinistry)
		});

		props.setEditting(false);
	};

	useEffect(() => {
		handleReset();
	}, [handleReset]);

	return (
		<>
			<CardContent
				id="card-content-asdck"
				style={{
					flexGrow: 1,
					display: !props.editting ? 'none' : 'block'
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							required
							size="small"
							label="Họ và tên"
							variant="outlined"
							value={values?.name || ''}
							onChange={(event) => handleChangeForm('name', event.target.value)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							required
							fullWidth
							size="small"
							label="Mã PU"
							variant="outlined"
							value={values?.code || ''}
							onChange={(event) => handleChangeForm('code', event.target.value)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							select
							required
							fullWidth
							size="small"
							label="Giới tính"
							variant="outlined"
							value={values?.gender || ''}
							onChange={(event) => handleChangeForm('gender', event.target.value)}
							SelectProps={{ native: true }}
						>
							<option value={null} />
							<option value="male">Nam</option>
							<option value="female">Nữ</option>
							<option value="other">Khác</option>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<KPTimePicker
							fullWidth
							label="Ngày sinh *"
							value={values?.birth || 'dd/mm/yyyy'}
							setValue={(newValue) => handleChangeForm('birth', newValue)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							size="small"
							label="Số CMND"
							variant="outlined"
							value={values?.identityNumber || ''}
							onChange={(event) => handleChangeForm('identityNumber', event.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							size="small"
							label="Số điện thoại"
							variant="outlined"
							value={values?.phone || ''}
							onChange={(event) => handleChangeForm('phone', event.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							multiline
							rows={3}
							fullWidth
							size="small"
							label="Địa chỉ"
							variant="outlined"
							value={values?.address || ''}
							onChange={(event) => handleChangeForm('address', event.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							disabled
							required
							fullWidth
							type="email"
							size="small"
							label="Email"
							variant="outlined"
							value={values?.mail || ''}
							onChange={(event) => handleChangeForm('mail', event.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							select
							required
							fullWidth
							size="small"
							label="Học vị"
							variant="outlined"
							value={values?.degree || ''}
							SelectProps={{ native: true }}
							onChange={(event) => handleChangeForm('degree', event.target.value)}
						>
							<option value={null} />
							<option value={'Tiến sĩ'}>Tiến sĩ</option>
							<option value={'Thạc sĩ'}>Thạc sĩ</option>
							<option value={'Cử nhân - Kỹ sư'}>Cử nhân - Kỹ sư</option>
							<option value={'Khác'}>Khác</option>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							select
							required
							fullWidth
							size="small"
							label="Học hàm"
							variant="outlined"
							value={values?.academicRank || ''}
							SelectProps={{ native: true }}
							onChange={(event) => handleChangeForm('academicRank', event.target.value)}
						>
							<option value={null} />
							<option value={'Giáo sư'}>Giáo sư</option>
							<option value={'Phó giáo sư'}>Phó giáo sư</option>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							type="number"
							size="small"
							label="Năm tốt nghiệp PhD"
							variant="outlined"
							value={values?.graduatedYearPhd || ''}
							onChange={(event) =>
								handleChangeForm('graduatedYearPhd', parseInt(event.target.value))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							size="small"
							label="Chuyên ngành"
							variant="outlined"
							value={values?.major || ''}
							onChange={(event) => handleChangeForm('major', event.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							size="small"
							label="Chuyên ngành hẹp"
							variant="outlined"
							value={values?.profession || ''}
							onChange={(event) => handleChangeForm('profession', event.target.value)}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions
				style={{
					display: !props.editting ? 'none' : 'flex',
					padding: 16,
					backgroundColor: '#fff',
					position: 'sticky',
					bottom: 0,
					width: 'calc(100% - 32px)',
					zIndex: 100
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Button
							fullWidth
							color="secondary"
							variant="outlined"
							onClick={() => props.setEditting(false)}
						>
							Huỷ
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth color="primary" variant="contained" onClick={handleSubmit}>
							Lưu
						</Button>
					</Grid>
				</Grid>
			</CardActions>
		</>
	);
}

const mapStateToProps = (state) => ({
	profile: select(state, 'profileReducer', 'detail')
});

const mapDispatchToProps = (dispatch) => ({
	updateProfile: (id, payload) => dispatch(updateProfile(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ProfileUpdateForm));
