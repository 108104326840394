import {
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Select,
	Box,
	MenuItem
} from '@material-ui/core';
import { REWARD_V2_TYPES } from '../../../../constants/selectOptions';
import {
	getRewardInformationV2,
	postRewardInformationV2,
	updateTypeFilter
} from '../../../../reducer/rewardInformation/action';
import toJS from '../../../../hoc/toJS';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Label } from '@material-ui/icons';
import select from '../../../../utils/select';

function RewardInformationInsertModal(props) {
	const { inserting, setInserting, postRewardInformationV2, typeFilter } = props;
	const [inputs, setInputs] = useState({
		type: props.typeFilter,
		condition: '',
		value: '',
		description: ''
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: value
		}));
	};

	const handleSelectChange = (event) => {
		setInputs((prevInputs) => ({
			...prevInputs,
			type: event.target.value
		}));
	};

	const handleCancel = () => {
		setInputs({
			type: 'scientificResearch',
			condition: '',
			value: '',
			description: ''
		});
		setInserting(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { type, condition, value, description } = inputs;
		const payload = {
			type,
			condition,
			value: parseInt(value.replace(/\D/g, '')),
			description
		};
		postRewardInformationV2(payload).then(() => {
			props.handleReload();
			props.updateTypeFilter(type);
		});

		handleCancel();
	};

	return (
		<Dialog open={inserting} onClose={() => setInserting(false)} maxWidth="sm" fullWidth>
			<DialogTitle>Thêm Quy Định Mức Thưởng</DialogTitle>
			<DialogContent>
				<Box p={3}>
					<Typography variant="h5" gutterBottom>
						Thêm Quy Định Mức Thưởng
					</Typography>
					<Box display="flex" flexDirection="column" mt={2}>
						<Select
							label="Loại mức thưởng"
							name="type"
							value={inputs.type}
							onChange={handleSelectChange}
							variant="outlined"
							fullWidth
							margin="normal"
							style={{ marginBottom: '16px' }}
						>
							{REWARD_V2_TYPES.map((e) => (
								<MenuItem value={e.value}>{e.name}</MenuItem>
							))}
						</Select>
						<TextField
							label="Điều kiện"
							variant="outlined"
							name="condition"
							value={inputs.condition}
							onChange={handleInputChange}
							margin="normal"
							style={{ marginBottom: '16px' }}
						/>
						<TextField
							label="Mức thưởng"
							variant="outlined"
							name="value"
							value={inputs.value}
							onChange={handleInputChange}
							margin="normal"
							style={{ marginBottom: '16px' }}
							type="number"
						/>
						<TextField
							label="Mô tả"
							variant="outlined"
							name="description"
							value={inputs.description}
							onChange={handleInputChange}
							margin="normal"
							style={{ marginBottom: '16px' }}
						/>
						<Box display="flex" alignItems="center">
							<Button
								variant="outlined"
								color="default"
								onClick={handleCancel}
								style={{ marginRight: '8px' }}
							>
								HỦY
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={!inputs.type || !inputs.condition || !inputs.value || !inputs.description}
							>
								XÁC NHẬN
							</Button>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary">
					Đóng
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	typeFilter: select(state, 'rewardInformationReducer', 'typeFilter')
});

const mapDispatchToProps = (dispatch) => ({
	updateTypeFilter: (type) => dispatch(updateTypeFilter(type)),
	postRewardInformationV2: (payload) => dispatch(postRewardInformationV2(payload)),
	getRewardInformationV2: () => dispatch(getRewardInformationV2())
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(RewardInformationInsertModal));
