import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	TextField
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTable from '../../../components/KPTable';
import ConferenceUpdateModal from '../ConferenceUpdateModal';
import ConferenceDeleteModal from '../ConferenceDeleteModal';
import { rankConference } from '../../../constants/data';
import { importConferenceByYear } from '../../../reducer/conference/action';

function ConferenceTable(props) {
	const [updating, setUpdating] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [uploadYear, setUploadYear] = useState(null);
	const [files, setFiles] = useState([]);

	const columns = [
		// {
		//   title: <b>ID</b>,
		//   key: 'id',
		//   width: '10%',
		//   sorter: (a, b) => a?.id - b?.id,
		// },
		{
			title: <b>Tên hội thảo</b>,
			key: 'titleWithAcronym',
			width: props.level === 'international' ? '40%' : '25%',
			searchable: true,
			render: (value, row) => `${value} (${row.year})`
		},
		{
			title: <b>Nguồn</b>,
			key: 'source',
			width: '15%'
		},
		{
			title: <b>Xếp hạng</b>,
			key: 'rank',
			width: '15%',
			type: 'include',
			filters: rankConference,
			onFilter: (value, record) => record.filter((e) => value.includes(e.rank))
		},
		// {
		//   title: <b>Thời gian tổ chức</b>,
		//   key: 'organizeAt',
		//   width: '15%',
		//   render: (value) => {
		//     var d = new Date(value);
		//     return `${d.getDate()} / ${d.getMonth() + 1} / ${d.getFullYear()}`;
		//   }
		// },
		{
			title: <b>Tác vụ</b>,
			key: 'action',
			width: '20%',
			render: (value, row) => (
				<>
					<Typography
						className="table-action-buton"
						variant="caption"
						color="primary"
						onClick={() => handleUpdate(row)}
					>
						Chi tiết
					</Typography>
					{props.role === 'sudo-admin' && row.level === 'domestic' ? (
						<>
							<Typography variant="caption"> | </Typography>
							<Typography
								className="table-action-buton"
								variant="caption"
								color="secondary"
								onClick={(event) => handleDelete(event, row)}
							>
								Xoá
							</Typography>
						</>
					) : (
						<> </>
					)}
				</>
			)
		}
	];

	const domesticColumns = [
		{
			title: <b>Tên hội thảo</b>,
			key: 'title',
			width: props.level === 'international' ? '40%' : '25%',
			searchable: true,
			render: (value, row) => `${value} (${row.year})`
		},
		{
			title: <b>Đơn vị tổ chức</b>,
			key: 'organizeBy',
			width: '15%'
		},
		{
			title: <b>Tác vụ</b>,
			key: 'action',
			width: '20%',
			render: (value, row) => (
				<>
					<Typography
						className="table-action-buton"
						variant="caption"
						color="primary"
						onClick={() => handleUpdate(row)}
					>
						Chi tiết
					</Typography>
					{props.role === 'sudo-admin' && row.level === 'domestic' ? (
						<>
							<Typography variant="caption"> | </Typography>
							<Typography
								className="table-action-buton"
								variant="caption"
								color="secondary"
								onClick={(event) => handleDelete(event, row)}
							>
								Xoá
							</Typography>
						</>
					) : (
						<> </>
					)}
				</>
			)
		}
	];

	if (props.level === 'international') columns.splice(3, 1);

	const handleUpdate = (e) => setUpdating(e);

	const handleDelete = (event, e) => {
		setDeleting(e.id);
		setAnchorEl(event.currentTarget);
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleImport = () => {
		if (uploadYear && files.length !== 0) {
			props.importConferenceByYear(uploadYear, files);
			handleClose();
		} else alert('Vui lòng nhập năm và chọn file để upload');
	};

	return (
		<>
			<KPTable
				shadow="none"
				rowsPerPage={10}
				maxHeight="calc(100vh - 278px)"
				columns={props.level === 'international' ? columns : domesticColumns}
				rows={props.list
					.filter((e) => {
						if (props.year === 0) {
							if (e.level === props.level) return e;
						} else {
							if (e.level === props.level && e.year === props.year) return e;
						}
					})
					.map((item) => {
						return {
							...item,
							titleWithAcronym: `(${item?.acronym ?? 'N/A'}) ${item?.title ?? 'N/A'}`
						};
					})}
			/>

			{props.role === 'sudo-admin' && props.level === 'international' && (
				<Button
					color="primary"
					variant="contained"
					startIcon={<Replay />}
					style={{ left: 16, bottom: 44 }}
					onClick={handleClickOpen}
				>
					Upload dữ liệu
				</Button>
			)}

			<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					Upload dữ liệu hội thảo quốc tế mới
				</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>
						Việc cập nhật dữ liệu sẽ tốn rất nhiều thời gian và yêu cầu đường truyền mạng ổn định do
						lượng dữ liệu lớn!
					</Typography>
					<Typography gutterBottom>Bạn có xác nhận việc cập nhật dữ liệu?</Typography>
					<div style={{ textAlign: 'center', marginBottom: 10 }}>
						<div style={{ display: 'inline-block', paddingTop: 8, marginRight: 10 }}>
							Cập nhật dữ liệu cho năm:{' '}
						</div>
						<TextField
							variant="outlined"
							size="small"
							style={{ width: 175, marginRight: 10 }}
							label="Năm"
							value={uploadYear}
							onChange={(event) => setUploadYear(parseInt(event.target.value))}
						/>
					</div>
					<DropzoneArea
						onChange={(files) => setFiles(files)}
						showPreviews={true}
						showPreviewsInDropzone={false}
						useChipsForPreview
						previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
						// previewChipProps={{classes: { root: classes.previewChip } }}
						previewText="File đã chọn:"
						filesLimit={1}
						defaultFiles={files}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="primary" variant="contained" onClick={handleImport}>
						Xác nhận
					</Button>
				</DialogActions>
			</Dialog>
			<ConferenceUpdateModal updating={updating} setUpdating={setUpdating} />
			<ConferenceDeleteModal deleting={deleting} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
		</>
	);
}

const mapStateToProps = (state) => ({
	list: select(state, 'conferenceReducer', 'list'),
	role: select(state, 'userReducer', 'role')
});

const mapDispatchToProps = (dispatch) => ({
	importConferenceByYear: (year, file) => dispatch(importConferenceByYear(year, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ConferenceTable));
