import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Chip, Tooltip, TextField, Typography, InputAdornment } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTable from '../../../components/KPTable';
import SolutionUpdateModal from '../SolutionUpdateModal';
import SolutionDeleteModal from '../SolutionDeleteModal';

import { statisticUnaccepted } from '../../../reducer/profile/action';
import { acceptSolution, updateSolution } from '../../../reducer/solution/action';
import { changePages } from '../../../reducer/home/action';

function SolutionTable(props) {
	const [editting, setEditting] = useState(null);
	const [updating, setUpdating] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleUpdate = (e) => setUpdating(e);
	const handleEditYear = (e) => setEditting(e);

	const handleDelete = (event, e) => {
		setDeleting(e.id);
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (value) => setEditting({ ...editting, dashboardYear: value });

	const handleAccept = (e) => {
		props.acceptSolution(e.id, !e.accepted).then((success) => {
			if (success) {
				props.statisticUnaccepted({ departmentId: 0, profileId: props.profile?.id });
				if (props.role === 'sudo-admin')
					props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
			}
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		props.updateSolution(editting.id, editting);
		setEditting(null);
	};

	const handleClear = () => {
		props.updateSolution(editting.id, { ...editting, dashboardYear: null });
		setEditting(null);
	};

	const list = [];
	if (props.type === 'all') props.listSolution.forEach((e) => list.push(e.solution));
	else props.listSolutionById.forEach((e) => list.push(e.solution));

	return (
		<>
			<KPTable
				shadow="none"
				rowsPerPage={10}
				maxHeight="calc(100vh - 288px)"
				columns={[
					{
						title: <b>Tên giải pháp</b>,
						key: 'title',
						width: props.role === 'sudo-admin' ? '20%' : '30%',
						searchable: true,
						sorter: (a, b) => a.title.toUpperCase().localeCompare(b.title.toUpperCase())
					},
					{
						title: <b>Nhóm tác giả</b>,
						key: 'authorTeam',
						width: '20%'
					},
					{
						title: <b>Mục đích</b>,
						key: 'purposeId',
						width: '15%',
						type: 'include',
						filters: props.purposes.map((e) => ({ value: e.id, text: e.description })),
						onFilter: (value, row) => row.filter((e) => value.includes(e.purposeId)),
						render: (value) => props.purposes.find((e) => e.id === value)?.description
					},
					props.role === 'sudo-admin' && props.type === 'all'
						? {
								title: <b>Năm hiển thị</b>,
								key: 'dashboardYear',
								width: '12%',
								searchable: true,
								render: (value, row) =>
									row?.id !== editting?.id ? (
										<Tooltip title="Click để chỉnh sửa năm">
											<div style={{ cursor: 'pointer' }} onClick={() => handleEditYear(row)}>
												{value > 0 ? value : 'Chưa có năm hiển thị'}
											</div>
										</Tooltip>
									) : (
										<form onSubmit={handleSubmit}>
											<TextField
												fullWidth
												size="small"
												type="tel"
												variant="outlined"
												value={editting?.dashboardYear || ''}
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															style={{ cursor: 'pointer' }}
															onClick={handleClear}
														>
															<Clear style={{ fontSize: 18 }} />
														</InputAdornment>
													)
												}}
												onChange={(e) => handleChange(parseInt(e.target.value))}
											/>
										</form>
									)
							}
						: {
								key: 'dashboardYear',
								width: '0%',
								render: () => null
							},
					{
						title: <b>Trạng thái</b>,
						key: 'accepted',
						width: '15%',
						render: (value) => (
							<Chip
								size="small"
								color={value ? 'primary' : 'default'}
								label={value ? 'Đã được duyệt' : 'Chưa được duyệt'}
								variant="outlined"
							/>
						),
						type: 'include',
						filters: [
							{
								text: 'Đã được duyệt',
								value: true
							},
							{
								text: 'Chưa được duyệt',
								value: false
							}
						],
						onFilter: (value, row) => row.filter((e) => value.includes(e.accepted))
					},
					{
						title: <b>Tác vụ</b>,
						key: 'action',
						width: '20%',
						render: (value, row) =>
							row?.id !== editting?.id ? (
								<>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="primary"
										onClick={() => handleUpdate(row)}
									>
										Chi tiết
									</Typography>
									{row.locked ? (
										<Typography
											className="table-action-buton"
											variant="caption"
											color="textPrimary"
										>
											{`Đã khóa ${
												(row.locked === 'kpi' && 'KPI') ||
												(row.locked === 'reward' && 'thưởng') ||
												(row.locked === 'timeResearch' && 'giờ nghiên cứu')
											}`}
										</Typography>
									) : (
										<>
											{(props.type !== 'all' || props.role === 'sudo-admin') && (
												<Typography
													className="table-action-buton"
													variant="caption"
													color="secondary"
													onClick={(event) => handleDelete(event, row)}
												>
													Xoá
												</Typography>
											)}
											{props.role === 'sudo-admin' &&
												props.filter !== 'workingPaper' &&
												row.purposeId !== 4 && (
													<>
														<Typography
															className="table-action-buton"
															variant="caption"
															color="primary"
															onClick={(event) => handleAccept(row)}
														>
															{row.accepted ? 'Hủy xác nhận' : 'Xác nhận'}
														</Typography>
													</>
												)}
										</>
									)}
								</>
							) : (
								<>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="primary"
										onClick={handleSubmit}
									>
										Lưu
									</Typography>
									<Typography
										className="table-action-buton"
										variant="caption"
										color="secondary"
										onClick={() => setEditting(null)}
									>
										Huỷ
									</Typography>
								</>
							)
					}
				]}
				rows={list
					.sort((a) => (!a.accepted ? -1 : 1))
					.slice(10 * props.workPages[1], 10 * (props.workPages[1] + 1))}
				totalData={list?.length}
				loading={props.isFetching}
				page={props.workPages[1]} // Ngu, nhưng nó chạy được
				onPageChange={(newPage) => props.changePages(1, newPage - 1)}
			/>
			<SolutionUpdateModal
				adminTab={props.adminTab}
				updating={updating}
				setUpdating={setUpdating}
			/>
			<SolutionDeleteModal deleting={deleting} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	workPages: select(state, 'homeReducer', 'workPages'),
	isFetching: select(state, 'solutionReducer', 'isFetching'),
	listSolution: select(state, 'solutionReducer', 'listFiltered'),
	listSolutionById: select(state, 'solutionReducer', 'listById'),
	purposes: select(state, 'scientificResearchReducer', 'purposes')
});

const mapDispatchToProps = (dispatch) => ({
	acceptSolution: (id, accept) => dispatch(acceptSolution(id, accept)),
	updateSolution: (id, payload) => dispatch(updateSolution(id, payload)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	changePages: (id, newPage) => dispatch(changePages(id, newPage))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(SolutionTable));
