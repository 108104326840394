import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Grid,
	Card,
	Menu,
	Backdrop,
	MenuItem,
	Snackbar,
	withStyles,
	Typography,
	CircularProgress,
	IconButton,
	Divider,
	Avatar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { ExitToApp, ExpandMore, MenuOutlined } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import ChangePasswordModal from '../UpdatePasswordModal';
import ProfileUpdateModal from '../../HumanResource/ProfileUpdateModal';

import { logout, refreshToken } from '../../../reducer/user/action';
import { notification, createHistory } from '../../../reducer/home/action';

import './AppHeader.css';
import noImage from '../../../assets/noimage.png';

function AppHeader(props) {
	const history = useHistory();
	const { noti, notification } = props;

	const url = window.location.href;
	const isGuestRoute = url.split('/').slice(-1)[0].toLowerCase().includes('guest');

	const [anchorEl, setAnchorEl] = useState(null);
	const [updatingPassword, setUpdatingPassword] = useState(false);

	useEffect(() => {
		if (props.history === null) props.createHistory(history);
		if (props.role === null && localStorage.getItem('refresh_token')) {
			props
				.refreshToken({ refresh_token: localStorage.getItem('refresh_token') })
				.then((res) => {
					if (!res) logout();
				})
				.catch();
		}
	}, []);

	useEffect(() => {
		if (props.history !== null && !localStorage.getItem('access_token') && !isGuestRoute)
			props.history.push(ROUTER.LOGIN);
	}, [props.history]);

	const getFirstname = (_fullname) => {
		const fullname = _fullname || '';
		const nameList = fullname.split(' ');
		const firstName = nameList[nameList.length - 1];
		return firstName;
	};

	// Handle select user button
	const handleUser = () => {
		props.history.push(ROUTER.USER);
		handleClose();
	};

	// Handle open menu
	const handleClick = (event) => {
		if (localStorage.getItem('access_token')) {
			setAnchorEl(event.currentTarget);
		} else {
			history.push(ROUTER.LOGIN);
		}
	};

	// Handle close menu
	const handleClose = () => setAnchorEl(null);

	const logout = () => {
		props.logout();
		history.push(ROUTER.LOGIN);
	};

	const StyledMenu = withStyles({
		paper: {
			minWidth: 180,
			boxShadow:
				'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
		}
	})((props) => (
		<Menu
			elevation={0}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			{...props}
		/>
	));

	if (props.hidden) return null;

	return (
		<Card>
			<Grid id="user-area" container>
				<Box display={{ xs: 'inline-block', md: 'none' }}>
					<IconButton size="small" onClick={() => props.setOpen(!props.open)}>
						<MenuOutlined color="secondary" />
					</IconButton>
				</Box>
				<Box display={{ xs: 'none', md: 'inline-block' }}>
					<Box display="flex" alignItems="center">
						<IconButton
							size="small"
							onClick={() => {
								props.setOpen(!props.open);
								props.setAnimText(false);
								setTimeout(() => {
									props.setAnimText(true);
								}, 450);
							}}
						>
							<MenuOutlined color="secondary" />
						</IconButton>
						<Typography
							variant="subtitle1"
							color="primary"
							style={{ marginLeft: 8, fontWeight: 'bold', textTransform: 'uppercase' }}
						>
							Hệ thống Quản lý nghiên cứu khoa học
						</Typography>
					</Box>
				</Box>
				<div style={{ flexGrow: 1 }} />
				<Grid item onClick={handleClick} id="right-menu">
					<Avatar
						alt="avatar"
						src={
							props.profile?.avatar
								? `${process.env.REACT_APP_DOMAIN}${props.profile?.avatar}`
								: noImage
						}
						style={{ width: 32, height: 32 }}
					/>
					<Typography
						color={anchorEl ? 'secondary' : 'inherit'}
						style={{ fontWeight: 'bold', lineHeight: '32px', paddingLeft: 8 }}
					>
						{localStorage.getItem('access_token') ? props.profile?.name : 'Chưa đăng nhập'}
					</Typography>
					<ExpandMore color={anchorEl ? 'secondary' : 'inherit'} style={{ margin: '4px 0' }} />
				</Grid>
			</Grid>

			<Backdrop open={!!props.isFetchingJournal} style={{ zIndex: 10 }}>
				<CircularProgress />
			</Backdrop>

			<StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={handleUser}>
					<Avatar
						alt="avatar"
						src={
							props.profile?.avatar
								? `${process.env.REACT_APP_DOMAIN}${props.profile?.avatar}`
								: noImage
						}
						style={{ width: 32, height: 32 }}
					/>
					<Box style={{ padding: '0 16px' }}>
						<Typography variant="subtitle1">
							<b>{props.profile?.name}</b>
						</Typography>
						<Typography variant="subtitle2" style={{ color: '#6a6a6a' }}>
							Trang cá nhân
						</Typography>
					</Box>
				</MenuItem>
				<Divider style={{ margin: '4px 0' }} />
				<MenuItem onClick={logout}>
					<Box
						padding={1}
						bgcolor="#bcbcbc40"
						borderRadius="50%"
						width={18}
						height={18}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<ExitToApp fontSize="small" />
					</Box>
					<Typography variant="inherit" noWrap style={{ marginLeft: 16 }}>
						<b>Đăng xuất</b>
					</Typography>
				</MenuItem>
			</StyledMenu>

			<Snackbar
				open={noti !== null}
				autoHideDuration={noti?.duration || 3000}
				style={{ marginTop: 58 }}
				onClose={() => notification(null)}
			>
				<Alert variant="filled" severity={noti?.type} onClose={() => notification(null)}>
					{noti?.message}
				</Alert>
			</Snackbar>

			<ChangePasswordModal
				updatingPassword={updatingPassword}
				setUpdatingPassword={setUpdatingPassword}
			/>
			<ProfileUpdateModal />
		</Card>
	);
}

const mapStateToProps = (state) => ({
	noti: select(state, 'homeReducer', 'noti'),
	role: select(state, 'userReducer', 'role'),
	history: select(state, 'homeReducer', 'history'),
	profile: select(state, 'profileReducer', 'detail'),
	isFetchingPatent: select(state, 'patentReducer', 'isFetching'),
	isFetchingJournal: select(state, 'journalReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	notification: (payload) => dispatch(notification(payload)),
	refreshToken: (payload) => dispatch(refreshToken(payload)),
	createHistory: (payload) => dispatch(createHistory(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(AppHeader));
