import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function colName(n) {
	const ordA = 'a'.charCodeAt(0);
	const ordZ = 'z'.charCodeAt(0);
	const len = ordZ - ordA + 1;

	let s = '';
	while (n >= 0) {
		s = String.fromCharCode((n % len) + ordA) + s;
		n = Math.floor(n / len) - 1;
	}
	return s;
}

export const EXPORT_TIME_RESEARCH_TO_EXCEL = async (data) => {
	const today = new Date();

	const fileName = `Báo cáo giờ nghiên cứu (${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()})`;

	const Workbook = new ExcelJS.Workbook();
	const worksheet = Workbook.addWorksheet('Báo cáo giờ nghiên cứu');
	const columns = [
		{
			header: 'Họ và tên',
			key: 'name',
			width: 30
		},
		{
			header: data[0].des0,
			key: '0',
			width: 20
		},
		{
			header: data[0].des1,
			key: '1',
			width: 20
		},
		{
			header: data[0].des2,
			key: '2',
			width: 20
		},
		{
			header: data[0].des3,
			key: '3',
			width: 20
		},
		{
			header: data[0].des4,
			key: '4',
			width: 20
		},
		{
			header: data[0].des5,
			key: '5',
			width: 20
		},
		{
			header: data[0].des6,
			key: '6',
			width: 20
		},
		{
			header: data[0].des7,
			key: '7',
			width: 20
		},
		{
			header: data[0].des8,
			key: '8',
			width: 20
		},
		{
			header: data[0].des9,
			key: '9',
			width: 20
		},
		{
			header: data[0].des10,
			key: '10',
			width: 20
		},
		{
			header: data[0].des11,
			key: '11',
			width: 20
		},
		{
			header: data[0].des12,
			key: '12',
			width: 20
		},
		{
			header: data[0].des13,
			key: '13',
			width: 20
		},
		{
			header: data[0].des14,
			key: '14',
			width: 20
		},
		{
			header: data[0].des15,
			key: '15',
			width: 20
		},
		{
			header: 'Tổng thời gian',
			key: 'total',
			width: 20
		}
	];

	worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }];

	worksheet.columns = columns;

	// some styles
	worksheet.getRow(1).font = {
		bold: true,
		size: 12
	};

	// data
	data.forEach((item, rowIndex) => {
		columns.forEach((col, colIndex) => {
			worksheet.getCell(`${colName(colIndex).toUpperCase()}${rowIndex + 2}`).value =
				data[rowIndex][`${col.key}`];
		});
	});

	const buf = await Workbook.xlsx.writeBuffer();

	saveAs(new Blob([buf]), `${fileName}.xlsx`);
};

export const EXPORT_CONTRACT_REWARD_TO_EXCEL = async (contractData, rewardData, year) => {
	const today = new Date();

	const fileName = `Báo cáo giờ nghiên cứu năm ${year} (${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()})`;

	const Workbook = new ExcelJS.Workbook();
	const worksheet = Workbook.addWorksheet('Báo cáo giờ nghiên cứu');
	const columns = [
		{
			header: 'Họ và tên',
			key: 'name',
			width: 30
		},
		{
			header: 'Thời hạn hợp đồng',
			key: 'duration',
			width: 20
		},
		{
			header: 'Thời gian bắt đầu hợp đồng',
			key: 'startAt',
			width: 25
		},
		{
			header: 'Trạng thái KPI',
			key: 'completed',
			width: 20
		},
		{
			header: 'Loại',
			key: 'condition',
			width: 20
		},
		{
			header: 'Đăng ký',
			key: 'numRegister',
			width: 20
		},
		{
			header: 'Đã làm',
			key: 'numCompleted',
			width: 20
		},
		{
			header: 'Còn lại',
			key: 'numRemain',
			width: 20
		},
		{
			header: 'Thưởng',
			key: 'reward',
			width: 20
		}
	];

	worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }];

	worksheet.columns = columns;

	// some styles
	worksheet.getRow(1).font = {
		bold: true,
		size: 12
	};

	// data
	let rowIndexTable = [2];
	rewardData.forEach((item, index) => {
		rowIndexTable.push(rowIndexTable[index] + item.kpi.kpiRewardInfo.length);
	});

	rewardData.forEach((item, rowIndex) => {
		// Profile Info from A - D
		let contractInfo = contractData.filter(
			(contractItem) => contractItem.profile.id === item.profileId
		)[0];

		worksheet.mergeCells(`A${rowIndexTable[rowIndex]}:A${rowIndexTable[rowIndex + 1] - 1}`);
		worksheet.getCell(`A${rowIndexTable[rowIndex]}`).value = contractInfo.profile.name;
		worksheet.getCell(`A${rowIndexTable[rowIndex]}`).alignment = {
			horizontal: 'center',
			vertical: 'middle'
		};

		worksheet.mergeCells(`B${rowIndexTable[rowIndex]}:B${rowIndexTable[rowIndex + 1] - 1}`);
		worksheet.getCell(`B${rowIndexTable[rowIndex]}`).value =
			contractInfo.contract.duration === 1000 ? 'Vô hạn' : `${contractInfo.contract.duration} năm`;
		worksheet.getCell(`B${rowIndexTable[rowIndex]}`).alignment = {
			horizontal: 'center',
			vertical: 'middle'
		};

		worksheet.mergeCells(`C${rowIndexTable[rowIndex]}:C${rowIndexTable[rowIndex + 1] - 1}`);
		let startAtFormat = new Date(contractInfo.contract.startAt);
		worksheet.getCell(`C${rowIndexTable[rowIndex]}`).value =
			`${startAtFormat.getDate()}/${startAtFormat.getMonth() + 1}/${startAtFormat.getFullYear()}`;
		worksheet.getCell(`C${rowIndexTable[rowIndex]}`).alignment = {
			horizontal: 'center',
			vertical: 'middle'
		};

		worksheet.mergeCells(`D${rowIndexTable[rowIndex]}:D${rowIndexTable[rowIndex + 1] - 1}`);
		let completedFormat = 'Chưa hoàn thành';
		contractInfo.contract.listKpi.forEach((kpi) => {
			if (kpi.year === year && kpi.completed === true) completedFormat = 'Đã hoàn thành';
		});
		worksheet.getCell(`D${rowIndexTable[rowIndex]}`).value = completedFormat;
		worksheet.getCell(`D${rowIndexTable[rowIndex]}`).alignment = {
			horizontal: 'center',
			vertical: 'middle'
		};

		// Reward Info from E - I
		item.kpi.kpiRewardInfo.forEach((rewardInfo, rewardInfoIndex) => {
			worksheet.getCell(`E${rowIndexTable[rowIndex] + rewardInfoIndex}`).value =
				rewardInfo.condition;

			worksheet.getCell(`F${rowIndexTable[rowIndex] + rewardInfoIndex}`).value =
				rewardInfo.numRegister;

			worksheet.getCell(`G${rowIndexTable[rowIndex] + rewardInfoIndex}`).value =
				rewardInfo.numCompleted;

			worksheet.getCell(`H${rowIndexTable[rowIndex] + rewardInfoIndex}`).value =
				rewardInfo.numRemain;

			worksheet.getCell(`I${rowIndexTable[rowIndex] + rewardInfoIndex}`).value = rewardInfo.reward;
			worksheet.getCell(`I${rowIndexTable[rowIndex] + rewardInfoIndex}`).numFmt = '# ?/?';
		});
	});

	const buf = await Workbook.xlsx.writeBuffer();

	saveAs(new Blob([buf]), `${fileName}.xlsx`);
};

export const getContractRewardByYear = (year) => async (dispatch) => {
	const api = API_URLS.STATISTIC.getContractRewardByYear(year);
	dispatch({
		type: TYPE.STATISTIC.GET_REWARD_CONTRACT_BY_YEAR_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.STATISTIC.GET_REWARD_CONTRACT_BY_YEAR_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		return { success: true, data: response.data.data };
	} else {
		dispatch({
			type: TYPE.STATISTIC.GET_REWARD_CONTRACT_BY_YEAR_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		return { success: false, data: null };
	}
};

export const getFinishContractStatistic = (payload) => async (dispatch) => {
	const api = API_URLS.STATISTIC.getFinishContractStatistic();
	dispatch({
		type: TYPE.STATISTIC.GET_FINISH_CONTRACT_STATISTIC_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.STATISTIC.GET_FINISH_CONTRACT_STATISTIC_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.STATISTIC.GET_FINISH_CONTRACT_STATISTIC_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
		return false;
	}
};

export const getReviewContractStatistic = (payload) => async (dispatch) => {
	const api = API_URLS.STATISTIC.getReviewContractStatistic();
	dispatch({
		type: TYPE.STATISTIC.GET_REVIEW_CONTRACT_STATISTIC_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.STATISTIC.GET_REVIEW_CONTRACT_STATISTIC_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.STATISTIC.GET_REVIEW_CONTRACT_STATISTIC_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thất bại!'
			})
		);
		return false;
	}
};

export const getScientificResearchStatistic = (payload) => async (dispatch) => {
	const api = API_URLS.STATISTIC.getScientificResearchStatistic();
	dispatch({
		type: TYPE.STATISTIC.GET_SCIENTIFIC_RESEARCH_STATISTIC_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.STATISTIC.GET_SCIENTIFIC_RESEARCH_STATISTIC_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Lấy dữ liệu thống kê thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.STATISTIC.GET_SCIENTIFIC_RESEARCH_STATISTIC_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thống kê thất bại!'
			})
		);
		return false;
	}
};

export const getCTKHStatistic = (page, pageSize, payload) => async (dispatch) => {
	const apiScientificResearch = API_URLS.SCIENTIFIC_RESEARCH.getFilteredScientificResearch(
		page,
		pageSize
	);
	const apiSolution = API_URLS.SOLUTION.getFilteredSolution(page, pageSize);
	const apiPatent = API_URLS.PATENT.getFilteredPatent(page, pageSize);
	const apiMonograph = API_URLS.MONOGRAPH.getFilteredMonograph(page, pageSize);
	dispatch({
		type: TYPE.STATISTIC.GET_CTKH_STATISTIC_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});

	const scientificResearch = await apiCall({
		...apiScientificResearch,
		payload: {
			...payload,
			purposeId: ['0', '1', '2', '3'],
			type: [
				'chapterJournal',
				'chapterConference',
				'scientificResearchJournal',
				'scientificResearchConference',
				'chapterJournalDomestic',
				'chapterConferenceDomestic',
				'scientificResearchJournalDomestic',
				'scientificResearchConferenceDomestic'
			]
		}
	});
	const responseScientificResearch = scientificResearch.response;
	const errorScientificResearch = scientificResearch.error;

	const solution = await apiCall({ ...apiSolution, payload });
	const responseSolution = solution.response;
	const errorSolution = solution.error;

	const patent = await apiCall({ ...apiPatent, payload });
	const responsePatent = patent.response;
	const errorPatent = patent.error;

	const monograph = await apiCall({ ...apiMonograph, payload });
	const responseMonograph = monograph.response;
	const errorMonograph = monograph.error;
	if (
		!errorScientificResearch &&
		responseScientificResearch.status === 200 &&
		!errorSolution &&
		responseSolution.status === 200 &&
		!errorPatent &&
		responsePatent.status === 200 &&
		!errorMonograph &&
		responseMonograph.status === 200
	) {
		dispatch({
			type: TYPE.STATISTIC.GET_CTKH_STATISTIC_SUCCESS,
			payload: {
				dataScientificResearch: responseScientificResearch.data.data,
				dataSolution: responseSolution.data.data,
				dataPatent: responsePatent.data.data,
				dataMonograph: responseMonograph.data.data
			},
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Lấy dữ liệu thống kê thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.STATISTIC.GET_CTKH_STATISTIC_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu thống kê thất bại!'
			})
		);
		return false;
	}
};

export const getRewardContractStatistic = (page, pageSize, payload) => async (dispatch) => {
	const api = API_URLS.STATISTIC.getRewardContractStatistic(page, pageSize);
	dispatch({
		type: TYPE.STATISTIC.GET_REWARD_CONTRACT_STATISTIC_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.STATISTIC.GET_REWARD_CONTRACT_STATISTIC_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Lấy dữ liệu chốt thưởng theo năm thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.STATISTIC.GET_REWARD_CONTRACT_STATISTIC_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Lấy dữ liệu chốt thưởng theo năm thất bại!'
			})
		);
		return false;
	}
};

export const updateKpiCompleted = (year, completed, reloadPayload) => async (dispatch) => {
	const api = API_URLS.STATISTIC.handleUpdateKpiCompleted(year, completed);
	dispatch({
		type: TYPE.STATISTIC.UPDATE_KPI_COMPLETED_LOADING,
		meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200) {
		dispatch({
			type: TYPE.STATISTIC.UPDATE_KPI_COMPLETED_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_SUCCESS] }
		});
		dispatch(
			getRewardContractStatistic(reloadPayload.page, reloadPayload.pageSize, reloadPayload.payload)
		);
		dispatch(
			notification({
				type: 'success',
				message: 'Chốt thưởng theo năm thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.STATISTIC.UPDATE_KPI_COMPLETED_FAILURE,
			meta: { prefix: [PREFIX.STATISTIC, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: 'Chốt thưởng theo năm thất bại!'
			})
		);
		return false;
	}
};
