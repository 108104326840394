import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { matchSorter } from 'match-sorter';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { updateJournalDomestic } from '../../../reducer/journalDomestic/action';

function JournalDomesticUpdateModal(props) {
	const { updating, setUpdating, updateJournalDomestic } = props;
	const [disabled, setDisabled] = useState(true);

	const [values, setValues] = useState(null);

	useEffect(() => {
		if (updating) setValues(updating);
	}, [updating]);

	const handleChangeForm = (name, value) => {
		setValues({ ...values, [name]: value });
	};

	const handleClose = () => {
		if (!disabled) {
			setDisabled(true);
		} else {
			setUpdating(null);
			setDisabled(true);
		}
	};

	const handleSubmit = () => {
		if (disabled) {
			setDisabled(false);
		} else {
			updateJournalDomestic(values.id, { ...values });
			setUpdating(null);
			setDisabled(true);
		}
	};

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth="md"
			open={updating !== null}
			onClose={() => {
				setUpdating(null);
				setDisabled(true);
			}}
		>
			<DialogTitle>{disabled ? 'Chi tiết' : 'Cập nhật'}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							label="Tên tạp chí"
							disabled={disabled}
							value={values?.title || ''}
							onChange={(event) => handleChangeForm('title', event.target.value)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							disabled={disabled}
							label="ISSN/ISBN"
							value={values?.issn || ''}
							onChange={(event) => handleChangeForm('issn', event.target.value)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							label="Loại"
							disabled={disabled}
							value={values?.type || ''}
							onChange={(event) => handleChangeForm('type', event.target.value)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							label="Ngành"
							disabled={disabled}
							value={values?.major || ''}
							onChange={(event) => handleChangeForm('major', event.target.value)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							label="Nhà xuất bản"
							disabled={disabled}
							value={values?.publisher || ''}
							onChange={(event) => handleChangeForm('publisher', event.target.value)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							label="Điểm GS-PGS"
							disabled={disabled}
							value={values?.score || ''}
							onChange={(event) => handleChangeForm('score', event.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<div style={{ float: 'right' }}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleClose}
						style={{ marginRight: 4 }}
					>
						Huỷ
					</Button>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						{disabled ? 'Chỉnh sửa' : 'Xác nhận'}
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	updateJournalDomestic: (id, payload) => dispatch(updateJournalDomestic(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalDomesticUpdateModal));
