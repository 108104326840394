import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Button,
	Select,
	Divider,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormHelperText,
	MenuItem
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import FileUploadModal from '../../File/FileUploadModal';

import { uploadFile } from '../../../reducer/file/action';
import { insertMonograph, updateMonograph } from '../../../reducer/monograph/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import { getAllEmploymentContractByProfileId } from '../../../reducer/employmentContract/action';

import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';

function MonographInsertModal(props) {
	const { inserting, setInserting } = props;

	const [values, setValues] = useState({ purposeId: 3 });
	const [err, setErr] = useState(false);
	const [detail, setDetail] = useState(null);
	const [profileList, setProfileList] = useState([]);
	const [helperText, setHelperText] = useState(false);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [files, setFiles] = useState([]);
	const [timeResearchYear, setTimeResearchYear] = useState(2023);

	// Tự gán bản thân vào danh sách tác giả
	React.useEffect(() => {
		if (props.profile?.id && profileList.length === 0) {
			setProfileList([
				{
					profileId: props.profile.id,
					roleInMonograph: 'main'
				}
			]);
		}
	}, [inserting, props.profile?.id]);

	React.useEffect(() => {
		if (values?.type) setErr(false);
	}, [values?.type]);

	const handleChangeForm = (name, value) => {
		if (name === 'timeResearchDetailCode') {
			setValues({
				...values,
				[name]: value === 'null' ? null : value
			});
		} else setValues({ ...values, [name]: value });
	};

	const handleChangeProfileList = (index, name, value) => {
		const temp = profileList;
		const tempElm = profileList[index];
		temp.splice(index, 1, { ...tempElm, [name]: value });
		setProfileList([...temp]);
	};
	console.log(handleChangeProfileList);

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInMonograph: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		const temp = profileList;
		temp.splice(index, 1);
		setProfileList([...temp]);
	};

	const insertMonograph = async () => {
		const d = new Date();
		const tempListMember = values?.listMember?.split(',').map((author) => author.trimStart());
		let result = await props
			.insertMonograph(props.profile?.id, {
				...values,
				listMember: tempListMember,
				dashboardYear: values?.year,
				monographProfile: profileList
			})
			.then((response) => {
				if (response.success && files.length !== 0) {
					props
						.uploadFile(response.data.id, 'monograph', files)
						.then((responseUpload) => {
							if (responseUpload.success)
								props.updateMonograph(response.data.id, {
									...response.data,
									fileUrl: responseUpload.data
								});
						})
						.catch();
				}
				if (response.success) {
					props.statisticUnaccepted({
						departmentId: 0,
						profileId: props.profile?.id
					});
					if (props.role === 'sudo-admin')
						props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
				}
				return response.success;
			});
	};

	const handleSubmit = (e) => {
		if (!values?.type) {
			setErr(true);
			return;
		}

		insertMonograph().then((success) => {
			if (success) {
				props.statisticUnaccepted({
					departmentId: 0,
					profileId: props.profile?.id
				});
				if (props.role === 'sudo-admin')
					props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
			}
		});

		setValues(null);
		setProfileList([]);
		setInserting(false);
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);

	const handleUpload = (files) => {
		setFiles(files);
		handleCloseUploadModal();
	};

	const profileListFromProps = [{ id: -1, name: '' }];
	props.profileList.forEach((e) => profileListFromProps.push(e));

	var timeResearchDetailList =
		props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
			?.timeResearchDetail || [];

	timeResearchDetailList = timeResearchDetailList.filter(
		(e) => !e.allowedUpdateNum && e.type === 'monograph'
	);

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	const yearList = [];
	console.log(props.timeResearchInformationList);
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth="md"
			open={inserting}
			onClose={() => setInserting(false)}
		>
			<DialogTitle>Thêm sách</DialogTitle>
			<DialogContent>
				<div
					style={{
						display: 'flex',
						paddingRight: 16,
						alignItems: 'flex-start',
						minWidth:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? '50%' : '100%',
						maxWidth:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 960 : '100%'
					}}
				>
					<Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 10 }}>
						<Grid item xs={9}>
							<TextField
								fullWidth
								size="small"
								label="Tên sách/ Tiêu đề"
								variant="outlined"
								value={values?.title?.toString() || ''}
								onChange={(event) => handleChangeForm('title', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								type="number"
								variant="outlined"
								label="Số lượng tác giả"
								value={values?.numOfMember || ''}
								onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								label="Nhà xuất bản"
								value={values?.publisher || ''}
								onChange={(event) => handleChangeForm('publisher', event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Tác giả"
								variant="outlined"
								value={values?.listMember || ''}
								multiline
								rows={4}
								onChange={(event) => handleChangeForm('listMember', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								type="number"
								variant="outlined"
								label="Năm xuất bản"
								value={values?.year || ''}
								onChange={(event) => handleChangeForm('year', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								type="number"
								label="Số trang"
								variant="outlined"
								value={values?.pageNumber || ''}
								onChange={(event) => handleChangeForm('pageNumber', parseInt(event.target.value))}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								label="Số ISBN"
								variant="outlined"
								value={values?.isbn || ''}
								onChange={(event) => handleChangeForm('isbn', event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								size="small"
								type="number"
								label="Điểm GS-PGS"
								variant="outlined"
								value={values?.score || ''}
								onChange={(event) => handleChangeForm('score', parseFloat(event.target.value))}
							/>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth variant="outlined" size="small" error={err}>
								<InputLabel style={{ padding: '0 8px', backgroundColor: 'white' }}>
									Phân loại
								</InputLabel>
								<Select
									native
									fullWidth
									value={values?.type || ''}
									onChange={(event) => handleChangeForm('type', event.target.value)}
								>
									<option value={null} />
									<option value="chapter">Chương sách</option>
									<option value="monograph">Chuyên khảo</option>
									<option value="reference">Tham khảo</option>
									<option value="curriculum">Giáo trình</option>
								</Select>
								{err && <FormHelperText>Không được bỏ trống</FormHelperText>}
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel style={{ padding: '0 8px', backgroundColor: 'white' }}>
									Ngôn ngữ
								</InputLabel>
								<Select
									native
									fullWidth
									value={values?.language || ''}
									onChange={(event) => handleChangeForm('language', event.target.value)}
								>
									<option value={null} />
									<option value="english">Tiếng Anh</option>
									<option value="other">Khác</option>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth variant="outlined" size="small">
								<InputLabel
									id="purpose-label"
									style={{ padding: '0 8px', backgroundColor: 'white' }}
								>
									Mục đích
								</InputLabel>
								<Select
									native
									fullWidth
									labelId="purpose-label"
									value={values?.purposeId ?? 'none'}
									onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value))}
								>
									{props.purposes.map((e) => (
										<option key={e.id} value={e.id}>
											{e.description}
										</option>
									))}
								</Select>
							</FormControl>
						</Grid>

						{values?.purposeId === 5 && (
							<>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										size="small"
										value={timeResearchYear}
										SelectProps={{ native: true }}
										label="Năm học tính giờ nghiên cứu"
										variant="outlined"
										onChange={(event) => setTimeResearchYear(parseInt(event.target.value))}
									>
										{yearList.map((e) => (
											<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
												{e} - {e + 1}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item xs={9}>
									<TextField
										select
										fullWidth
										size="small"
										label="Nhiệm vụ tính giờ nghiên cứu"
										variant="outlined"
										value={values?.timeResearchDetailCode || null}
										onChange={(event) =>
											handleChangeForm('timeResearchDetailCode', event.target.value)
										}
										InputLabelProps={{
											shrink: !(
												values?.timeResearchDetailCode === undefined ||
												values?.timeResearchDetailCode === null
											)
										}}
										error={!values?.timeResearchDetailCode}
									>
										{timeResearchDetailList.length > 0 ? (
											timeResearchDetailList?.map((e) => (
												<MenuItem
													key={e.code}
													value={e.code}
												>{`${e.description} - ${e.value}(h)`}</MenuItem>
											))
										) : (
											<MenuItem value={null} disabled>
												Chưa có dữ liệu
											</MenuItem>
										)}
									</TextField>
									{!values?.timeResearchDetailCode && (
										<FormHelperText error>Phải chọn một nhiệm vụ!</FormHelperText>
									)}
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Ghi chú"
								variant="outlined"
								value={values?.note || ''}
								multiline
								rows={3}
								onChange={(event) => handleChangeForm('note', event.target.value)}
							/>
						</Grid>

						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>
						<Grid item xs={2}>
							<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
								Thành viên
							</Typography>
						</Grid>
						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>

						<Grid item xs={12}>
							{profileList.map((e, index) => (
								<Grid key={`list-${index}`} container spacing={2}>
									<Grid
										item
										xs={3}
										style={{
											display: 'flex'
										}}
									>
										<FormControl required fullWidth error={!values?.profileIdForKpi}>
											<Button
												fullWidth
												color="primary"
												variant="outlined"
												style={{ height: 40 }}
												startIcon={e.isExternalTeacher ? <CheckBox /> : <CheckBoxOutlineBlank />}
												onClick={() => {
													handleChangeProfileList(index, 'isExternalTeacher', !e.isExternalTeacher);
													handleChangeProfileList(index, 'profileId', 0);
													handleChangeProfileList(index, 'name', null);
												}}
											>
												Ngoài trường
											</Button>
										</FormControl>
									</Grid>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 3 : 3}>
										{e.isExternalTeacher ? (
											<TextField
												onChange={(event) => {
													handleChangeProfileList(index, 'name', event.target.value);
													handleChangeProfileList(index, 'profileId', 0);
													if (event.target.value) setHelperText(false);
												}}
												error={helperText && e.name === null}
												size="small"
												label="Thành viên"
												variant="outlined"
											/>
										) : (
											<Autocomplete
												disableClearable
												options={props.profileList}
												getOptionLabel={(option) => {
													if (!option) return '';
													const code = option.code || '';
													const name = option.name || '';
													if (code && name) {
														return `${code} - ${name}`;
													} else if (code) {
														return code;
													} else if (name) {
														return name;
													} else {
														return '';
													}
												}}
												value={
													profileListFromProps?.find((profile) => profile.id === e.profileId) ||
													profileListFromProps[0]
												}
												onChange={(event, profile) => {
													if (profileList[index]?.profileId === values?.profileIdForKpi)
														handleChangeForm('profileIdForKpi', null);
													handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
													handleChangeProfileList(index, 'name', profile?.name);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														label="Thành viên"
														variant="outlined"
													/>
												)}
											/>
										)}
										{helperText && e.profileId === null && (
											<FormHelperText style={{ color: 'red' }}>Không được để trống</FormHelperText>
										)}
									</Grid>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 4 : 2}>
										<FormControl fullWidth variant="outlined" size="small">
											<InputLabel
												id="roleInMonograph-label"
												style={{ padding: '0 8px', backgroundColor: 'white' }}
											>
												Vai trò
											</InputLabel>
											<Select
												native
												fullWidth
												labelId="roleInMonograph-label"
												onChange={(event) =>
													handleChangeProfileList(index, 'roleInMonograph', event.target.value)
												}
											>
												<option value="main">Chủ biên</option>
												<option value="member">Đồng tác giả</option>
												{/* <option value="mainOther">Chủ biên tính hộ</option>
												<option value="memberOther">Đồng tác giả tính hộ</option> */}
											</Select>
										</FormControl>
									</Grid>
									{values?.purposeId === 1 && (
										<Grid item xs={3}>
											<FormControl required fullWidth error={!values?.profileIdForKpi}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForKpi === 'number' &&
														values?.profileIdForKpi === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														handleChangeForm(
															'profileIdForKpi',
															values?.profileIdForKpi !== e.profileId ? parseInt(e.profileId) : null
														);
													}}
												>
													Tính KPI
												</Button>
												{!values?.profileIdForKpi && (
													<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									{values?.purposeId === 99999999 && (
										<Grid item xs={3}>
											<FormControl required fullWidth error={!values?.profileIdForReward}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForReward === 'number' &&
														values?.profileIdForReward === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														handleChangeForm(
															'profileIdForReward',
															values?.profileIdForReward !== e.profileId
																? parseInt(e.profileId)
																: null
														);
													}}
												>
													Tính thưởng
												</Button>
												{!values?.profileIdForReward && (
													<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									<Grid item xs={2}>
										<Button
											fullWidth
											color="secondary"
											variant="contained"
											style={{ height: 40 }}
											onClick={() => handleRemove(index)}
										>
											Xoá
										</Button>
									</Grid>
								</Grid>
							))}
						</Grid>

						<Grid item xs={12}>
							<Button fullWidth variant="outlined" onClick={handleInsert}>
								Thêm thành viên
							</Button>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						flexDirection: 'column',
						display:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 'block' : 'none'
					}}
				>
					<EmploymentContractTable
						contract={detail}
						contractList={contractList}
						kpiIdForKpi={values?.kpiIdForKpi}
						contractIdForKpi={values?.contractIdForKpi}
						setDetail={setDetail}
						values={values}
						setValues={setValues}
						handleChangeForm={handleChangeForm}
					/>
					<EmploymentContractDetailTable kpi={detail} />
				</div>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<Grid container justifyContent={'space-between'}>
					<div>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleOpenUploadModal}
							style={{ marginRight: 4 }}
						>
							Upload file minh chứng
						</Button>
					</div>
					<div style={{ float: 'right' }}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setInserting(false)}
							style={{ marginRight: 4 }}
						>
							Huỷ
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleSubmit()}
							disabled={values?.purposeId === 5 && !values?.timeResearchDetailCode}
						>
							Xác nhận
						</Button>
					</div>
				</Grid>
			</DialogActions>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
				defaultFiles={files}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	profile: select(state, 'profileReducer', 'detail'),
	purposes: select(state, 'scientificResearchReducer', 'purposes'),
	profileList: select(state, 'profileReducer', 'list'),
	listById: select(state, 'monographReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById')
});

const mapDispatchToProps = (dispatch) => ({
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	insertMonograph: (profileId, payload) => dispatch(insertMonograph(profileId, payload)),
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	updateMonograph: (profileId, payload) => dispatch(updateMonograph(profileId, payload)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(MonographInsertModal));
