import { useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTable from '../../../components/KPTable';

import DepartmentUpdateModal from '../DepartmentUpdateModal';
import DepartmentDeleteModal from '../DepartmentDeleteModal';

const useStyles = makeStyles((theme) =>
	createStyles({
		btnAction: {
			cursor: 'pointer'
		}
	})
);

function DepartmentTable(props) {
	const { departments, role } = props;

	const classes = useStyles();

	const [updating, setUpdating] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleDelete = (event, row) => {
		setDeleting(row.id);
		setAnchorEl(event.currentTarget);
	};

	let columns = [
		{
			title: <b>Tên đơn vị</b>,
			key: 'name',
			width: '10%',
			searchable: true,
			render: (value, row) => {
				return <Typography>{row.name}</Typography>;
			}
		},
		{
			title: <b>Địa điểm</b>,
			key: 'location',
			width: '10%',
			searchable: true,
			render: (value, row) => {
				return <Typography>{row.location}</Typography>;
			}
		},
		{
			title: <b>Hướng nghiên cứu chính</b>,
			key: 'researchArea',
			width: '15%',
			searchable: true,
			render: (value, row) => {
				return <Typography>{row.researchArea}</Typography>;
			}
		},
		{
			title: <b>Ngày thành lập</b>,
			key: 'foundedAt',
			width: '10%',
			render: (value, row) => {
				return (
					<Typography>
						{dayjs(row.foundedAt).isValid() ? dayjs(row.foundedAt).format('DD/MM/YYYY') : 'N/A'}
					</Typography>
				);
			}
		},
		{
			title: <b>Mô tả</b>,
			key: 'description',
			width: '15%',
			searchable: true,
			render: (value, row) => {
				return <Typography>{row.description}</Typography>;
			}
		}
	];

	const taskColumn = {
		title: <b>Tác vụ</b>,
		key: 'actions',
		width: '5%',
		render: (value, row) => {
			return (
				<>
					<Typography
						variant="caption"
						color="primary"
						className="table-action-buton"
						onClick={() => setUpdating(row)}
					>
						Chỉnh sửa
					</Typography>
					<Typography
						variant="caption"
						color="secondary"
						className="table-action-buton"
						onClick={(event) => handleDelete(event, row)}
					>
						Xóa
					</Typography>
				</>
			);
		}
	};

	columns = columns.concat(role === 'sudo-admin' ? taskColumn : []);

	return (
		<>
			<Box m={2} />
			<KPTable
				shadow="none"
				rowsPerPage={10}
				columns={columns}
				maxHeight="calc(100vh - 288px)"
				rows={(departments ?? []).filter(
					(departmentElement) => String(departmentElement.id) !== '0'
				)}
			/>
			<DepartmentUpdateModal updating={updating} setUpdating={setUpdating} />
			<DepartmentDeleteModal deleting={deleting} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
		</>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	departments: select(state, 'departmentReducer', 'departments')
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DepartmentTable));
